import * as React from "react";

import { Symptom } from "myjourney-frontend/src/vendor/umbraco";

import * as Hooks from "../hooks";

export const useSymptoms = (options: useSymptomsOptions = {}): {
  loading: boolean;
  symptoms: Array<Symptom>;
  graph: boolean;
} => {
  const { getSymptomsByDateRange } = Hooks.useSymptomTrackerApi();
  const hookDateRange = Hooks.useDateRange();

  const [symptoms, setSymptoms] = React.useState<Array<Symptom>>([]);
  const [graph, setGraph] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  const getSymptoms = async () => {
    const selectedDateRange = options.dateRange ?? hookDateRange.default;
    const res = await getSymptomsByDateRange({
      FromDay: selectedDateRange.start.getDate(),
      FromMonth: selectedDateRange.start.getMonth() + 1,
      FromYear: selectedDateRange.start.getFullYear(),
      ToDay: selectedDateRange.end.getDate(),
      ToMonth: selectedDateRange.end.getMonth() + 1,
      ToYear: selectedDateRange.end.getFullYear(),
    });
    if (res.status === 200) {
      const symptomsRes = await res.json();
      setSymptoms(symptomsRes.symptoms);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    getSymptoms();
  }, [options.dateRange?.start.toDateString(), options.dateRange?.end.toDateString()]);

  React.useEffect(() => {
    setGraph(
      symptoms.filter((symptom) => symptom.severityType === "range").length > 1
    );
  }, [symptoms.map((symptom) => symptom.id).join("")]);

  return {
    loading: loading,
    symptoms: symptoms,
    graph: graph,
  };
};

export type useSymptomsOptions = {
  dateRange?: {
    start: Date;
    end: Date;
  };
};
