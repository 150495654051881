import tw from "twin.macro";

const none = tw``;
export const Global = {
  border: {
    // primary
    pink: tw`border-pink`,
    blueNavy: tw`border-blue-navy`,

    // secondary
    pinkMedium: tw`border-pink-medium`,
    pinkLight: tw`border-pink-light`,
    pinkDusty: tw`border-pink-dusty`,
    blueDusty: tw`border-blue-dusty`,

    // neutral
    greyDark: tw`border-grey-dark`,
    greyMedium: tw`border-grey-medium`,
    greyLight: tw`border-grey-light`,
    greyLightest: tw`border-grey-lightest`,
    white: tw`border-white`,

    // validation
    redValidation: tw`border-red-validation`,
    greenValidation: tw`border-green-validation`,

    none,
  },
  background: {
    // primary
    pink: tw`bg-pink`,
    blueNavy: tw`bg-blue-navy`,

    // secondary
    pinkMedium: tw`bg-pink-medium`,
    pinkLight: tw`bg-pink-light`,
    pinkDusty: tw`bg-pink-dusty`,
    blueDusty: tw`bg-blue-dusty`,

    // neutral
    greyDark: tw`bg-grey-dark`,
    greyMedium: tw`bg-grey-medium`,
    greyLight: tw`bg-grey-light`,
    greyLightest: tw`bg-grey-lightest`,
    white: tw`bg-white`,

    // validation
    redValidation: tw`bg-red-validation`,
    greenValidation: tw`bg-green-validation`,

    // utility
    black: tw`bg-black`,

    none,
  },
  colour: {
    // primary
    pink: tw`text-pink`,
    blueNavy: tw`text-blue-navy`,

    // secondary
    pinkMedium: tw`text-pink-medium`,
    pinkLight: tw`text-pink-light`,
    pinkDusty: tw`text-pink-dusty`,
    blueDusty: tw`text-blue-dusty`,

    // neutral
    greyDark: tw`text-grey-dark`,
    greyMedium: tw`text-grey-medium`,
    greyLight: tw`text-grey-light`,
    greyLightest: tw`text-grey-lightest`,
    white: tw`text-white`,

    // validation
    redValidation: tw`text-red-validation`,
    greenValidation: tw`text-green-validation`,

    none,
  },
  shadow: {
    none,
    default: tw`shadow`,
    "md:default": tw`md:shadow`,
    pink: tw`shadow-pink`,
  },
  spacing: {
    none,
    xxs: tw`mb-2`,
    xs: tw`mb-4`,
    ssm: tw`mb-5`,
    sm: tw`mb-6`,
    default: tw`mb-8`,
    md: tw`mb-10`,
    lg: tw`mb-12`,
    xl: tw`mb-15`,
    xxl: tw`mb-20`,

    // new spacings
    ogxxxxxxs: tw`mb-ogxxxxxxs`,
    ogxxxxxs: tw`mb-ogxxxxxs`,
    ogxxxxs: tw`mb-ogxxxxs`,
    ogxxxs: tw`mb-ogxxxs`,
    ogxxs: tw`mb-ogxxs`,
    ogxs: tw`mb-ogxs`,
    ogsm: tw`mb-ogsm`,
    ogmd: tw`mb-ogmd`,
    oglg: tw`mb-oglg`,
    ogxl: tw`mb-ogxl`,

    og1: tw`mb-og1`,
    og2: tw`mb-og2`,
    og4: tw`mb-og4`,
    og6: tw`mb-og6`,
    og8: tw`mb-og8`,
    og10: tw`mb-og10`,
    og12: tw`mb-og12`,
  },
  maxWidth: {
    none,
    xs: tw`max-w-screen-xs`,
    sm: tw`max-w-screen-sm`,
    md: tw`max-w-screen-md`,
    lg: tw`max-w-screen-lg`,
    xl: tw`max-w-screen-xl`,
    "2xl": tw`max-w-screen-2xl`,
    default: tw`max-w-full`,
  },
  minHeight: {
    none,
    zero: tw`min-h-0`,
    full: tw`min-h-full`,
    screen: tw`min-h-screen`,
    default: none,
  },
  wrap: {
    none,
    xs: tw`flex-wrap`,
    sm: tw`sm:flex-wrap`,
    md: tw`md:flex-wrap`,
    lg: tw`lg:flex-wrap`,
  },
  nowrap: {
    none,
    xs: tw`flex-nowrap`,
    sm: tw`sm:flex-nowrap`,
    md: tw`md:flex-nowrap`,
    lg: tw`lg:flex-nowrap`,
  },
  items: {
    none,
    start: tw`items-start`,
    center: tw`items-center`,
    stretch: tw`items-stretch`,
    end: tw`items-end`,
  },
  self: {
    none,
    start: tw`self-start`,
    center: tw`self-center`,
    stretch: tw`self-stretch`,
    end: tw`self-end`,
  },
  justify: {
    start: tw`flex justify-start`,
    center: tw`flex justify-center`,
    end: tw`flex justify-end`,
    between: tw`flex justify-between`,
    none,
  },
  direction: {
    none,
    row: tw`flex flex-row`,
    column: tw`flex flex-col`,
  },
  textAlign: {
    left: tw`text-left`,
    center: tw`text-center`,
    right: tw`text-right`,
  },
  weight: {
    bold: tw`font-bold`,
    semibold: tw`font-semibold`,
    medium: tw`font-medium`,
    normal: tw`font-normal`,
  },
  gutterPadding: {
    none,
    default: tw`px-1`,
    md: tw`px-2`,
    lg: tw`px-4`,
    xl: tw`px-6`,

    "og1": tw`px-og1`,
    "og2": tw`px-og2`,
    "og4": tw`px-og4`,
    "og6": tw`px-og6`,
    "og8": tw`px-og8`,
  },
  gutterMargin: {
    none,
    default: tw`-mx-1`,
    md: tw`-mx-2`,
    lg: tw`-mx-4`,
    xl: tw`-mx-6`,

    "og1": tw`-mx-og1`,
    "og2": tw`-mx-og2`,
    "og4": tw`-mx-og4`,
    "og6": tw`-mx-og6`,
    "og8": tw`-mx-og8`,
  },
  leading: {
    none: tw`leading-normal`,
    xs: tw`leading-4 md:leading-5`,
    sm: tw`leading-5`,
    md: tw`leading-6`,
    lg: tw`leading-8`,
  },
  tracking: {
    none,
    body: tw`md:tracking-wide`,
  },
  flex: {
    none,
    "1": tw`flex-1`,
  },
  reverse: {
    none,
    xs: tw`flex-row-reverse`,
    sm: tw`sm:flex-row-reverse`,
    md: tw`md:flex-row-reverse`,
  },
  position: {
    none,
    relative: tw`relative`,
  },
  fontSize: {
    none,
    xxxs: tw`text-xxxs`,
    xxs: tw`text-xxs`,
    xs: tw`text-xs`,
    ssm: tw`text-ssm`,
    sm: tw`text-sm`,
    mdsm: tw`text-mdsm`,
    base: tw`text-base`,
    md: tw`text-md`,
    lg: tw`text-lg`,
    28: tw`text-28`,
    xl: tw`text-xl`,
    "2xl": tw`text-2xl`,
    "3xl": tw`text-3xl`,
  },
};

export type Gutters = "none" | "default" | "og1" | "og2" | "og4" | "og6" | "og8" | "md" | "lg" | "xl"
export type Colours = "none" | "pink" | "blueNavy" | "pinkMedium" | "pinkLight" | "pinkDusty" | "blueDusty" | "greyDark" | "greyMedium" | "greyLight" | "greyLightest" | "white" | "redValidation" | "greenValidation";
export type Spacings = "none" | "og1" | "og2" | "og4" | "og6" | "og8" | "og10" | "og12" | "ogxxxxxxs" | "ogxxxxxs" | "ogxxxxs" | "ogxxxs" | "ogxxs" | "ogxs" | "ogsm" | "ogmd" | "oglg" | "ogxl" | "xxs" | "xs" | "ssm" | "sm" | "md" | "lg" | "xl" | "xxl" | "default"
export type MaxWidths = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "default" | "none"
export type MinHeights = "zero" | "full" | "screen" | "none"
export type Items = "none" | "start" | "center" | "stretch" | "end"
export type Selfs = "none" | "start" | "center" | "stretch" | "end"
export type Justifys = "none" | "start" | "center" | "between" | "end"
export type Directions = "none" | "row" | "column"
export type TextAligns = "left" | "center" | "right"
export type Shadows = "default" | "md:default" | "pink" | "none"
export type Weights = "normal" | "medium" | "semibold" | "bold"
export type Wraps = "none" | "lg" | "md" | "sm" | "xs"
export type Leadings = "none" | "lg" | "md" | "sm" | "xs"
export type Trackings = "none" | "body"
export type Flexs = "none" | "1"
export type Reverses = "none" | "md" | "sm" | "xs"
export type Positions = "none" | "relative"
export type FontSizes = "none" | "xxxs" | "xxs" | "xs" | "ssm" | "sm" | "mdsm" | "base" | "md" | "lg" | "28" | "xl" | "2xl" | "3xl"
