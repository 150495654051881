import * as React from "react";
import tw, { styled } from "twin.macro";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router-dom";

import { StickyButtonWrapper } from "./styledSignUp";

import { RadioData } from "~/components/Radio/withRadio";
import { useAnalytics } from "~/hooks/useAnalytics";
import { usePatientApi } from "~/hooks/usePatientApi";
import { PatientContext } from "~/providers/PatientProvider";
import { USER_DASHBOARD, USER_WELCOME } from "~/utils/constants";
import { validName, validPostcode } from "~/utils/validation";

import { StepPrivacy } from "./StepPrivacy/StepPrivacy";
import { StepAccount } from "./StepAccount/StepAccount";
import { StepDetails } from "./StepDetails/StepDetails";
import { StepCustomize } from "./StepCustomize/StepCustomize";
import { StepConfirm } from "./StepConfirm/StepConfirm";
import { DiagnosisProps, useDiagnosis } from "~/hooks/useDiagnosis";
import { UserTypeProps, useUserTypes } from "~/hooks/useUserTypes";
import {
  AccountDetailsProps,
  useAccountDetails,
} from "~/hooks/useAccountDetails";
import { VendorSalesforce } from "@myjourney/shared";
import { generateSelectedUserTypeIds } from "~/utils/functions";
import { isAfter, isBefore, sub } from "date-fns";

import * as Utils from "~/utils";
import * as Components from "~/components";
import useGetMetastaticBreastCancerDiagnosisId from "~/hooks/useGetMetastaticBreastCancerDiagnosisId";

const steps: Array<SignUpStep> = [
  {
    label: "Why My Journey asks for your information",
    component: StepPrivacy,
    validate: () => true,
  },
  {
    label: "Create my account",
    component: StepAccount,
    validate: (data) =>
      Boolean(
        data.emailValid && data.passwordValid && data.confirmPasswordValid
      ),
  },
  {
    label: "My details",
    component: StepDetails,
    validate: (data) =>
      Boolean(
        data.firstNameValid &&
          data.lastNameValid &&
          data.dateOfBirthValid &&
          data.postcodeValid
      ),
  },
  {
    label: "Customise My Journey",
    component: StepCustomize,
    validate: (data) =>
      Boolean(
        (data.diagnosis && data.situation && data.diagnosisDateValid) ||
          (data.diagnosis === `${data.MBC_ID || Utils.MBC_DIAGNOSIS_ID}` &&
            data.treatment &&
            data.locations.length > 0)
      ),
  },
  {
    label: "Confirm my account",
    component: StepConfirm,
    validate: (data) => Boolean(data.referralUrl && data.referralMethod),
  },
];

const referralData = [
  {
    label: "BCNA website or email",
    value: "BCNA website or email",
  },
  {
    label: "BCNA podcast",
    value: "BCNA podcast",
  },
  {
    label: "BCNA event",
    value: "BCNA event",
  },
  {
    label: "BCNA Helpline",
    value: "BCNA Helpline",
  },
  {
    label: "BCNA My Care Kit",
    value: "BCNA My Care Kit",
  },
  {
    label: "Breast care nurse",
    value: "Breast care nurse",
  },
  {
    label: "Breast screen staff",
    value: "Breast screen staff",
  },
  {
    label: "Medical Oncologist",
    value: "Medical Oncologist",
  },
  {
    label: "Surgeon",
    value: "Surgeon",
  },
  {
    label: "GP",
    value: "GP",
  },
  {
    label: "Other health professional",
    value: "Other health professional",
  },
  {
    label: "Relative, friend or co-worker",
    value: "Relative, friend or co-worker",
  },
  {
    label: "Social media",
    value: "Social media",
  },
  {
    label: "Web search",
    value: "Web search",
  },
  {
    label: "Television or Radio",
    value: "Television or Radio",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const StyledStepHeader = styled.div(() => [tw`pt-og6`]);

export const SignUp: React.FunctionComponent = () => {
  const history = useHistory();
  const { patientRegister, patientSignIn, patientReferral } = usePatientApi();
  const {
    trackFormStart,
    trackFormStepComplete,
    trackFormStepView,
    trackFormInteraction,
    trackFormFieldComplete,
    trackFormError,
  } = useAnalytics();
  const diagnosisProps = useDiagnosis();
  const userTypeProps = useUserTypes();
  const accountDetailsProps = useAccountDetails();
  const { metastaticDiagnosisId } = useGetMetastaticBreastCancerDiagnosisId();
  const { auth, handleAuth } = React.useContext(PatientContext);
  const [formError, setFormError] = React.useState<FormError>();
  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [postcode, setPostcode] = React.useState<string>("");
  const [dob, setDOB] = React.useState<Date>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<number>(0);
  const [firstNameValid, setFirstNameValid] = React.useState<boolean>(false);
  const [firstNameError, setFirstNameError] = React.useState<
    JSX.Element | string | undefined
  >();
  const [lastNameValid, setLastNameValid] = React.useState<boolean>(false);
  const [lastNameError, setLastNameError] = React.useState<
    JSX.Element | string | undefined
  >();
  const [dateOfBirthError, setDateOfBirthError] = React.useState<
    JSX.Element | string | undefined
  >();
  const [dateOfBirthValid, setDateOfBirthValid] =
    React.useState<boolean>(false);
  const [postcodeError, setPostcodeError] = React.useState<
    JSX.Element | string | undefined
  >();
  const [postcodeValid, setPostcodeValid] = React.useState<boolean>(false);
  const [passwordError, setPasswordError] = React.useState<
    JSX.Element | string | undefined
  >();
  const [passwordValid, setPasswordValid] = React.useState<boolean>(false);
  const [confirmPasswordValid, setConfirmPasswordValid] =
    React.useState<boolean>(false);
  const [passwordRules, setPasswordRules] = React.useState<
    Record<string, boolean>
  >({
    "6 characters minimum": false,
    "One uppercase character": false,
    "One number": false,
    "One lowercase character": false,
    "One symbol": false,
    "Confirmation password matches": false,
  });
  const [confirmPasswordError, setConfirmPasswordError] = React.useState<
    JSX.Element | string | undefined
  >();

  const referralId =
    new URLSearchParams(history.location.search).get("referral_id") ??
    undefined;
  const [referralPatientDetails, setReferralPatientDetails] = React.useState<{
    email?: string;
    phone?: string;
  }>({});
  const [referralUrl, setReferralUrl] = React.useState<string>(
    window.location.href
  );
  const [referralMethod, setReferralMethod] =
    React.useState<VendorSalesforce.ReferralMethod>();
  const [referralLoading, setReferralLoading] = React.useState<boolean>(
    Boolean(referralId)
  );

  React.useEffect(() => {
    if (referralId) {
      setReferralLoading(true);
      (async () => {
        try {
          const response = await patientReferral({
            referral_id: referralId,
          }).then((response) => response.json());

          if (response?.success === true) {
            setReferralPatientDetails({
              email: response.data.patient_details?.email,
              phone: response.data.patient_details?.phone,
            });
            if (response.data.patient_details?.email) {
              accountDetailsProps.handleEmail(
                response.data.patient_details.email
              );
            }
            if (response.data.patient_details?.phone) {
              accountDetailsProps.handlePhone(
                response.data.patient_details.phone
              );
            }
            if (response.data.patient_details?.first_name) {
              handleFirstName(response.data.patient_details.first_name);
            }
            if (response.data.patient_details?.last_name) {
              handleLastName(response.data.patient_details.last_name);
            }
            if (response.data.patient_details?.dob) {
              handleDateOfBirth(new Date(response.data.patient_details.dob));
            }
            if (response.data.patient_details?.address_postcode) {
              handlePostcode(response.data.patient_details.address_postcode);
            }
            if (response.data.patient_details?.diagnosis_ids?.[0]?.[0]) {
              diagnosisProps.handleDiagnosis(
                response.data.patient_details.diagnosis_ids[0][0]
              );
            }
            if (response.data.patient_details?.diagnosis_date) {
              diagnosisProps.handleDiagnosisDate(
                response.data.patient_details.diagnosis_data
              );
            }
          }
        } catch (error) {
          Sentry.captureException(error);
          throw error;
        } finally {
          setReferralLoading(false);
        }
      })();
    }
  }, [referralId]);

  React.useEffect(() => {
    if (auth) {
      history.push(USER_DASHBOARD);
    }
  }, []);

  React.useEffect(() => {
    trackFormStart({
      group: "userSignup",
      label: steps[step].label,
    });
  }, []);

  React.useEffect(() => {
    trackFormStepView({
      group: "userSignup",
      step: {
        sequence: step + 1,
        label: steps[step].label,
      },
    });
  }, [step]);

  React.useEffect(() => {
    setPasswordRules({
      "6 characters minimum": password ? password.length >= 6 : false,
      "One uppercase character": password ? /[A-Z]/.test(password) : false,
      "One number": password ? /[0-9]/.test(password) : false,
      "One lowercase character": password ? /[a-z]/.test(password) : false,
      "One symbol": password ? /\W|_/gm.test(password) : false,
      "Confirmation password matches": password
        ? password === confirmPassword
        : false,
    });
  }, [password, confirmPassword]);

  for (const passwordRuleKey of Object.keys(passwordRules)) {
    React.useEffect(() => {
      if (passwordRules[passwordRuleKey] === false) {
        trackFormError({
          event: "error",
          group: "userSignup",
          label: passwordRuleKey,
          step: {
            sequence: step + 1,
            label: steps[step].label,
          },
        });
      }
    }, [passwordRules[passwordRuleKey]]);
  }

  const handleReferralMethod = (value: VendorSalesforce.ReferralMethod) =>
    setReferralMethod(value);

  const handlePrevious = () => {
    if (step === 0) {
      history.goBack();

      return;
    }

    setStep((prev) => prev - 1);
  };

  const handleNextStep = () => {
    trackFormStepComplete({
      parentGroup: "userSignup",
      step: {
        sequence: step + 1,
        label: steps[step].label,
      },
    });
    setStep((prev) => prev + 1);
    scrollTo(0, 0);
  };

  const handleFirstName = (value: string) => {
    setFirstName(value);
    setFirstNameValid(false);
    if (value === "") {
      setFirstNameError("First name required");
      trackFormError({
        event: "error",
        group: "userSignup",
        label: "First name required",
        step: {
          sequence: step + 1,
          label: steps[step].label,
        },
      });
    } else if (!validName(value)) {
      setFirstNameError("Invalid first name");
      trackFormError({
        event: "error",
        group: "userSignup",
        label: "Invalid first name",
        step: {
          sequence: step + 1,
          label: steps[step].label,
        },
      });
    } else {
      setFirstNameError(undefined);
      setFirstNameValid(true);
    }
  };

  const handleLastName = (value: string) => {
    setLastName(value);
    setLastNameValid(false);
    if (value === "") {
      setLastNameError("Last name required");
      trackFormError({
        event: "error",
        group: "userSignup",
        label: "Last name required",
        step: {
          sequence: step + 1,
          label: steps[step].label,
        },
      });
    } else if (!validName(value)) {
      trackFormError({
        event: "error",
        group: "userSignup",
        label: "Invalid last name",
        step: {
          sequence: step + 1,
          label: steps[step].label,
        },
      });
    } else {
      setLastNameError(undefined);
      setLastNameValid(true);
    }
  };

  const handleDateOfBirth = (date: Date) => {
    setDOB(date);
    setDateOfBirthValid(false);
    if (!date) {
      setDateOfBirthError("Date of birth required");
      trackFormError({
        event: "error",
        group: "userSignup",
        label: "Date of birth required",
        step: {
          sequence: step + 1,
          label: steps[step].label,
        },
      });
    } else {
      /** If their birth date is older than 150 years return an error */
      if (
        isBefore(
          date,
          sub(new Date(), {
            years: 150,
          })
        )
      ) {
        setDateOfBirthError("It looks like you've entered an invalid date.");
        trackFormError({
          event: "error",
          group: "userSignup",
          label: "It looks like you've entered an invalid date.",
          step: {
            sequence: step + 1,
            label: steps[step].label,
          },
        });
      } else if (isAfter(date, new Date())) {
        /** If the date selected is later than today return an error */
        setDateOfBirthError("The date you've enterered is in the future.");
        trackFormError({
          event: "error",
          group: "userSignup",
          label: "The date you've enterered is in the future.",
          step: {
            sequence: step + 1,
            label: steps[step].label,
          },
        });
      } else {
        setDateOfBirthError(undefined);
        setDateOfBirthValid(true);
      }
    }
  };

  const handlePostcode = (value: string) => {
    setPostcode(value);
    setPostcodeValid(false);
    if (value === "" || value.length !== 4) {
      setPostcodeError("Postcode required");
      trackFormError({
        event: "error",
        group: "userSignup",
        label: "Postcode required",
        step: {
          sequence: step + 1,
          label: steps[step].label,
        },
      });
    } else if (!validPostcode(value)) {
      setPostcodeError("Invalid postcode");
      trackFormError({
        event: "error",
        group: "userSignup",
        label: "Invalid postcode",
        step: {
          sequence: step + 1,
          label: steps[step].label,
        },
      });
    } else {
      setPostcodeError(undefined);
      setPostcodeValid(true);
    }
  };

  const handleSignup = async () => {
    setLoading(true);
    setFormError(undefined);
    try {
      trackFormInteraction({
        event: "submit",
        group: "userSignup",
        step: {
          sequence: 4,
          label: steps[step].label,
        },
      });
      const res = await patientRegister({
        patient_details: {
          first_name: firstName,
          last_name: lastName,
          email: accountDetailsProps.email,
          phone: accountDetailsProps.phone.replace(/^(0)/, "+61"),
          dob: dob ? dob.toISOString() : new Date().toISOString(),
          diagnosis_date: diagnosisProps.diagnosisDate?.toISOString() || "",
          address_postcode: postcode,
          diagnosis_ids: [
            [diagnosisProps.diagnosis],
            [diagnosisProps.situation],
            [diagnosisProps.treatment],
            diagnosisProps.locations,
          ],
          usertype_ids: generateSelectedUserTypeIds(
            userTypeProps.selectedUserTypes,
            dob ? dob : new Date(),
            diagnosisProps.diagnosisDate
              ? diagnosisProps.diagnosisDate
              : new Date()
          ),
          bookmark_ids: [],
        },
        patient_referral: {
          referral_method: referralMethod as VendorSalesforce.ReferralMethod,
          referral_url: referralUrl,
          referral_id: referralId,
        },
        patient_credentials: {
          email: accountDetailsProps.email,
          password,
        },
      });
      trackFormInteraction({
        event: "complete",
        group: "userSignup",
        step: {
          sequence: 4,
          label: steps[step].label,
        },
      });
      const json = await res.json();
      if (json.success) {
        const patientSignInResponse = await patientSignIn({
          email: accountDetailsProps.email,
          password,
        });
        if (patientSignInResponse.success) {
          if (handleAuth) {
            await handleAuth(patientSignInResponse.data);
          }
          history.push(USER_WELCOME);
        }
      } else {
        setFormError("An unexpected error occurred. Please try again later.");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setFormError("An unexpected error occurred. Please try again later.");
      Sentry.captureException(error);
      throw error;
    }
  };

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>): void => {
    event?.preventDefault();
    if (step === steps.length - 1) {
      handleSignup();
    } else {
      handleNextStep();
    }
  };

  const handlePassword = (value: string) => {
    setPassword(value);
    if (passwordValid) {
      setPasswordValid(false);
    }
    if (value === "") {
      setPasswordError("Password required");
    } else if (value.length < 6) {
      setPasswordError("Your password must be at least 6 characters in length");
    } else if (!/[A-Z]/.test(value)) {
      setPasswordError(
        "Your password must contain at least one uppercase letter"
      );
    } else if (!/[0-9]/.test(value)) {
      setPasswordError("Your password must contain at least one number");
    } else if (!/\W|_/gm.test(value)) {
      setPasswordError(
        "Your password must contain at least one non-alphanumeric symbol"
      );
    } else {
      setPasswordError(undefined);
      setPasswordValid(true);
      if (confirmPassword !== value) {
        setConfirmPasswordError("The passwords you entered do not match");
      } else {
        setConfirmPasswordError(undefined);
        setConfirmPasswordValid(true);
      }
    }
  };

  const handleConfirmPassword = (value: string) => {
    setConfirmPassword(value);
    if (value === "") {
      setConfirmPasswordError("Confirm password required");
    } else if (password !== value) {
      setConfirmPasswordError("The passwords you entered do not match");
    } else {
      setConfirmPasswordError(undefined);
      setConfirmPasswordValid(true);
    }
  };

  const handleFieldTracking = (
    label: string,
    id: string,
    position: number,
    value: string
  ) => {
    trackFormFieldComplete({
      group: "userSignup",
      label,
      id,
      position,
      value,
      step: {
        sequence: step + 1,
        label: steps[step].label,
      },
    });
  };

  const currentStep = steps[step];

  const stepValid = currentStep.validate({
    emailValid: accountDetailsProps.emailValid,
    passwordValid: passwordValid,
    confirmPasswordValid: confirmPasswordValid,
    firstNameValid: firstNameValid,
    lastNameValid: lastNameValid,
    dateOfBirthValid: dateOfBirthValid,
    postcodeValid: postcodeValid,
    diagnosis: diagnosisProps.diagnosis,
    situation: diagnosisProps.situation,
    diagnosisDateValid: diagnosisProps.diagnosisDateValid,
    treatment: diagnosisProps.treatment,
    locations: diagnosisProps.locations,
    referralUrl: referralUrl,
    referralMethod: referralMethod,
    MBC_ID: metastaticDiagnosisId,
  });

  return (
    <Components.AppLayout headerVariation="standard">
      <Components.Meta
        title="Sign up to My Journey"
        metaDescription="Create your My Journey account for access to reliable information tailored to your breast cancer experience."
      />
      <StyledStepHeader>
        <Components.Grid spacing="og4" cols="2">
          <Components.TextVariation
            variation="paragraph1bold"
            color="greyMedium"
          >
            {"Registration"}
          </Components.TextVariation>
          <Components.TextContainer align="right">
            <Components.TextVariation variation="paragraph1bold" color="pink">
              {`Step ${step + 1} `}
            </Components.TextVariation>
            <Components.TextVariation
              variation="paragraph1bold"
              color="greyMedium"
            >
              {`of ${steps.length}`}
            </Components.TextVariation>
          </Components.TextContainer>
        </Components.Grid>
      </StyledStepHeader>
      <Components.Form onSubmit={handleSubmit}>
        <currentStep.component
          step={step}
          steps={steps}
          stepValid={stepValid}
          currentStep={currentStep}
          setStep={setStep}
          handlePrevious={handlePrevious}
          handleNextStep={handleNextStep}
          handleSignup={handleSignup}
          handlePassword={handlePassword}
          handleConfirmPassword={handleConfirmPassword}
          password={password}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          setPassword={setPassword}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          referralUrl={referralUrl}
          setReferralUrl={setReferralUrl}
          setPostcode={setPostcode}
          setDOB={setDOB}
          loading={loading}
          passwordRules={passwordRules}
          passwordError={passwordError}
          confirmPasswordError={confirmPasswordError}
          passwordValid={passwordValid}
          confirmPasswordValid={confirmPasswordValid}
          firstNameValid={firstNameValid}
          firstNameError={firstNameError}
          lastNameValid={lastNameValid}
          lastNameError={lastNameError}
          handleFirstName={handleFirstName}
          handleLastName={handleLastName}
          handleDateOfBirth={handleDateOfBirth}
          dateOfBirthError={dateOfBirthError}
          dateOfBirthValid={dateOfBirthValid}
          dob={dob}
          handlePostcode={handlePostcode}
          postcodeError={postcodeError}
          postcodeValid={postcodeValid}
          postcode={postcode}
          referralData={referralData}
          handleReferralMethod={handleReferralMethod}
          referralMethod={referralMethod}
          referralLoading={referralLoading}
          referralId={referralId}
          referralPatientDetails={referralPatientDetails}
          handleFieldTracking={handleFieldTracking}
          formError={formError}
          {...accountDetailsProps}
          {...userTypeProps}
          {...diagnosisProps}
        />
        <StickyButtonWrapper>
          <Components.Grid cols="2" gap="2">
            <Components.ButtonVariation
              variation="secondary"
              type="button"
              onClick={() => handlePrevious()}
              fullWidth
            >
              {"Back"}
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="primary"
              type="submit"
              disabled={!stepValid || loading}
              fullWidth
            >
              {step === steps.length - 1
                ? loading
                  ? "Loading..."
                  : "Complete"
                : "Continue"}
            </Components.ButtonVariation>
          </Components.Grid>
        </StickyButtonWrapper>
      </Components.Form>
    </Components.AppLayout>
  );
};

export type SignUpStepProps = {
  handlePrevious: () => void;
  handleNextStep: () => void;
  handleSignup: () => void;
  handlePassword: (value: string) => void;
  handleConfirmPassword: (value: string) => void;
  handleFirstName: (value: string) => void;
  handleLastName: (value: string) => void;
  handleDateOfBirth: (value: Date) => void;
  handlePostcode: (value: string) => void;
  handleReferralMethod: (value: VendorSalesforce.ReferralMethod) => void;
  handleFieldTracking: (
    label: string,
    id: string,
    position: number,
    value: string
  ) => void;
  setReferralUrl: React.Dispatch<React.SetStateAction<string>>;
  setPostcode: React.Dispatch<React.SetStateAction<string>>;
  setDOB: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  step: number;
  steps: Array<SignUpStep>;
  currentStep: SignUpStep;
  stepValid: boolean;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  loading: boolean;
  passwordRules: Record<string, boolean>;
  passwordError?: FormError;
  confirmPasswordError?: FormError;
  passwordValid: boolean;
  confirmPasswordValid: boolean;
  dob?: Date;
  firstNameValid: boolean;
  firstNameError?: FormError;
  lastNameValid: boolean;
  lastNameError?: FormError;
  dateOfBirthValid: boolean;
  dateOfBirthError?: FormError;
  postcodeValid: boolean;
  postcodeError?: FormError;
  postcode: string;
  referralUrl: string;
  referralData: Array<RadioData>;
  referralMethod?: VendorSalesforce.ReferralMethod;
  referralLoading: boolean;
  referralPatientDetails: {
    email?: string;
    phone?: string;
  };
  referralId?: string;
  formError?: FormError;
} & DiagnosisProps &
  UserTypeProps &
  AccountDetailsProps;

export type SignUpStep = {
  label: string;
  component: React.FunctionComponent<SignUpStepProps>;
  validate: (data: {
    emailValid: boolean;
    passwordValid: boolean;
    confirmPasswordValid: boolean;
    firstNameValid: boolean;
    lastNameValid: boolean;
    dateOfBirthValid: boolean;
    postcodeValid: boolean;
    diagnosis: string;
    situation: string;
    diagnosisDateValid: boolean;
    treatment: string;
    locations: Array<string>;
    referralUrl: string;
    referralMethod?: VendorSalesforce.ReferralMethod;
    MBC_ID?: number;
  }) => boolean;
};

type FormError = JSX.Element | string | undefined;
