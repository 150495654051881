import * as React from "react";

import * as Utils from "~/utils";
import * as Components from "~/components";

import { DiagnosisProps } from "~/hooks/useDiagnosis";
import useGetMetastaticBreastCancerDiagnosisId from "~/hooks/useGetMetastaticBreastCancerDiagnosisId";

const DiagnosisFormWithMBC: React.FunctionComponent<DiagnosisFormProps> = (
  props
) => (
  <>
    <Components.HorizontalRule />
    <Components.TextContainer spacing="ogxxxs">
      <Components.TextVariation variation="heading3" color="greyDark">
        {"What was the date of your current diagnosis?"}
      </Components.TextVariation>
    </Components.TextContainer>
    <Components.Box spacing="og2" fullWidth>
      <Components.DateInput
        label="Diagnosis date"
        value={props.diagnosisDate}
        min={new Date("01/01/1900")}
        max={new Date()}
        required
        onChange={(date) => props.handleDiagnosisDate(date)}
        onBlur={() => props.onBlur?.("date")}
        // hideDayInput
      />
    </Components.Box>
    {props.diagnosisDate && props.situationData && (
      <>
        <Components.HorizontalRule />
        <Components.TextContainer spacing="ogxxxs">
          <Components.TextVariation variation="heading3" color="greyDark">
            {"Have you had a previous diagnosis of breast cancer?"}
          </Components.TextVariation>
        </Components.TextContainer>
        <Components.Box spacing="og2" fullWidth>
          <Components.RadioGroupCard
            checked={props.situation}
            onChange={props.handleSituation}
            onBlur={(index) => props.onBlur?.("situation", index)}
            data={props.situationData}
            showDescription={true}
          />
        </Components.Box>
      </>
    )}
    {props.treatmentData && (
      <>
        <Components.HorizontalRule />
        <Components.TextContainer spacing="ogxxxs">
          <Components.TextVariation variation="heading3" color="greyDark">
            {"Where are you at with your breast cancer experience?"}
          </Components.TextVariation>
        </Components.TextContainer>
        <Components.Box spacing="og2" fullWidth>
          <Components.RadioGroupTimeline
            checked={props.treatment}
            onChange={props.handleTreatment}
            onBlur={(index) => props.onBlur?.("treatment", index)}
            data={props.treatmentData}
          />
        </Components.Box>
      </>
    )}
    {props.treatmentData && props.locationData ? (
      <>
        <Components.HorizontalRule />
        <Components.TextContainer spacing="ogxxxs">
          <Components.TextVariation variation="heading3" color="greyDark">
            {"Where has your metastatic cancer been found?"}
          </Components.TextVariation>
        </Components.TextContainer>
        <Components.Box spacing="og2" fullWidth>
          <Components.CheckboxGroup
            checked={props.locations}
            onChange={props.handleLocations}
            onBlur={(index) => props.onBlur?.("location", index)}
            data={props.locationData}
          />
        </Components.Box>
        {props.otherSelected ? (
          <Components.Box spacing="og2" fullWidth>
            <Components.Input
              type="text"
              value={props.otherLocation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.handleOtherLocation(e.target.value)
              }
              onBlur={() => props.onBlur?.("location")}
              placeholder="Enter other location"
            />
          </Components.Box>
        ) : null}
      </>
    ) : null}
  </>
);

const DiagnosisFormWithoutMBC: React.FunctionComponent<DiagnosisFormProps> = (
  props
) => (
  <>
    <Components.HorizontalRule />
    <Components.TextContainer spacing="ogxxxs">
      <Components.TextVariation variation="heading3" color="greyDark">
        {"What was the date of your current diagnosis?"}
      </Components.TextVariation>
    </Components.TextContainer>
    <Components.Box spacing="og2" fullWidth>
      <Components.DateInput
        label="Diagnosis date"
        value={props.diagnosisDate}
        min={new Date("01/01/1900")}
        max={new Date()}
        required
        onChange={(date) => props.handleDiagnosisDate(date)}
        onBlur={() => props.onBlur?.("date")}
        // hideDayInput
      />
    </Components.Box>
    {props.diagnosisDate && (
      <>
        <Components.HorizontalRule />
        <Components.TextContainer spacing="ogxxxs">
          <Components.TextVariation variation="heading3" color="greyDark">
            {"Which scenario best reflects your current situation?"}
          </Components.TextVariation>
        </Components.TextContainer>
        <Components.Box spacing="og2" fullWidth>
          {props.situationData ? (
            <Components.RadioGroupTimeline
              checked={props.situation}
              onChange={props.handleSituation}
              onBlur={(index) => props.onBlur?.("situation", index)}
              data={props.situationData}
            />
          ) : null}
        </Components.Box>
      </>
    )}
  </>
);

export const DiagnosisForm: React.FunctionComponent<DiagnosisFormProps> = (
  props
) => {
  const { metastaticDiagnosisId } = useGetMetastaticBreastCancerDiagnosisId();
  return (
    <Components.Box fullWidth>
      <Components.TextContainer spacing="ogxxxs">
        <Components.TextVariation variation="heading3" color="greyDark">
          {"What is your current diagnosis?"}
        </Components.TextVariation>
      </Components.TextContainer>
      <Components.Box spacing="og2" fullWidth>
        {props.diagnosisData && (
          <Components.RadioGroupCard
            checked={props.diagnosis}
            onChange={props.handleDiagnosis}
            onBlur={(index) => props.onBlur?.("diagnosis", index)}
            data={props.diagnosisData}
            showDescription
          />
        )}
      </Components.Box>
      <Components.InfoDropDown label="Need help selecting your diagnosis?">
        <Components.TextContainer>
          {
            "If you have a less common form of breast cancer or you're unsure, please read the "
          }
          <Components.TextLink
            textVariation="textlink2"
            target="_blank"
            rel="noopener"
            href="/article/3055"
          >
            {"Types and subtypes of breast cancer"}
          </Components.TextLink>
          {" article or call our Helpline for guidance on "}
          <Components.TextLink textVariation="textlink2" href="tel:1800500258">
            {"1800 500 258"}
          </Components.TextLink>
          {"."}
          <br />
          <br />
          {
            "If you have a combination of DCIS and cancer that has spread into breast tissue, please select the "
          }
          <Components.TextVariation variation="paragraph2bold">
            {"Early Breast Cancer"}
          </Components.TextVariation>
          {" option."}
        </Components.TextContainer>
      </Components.InfoDropDown>
      {props.diagnosis && (
        <React.Fragment>
          {props.diagnosis === `${metastaticDiagnosisId}` ? (
            <DiagnosisFormWithMBC {...props} />
          ) : (
            <DiagnosisFormWithoutMBC {...props} />
          )}
        </React.Fragment>
      )}
    </Components.Box>
  );
};

export type DiagnosisFormProps = {
  onBlur?: (
    field: "diagnosis" | "situation" | "treatment" | "location" | "date",
    index?: number
  ) => void;
} & DiagnosisProps;
