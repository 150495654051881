import * as React from "react";
import { differenceInDays, format }  from "date-fns";
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLine, VictoryScatter } from "victory";

import * as Utils from "~/utils";

const graphStyles = {
  title: {
    textAnchor: "start",
    verticalAnchor: "end",
    fill: Utils.DARK_BLUE_COLOR,
    fontFamily: "inherit",
    fontSize: "11px",
    fontWeight: "600",
  },
  verticalAxis: {
    axis: {
      stroke: "transparent",
      padding: 0,
    },
    grid: {
      stroke: Utils.DUSTY_PINK_COLOR,
      strokeOpacity: 0.75,
      strokeWidth: 1,
    },
  },
  crossAxis: {
    axis: {
      stroke: Utils.DUSTY_PINK_COLOR,
    },
    grid: {
      stroke: Utils.DUSTY_PINK_COLOR,
      strokeOpacity: 0.75,
      strokeWidth: 1,
    },
  },
  dateLabel: {
    textTransform: "uppercase",
    fill: Utils.DARK_BLUE_COLOR,
    fontFamily: "var(--font-sans)",
    fontWeight: "600",
    height: "20px",
  },
};


export const SymptomGraphSingle: React.FunctionComponent<SymptomGraphSingleProps> = (props) => {
  if (!props.data?.length) {
    return null;
  }

  const _ticks = differenceInDays(props.data[props.data.length - 1].x, props.data[0].x);

  return (
    <VictoryChart
      padding={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 50,
      }}
      scale={{ y: "linear", x: "time" }}
      domainPadding={{ x: 50, y: 10 }}
      domain={{
        y: [0, 10],
      }}
    >
      <VictoryAxis
        dependentAxis
        tickCount={10}
        style={graphStyles.verticalAxis}
      />
      <VictoryAxis
        crossAxis
        tickCount={_ticks ? _ticks + 1 : 10}
        style={graphStyles.crossAxis}
        tickFormat={(t) => `${format(t, "dd")}\n${format(t, "MMM")}`}
        tickLabelComponent={(
          <VictoryLabel
            style={[graphStyles.dateLabel, {
              ...graphStyles.dateLabel,
              fontSize: "8px",
              paddingTop: "5px",
            }]}
          />
        )}
      />
      {/* Define labels */}
      <VictoryLabel
        x={25}
        y={25}
        style={graphStyles.title}
        text="Extreme"
      />
      <VictoryLabel
        x={25}
        y={235}
        style={graphStyles.title}
        text="OK"
      />

      <VictoryLine
        data={props.data}
        animate={{
          duration: 2000,
          onLoad: { duration: 1000 },
        }}
        interpolation="linear"
        style={{
          data: {
            stroke: Utils.PINK_COLOR,
            strokeWidth: 1.5,
          },
        }}
      />
      <VictoryScatter
        data={props.data}
        size={5}
        style={{
          data: { fill: Utils.PINK_COLOR },
        }}
      />
    </VictoryChart>
  );
};

export type SymptomGraphSingleProps = {
  data: Array<{
    x: Date
    y: number
  }>;
}
