import * as React from "react";

export const useScrollContainer = (tiles: number): [React.RefObject<HTMLDivElement>, () => void] => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    const elem = wrapperRef.current;
    if (elem) {
      /**
       * Scroll the container when clicking on the arrow button by its
       * width divided by the number of cards.
       */
      elem.scrollTo({
        left: elem.scrollLeft + (elem.scrollWidth / tiles),
        behavior: "smooth",
      });
    }
  };

  return [wrapperRef, handleScroll];
};
