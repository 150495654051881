import * as React from "react";

export const withRadio = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "Radio", ...props }: Props) => {
  Component.displayName = name;
  return (
    <Component
      { ...props as P & Props }
    />
  );
};

type Props = {
  name?: string
  data: Array<RadioData>
  checked?: string
  onChange: (index: string) => void;
  onBlur?: (index: number) => void
}

export type RadioData = {
  label: string
  value?: string
}
