import * as React from "react";
import { useParams, useHistory } from "react-router-dom";

import * as Utils from "~/utils/";
import * as Styled from "./styledGlossary";
import * as Components from "~/components";

import glossary from "~/../assets/data/glossary.json";

export const Glossary: React.FunctionComponent<GlossaryProps> = () => {
  const routerHistory = useHistory();
  const routerParams = useParams<{ letter: string; }>();

  const category = routerParams.letter.toLowerCase();

  const categories = React.useMemo(() => {
    return glossary.map((item) => item.label[0].toLowerCase()).filter((item, index, self) => self.indexOf(item) === index);
  }, []);

  const items = React.useMemo(() => {
    return glossary.filter((item) => item.label[0].toLowerCase() === category);
  }, [routerParams.letter]);

  const handleCategory = (letter: string): void => {
    routerHistory.replace(Utils.APP_GLOSSARY(letter));
  };

  return (
    <Components.AppLayout
      headerVariation="navigationHome"
    >
      <Components.Meta
        title={`Glossary | ${routerParams.letter.toUpperCase()}`}
      />
      <Components.Row>
        <Components.Column
          spacing="md"
        >
          <Components.H2>
            Glossary
          </Components.H2>
          <Styled.HorizontalScroller>
            {categories.map((letter) => {
              return (
                <Styled.StyledGlossaryItem
                  key={letter}
                >
                  <Components.ButtonCircle
                    colour={category === letter ? "pink" : "pink-light"}
                    onClick={() => handleCategory(letter)}
                  >
                    {letter.toUpperCase()}
                  </Components.ButtonCircle>
                </Styled.StyledGlossaryItem>
              );
            })}
          </Styled.HorizontalScroller>
          <Components.GlossaryAccordion
            items={items}
            single
          />
        </Components.Column>
      </Components.Row>
    </Components.AppLayout>
  );
};

export type GlossaryProps = unknown;
