import { StackedContent } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import parse from "html-react-parser";
import { Card, H2, H4, RichText, Section, StyledHyperlink } from "~/components/Styled";
import { IconWrapper, LabelWrapper } from "./styledArticle";
import * as Utils from "~/utils";
import { useAnalytics } from "~/hooks/useAnalytics";

export const HelpfulResources: React.FC<Props> = (props) => {
  const analytics = useAnalytics();
  return (
    <Section
      margin="negative"
      padding="md"
    >
      <H2
        align="center"
      >Helpful Resources</H2>
      {props.items.map(({ name, description, resourceType, link }) => (
        <Card
          color="pinkMedium"
          spacing="xs"
          padding="sm"
          key={name}
        >
          <H4
            colour="pink"
            spacing="xs"
          >
            <IconWrapper>
              {Utils.helpfulResources[resourceType]?.icon({})}
              <LabelWrapper>{resourceType}</LabelWrapper>
            </IconWrapper>
          </H4>
          <H4>{name}</H4>
          <RichText>
            {parse(description)}
          </RichText>
          <StyledHyperlink
            target={link.target}
            title={link.name}
            href={Utils.getMediaLink(link)}
            onClick={() => analytics.trackArticleFileDownload({
              group: resourceType,
              label: link.name,
              url: Utils.getMediaLink(link),
            })}
          >
            {Utils.helpfulResources[resourceType].label}
          </StyledHyperlink>
        </Card>
      ))}
    </Section>
  );
};

type Props = StackedContent.HelpfulResources
