import App from "~/../assets/icons/resource-app.svg";
import Podcast from "~/../assets/icons/resource-podcast.svg";
import Website from "~/../assets/icons/resource-website.svg";
import Webcast from "~/../assets/icons/resource-webcast.svg";
import FactSheet from "~/../assets/icons/resource-factsheet.svg";
import Booklet from "~/../assets/icons/resource-booklet.svg";
import Book from "~/../assets/icons/resource-book.svg";
import Phone from "~/../assets/icons/resource-phone.svg";

export const helpfulResources = {
  "Phone line": {
    label: "Call now",
    icon: Phone,
  },
  "Website": {
    label: "View Website",
    icon: Website,
  },
  "Podcast": {
    label: "Listen to Podcast",
    icon: Podcast,
  },
  "App": {
    label: "Get the app",
    icon: App,
  },
  "Factsheet": {
    label: "View fact sheet",
    icon: FactSheet,
  },
  "Webcast": {
    label: "View Webcast",
    icon: Webcast,
  },
  "Booklet": {
    label: "View Booklet",
    icon: Booklet,
  },
  "Book": {
    label: "View Book",
    icon: Book,
  },
};
