import * as React from "react";
import { Topic } from "myjourney-frontend/src/vendor/umbraco";
import { TopicCard } from "../TopicCard/TopicCard";

import * as Hooks from "~/hooks";
// import * as Utils from "~/utils";
import * as Styled from "./styledTopicList";

export const TopicList: React.FunctionComponent<TopicListProps> = () => {
  Hooks.useAuthentication();
  const { getCategories, getNonEmptyCategories } = Hooks.useContentApi();
  const [topics, setTopics] = React.useState<Array<Topic> | null>(null);

  React.useEffect(() => {
    (async () => {
      const _categories = await getCategories();
      const _nonEmptyCategories = await getNonEmptyCategories();
      if (_nonEmptyCategories) {
        const _topics = _categories.filter((c) => _nonEmptyCategories.includes(`${c.id}`));
        setTopics(_topics);
      }
    })();
  }, []);

  return (
    <Styled.StyledGrid>
      {topics ? topics.map((topic) => (
        <TopicCard
          topic={topic}
          key={topic.id}
        />
      )) : null}
    </Styled.StyledGrid>
  );
};

export type TopicListProps = unknown;
