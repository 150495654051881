import * as React from "react";
import { Symptom, SymptomResponse } from "myjourney-frontend/src/vendor/umbraco";
import { useSymptomForm } from "~/hooks/useSymptomForm";
import { useSymptomTrackerApi } from "~/hooks/useSymptomTrackerApi";
import { USER_SYMPTOMS } from "~/utils/constants";
import { useHistory } from "react-router-dom";

export const withEditSymptom = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ displayName = "EditSymptom", ...props }: Props) => {
  Component.displayName = displayName;
  const history = useHistory();
  const { editSymptom } = useSymptomTrackerApi();
  const symptomFormProps = useSymptomForm(props.symptomResponse);
  const [date, setDate] = React.useState<Date>(props.date);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [datePicker, setDatePicker] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [imageIds, setImageIds] = React.useState<Array<string>>(props.symptomResponse?.symptomLogEntry.images ? props.symptomResponse?.symptomLogEntry.images : []);

  React.useEffect(() => {
    setDate(props.date);
  }, [props.date]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setSaving(true);
    try {
      const { severityRanges } = props.symptom;
      const _severity = severityRanges.length ? severityRanges[0].minRange === 0 ? symptomFormProps.severity : symptomFormProps.temperature : 0;
      const res = await editSymptom({
        symptomId: props.editSymptomId as string,
        severity: {
          severity: _severity,
          severityType: severityRanges.length ? severityRanges[0].minRange === 0 ? severityRanges[severityRanges.length - 1].maxRange === 0 ? "none" : "range" : "temperature" : "none",
        },
        additional: {
          description: symptomFormProps.description,
          actionsTaken: symptomFormProps.actions,
          questions: symptomFormProps.questions,
        },
        date: {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        },
        images: imageIds,
        attachments: [],
      });
      if (res.status === 200) {
        const json: SymptomResponse = await res.json();
        props.setSaved(json);
        props.handleEditSymptomId();
      } else {
        setError("An error occurred, please try again later.");
      }
    } catch {
      setError("An error occurred, please try again later.");
    }
    setSaving(false);
  };
  
  const handleBackToDashboard = () => {
    props.handleEditSymptomId();
    history.push(USER_SYMPTOMS);
  };

  const onDateChange = (date: Date) => setDate(date);

  return (
    <Component
      { ...props as P & Props }
      { ...symptomFormProps }
      error={error}
      imageIds={imageIds}
      handleSubmit={handleSubmit}
      date={date}
      setDate={setDate}
      setImageIds={setImageIds}
      saving={saving}
      handleBackToDashboard={handleBackToDashboard}
      datePicker={datePicker}
      setDatePicker={setDatePicker}
      onDateChange={onDateChange}
    />
  );
};

type Props = {
  displayName?: string
  handleEditSymptomId: (id?: string) => void
  editSymptomId?: string
  symptom: Symptom
  symptomResponse?: SymptomResponse;
  date: Date
  setSaved: React.Dispatch<React.SetStateAction<(SymptomResponse & { feedback?: string }) | undefined>>
}
