import * as Sentry from "@sentry/react";
import { API_SHARE_PATH } from "../utils/constants";

/**
 * Call salesforce endpoints to send emails
 */
export const useSalesforceApi = (): SalesforceAPIProps => {

  const apiPost = (url: string, body: {
    share_emails: Array<string>
    share_details: Record<string, unknown>
  }) => fetch(`${runtimeconfig.application_domain}${url}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      data: body,
    }),
  });

  const apiSendEmail = async (data: EmailData) => {
    try {
      return await apiPost(API_SHARE_PATH, {
        share_emails: data.to,
        share_details: data.templateData,
      });
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
  return {
    apiSendEmail,
  };
};

type EmailData = {
  to: Array<string>
  templateData: {
    referrerFirstName?: string
    referrerLastName?: string
    message?: string
    article: {
      title: string
      intro: string
      relevantFor: string
      forDiagnosis: Array<string>
      url: string
    }
  }
}

export type SalesforceAPIProps = {
  apiSendEmail: (data: EmailData) => Promise<Response>
}