import React from "react";
import { Link } from "react-router-dom";
import tw, { css } from "twin.macro";

import { TextVariations } from "~/components/Text";

const ButtonStyles = {
  shared: tw`inline-flex items-center justify-center outline-none focus:outline-none transition-all duration-200`,
  size: {
    medium: [
      tw`py-ogxxxxs px-ogxxs rounded`,
      TextVariations.button1,
    ],
    small: [
      tw`py-ogxxxxxs px-ogxxxs min-w-60 rounded`,
      TextVariations.button2,
    ],
    tiny: [
      tw`py-ogxxxxs px-ogxxxs min-w-60 text-right rounded`,
      TextVariations.button3,
    ],
    square: [
      tw`w-full py-ogxxxxs px-ogxxs relative rounded-sm hover:(shadow-md) border`,
      TextVariations.paragraph1,
      css`
        svg {
          position: absolute;
          left: 24px;
          width: 20px;
          height: 20px;
        }
      `,
    ],
  },
  style: {
    primary: tw`bg-pink text-white hover:(bg-pink-dark)`,
    secondary: tw`bg-white text-pink shadow hover:(bg-pink-medium shadow-md)`,
    disabled: tw`bg-grey-light text-grey-medium cursor-not-allowed pointer-events-none border-transparent shadow-none`,
    square: tw`bg-white text-pink border border-pink-dusty shadow hover:(bg-pink text-white border-pink)`,
  },
};

const ButtonVariations = {
  primary: [
    ...ButtonStyles.size.medium,
    ButtonStyles.style.primary,
  ],
  secondary: [
    ...ButtonStyles.size.medium,
    ButtonStyles.style.secondary,
  ],
  disabled: [
    ...ButtonStyles.size.medium,
    ButtonStyles.style.disabled,
  ],
  primarysmall: [
    ...ButtonStyles.size.small,
    ButtonStyles.style.primary,
  ],
  secondarysmall: [
    ...ButtonStyles.size.small,
    ButtonStyles.style.secondary,
  ],
  disabledsmall: [
    ...ButtonStyles.size.small,
    ButtonStyles.style.disabled,
  ],
  primarytiny: [
    ...ButtonStyles.size.tiny,
    ButtonStyles.style.primary,
  ],
  secondarytiny: [
    ...ButtonStyles.size.tiny,
    ButtonStyles.style.secondary,
  ],
  disabledtiny: [
    ...ButtonStyles.size.tiny,
    ButtonStyles.style.disabled,
  ],
  square: [
    ...ButtonStyles.size.square,
    ButtonStyles.style.square,
  ],
  disabledsquare: [
    ...ButtonStyles.size.square,
    ButtonStyles.style.disabled,
  ],
};

export const ButtonVariation: React.FunctionComponent<ButtonVariationProps> = (props) => {
  if (!props.variation) {
    throw new Error("Missing variation");
  }

  const fullWidth = typeof props.fullWidth === "undefined" ? false : props.fullWidth;

  const content = (
    <>
      {props.iconLeft}
      {props.children && (
        <span
          css={[
            ...props.iconLeft ? [tw`pl-ogxxxxxs`] : [],
            ...props.iconRight ? [tw`pr-ogxxxxxs`] : [],
          ]}
        >
          {props.children}
        </span>
      )}
      {props.iconRight}
    </>
  );

  if (!props.disabled && props.href) {
    if(props.external){
      return(
        <a 
          href={props.href} 
          target="_blank"
          rel="noreferrer"
          css={[
            ButtonStyles.shared,
            ...ButtonVariations[props.variation],
            ...fullWidth ? [tw`w-full`] : [],
          ]}
        >
          {content}
        </a>
      );
    }
    return (
      <Link
        css={[
          ButtonStyles.shared,
          ...ButtonVariations[props.variation],
          ...fullWidth ? [tw`w-full`] : [],
        ]}
        to={props.href}
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      css={[
        ButtonStyles.shared,
        ...ButtonVariations[props.variation],
        ...props.disabled ? [ButtonStyles.style.disabled] : [],
        ...fullWidth ? [tw`w-full`] : [],
      ]}
      {...props}
      disabled={props.disabled ?? props.variation.includes("disabled")}
    >
      {content}
    </button>
  );
};

export type ButtonVariationProps = ({
  variation: keyof typeof ButtonVariations;
  fullWidth?: boolean;
  external?:boolean;
  href?: string;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>);
