import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";

export const IconWrapper = styled.span(() => [
  tw`text-blue-navy opacity-40 group-hover:text-pink group-hover:opacity-100 absolute top-0 right-0 p-4`,
]);

export const CardLink = styled(Link)(() => [
  tw`bg-pink-light text-center text-blue-navy rounded-md leading-normal h-52 w-full relative flex p-4 items-center hover:(shadow bg-white) md:(p-8)`,
]);
