import * as React from "react";
import tw, { styled, css } from "twin.macro";
import { HiddenCheckbox } from "../Styled/Form";

import * as Components from "~/components";

import IconRadioTimelineChecked from "~/../assets/icons/radio-timeline-checked.svg";
import IconRadioTimelineUnchecked from "~/../assets/icons/radio-timeline-unchecked.svg";

const StyledWrapper = styled.div(() => [
  tw`-mt-4`,
]);

const StyledTimelineItem = styled.label(() => [
  tw`flex flex-col items-center relative`,
]);

const StyledTimelineIndicator = styled.div(() => [
  tw`mb-4`,
  css`
    z-index: 2;
  `,
]);

const StyledTimelineLabel = styled.div(() => [
  tw`text-center`,
]);

const StyledTimelineStroke = styled.div(() => [
  tw``,
  css`
    position: absolute;
    width: calc(100% - 26px);
    height: 2px;
    top: 22px;
    left: calc(50% + 21px);
    z-index: 1;
    background-color: #ded6d6;
  `,
]);

export const RadioGroupTimeline: React.FunctionComponent<RadioGroupTimelineProps> =(props) => {
  return (
    <StyledWrapper>
      <Components.HorizontalSlider
        itemWidth={50}
        dynamicWidth
      >
        {props.data.map((item, index) => (
          <StyledTimelineItem
            key={item.value}
          >
            <HiddenCheckbox
              type="radio"
              value={item.value}
              onChange={(e) => props.onChange(e.target.value)}
              onBlur={() => props.onBlur?.(index)}
              checked={props.checked === item.value}
            />
            <StyledTimelineIndicator>
              {props.checked === item.value ? <IconRadioTimelineChecked /> : <IconRadioTimelineUnchecked />}
            </StyledTimelineIndicator>
            {index < props.data.length - 1 && (
              <StyledTimelineStroke />
            )}
            <StyledTimelineLabel>
              <Components.TextVariation
                variation="label2"
                color="greyMedium"
              >
                {item.label}
              </Components.TextVariation>
            </StyledTimelineLabel>
          </StyledTimelineItem>
        ))}
      </Components.HorizontalSlider>
    </StyledWrapper>
  );
};

export type RadioGroupTimelineProps = {
  interactive?: boolean;
  data: Array<{
    label: string;
    value: string;
  }>;
  checked: string;
  onChange: (index: string) => void;
  onBlur?: (index: number) => void;
  showDescription?: boolean;
};
