import * as React from "react";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Components from "~/components";

import IconPlus from "~/../assets/icons/icon-plus.svg";

export const Notes: React.FunctionComponent<NotesProps> = () => {
  Hooks.useAuthentication();

  const hookDateRange = Hooks.useDateRange();
  const hookNotes = Hooks.useNotes({
    dateRange: hookDateRange.values[Hooks.DATE_RANGE.ALL_TIME],
  });

  return (
    <Components.AppLayout headerVariation="navigationHome">
      <Components.Meta title="Notes" />
      <Components.TextVariation variation="heading1" spacing="og1">
        {"My notes"}
      </Components.TextVariation>
      <Components.TextVariation variation="heading3" spacing="og4">
        {"Record notes for reference"}
      </Components.TextVariation>
      <Components.Box spacing="og4">
        <Components.ButtonVariation
          variation="primary"
          href={Utils.USER_NOTES_ADD}
          aria-label="Add note"
          iconLeft={<IconPlus />}
          fullWidth
        >
          {"Note"}
        </Components.ButtonVariation>
      </Components.Box>
      {!hookNotes.loading &&
        (hookNotes.notes.length === 0 ? (
          <Components.Card
            color="pinkMedium"
            padding="og4"
            aria-label="Symptoms card"
          >
            <Components.TextVariation variation="heading3">
              {
                "Add your first note above and keep all your questions and reminders in one place"
              }
            </Components.TextVariation>
          </Components.Card>
        ) : (
          <Components.Card
            color="pinkMedium"
            padding="og4"
            aria-label="Notes card"
          >
            <Components.TextVariation
              variation="heading3"
              spacing="og4"
              fullWidth
            >
              {"Recent notes"}
            </Components.TextVariation>
            <Components.NoteList
              dateRange={hookDateRange.values[Hooks.DATE_RANGE.ALL_TIME]}
            />
          </Components.Card>
        ))}
    </Components.AppLayout>
  );
};

export type NotesProps = unknown;
