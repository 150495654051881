import * as React from "react";
import tw, { styled } from "twin.macro";

import * as Hooks from "~/hooks";
import * as Providers from "~/providers";
import * as Components from "~/components";
import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";

import IconStar from "~/../assets/icons/icon-star.svg";

const StyledWrapperNegative = styled.div(() => [tw`-mx-og4 md:-mx-og12`]);

const StyledWrapperPink = styled.div(() => [
  tw`py-og10 px-og4 md:px-og12 bg-pink-medium md:bg-transparent`,
]);

const StyledWrapperWhite = styled.div(() => [tw`py-og10`]);

const StyledUserTypeImage = styled.img(() => [tw`rounded-full p-2`]);

export const TemplateWelcome: React.FunctionComponent<
  TemplateWelcomeProps
> = () => {
  Hooks.useAuthentication();
  const patientContext = React.useContext(Providers.PatientContext);
  const modalContext = React.useContext(Providers.ModalContext);
  const [userTypeStories, setUserTypeStories] = React.useState<
    Array<MergedArticle>
  >([]);

  const userTypeHook = Hooks.useUserTypes();
  const contentApiHook = Hooks.useContentApi();

  const userTypes = userTypeHook.userTypeData.filter((item) =>
    userTypeHook.selectedUserTypes.includes(item.value)
  );

  React.useEffect(() => {
    if (userTypeHook.selectedUserTypes) {
      (async () => {
        const stories: MergedArticle[] = [];
        await Promise.all(
          userTypeHook.selectedUserTypes.map(async (itemUserTypeId) => {
            const storiesRes = await contentApiHook.getStories(itemUserTypeId);
            if (storiesRes.length > 0) {
              storiesRes.forEach((storyItem) => {
                stories.push(storyItem);
              });
            }
          })
        );

        setUserTypeStories(stories);
      })();
    }
  }, [userTypeHook.selectedUserTypes.sort().join()]);

  return (
    <Components.AppLayout
      headerVariation="navigationUserWelcome"
      footerVariation="standard"
    >
      <Components.Meta title="Welcome" />
      <StyledWrapperWhite>
        <Components.TextVariation variation="heading1" spacing="og4">
          {`Hello ${patientContext.auth?.patient_details.first_name}`},
        </Components.TextVariation>
        <Components.TextVariation variation="headingdisplay" spacing="og1">
          {"Welcome to"}
          <br />
          {"My Journey"}
        </Components.TextVariation>
        <StyledWrapperNegative>
          <Components.HorizontalSlider itemWidth={75} hideNavigation>
            {/* {[{
              label: "Welcome aboard My Journey",
              title: "Start using My Journey for articles, research, references, and more",
              duration: "2:58",
              videoUrl: "https://youtu.be/1CBrsTP7_80",
            },{
              label: "What is My Journey?",
              title: "Discover My Journey and how easy it is to start using",
              duration: "2:14",
              videoUrl: "https://youtu.be/dibJqL6rQoM",
            },{
              label: "LGBTIQ+ welcome",
              title: "Discover how to find articles, track symptoms, make notes, and make connections",
              duration: "3:16",
              videoUrl: "https://youtu.be/GVVIX-76A14",
            },{
              label: "First Peoples welcome",
              title: "Hear how others use My Journey and the difference it makes",
              duration: "3:53",
              videoUrl: "https://youtu.be/pUfltrsad2I",
            },{
              label: "Men with breast cancer",
              title: "Learn about treatment, saving notes, tracking symptoms and connecting",
              duration: "3:05",
              videoUrl: "https://youtu.be/JOaIbq1r7eM",
            }] */}

            {userTypeStories
              ? userTypeStories.length > 0
                ? [
                    ...userTypeStories,
                    {
                      name: "New diagnosis of breast cancer",
                      videoUrl: "https://youtu.be/58rCO5ucbLs",
                    },
                    {
                      label: "What is My Journey?",
                      name: "Discover My Journey and how easy it is to start using",
                      duration: "2:14",
                      videoUrl: "https://youtu.be/dibJqL6rQoM",
                    },
                    {
                      name: "Communicating with family and friends",
                      videoUrl: "https://youtu.be/Aq9I1gfjRns",
                    },
                    {
                      name: "Getting through treatment for breast cancer",
                      videoUrl: "https://youtu.be/kGTHz4jZzfw",
                    },
                    {
                      name: "Other ways to manage anxiety",
                      videoUrl: "https://youtu.be/_wntEa0vpZk",
                    },
                  ].map((item, index) => (
                    <Components.Box
                      key={index}
                      flex
                      flexDirection="col"
                      justifyContent="between"
                      fullHeight
                    >
                      {/* <Components.TextVariation
                  variation="label1"
                  color="blueNavy"
                  spacing="og6"
                  fullWidth
                >
                  {item.name}
                </Components.TextVariation> */}
                      <Components.Box fullWidth>
                        <Components.Video
                          title={item.name}
                          videoUrl={item.videoUrl}
                          // duration={item.duration}
                        />
                      </Components.Box>
                    </Components.Box>
                  ))
                : [
                    {
                      label: "Welcome aboard My Journey",
                      title:
                        "Start using My Journey for articles, research, references, and more",
                      duration: "2:58",
                      videoUrl: "https://youtu.be/1CBrsTP7_80",
                    },
                    {
                      label: "What is My Journey?",
                      title:
                        "Discover My Journey and how easy it is to start using",
                      duration: "2:14",
                      videoUrl: "https://youtu.be/dibJqL6rQoM",
                    },
                    {
                      label: "New diagnosis of breast cancer",
                      videoUrl: "https://youtu.be/58rCO5ucbLs",
                    },
                    {
                      label: "Communicating with family and friends",
                      videoUrl: "https://youtu.be/Aq9I1gfjRns",
                    },
                    {
                      label: "Getting through treatment for breast cancer",
                      videoUrl: "https://youtu.be/kGTHz4jZzfw",
                    },
                    {
                      label: "Other ways to manage anxiety",
                      videoUrl: "https://youtu.be/_wntEa0vpZk",
                    },
                  ].map((item, index) => (
                    <Components.Box
                      key={index}
                      flex
                      flexDirection="col"
                      justifyContent="between"
                      fullHeight
                    >
                      {/* <Components.TextVariation
                  variation="label1"
                  color="blueNavy"
                  spacing="og6"
                  fullWidth
                >
                  {item.name}
                </Components.TextVariation> */}
                      <Components.Box fullWidth>
                        <Components.Video
                          title={item.label}
                          videoUrl={item.videoUrl}
                          // duration={item.duration}
                        />
                      </Components.Box>
                    </Components.Box>
                  ))
              : [
                  {
                    label: "Welcome aboard My Journey",
                    title:
                      "Start using My Journey for articles, research, references, and more",
                    duration: "2:58",
                    videoUrl: "https://youtu.be/1CBrsTP7_80",
                  },
                  {
                    label: "What is My Journey?",
                    title:
                      "Discover My Journey and how easy it is to start using",
                    duration: "2:14",
                    videoUrl: "https://youtu.be/dibJqL6rQoM",
                  },
                  {
                    label: "New diagnosis of breast cancer",
                    videoUrl: "https://youtu.be/58rCO5ucbLs",
                  },
                  {
                    label: "Communicating with family and friends",
                    videoUrl: "https://youtu.be/Aq9I1gfjRns",
                  },
                  {
                    label: "Getting through treatment for breast cancer",
                    videoUrl: "https://youtu.be/kGTHz4jZzfw",
                  },
                  {
                    label: "Other ways to manage anxiety",
                    videoUrl: "https://youtu.be/_wntEa0vpZk",
                  },
                ].map((item, index) => (
                  <Components.Box
                    key={index}
                    flex
                    flexDirection="col"
                    justifyContent="between"
                    fullHeight
                  >
                    {/* <Components.TextVariation
                  variation="label1"
                  color="blueNavy"
                  spacing="og6"
                  fullWidth
                >
                  {item.name}
                </Components.TextVariation> */}
                    <Components.Box fullWidth>
                      <Components.Video
                        title={item.label}
                        videoUrl={item.videoUrl}
                        // duration={item.duration}
                      />
                    </Components.Box>
                  </Components.Box>
                ))}
          </Components.HorizontalSlider>
        </StyledWrapperNegative>
        {1 < 0 && (
          <React.Fragment>
            <Components.HorizontalRule />
            <Components.TextVariation
              variation="heading1"
              spacing="og4"
              fullWidth
            >
              {"A message from our CEO"}
            </Components.TextVariation>
            <Components.Box spacing="og10">
              <Components.Video
                title={
                  <>
                    A welcome message
                    <br />
                    from BCNA CEO
                    <br />
                    Kirsten Pilatti
                  </>
                }
                videoUrl="https://www.youtube.com/watch?v=bPXfHOoo8Vo"
                duration="2:42"
              />
            </Components.Box>
          </React.Fragment>
        )}
      </StyledWrapperWhite>
      <StyledWrapperNegative>
        <StyledWrapperPink>
          <Components.TextVariation
            variation="heading1"
            color="blueNavy"
            spacing="og4"
            fullWidth
          >
            {"My content"}
          </Components.TextVariation>
          <Components.TextVariation
            variation="paragraph1"
            color="greyDark"
            spacing="og4"
            fullWidth
          >
            {
              "Just a reminder... My Journey recommends info and articles based on on your current diagnosis, situation and preferences."
            }
          </Components.TextVariation>
          <Components.TextVariation
            variation="heading4"
            color="blueNavy"
            spacing="og2"
            fullWidth
          >
            {"My diagnosis"}
          </Components.TextVariation>
          <Components.TextVariation
            variation="paragraph1"
            color="greyDark"
            spacing="og4"
            fullWidth
          >
            {patientContext.diagnosis?.name}
          </Components.TextVariation>
          <Components.TextVariation
            variation="heading4"
            color="blueNavy"
            spacing="og2"
            fullWidth
          >
            {"My situation"}
          </Components.TextVariation>
          <Components.TextVariation
            variation="paragraph1"
            color="greyDark"
            spacing="og4"
            fullWidth
          >
            {patientContext.situation?.name}
          </Components.TextVariation>
          <Components.TextVariation
            variation="heading4"
            color="blueNavy"
            spacing="og2"
            fullWidth
          >
            {"I'm interested in information relating to particular groups:"}
          </Components.TextVariation>
          <Components.Box spacing="og4">
            <StyledWrapperNegative>
              <Components.HorizontalSlider itemWidth={40}>
                {userTypes.map((item) => (
                  <Components.Box key={item.value} textAlign="center">
                    <StyledUserTypeImage src={item.image} />
                    <Components.TextVariation
                      variation="label2"
                      color="greyDark"
                    >
                      {item.label}
                    </Components.TextVariation>
                  </Components.Box>
                )) ?? []}
              </Components.HorizontalSlider>
            </StyledWrapperNegative>
          </Components.Box>
          <Components.TextVariation
            variation="heading4"
            color="blueNavy"
            spacing="og4"
            fullWidth
          >
            {
              "Update your content preferences at any time by selecting 'Customise my content' on your My Journey home screen."
            }
          </Components.TextVariation>
          <Components.ButtonVariation
            variation="square"
            iconLeft={<IconStar />}
            onClick={() => modalContext.toggleModal("accountCustomise")}
          >
            {"Customise my content"}
          </Components.ButtonVariation>
        </StyledWrapperPink>
      </StyledWrapperNegative>
      <StyledWrapperWhite>
        <Components.Box spacing="og4">
          <Components.TextVariation
            variation="paragraph1bold"
            color="blueNavy"
            align="center"
            fullWidth
          >
            {
              "If you can't find the information you're looking for or need further assistance, please contact the BCNA Helpline on"
            }
          </Components.TextVariation>
        </Components.Box>
        <Components.Box spacing="og4">
          <Components.TextVariation
            variation="headingdisplayshrink"
            color="pink"
            align="center"
            fullWidth
          >
            <Components.TextLink
              textVariation="none"
              href="tel:1800500258"
              target="_blank"
            >
              {"1800 500 258"}
            </Components.TextLink>
          </Components.TextVariation>
        </Components.Box>
        <Components.Box spacing="og4">
          <Components.TextVariation
            variation="paragraph1bold"
            color="blueNavy"
            align="center"
            fullWidth
          >
            {"You can also contact us here"}
          </Components.TextVariation>
        </Components.Box>
        <Components.Box spacing="og4">
          <Components.TextVariation
            variation="headingdisplayshrink"
            color="pink"
            align="center"
            fullWidth
          >
            <Components.TextLink
              textVariation="none"
              href="https://www.bcna.org.au/get-in-touch/"
              target="_blank"
            >
              {"Get in touch"}
            </Components.TextLink>
          </Components.TextVariation>
        </Components.Box>
      </StyledWrapperWhite>
    </Components.AppLayout>
  );
};

export type TemplateWelcomeProps = unknown;
