import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import parse from "html-react-parser";
import { ArticleAccordion } from "~/components/Accordion/ArticleAccordion";

export const QuestionBlocks: React.FC<Props> = ({ article }) => {
  const items = article.questionBlocks?.map(({ subheading, questions }) => ({ label: subheading, content: <>{parse(`<ul>${questions.map((question) => `<li>${question}</li>`).join("")}</ul>`)}</>}));
  return (
    <ArticleAccordion
      articleTitle={article.name}
      items={items}
    />
  );
};

type Props = {
  article: MergedArticle
}
