import * as React from "react";
import { useHistory } from "react-router-dom";

export const withSignUpSupporters = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "SignUpSupporters", ...props }: Props) => {
  Component.displayName = name;
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Component
      { ...props as P }
      handleBack={handleBack}
    />
  );
};
type Props = {
  name?: string
}
