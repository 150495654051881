import * as ReactDom from "react-dom";
import * as ReactHotLoader from "react-hot-loader";
import * as Sentry from "@sentry/react";
import * as SentryTracing from "@sentry/tracing";
import { getBreadcrumbPath } from "~/utils/sentry";

import { Router } from "./router";

const bootstrap = async () => {
  Sentry.init({
    dsn: runtimeconfig.sentry_dsn,
    release: `${packagejson.npm_package_version}-${runtimeconfig.application_stage}`,
    environment: runtimeconfig.application_stage,
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
      new SentryTracing.Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 0.5,
    ignoreErrors: [
      "TypeError: cancelled",
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource."
    ],
    beforeSend: (event) => {
      const exceptionValue = event.exception?.values?.[0];
      const stacktrace = exceptionValue?.stacktrace;
      for (const frame of stacktrace?.frames ?? []) {
        if (typeof frame.filename !== "undefined" && frame.filename !== "[native code]") {
          frame.filename = frame.filename.replace(/^capacitor/, "https");
        }
      }

      return event;
    },
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category?.match(/^ui\./)) {
        if (hint?.event.target) {
          breadcrumb.message = getBreadcrumbPath(hint.event.target) || breadcrumb.message;
        }
      }

      return breadcrumb;
    }
  });

  const HotApp = ReactHotLoader.hot(module)(Router);
  ReactDom.render(<HotApp />, document.getElementById("root"));
};

document.addEventListener("DOMContentLoaded", bootstrap);

// @ts-expect-error
module.hot?.accept()
