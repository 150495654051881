import * as React from "react";

import * as Providers from "~/providers";
import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Components from "~/components";

import IconTopic from "~/../assets/icons/icon-topic.svg";
import IconBookmark from "~/../assets/icons/icon-bookmark.svg";

export const TemplateDashboardResourceArticles: React.FunctionComponent<TemplateDashboardResourceArticlesProps> = () => {
  const patientContext = React.useContext(Providers.PatientContext);
  const contentApiHook = Hooks.useContentApi();

  React.useEffect(() => {
    (async () => {
      await contentApiHook.getArticles();
    })();
  }, []);

  return (
    <React.Fragment>
      <Components.Box
        spacing="og6"
      >
        <Components.TextVariation
          variation="heading1"
          spacing="og1"
          fullWidth
        >
          {"My articles"}
        </Components.TextVariation>
        <Components.TextVariation
          variation="heading3"
          fullWidth
        >
          {"Learn about what to expect"}
        </Components.TextVariation>
      </Components.Box>
      <Components.Box
        spacing="og6"
      >
        <Components.SearchForm />
      </Components.Box>
      <Components.Grid
        cols="1"
        gap="2"
        spacing="og6"
      >
        <Components.ButtonVariation
          href={Utils.USER_ARTICLES}
          variation="square"
          iconLeft={<IconTopic />}
        >
          {"Articles by topic"}
        </Components.ButtonVariation>
        <Components.ButtonVariation
          href={Utils.USER_ARTICLES_BOOKMARKS}
          variation="square"
          iconLeft={<IconBookmark />}
        >
          {"Bookmarks"}
        </Components.ButtonVariation>
      </Components.Grid>
      {patientContext.categories?.filter((item) => patientContext.userArticles?.[item.id]).map((item) => (
        <React.Fragment
          key={item.id}
        >
          <Components.TextVariation
            variation="heading4"
            fullWidth
          >
            {item.name}
          </Components.TextVariation>
          <Components.Box>
            <Components.HorizontalSlider
              itemWidth={[60,25]}
              dynamicWidth
              hideNavigation
            >
            {(patientContext.userArticles?.[item.id] ?? []).map((itemArticle) => (
              <Components.Box
                key={itemArticle.id}
                spacing="og4"
              >
                <Components.ArticleCard
                  key={itemArticle.id}
                  article={itemArticle}
                />
              </Components.Box>
            ))}
            </Components.HorizontalSlider>
          </Components.Box>
        </React.Fragment>
      ))}
      <Components.ViewAllLink
        label="View all articles"
        href={Utils.USER_ARTICLES}
      />
    </React.Fragment>
  );
};

export type TemplateDashboardResourceArticlesProps = unknown;
