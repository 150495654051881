import * as React from "react";
import ReactDOM from "react-dom";
import { format } from "date-fns";
import { Content, Header, Overlay, OverlayTitle, CloseButton, Inner } from "./styledEditNote";
import CloseIcon from "~/../assets/icons/cross.svg";
import { withEditNote } from "./withEditNote";
import { NoteForm } from "../NoteForm/NoteForm";
import { ButtonLink, Strong } from "../Styled";
import { Note } from "myjourney-frontend/src/vendor/umbraco";

export const EditNote = withEditNote(({
  id, handleEditNoteId, handleSubmit, title, setTitle, handleText, text, textError, setImageIds, error, note, date, saving,
}: Props) => ReactDOM.createPortal(
  note ? (
    <Overlay
      active={Boolean(id)}
    >
      <Inner>
        <Header>
          <div>
            <OverlayTitle>
              <Strong
                weight="bold"
              >{"Editing: "}</Strong>{note.title}
            </OverlayTitle>
            <ButtonLink
              fontSize="xs"
              weight="medium"
              underlined
            >
              {format(date, "dd MMMM yyyy")}
            </ButtonLink>
          </div>
          <CloseButton
            onClick={() => handleEditNoteId()}
          >
            <CloseIcon
              width="20"
              height="20"
              viewBox="0 0 24 24"
            />
          </CloseButton>
        </Header>
        <Content>
          <NoteForm
            handleSubmit={handleSubmit}
            setTitle={setTitle}
            title={title}
            handleText={handleText}
            text={text}
            textError={textError}
            setImageIds={setImageIds}
            error={error}
            disabled={saving}
          />
        </Content>
      </Inner>
    </Overlay>
  ) : null, document.querySelector("#root") as Element
));

type Props = {
  id?: string;
  handleEditNoteId: (id?: string) => void
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  setTitle: React.Dispatch<React.SetStateAction<string>>
  handleText: (value: string) => void
  title: string
  text: string
  setImageIds: React.Dispatch<React.SetStateAction<Array<string>>>
  error: string | null
  textError: string | null
  note: Note
  date: Date
  saving: boolean
  getNotes?: () => Promise<void>
}
