import * as React from "react";
import IconJourney from "~/../assets/icons/icon-hp-intro-journey.svg";
import IconInfo from "~/../assets/icons/icon-hp-intro-information.svg";
import IconSupport from "~/../assets/icons/icon-hp-intro-support.svg";

export const withHpIntro = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "HpIntro", ...props }: Props) => {
  Component.displayName = name;

  const usps = [
    {
      Icon: IconJourney,
      content: "Personalised content, specific to your patients' diagnosis and situation",
    },
    {
      Icon: IconInfo,
      content: "Patient symptom tracking with severity, notes and photos",
    },
    {
      Icon: IconSupport,
      content: "Download a summary log of your patients' symptoms over time",
    },
  ];

  return (
    <Component
      { ...props as P & Props }
      usps={usps}
    />
  );
};

type Props = {
  name?: string
}
