import * as React from "react";
import { Switch } from "../Switch/Switch";
import { SwitchWrapper } from "./styledSwitchGroup";

export const SwitchGroup: React.FC<Props> = ({ options, handleToggle, selected, displayAll }) => (
  <SwitchWrapper>
    {displayAll ? (
      <Switch
        label="All"
        value="all"
        active={!selected.length}
        handleToggle={handleToggle}
      />
    ) : null}
    {options.map(({ label, value }) => (
      <Switch
        key={value}
        label={label}
        value={value}
        active={selected.includes(value)}
        handleToggle={handleToggle}
      />
    ))}
  </SwitchWrapper>
);

type Props = {
  options: Array<{
    label: string
    value: string
  }>
  handleToggle: (value: string) => void
  selected: Array<string>
  displayAll?: boolean
}