import tw, { styled } from "twin.macro";
import { Global, MaxWidths } from "../Styled/Global";

const styles = {
  ...Global,
  padding: {
    none: tw``,
    default: tw`px-8 py-14 md:(px-32 py-20)`,
    md: tw`px-4 py-7`,
  },
};

export const ModalOuter = styled.div(({ active }: { active: boolean }) => [
  tw`fixed inset-0 transition p-2 md:p-4 h-full w-full z-100 print:(hidden)`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0 pointer-events-none`]),
]);

export const ModalBackground = styled.div(({ active }: ActiveProps) => [
  tw`bg-black bg-opacity-80 fixed inset-0 w-full h-full transition duration-500`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0`]),
]);

export const ModalInner = tw.div`flex flex-col h-full items-center justify-center p-2 md:p-4`;
export const ModalContent = styled.div(({ 
  active, 
  maxWidth = "md",
  padding = "default",
}: ContentProps) => [
  tw`bg-white rounded-sm z-40 relative mx-auto transform transition duration-500 overflow-y-auto`,
  ...(active ? [tw`translate-y-0`] : [tw`translate-y-28`]),
  styles.padding[padding],
  styles.maxWidth[maxWidth],
]);

export const CloseButton = tw.button`bg-white rounded-full flex items-center justify-center text-pink hover:bg-pink-light h-12 w-12 absolute top-0 right-0 mr-4 mt-4 focus:outline-none`;

type ActiveProps = {
  active: boolean
}

type ContentProps = ActiveProps & {
  maxWidth?: MaxWidths
  padding?: "default" | "none" | "md"
}
