import * as React from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "~/hooks/useAnalytics";

export const Meta: React.FC<Props> = ({
  title,
  metaDescription,
  children,
}) => {
  const { trackRouteChange } = useAnalytics();
  const { pathname } = useLocation();
  
  React.useEffect(() => {
    trackRouteChange({
      current: pathname,
      previous: window.prevRoute || pathname,
      title: document.title,
    });
  }, [title]);

  return (
    <Helmet>
      <title>{`${title} | My Journey`}</title>
      {metaDescription && <meta
        name="description"
        content={metaDescription}
                          />}
      {children}
    </Helmet>
  );
};

type Props = {
  title: string
  metaDescription?: string
}
