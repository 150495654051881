import tw, { styled } from "twin.macro";

const styles = {
  spacing: {
    default: tw`mt-og10 mb-og10`,
    row: tw`mt-6 mb-8`,
    none: tw``,
  },
};

export const HorizontalRule = styled.hr(({
  spacing = "default",
}: Props) => [
  tw`w-full max-w-full border-t border-pink-dusty`,
  styles.spacing[spacing],
]);

type Props = {
  spacing?: "default" | "row" | "none";
}
