import * as React from "react";
import { SymptomResponse } from "myjourney-frontend/src/vendor/umbraco";
import { useSymptomTrackerApi } from "./useSymptomTrackerApi";

const SEVERITY_DEFAULT = 5;
const TEXT_DEFAULT = "";

export const useSymptomForm = (symptom?: SymptomResponse | null): SymptomFormProps => {
  const { getUserImage } = useSymptomTrackerApi();
  const [severity, setSeverity] = React.useState<number>(SEVERITY_DEFAULT);
  const [temperature, setTemperature] = React.useState<number>(372);
  const [description, setDescription] = React.useState<string>(TEXT_DEFAULT);
  const [actions, setActions] = React.useState<string>(TEXT_DEFAULT);
  const [questions, setQuestions] = React.useState<string>(TEXT_DEFAULT);
  const [files, setFiles] = React.useState<Array<Blob>>([]);

  React.useEffect(() => {
    if (symptom) {
      setSeverity(symptom.symptomLogEntry.severity);
      setTemperature(symptom.symptomLogEntry.severity);
      setDescription(symptom.symptomLogEntry.description);
      setActions(symptom.symptomLogEntry.actionsTaken);
      setQuestions(symptom.symptomLogEntry.questions);

      if (symptom?.symptomLogEntry?.images?.length) {
        symptom.symptomLogEntry.images.forEach(async (imageId) => {
          const res = await getUserImage(imageId);
          const blob = await res.blob();
          setFiles((prev) => [...prev, blob]);
        });
      }
    }
  }, [symptom]);

  const handleSeverity = ({ x }: { x: number }) => {
    setSeverity(x);
  };

  const handleDescription = (val: string) => {
    setDescription(val);
  };

  const handleActions = (val: string) => {
    setActions(val);
  };

  const handleQuestions = (val: string) => {
    setQuestions(val);
  };

  const handleAddNew = () => {
    setSeverity(SEVERITY_DEFAULT);
    setDescription(TEXT_DEFAULT);
    setActions(TEXT_DEFAULT);
    setQuestions(TEXT_DEFAULT);
  };

  return {
    handleSeverity,
    handleDescription,
    handleActions,
    handleQuestions,
    handleAddNew,
    setTemperature,
    questions,
    actions,
    description,
    temperature,
    severity,
    files,
  };
};

export type SymptomFormProps = {
  handleDescription: (val: string) => void
  handleActions: (val: string) => void
  handleQuestions: (val: string) => void
  handleSeverity: (val: { x: number }) => void
  handleAddNew: () => void
  setTemperature: React.Dispatch<React.SetStateAction<number>>
  symptomResponse?: SymptomResponse | null
  severity: number
  questions: string
  actions: string
  description: string
  temperature: number
  files: Array<Blob>
}