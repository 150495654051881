import * as React from "react";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Components from "~/components";

import IconBookmark from "~/../assets/icons/icon-bookmark.svg";
import IconInfoCircle from "~/../assets/icons/icon-info-circle.svg";

export const Articles: React.FunctionComponent<ArticlesProps> = () => {
  Hooks.useAuthentication();
  const searchProps = Hooks.useSearch();

  return (
    <Components.AppLayout
      headerVariation="navigationHome"
    >
      <Components.Meta
        title={`${searchProps.keywordSearch ? `${searchProps.keywordSearch} | Article Search` : ""} Articles`}
      />
      <Components.TextVariation
        variation="heading1"
        spacing="og1"
        fullWidth
      >
        {"My articles"}
      </Components.TextVariation>
      <Components.TextVariation
        variation="heading3"
        spacing="og4"
        fullWidth
      >
        {"What would you like to learn about?"}
      </Components.TextVariation>
      <Components.Card
        color="pinkMedium"
        padding="md"
        spacing="og4"
        aria-label="Search card"
      >
        <Components.Grid
          cols="1"
          gapY="4"
        >
          <Components.SearchForm />
          <Components.ButtonVariation
            href={Utils.USER_ARTICLES_BOOKMARKS}
            variation="square"
            iconLeft={<IconBookmark />}
          >
            {"Bookmarks"}
          </Components.ButtonVariation>
          <Components.ButtonVariation
            href={Utils.APP_GLOSSARY("a")}
            variation="square"
            iconLeft={<IconInfoCircle />}
          >
            {"Glossary"}
          </Components.ButtonVariation>
        </Components.Grid>
      </Components.Card>
      <Components.TextVariation
        variation="heading1"
        spacing="og1"
      >
        {"Topics"}
      </Components.TextVariation>
      <Components.TextVariation
        variation="heading3"
        spacing="og4"
      >
        {"Which topic interests you today?"}
      </Components.TextVariation>
      <Components.TopicList />
    </Components.AppLayout>
  );
};

export type ArticlesProps = unknown;
