import * as React from "react";
import ArrowRight from "~/../assets/icons/thin-arrow-right.svg";
import { Column, MediumSmall, Row, StyledLink } from "../Styled";
import { Wraps } from "../Styled/Global";
import { ArrowButton, ArrowContainer } from "./styledArrowScroller";

export const ArrowScroller: React.FC<Props> = ({
  handleScroll, children, view, link, linkText,
}) => (
  <>
    {children}
    <Row
      spacing="none"
      nowrap="xs"
    >
      {link && linkText ? (
        <Column>
          <MediumSmall
            spacing="none"
          >
            <StyledLink
              to={link}
              weight="medium"
            >
              {linkText}
            </StyledLink>
          </MediumSmall>
        </Column>
      ) : null}
      <Column>
        <ArrowContainer
          view={view}
        >
          {handleScroll ? (
            <ArrowButton
              onClick={() => handleScroll()}
            >
              <ArrowRight />
            </ArrowButton>
          ) : link && (
            <StyledLink
              to={link}
            >
              <ArrowButton>
                <ArrowRight />
              </ArrowButton>
            </StyledLink>
          )}
        </ArrowContainer>
      </Column>
    </Row>
  </>
);

type Props = {
  handleScroll?: () => void
  view?: Wraps 
  link?: string
  linkText?: string
}
