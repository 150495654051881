import * as React from "react";
import { PatientContext } from "../providers/PatientProvider";
import { API_GET_IMAGE, API_GET_NOTES_BY_DATE_RANGE, API_GET_SYMPTOM, API_GET_SYMPTOM_BY_DATE, API_GET_SYMPTOM_BY_DATE_RANGE, API_GET_SYMPTOM_MONTH, API_POST_ATTACHMENT_IMAGE, API_POST_NOTES_ADD, API_POST_NOTES_UPDATE, API_POST_SYMPTOM_EDIT, API_POST_TRACK_SYMPTOM } from "../utils/constants";
import Cookies from "js-cookie";
export const useSymptomTrackerApi = (): {
	uploadImage: typeof uploadImage
	saveSymptom: typeof saveSymptom
	editSymptom: typeof editSymptom
	saveNote: typeof saveNote
	editNote: typeof editNote
	getSymptomById: typeof getSymptomById
	getSymptomsByMonth: typeof getSymptomsByMonth
	getSymptomsByDate: typeof getSymptomsByDate
	getSymptomsByDateRange: typeof getSymptomsByDateRange
	getNotesByDateRange: typeof getNotesByDateRange
	getUserImage: typeof getUserImage
} => {
	const URL = `${runtimeconfig.koben_tracker_domain}${runtimeconfig.koben_tracker_version}`;

	const {
		auth,
	} = React.useContext(PatientContext);
	const getSymptomApi = async (url: string, token?: string) => {
		const kobenToken = token ?? (Cookies.get("koben_token") || auth?.koben_token.access_token);
		return fetch(`${URL}${url}`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": kobenToken ? `Bearer ${kobenToken}` : "Bearer",
			},
			method: "GET",
		});
	};

	const postSymptomApi = async (url: string, body: Record<string, unknown>) => fetch(`${URL}${url}`, {
		headers: {
			"Content-Type": "application/json",
			"Authorization": Cookies.get("koben_token") ? `Bearer ${Cookies.get("koben_token")}` : auth?.koben_token ? `Bearer ${auth.koben_token.access_token}` : "Bearer",
		},
		method: "POST",
		body: JSON.stringify(body),
	});

	const uploadImage = async (file: File): Promise<Response> => {
		const body = new FormData();
		body.append("file", file);
		const options = {
			headers: {
				"Authorization": Cookies.get("koben_token") ? `Bearer ${Cookies.get("koben_token")}` : auth?.koben_token ? `Bearer ${auth.koben_token.access_token}` : "Bearer",
			},
			method: "POST",
			body,
		};
		return fetch(`${URL}${API_POST_ATTACHMENT_IMAGE}`, options);
	};

	const getDateParams = ({ FromDay, FromMonth, FromYear, ToDay, ToMonth, ToYear }: GetByDateParams): string => {
		const urlParams = new URLSearchParams();
		urlParams.set("FromDay", `${FromDay}`);
		urlParams.set("FromMonth", `${FromMonth}`);
		urlParams.set("FromYear", `${FromYear}`);
		urlParams.set("ToDay", `${ToDay}`);
		urlParams.set("ToMonth", `${ToMonth}`);
		urlParams.set("ToYear", `${ToYear}`);
		return urlParams.toString();
	};

	const saveSymptom = async (body: AddSymptomBody): Promise<Response> => await postSymptomApi(API_POST_TRACK_SYMPTOM, body);
	const editSymptom = async (body: EditSymptomBody): Promise<Response> => await postSymptomApi(API_POST_SYMPTOM_EDIT, body);

	const saveNote = async (body: NoteBody): Promise<Response> => await postSymptomApi(API_POST_NOTES_ADD, body);
	const editNote = async (body: EditNoteBody): Promise<Response> => await postSymptomApi(API_POST_NOTES_UPDATE, body);

	const getSymptomById = async (id: string): Promise<Response> => await getSymptomApi(API_GET_SYMPTOM.replace(":id", id));
	const getSymptomsByMonth = async ({ Month, Year }: GetByMonthParams): Promise<Response> => {
		const urlParams = new URLSearchParams();
		urlParams.set("Month", `${Month}`);
		urlParams.set("Year", `${Year}`);
		return await getSymptomApi(`${API_GET_SYMPTOM_MONTH}?${urlParams.toString()}`);
	};
	const getSymptomsByDate = async ({ Day, Month, Year }: GetByDayParams): Promise<Response> => {
		const urlParams = new URLSearchParams();
		urlParams.set("Day", `${Day}`);
		urlParams.set("Month", `${Month}`);
		urlParams.set("Year", `${Year}`);
		return await getSymptomApi(`${API_GET_SYMPTOM_BY_DATE}?${urlParams.toString()}`);
	};
	const getSymptomsByDateRange = async (dates: GetByDateParams): Promise<Response> => await getSymptomApi(`${API_GET_SYMPTOM_BY_DATE_RANGE}?${getDateParams(dates)}`);

	const getNotesByDateRange = async (dates: GetByDateParams): Promise<Response> => await getSymptomApi(`${API_GET_NOTES_BY_DATE_RANGE}?${getDateParams(dates)}`);

	const getUserImage = async (id: string, token?: string): Promise<Response> => {

		const urlParams = new URLSearchParams();
		urlParams.set("id", id);
		return getSymptomApi(`${API_GET_IMAGE}?${urlParams.toString()}`, token);
	};

	return {
		uploadImage,
		saveSymptom,
		editSymptom,
		saveNote,
		editNote,
		getSymptomById,
		getSymptomsByMonth,
		getSymptomsByDate,
		getSymptomsByDateRange,
		getNotesByDateRange,
		getUserImage,
	};
};

type GetByMonthParams = {
	Month: number
	Year: number
}

type GetByDayParams = {
	Day: number
	Month: number
	Year: number
}

type GetByDateParams = {
	FromDay: number
	FromMonth: number
	FromYear: number
	ToDay: number
	ToMonth: number
	ToYear: number
}

type NoteBody = {
	title: string
	text: string
	day: number
	month: number
	year: number
	attachments: Array<string>
	images: Array<string>
}

type AddSymptomBody = {
	symptomTypeId: number
	symptomType: string
	severity: number
	severityType: string
	description: string
	actionsTaken: string
	questions: string
	day: number
	month: number
	year: number
	images: Array<string>
}

type EditSymptomBody = {
	symptomId: string
	severity: {
		severity: number
		severityType: string
	}
	additional: {
		questions: string
		actionsTaken: string
		description: string
	}
	date: {
		day: number
		month: number
		year: number
	}
	images: Array<string>
	attachments: Array<string>
}


type EditNoteBody = {
	noteId: string;
	additional: {
		title: string;
		text: string;
	},
	images: Array<string>;
	attachments: Array<string>;
}
