import tw, { styled } from "twin.macro";

const widths = [tw`w-1/6`, tw`w-2/6`, tw`w-3/6`, tw`w-4/6`, tw`w-5/6`, tw`w-full`];

export const Header = styled.div(({ step }: Props) => [
  tw`px-4 pb-6 mb-8 relative flex justify-between sm:(pt-6 px-6) transition duration-500 transform`,
  ...(step === -1 ? [tw`opacity-0 translate-y-full`] : [tw`opacity-100 translate-y-0`]),
]);

export const ProgressBar = tw.div`absolute inset-x-0 bottom-0 bg-pink-light`;
export const ProgressInner = styled.div(({ step }: Props) => [
  tw`h-0.5 bg-pink transition-all`,
  widths[step],
]);

type Props = {
  step: number
}
