import * as React from "react";

import * as Components from "~/components";

import { SignUpStepProps } from "../SignUp";

export const StepDetails: React.FunctionComponent<SignUpStepProps> = (props) => (
  <React.Fragment>
    <Components.TextVariation
      variation="headingdisplay"
      spacing="og6"
      fullWidth
    >
      {"My details"}
    </Components.TextVariation>
    <Components.TextContainer
      spacing="og4"
    >
      <Components.TextVariation
        variation="heading3"
        color="greyDark"
      >
        {"Please tell us a little about yourself"}
      </Components.TextVariation>
    </Components.TextContainer>
    <Components.Grid
      cols="1"
      colsMd="2"
      gapX="4"
      gapY="6"
      spacing="og6"
    >
      <Components.Input
        label="First name"
        placeholder="My first name"
        onChange={(e) => props.handleFirstName(e.target.value)}
        error={props.firstNameError}
        valid={props.firstNameValid}
        value={props.firstName}
        required
      />
      <Components.Input
        label="Last name"
        placeholder="My last name"
        onChange={(e) => props.handleLastName(e.target.value)}
        error={props.lastNameError}
        valid={props.lastNameValid}
        value={props.lastName}
        required
      />
      <Components.DateInput
        label="Date of birth"
        onChange={(value) => props.handleDateOfBirth(value)}
        error={props.dateOfBirthError}
        valid={props.dateOfBirthValid}
        value={props.dob}
        onBlur={() => props.dateOfBirthValid ? props.handleFieldTracking("Year of birth", "yob", 4, `${new Date(props.dob ?? "").getFullYear()}`) : null}
        max={new Date()}
        required
      />
      <Components.Input
        label="Postcode"
        placeholder="My postcode"
        onChange={(e) => props.handlePostcode(e.target.value)}
        error={props.postcodeError}
        valid={props.postcodeValid}
        value={props.postcode}
        onBlur={() => props.handleFieldTracking("Postcode", "postcode", 4, props.postcode)}
        required
      />
    </Components.Grid>
  </React.Fragment>
);
