import tw, { css, styled } from "twin.macro";
import { Colours, FontSizes, Global } from "../Styled/Global";

const styles = {
  ...Global,
  padding: {
    md: tw`px-4 py-3 md:(py-4 px-20) print:(px-0)`,
    xl: tw`py-8`,
  },
  contentPadding: {
    md: tw`pb-4 px-4 md:px-20 print:(px-0)`,
    xl: tw`pb-4 `,
  },
};

export const Items = styled.ul(() => [
  styles.border["greyLight"],
  tw`block mb-0 border-b`,
]);
export const SymptomItems = tw.ul`block`;
export const Item = styled.li(({ border = "pinkDusty" }: { border?: Colours }) => [
  tw`border-t print:(border-0)`,
  styles.border[border],
]);

export const Button = styled.button(() => [
  tw`w-full flex items-center select-none cursor-pointer focus:outline-none py-og4`,
]);

export const ArticleButton = styled.button(() => [
  tw`w-full flex items-center select-none cursor-pointer focus:outline-none py-og4 px-og12`,
]);

export const SymptomButton = styled.button(({
  padding = "xl",
  active,
}: ButtonProps) => [
  styles.padding[padding],
  styles.background.white,
  tw`w-full flex items-center select-none cursor-pointer focus:outline-none sticky border-b border-pink-light`,
  ...(active ? [tw`z-20`] : []),
  css`
    top: 189px;
  `,
]);

export const Label = styled.p(({
  colour = "blueNavy",
  size = "base",
}: LabelProps) => [
  styles.colour[colour],
  styles.fontSize[size],
  tw`font-medium flex-1 text-left tracking-wide print:(text-blue-navy)`,
]);

export const SymptomLabel = styled(Label)(({
  active, 
}: { 
  active?: boolean
}) => [
  tw`text-sm`,
  ...(active ? [tw`font-semibold`] : []),
]);

export const IconWrapper = styled.span(({ 
  active,
  colour = "greyMedium",
}: IconProps) => [
  tw`block transform transition`,
  ...(active ? [tw`rotate-180`] : []),
  styles.colour[colour],
]);

export const Content = styled.div(({ active }: ContentProps) => [
  tw`print:(block)`,
  ...(active ? [tw`block pb-og4`] : [tw`hidden`]),
]);

export const ArticleContent = styled.div(({ active }: ContentProps) => [
  tw`print:(block)`,
  ...(active ? [tw`block pb-og4 px-og12`] : [tw`hidden`]),
]);
export const SymptomContent = styled.div(({ active, padding = "xl" }: ContentProps) => [
  tw`bg-grey-light px-4 pt-4 pb-10 md:(px-20) print:(block)`,
  styles.contentPadding[padding],
  ...(active ? [tw`block`] : [tw`hidden`]),
]);

type ButtonProps = {
  active?: boolean
  padding?: "md" | "xl"
}

type IconProps = {
  active: boolean
  colour?: Colours
}

type ContentProps = {
  active: boolean
  padding?: "md" | "xl"
}

type LabelProps = {
  colour?: Colours
  size?: FontSizes
}
