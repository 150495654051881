import * as React from "react";

import * as Hooks from "~/hooks";
import * as Components from "~/components";
import { Suggestion, SuggestionList, SuggestionListItem } from "../SearchForm/styledSearchForm";

import MagnifyingGlassIcon from "~/../assets/icons/magnifying-glass.svg";

export const SearchInput: React.FunctionComponent<SearchInputProps> = () => {
  const search = Hooks.useSearch();

  return (
    <Components.Form
      onSubmit={search.onSubmit}
    >
      <Components.Row
        spacing="none"
        justify="end"
      >
        <Components.Column
          justify="end"
          lg="1/2"
          spacing="sm"
        >
          <Components.SearchInputWrapper>
            <Components.FieldWrapper>
              <Components.StyledSearchInput
                type="text"
                value={search.keywordInput}
                placeholder="Search"
                onChange={(e) => search.onChangeKeyword(e.target.value)}
              />
              <Components.IconWrapper
                colour="pink"
              >
                <MagnifyingGlassIcon
                  width="17"
                  height="17"
                />
              </Components.IconWrapper>
            </Components.FieldWrapper>
            <SuggestionList
              active={Boolean(search.suggestions.length)}
            >
              {search.suggestions.slice(0, 5).map((suggestion) => (
                <SuggestionListItem
                  key={suggestion}
                >
                  <Suggestion
                    onClick={() => search.onClickSuggestion(suggestion)}
                  >{suggestion}</Suggestion>
                </SuggestionListItem>
              ))}
            </SuggestionList>
          </Components.SearchInputWrapper>
        </Components.Column>
      </Components.Row>
    </Components.Form>
  );
};

export type SearchInputProps = unknown
