import { format } from "date-fns";
import * as React from "react";

import { VendorSalesforce } from "@myjourney/shared";

import * as Utils from "~/utils";
import * as Components from "~/components";

import { SignUpStepProps } from "../SignUp";
import useGetMetastaticBreastCancerDiagnosisId from "~/hooks/useGetMetastaticBreastCancerDiagnosisId";

export const StepConfirm: React.FunctionComponent<SignUpStepProps> = (
  props
) => {
  const { metastaticDiagnosisId } = useGetMetastaticBreastCancerDiagnosisId();
  return (
    <React.Fragment>
      <Components.TextVariation
        variation="headingdisplay"
        spacing="og6"
        fullWidth
      >
        {"Confirm my account"}
      </Components.TextVariation>
      <Components.TextContainer spacing="og4">
        <Components.TextVariation variation="heading3" color="greyDark">
          {"Please review your details."}
        </Components.TextVariation>
      </Components.TextContainer>
      <Components.AccountCard
        title="Account details"
        linkText="Edit details"
        onClick={() => props.setStep(1)}
        items={[
          {
            label: "Email",
            value: props.email,
          },
          {
            label: "Phone number",
            value: props.phone,
          },
          {
            label: "Password",
            value: "***********",
          },
        ]}
      />
      <Components.AccountCard
        title="My details"
        linkText="Edit details"
        onClick={() => props.setStep(2)}
        items={[
          {
            label: "First name",
            value: props.firstName,
          },
          {
            label: "Last name",
            value: props.lastName,
          },
          {
            label: "Date of birth",
            value: props.dob ? format(props.dob, "dd / MM / yyyy") : undefined,
          },
          {
            label: "Postcode",
            value: props.postcode,
          },
        ]}
      />
      <Components.AccountCard
        title="Customize My Journey"
        linkText="Edit details"
        onClick={() => props.setStep(3)}
        items={[
          {
            label: "Diagnosis",
            value: props.diagnosisItem?.name,
          },
          {
            label: "Mon / Year of diagnosis",
            value: props.diagnosisDate
              ? format(props.diagnosisDate, "MM / yyyy")
              : undefined,
          },
          ...(props.diagnosisItem?.id !== metastaticDiagnosisId
            ? [
                {
                  label: "Scenario",
                  value: props.situationItem?.name,
                },
              ]
            : [
                {
                  label: "Scenario",
                  value: props.treatmentItem?.name,
                },
                {
                  label: "Locations",
                  value: props.locationItems
                    ?.map((item) => item.name)
                    .join(", "),
                },
              ]),
          {
            label: "Interested in information for ",
            value: props.userTypeData
              .filter((item) => props.selectedUserTypes.includes(item.value))
              .map((item) => item.label)
              .join(", "),
          },
        ]}
      />
      <Components.HorizontalRule />
      <Components.TextVariation
        variation="heading3"
        color="greyDark"
        spacing="og4"
      >
        {"How did you hear about My Journey?"}
      </Components.TextVariation>
      <Components.Radio
        checked={props.referralMethod}
        onChange={(value) =>
          props.handleReferralMethod(value as VendorSalesforce.ReferralMethod)
        }
        onBlur={(index) =>
          props.referralMethod &&
          props.handleFieldTracking(
            "How did you hear about us",
            "how-did-you-hear-about-us",
            index,
            props.referralMethod
          )
        }
        data={props.referralData}
      />
      {props.formError ? (
        <Components.ErrorContainer message={props.formError} />
      ) : null}
    </React.Fragment>
  );
};
