import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { GlobalStyles } from "twin.macro";
import Logo from "~/../assets/icons/logo.svg";

import * as Utils from "~/utils";
import * as Providers from "~/providers";
import * as Components from "~/components";
import * as Templates from "~/templates";
import * as Store from "store";

const FIVE_MINUTES = 300000;
export const Router: React.FunctionComponent = Sentry.withErrorBoundary(
  () => {
    const [isOffline, setIsOffline] = useState<null | boolean>(null);

    const protocolOrWhiteSpace = runtimeconfig.koben_umbraco_domain.includes(
      "https://"
    )
      ? ""
      : "https://";

    useEffect(() => {
      async function fetchOnlineStatus() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const bypassOffline = urlParams.get("bypassOffline");
        if (bypassOffline === "true" || Store.get("bypassOffline") === "true") {
          Store.set("bypassOffline", "true");
          setIsOffline(false);
        } else {
          try {
            const res = await fetch(
              `${
                protocolOrWhiteSpace + runtimeconfig.koben_umbraco_domain
              }/umbraco/api/App/GetStatus/`
            );
            console.log(res.status);
            if (res.status === 404) {
              setIsOffline(false);
            } else {
              const data = await res.json();
              if (data.offline === true) {
                Store.remove("auth");
                Store.remove("bypassOffline");
              }
              setIsOffline(data.offline);
            }
          } catch (err) {
            console.log("fetch status is not available");
            setIsOffline(false);
          }
        }
      }

      fetchOnlineStatus();
      const interval = setInterval(() => {
        fetchOnlineStatus();
      }, FIVE_MINUTES);

      return () => clearInterval(interval);
    }, []);

    if (isOffline === null) return null;

    if (isOffline === true)
      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Logo width={200} />
          </div>
          <Components.TextVariation
            variation="heading1"
            style={{ textAlign: "center", padding: 16 }}
          >
            My Journey is down for maintenance until Tuesday, 19 December.
            During this time, you can still access information via our website
            at{" "}
            <a href="https://bcna.org.au/" target="_blank" rel="noreferrer">
              bcna.org.au
            </a>
            , where you can view articles, podcasts, webcasts and more. For
            further information or support, please contact our Helpline on{" "}
            <a href="tel:1800500258">1800 500 258</a>.
          </Components.TextVariation>
        </div>
      );
    return (
      <Providers.AnalyticsProvider>
        <Providers.PatientProvider>
          <Providers.SymptomProvider>
            <GlobalStyles />
            <Providers.ToastProvider>
              <BrowserRouter>
                <Providers.ModalProvider>
                  <Components.RouteChanger />
                  <Switch>
                    <Route path="/hp/intro">
                      <Templates.HpIntro />
                    </Route>
                    <Route path="/hp">
                      {(props: any) => <Components.HpLayout {...props} />}
                    </Route>

                    {Utils.routes.map((route) => (
                      <Route path={route.path} exact key={route.path}>
                        <Sentry.ErrorBoundary
                          fallback={(props) => (
                            <Components.ErrorDetail {...props} />
                          )}
                          beforeCapture={(scope) => {
                            scope.setTag("route", route.path);
                          }}
                        >
                          <route.component />
                        </Sentry.ErrorBoundary>
                      </Route>
                    ))}
                    <Route path="*">
                      <Sentry.ErrorBoundary
                        fallback={(props) => (
                          <Components.ErrorDetail {...props} />
                        )}
                      >
                        <Templates.NotFound />
                      </Sentry.ErrorBoundary>
                    </Route>
                  </Switch>
                </Providers.ModalProvider>
              </BrowserRouter>
              <Components.Tooltip />
            </Providers.ToastProvider>
          </Providers.SymptomProvider>
        </Providers.PatientProvider>
      </Providers.AnalyticsProvider>
    );
  },
  {
    fallback: (props) => <Components.ErrorDetail {...props} />,
  }
);
