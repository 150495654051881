import * as React from "react";
import { debounce } from "ts-debounce";

import * as Utils from "~/utils";
import * as Providers from "~/providers";
import * as Hooks from "~/hooks";
import * as Components from "~/components";

export const useAccountDetails = (): AccountDetailsProps => {
  const { patientSearch } = Hooks.usePatientApi();

  const { auth } = React.useContext(Providers.PatientContext);
  const [email, setEmail] = React.useState<string>(auth?.patient_details?.email || "");
  const [phone, setPhone] = React.useState<string>(auth?.patient_details?.phone || "");
  const [emailValid, setEmailValid] = React.useState<boolean>(false);
  const [emailValidating, setEmailValidating] = React.useState<boolean>(false);
  const [emailError, setEmailError] = React.useState<FormError>();
  const [phoneValid, setPhoneValid] = React.useState<boolean>(false);
  const [phoneValidating] = React.useState<boolean>(false);
  const [phoneError, setPhoneError] = React.useState<FormError>();

  const handleEmail = async (value: string) => {
    setEmail(value);
    if (emailValidating) {
      setEmailValidating(false);
    }
    const debouncedFunction = debounce(async () => {
      if (value === "") {
        setEmailValid(false);
        setEmailError("Required");
      } else if (!Utils.validEmail(value)) {
        setEmailValid(false);
        setEmailError(React.createElement(Components.EmailError));
      } else {
        setEmailValidating(true);
        const res = await patientSearch({
          provider: "koben_identity",
          key: "email",
          value,
        });
        const json = await res.json();
        setEmailValidating(false);
        if (json?.data?.result) {
          setEmailError(React.createElement(Components.EmailExistsError));
          setEmailValid(false);
        } else {
          setEmailValid(true);
          setEmailError(undefined);
        }
      }
    }, 500);
    debouncedFunction();
  };

  const handlePhone = async (value: string) => {
    const parsedValue = Utils.getFormattedPhoneOrEmail(value);

    setPhone(parsedValue);
    if (parsedValue === "") {
      setPhoneValid(false);
      setPhoneError(undefined);
    } else if (!Utils.validPhone(parsedValue)) {
      setPhoneValid(false);
      const error = Utils.invalidDescriptionPhone(parsedValue);
      setPhoneError(error);
    } else {
      setPhoneValid(true);
      setPhoneError(undefined);
    }
  };

  return {
    handleEmail,
    handlePhone,
    emailValid,
    emailValidating,
    emailError,
    phoneValid,
    phoneValidating,
    phoneError,
    setEmail,
    setPhone,
    email,
    phone,
  };
};


export type AccountDetailsProps = {
  handleEmail: (value: string) => void
  handlePhone: (value: string) => void
  emailValid: boolean
  emailValidating: boolean
  emailError: FormError
  phoneValid: boolean
  phoneValidating: boolean
  phoneError: FormError
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setPhone: React.Dispatch<React.SetStateAction<string>>
  email: string
  phone: string
}

type FormError = JSX.Element | string | undefined;
