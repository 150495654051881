import * as React from "react";
import * as Store from "store";
import { Card, Column, Container, H3, P, Row } from "../Styled";

import * as Components from "~/components";

export const ErrorDetail: React.FC<Props> = ({ resetError }) => (
  <Container>
    <Card
      padding="md"
    >
      <H3>Oops, something went wrong!</H3>
      <P>We are aware of this issue and working to resolve it!</P>
      <Row>
        <Column
          spacing="xs"
        >
          <Components.ButtonVariation
            variation="primary"
            onClick={resetError}
            fullWidth
          >
            {"Click here to reset"}
          </Components.ButtonVariation>
        </Column>
        <Column>
          <Components.ButtonVariation
            variation="secondary"
            onClick={() => Store.remove("auth")}
            fullWidth
          >
            {"Click here to hard reset"}
          </Components.ButtonVariation>
        </Column>
      </Row>
    </Card>
  </Container>
);

type Props = {
  error: Error;
  componentStack: string | null;
  resetError: () => void;
}
