import tw, { css, styled } from "twin.macro";

export const EntryContainer = tw.div`border-r border-pink-light inline-block pr-4`;
export const EntryNumber = tw.span`text-pink text-xl block text-center font-medium`;
export const EntryText = tw.span`text-blue-navy tracking-widest uppercase text-xxxs block w-full text-center font-semibold`;
export const EntryTitle = tw.div`flex`;
export const Title = tw.p`text-blue-navy font-medium text-left`;
export const TitleWrapper = tw.div`pl-4 flex-grow self-start`;
export const DateText = tw.button`text-pink font-medium text-left`;
export const DateSpan = tw.span`text-pink font-medium text-left block`;
export const DateLabel = tw.span`font-medium text-xs text-blue-navy w-12 inline-block`;
export const DateRow = tw.div`flex items-start mb-2`;

export const ArrowWrapper = tw.div`inline-block px-1`;

export const SymptomsHeader = styled.div(({ stuck }: StuckProps ) => [
  tw`pointer-events-auto bg-pink-light -mx-4 px-4 pb-4 pt-8 md:(-mx-20 px-20)`,
  ...(stuck ? [tw`shadow pt-12`] : []),
]);

export const HeaderOuter = styled.header(({ active }: ActiveProps) => [
  tw`sticky top-0 pointer-events-none`,
  ...(active ? [tw`z-50`] : [tw`z-20`]),
  css`
    height: 155px;
  `,
]);

export const CalendarWrapper = styled.div(({ active }: ActiveProps) => [
  tw`bg-white absolute top-full inset-x-0 p-4 rounded-b-md -mx-3 md:(-mx-20 px-20)`,
  ...(active ? [] : [tw`hidden`]),
]);

export const RelativeContainer = tw.div`relative w-full mx-1`;

export const CalendarBackground = styled.div(({ active }: ActiveProps) => [
  tw`bg-black bg-opacity-80 fixed inset-0 transition duration-500 z-40`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0 pointer-events-none`]),
]);

type ActiveProps = {
  active: boolean
}

type StuckProps = {
  stuck: boolean
}
