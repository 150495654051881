import * as React from "react";
import tw, { css, styled } from "twin.macro";

import * as Utils from "~/utils";
import * as Components from "~/components";

import { SignUpStepProps } from "../SignUp";

import ExternalIcon from "~/../assets/icons/external.svg";

const StyledListIcon = styled.div(() => [
  tw`bg-pink text-white font-semibold rounded-full flex justify-center items-center mr-og4`,
  css`
    width: 28px;
    height: 28px;
  `,
]);

export const StepPrivacy: React.FunctionComponent<SignUpStepProps> = () => (
  <Components.Row>
    <Components.Column>
      <Components.TextVariation
        variation="headingdisplay"
        spacing="og6"
        fullWidth
      >
        {"Why My Journey"}
        <br />
        {"asks for your information"}
      </Components.TextVariation>
      <Components.Row>
        <Components.Column
          spacing="ogxxs"
        >
          <Components.TextVariation
            variation="paragraph1"
            color="greyDark"
          >
            <Components.Grid
              cols="1"
              gap="4"
            >
              {[{
                content: "In order to recommend articles and information, we will ask for your diagnosis and other details",
              },{
                content: "We understand your breast cancer journey is a very personal experience",
              },{
                content: "Breast Cancer Network Australia only collects personal information necessary for its functions and activities and to improve the quality of My Journey",
              }].map((item, index) => (
                <Components.Box
                  key={index}
                  flex
                >
                  <Components.Box>
                    <StyledListIcon>
                      {index + 1}
                    </StyledListIcon>
                  </Components.Box>
                  <Components.TextVariation
                    variation="paragraph1"
                    color="greyDark"
                  >
                    {item.content}
                  </Components.TextVariation>
                </Components.Box>
              ))}
            </Components.Grid>
          </Components.TextVariation>
        </Components.Column>
      </Components.Row>
      <Components.TextVariation
        variation="paragraph1bold"
        color="blueNavy"
        spacing="og4"
        fullWidth
      >
        {"By continuing you agree to our Terms of Use and Privacy Policy"}
      </Components.TextVariation>
      <Components.Grid
        cols="1"
        gap="2"
      >
        <Components.TextLink
          href={Utils.BCNA_TERMS_OF_USE}
          target="_blank"
          rel="noopener"
          iconLeft={<ExternalIcon />}
        >
          {"View Terms of Use"}
        </Components.TextLink>
        <Components.TextLink
          href={Utils.BCNA_PRIVACY_POLICY}
          target="_blank"
          rel="noopener"
          iconLeft={<ExternalIcon />}
        >
          {"View Privacy Policy"}
        </Components.TextLink>
      </Components.Grid>
    </Components.Column>
  </Components.Row>
);
