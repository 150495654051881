import {
  Switch,
  Route,
} from "react-router-dom";

import { withSignUpSupporters } from "./withSignUpSupporters";
import { Italic } from "./styledSignUpSupporters";
import { Column, H2, P, Row, StyledHyperlink } from "~/components/Styled";
import { PublicLayout } from "~/components/PublicLayout/PublicLayout";
import { BackButton } from "~/components/AppHeader/styledAppHeader";
import { SIGNUP_SUPPORTERS_FRIEND_OR_FAMILY, SIGNUP_SUPPORTERS_HIGH_RISK, SIGNUP_SUPPORTERS_CHANGE_IN_MY_BREASTS, SIGNUP_SUPPORTERS_TESTS } from "~/utils/constants";

import ArrowLeft from "~/../assets/icons/arrow-left.svg";
import { Meta } from "~/components/Meta/Meta";

export const SignUpSupporters = withSignUpSupporters(({ handleBack }: Props) => (
  <PublicLayout>
    <Row
      spacing="md"
    >
      <Column>
        <BackButton
          onClick={handleBack}
        >
          <ArrowLeft
            width="12"
            height="12"
          />{"Back"}
        </BackButton>
      </Column>
    </Row>

    <H2
      leading="lg"
    >
      {"Thank you for visiting BCNA's "}
      <Italic>
        {"My Journey"}
      </Italic>
      {". At the moment, the information in this tool is for people diagnosed with breast cancer."}
    </H2>
    <Switch>
      <Route
        path={SIGNUP_SUPPORTERS_FRIEND_OR_FAMILY}
        exact
      >
        <P>
          {"It can be hard to know what to say and how to support a family member, friend or colleague diagnosed with breast cancer."}
        </P>
        <P>
          {"Content for you may be available through the online tool soon. For now, you can find information on BCNA's website through the links below."}
        </P>
        <P
          spacing="xs"
        >
          <StyledHyperlink
            href="https://www.bcna.org.au/understanding-breast-cancer/talking-to-family-and-friends/information-for-partners/"
          >
            {"Information for partners"}
          </StyledHyperlink>
        </P>
        <P
          spacing="xs"
        >
          <StyledHyperlink
            href="https://www.bcna.org.au/understanding-breast-cancer/caring-for-someone-with-early-breast-cancer/"
          >
            {"Information for carers"}
          </StyledHyperlink>
          {"(carers can be anyone – spouses, partners, parents, daughters, sons, sisters, brothers, friends, colleagues, or neighbours)."}
        </P>
        <P
          spacing="xs"
        >
          <StyledHyperlink
            href="https://www.bcna.org.au/understanding-breast-cancer/talking-to-family-and-friends/helping-a-friend-or-colleague-with-breast-cancer/"
          >
            {"Information for friends or colleagues"}
          </StyledHyperlink>
        </P>
        <P
          spacing="xs"
        >
          <StyledHyperlink
            href="https://www.bcna.org.au/work-and-breast-cancer/colleague/"
          >
            {"Information for colleagues"}
          </StyledHyperlink>
        </P>
        <Meta
          title="Friends and family"
        />
      </Route>
      <Route
        path={SIGNUP_SUPPORTERS_HIGH_RISK}
        exact
      >
        <P>
          {"While some people do have an increased risk of breast cancer because of their family history and genetics, you may be surprised to know that 90–95 per cent of all breast cancers have nothing to do with family history."}
        </P>
        <P>{"Visit BCNA's website for more information about "}
          <StyledHyperlink
            href="https://www.bcna.org.au/breast-health-awareness/risk-factors/"
          >
            {"risk factors"}
          </StyledHyperlink>
          {" and "}
          <StyledHyperlink
            href="https://www.bcna.org.au/understanding-breast-cancer/breast-cancer-in-the-family/"
          >
            {"breast cancer in the family"}
          </StyledHyperlink>
          {"."}
        </P>
        <Meta
          title="High Risk"
        />
      </Route>
      <Route
        path={SIGNUP_SUPPORTERS_CHANGE_IN_MY_BREASTS}
        exact
      >
        <P>
          {"A woman's breasts often change throughout her lifetime, due to changes in hormonal levels as part of aging. Although a breast change can be normal, many women worry that it might be a sign of breast cancer."}
        </P>
        <P>
          {"If you have noticed a change in your breast, it's best to see your doctor to have it checked out."}
        </P>
        <Meta
          title="Noticed a change in my breasts"
        />
      </Route>
      <Route
        path={SIGNUP_SUPPORTERS_TESTS}
        exact
      >
        <P>
          {"Waiting for test results can be a difficult and anxious time, and you may have many questions."}
        </P>
        <P>
          <Italic>
            {"My Journey"}
          </Italic>
          {" will be of most help to you once you have the results of your tests. While you are waiting for your results, you may find it helpful to read the "}
          <StyledHyperlink
            href="https://www.bcna.org.au/understanding-breast-cancer/"
          >
            {"'Understanding breast cancer'"}
          </StyledHyperlink>
          {" information on BCNA's website."}
        </P>
        <P>
          {"Following your tests, if you are diagnosed with breast cancer, please come back to "}
          <Italic>
            {"My Journey"}
          </Italic>
          {" to access information and support tailored to your needs."}
        </P>
        <Meta
          title="Waiting for test results"
        />
      </Route>
    </Switch>
  </PublicLayout>
));

type Props = {
  handleBack: () => void
}
