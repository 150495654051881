import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { isValid, differenceInCalendarDays, differenceInCalendarMonths } from "date-fns";
import * as PhoneNumberJs from "libphonenumber-js";
import { Diagnosis, DiagnosisTree, StackedContent } from "myjourney-frontend/src/vendor/umbraco";
import image from "~/../assets/images/video-bg-large.png";
import getVideoId from "get-video-id";
import glossary from "~/../assets/data/glossary.json";
import { HIDDEN_USER_TYPES } from "./config";

/**
 * getArrayRange - Create and array of numbers between two values.
 * @param min
 * @param max
 */
export const getArrayRange = (min: number, max: number): Array<number> => Array(max - min + 1).fill(undefined).map((_, i) => min + i);

/**
 * Pass in an array and length and it will return a random selection of those items of length provided
 **/
export const getRandomArrayItems = (array: Array<unknown>, length: number): Array<unknown> => {
  /** Shuffle array */
  const shuffled = array.sort(() => 0.5 - Math.random());
  // Get sub-array of first n elements after shuffled
  return shuffled.slice(0, length);
};

export const glossaryJson = (): Record<string, string> => {
  const glossaryMap: Record<string, string> = {};
  glossary.forEach(({ label, content }) => glossaryMap[label.toLowerCase()] = content);
  return glossaryMap;
};

/**
 * getVideoImage - Pass the url of a video for youtube, vimeo etc and get back the poster image.
 * @param url - the url of the video that you want to get the post image of. This can be in any format.
 * @param q - quality of the video image. h for high m for medium.
 */
export const getVideoImage = (url?: string, q: "m" | "h" = "m"): string => url ? `https://img.youtube.com/vi/${getVideoId(url).id}/${q}qdefault.jpg` : image;

/**
 * addGlossary - Pass a html string and return the html with glossary items wrapped in spans that will trigger glossary tooltips.
 * @param content - html string
 */
export const addGlossary = (content: string): string => {
  const glossaryMap = glossaryJson();
  const glossaryKeys = Object.keys(glossaryMap);
  const re = new RegExp(`(${glossaryKeys.join("|")})(?![^<]*>)`, "gmi");
  return content.replace(re, (_matched, p1) => ` <span class="glossary" data-tooltip="${glossaryMap[p1.toLowerCase()]}">${_matched}</span>`);
};

/**
 * getDiagnosisParent - Walk up the diagnosis tree
 * @param ids
 * @param tree
 */
export const getDiagnosisParent = (ids: Array<string>, tree: DiagnosisTree): Array<string> => {
  const findDiagnosisNode = (ids: Array<string>, nodes: Array<Diagnosis>): boolean => !!nodes.find(node => {
    if (
      node.id
        && ids.includes(node.id.toString())
    ) {
      return true;
    }
    if (node.children) {
      return findDiagnosisNode(ids, node.children);
    }
    return false;
  }) || false;

  return tree.reduce((accumulator: Array<string>, diagnosis) => {
    const found = findDiagnosisNode(ids, diagnosis.children);

    if (found) {
      accumulator.push(diagnosis.hpReferralTitle);
    }

    return accumulator;
  }, []);
};

/**
 * Generate user types based on currently selected user types, date of birth, and diagnosis date
 * @param {Array<number>} selectedUserTypeIds
 * @param {Date} dateOfBirth
 * @param {Date} diagnosisDate
 */
export const generateSelectedUserTypeIds = (selectedUserTypeIds: Array<string> = [], dateOfBirth: Date, diagnosisDate: Date): Array<string> => {
  /** validate selectedUserTypeIds */
  if (!Array.isArray(selectedUserTypeIds)) throw TypeError("Invalid data type, Field: selectedUserTypesIds");
  /** validate dateOfBirth */
  if (!dateOfBirth) throw Error("Missing data, Field: dateOfBirth");
  if (!isValid(dateOfBirth)) throw Error(`Invalid data, Field: dateOfBirth, Value ${dateOfBirth}`);

  /** validate diagnosisDate */
  if (!diagnosisDate) throw Error("Missing data, Field: diagnosisDate");
  if (!isValid(diagnosisDate)) throw Error(`Invalid data, Field: diagnosisDate, Value ${diagnosisDate}`);

  let userTypeIds = [
    "1", // always include "General" user type
    ...selectedUserTypeIds
      .filter(userTypeId => !HIDDEN_USER_TYPES.includes(Number(userTypeId))), // filter out hidden user types
  ];

  /**
   * If user did not select "man with breast cancer (ID: 5), then we need to add the relevant
   * hidden women user types based on their age
   */
  if (!userTypeIds.includes("5")) {
    const age = Math.floor(differenceInCalendarMonths(Date.now(), dateOfBirth) / 12);

    if (age < 30) {
      userTypeIds = [...userTypeIds, "2", "9"];
    } else if (age >= 30 && age <= 45) {
      userTypeIds = [...userTypeIds, "3", "9"];
    } else if (age > 45) {
      userTypeIds.push("8");
    }
  }

  /**
   * If less than 14 days since diagnosis, add the "new diagnosis" to userTypeIds
   */
  const daysSinceDiagnosis = differenceInCalendarDays(Date.now(), diagnosisDate);
  if (daysSinceDiagnosis <= 14) {
    userTypeIds.push("12");
  }

  return userTypeIds;
};

/**
 * get base 64 of an image file
 */
 export const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        if (typeof reader.result === "string") {
          resolve(reader.result);
        }
      },
      false
    );

    reader.addEventListener(
      "error",
      () => {
        reject(new Error("There was an error uploading the file"));
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  });
};

export const getRandomInteger = (max: number, min: number = 0): number => Math.round(Math.random() * (max - min) + min);

export const downloadPdf = async (element: HTMLElement, filename: string = "download"): Promise<void> => {
  const canvas = await html2canvas(element, {});

  const pdf = new jsPDF({
    format: "a4",
  });
  
  pdf.addImage(canvas.toDataURL("image/png", 1), "png", 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
  pdf.save(filename);

  // const link = document.createElement("a");
  // link.download = filename;
  // link.href = canvas.toDataURL("image/jpeg", 1);
  // link.click();
};

/**
 * Convert urls to pdfs inside articles to use the umbraco url
 * @param string 
 * @returns 
 */
export const getMediaLinks = (string: string): string => {
  const re = new RegExp("href=\\\"(?=media)(.*?)\\\"", "gi");
    const protocolOrWhiteSpace = runtimeconfig.koben_umbraco_domain.includes('https://') ? '' : 'https://'; 
  return string.replace(re, (matched, p1) => `href="${protocolOrWhiteSpace + runtimeconfig.koben_umbraco_domain + p1}"`);
};

export const getMediaLink = (link: StackedContent.ResourceLink): string => {
  const protocolOrWhiteSpace = runtimeconfig.koben_umbraco_domain.includes('https://') ? '' : 'https://'; 
  return link.type === "Media" || link.type === "Content" ? `${protocolOrWhiteSpace + runtimeconfig.koben_umbraco_domain}${link.url}` : link.url
};

export const getFormattedPhoneOrEmail = (input: string): string => {
  let parsedValue = input;
  const isOnlyDigits = /^[0-9+]+$/.test(parsedValue);
  if(isOnlyDigits){
    try {
      const parsedPhoneNumber = PhoneNumberJs.parsePhoneNumber(new PhoneNumberJs.AsYouType("AU").input(input), "AU");
      if (parsedPhoneNumber.isPossible()) {
        parsedValue = parsedPhoneNumber.format("NATIONAL").replace(/\s/g, "");
  
        if (!parsedValue.startsWith("0")) {
          parsedValue = `0${parsedValue}`;
        }
      }
    } catch (error) {
      return parsedValue;
    }

  }else{
    return parsedValue;
  }

  return parsedValue;
};
