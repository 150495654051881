import * as React from "react";

import * as Components from "~/components";

export const ModalContext = React.createContext<ModalContextState>({
  activeModal: undefined,
  toggleModal: () => null,
  hideModal: () => null,
});

export const ModalProvider: React.FunctionComponent = (props) => {
  const [activeModal, setActiveModal] = React.useState<ModalSet>();

  const toggleModal = (modal: ModalSet) => {
    setActiveModal((prevModalActive) => prevModalActive === modal ? undefined : modal);
  };

  const hideModal = () => {
    setActiveModal(undefined);
  };

  return (
    <ModalContext.Provider
      value={{ 
        activeModal,
        toggleModal,
        hideModal,
      }}
    >
      {props.children}
      <Components.SwipeModalChooseLook
        active={activeModal === "accountChooseLook"}
        onClose={() => toggleModal("accountChooseLook")}
      />
      <Components.SwipeModalCustomise
        active={activeModal === "accountCustomise"}
        onClose={() => toggleModal("accountCustomise")}
      />
      <Components.SwipeModalProfile
        active={activeModal === "accountProfile"}
        onClose={() => toggleModal("accountProfile")}
      />
    </ModalContext.Provider>
  );
};

export type ModalContextState = {
  activeModal: ModalSet;
  toggleModal: (modal: ModalSet) => void;
  hideModal: () => void;
};

export type ModalSet = "accountChooseLook" | "accountCustomise" | "accountProfile" | "shareArticle" | undefined;
