import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";
import { Colours, Directions, Global, Items, Justifys, Selfs, Shadows } from "~/components/Styled/Global";
import { Card } from "~/components/Styled";

const styles = {
  ...Global,
  padding: {
    md: tw`px-4 py-3 md:(py-4 px-20) print:(px-0)`,
    xl: tw`py-8`,
  },
  contentPadding: {
    md: tw`px-4 md:px-20 print:(px-0)`,
    xl: tw``,
  },
};

export const SymptomTitle = tw.h4`font-semibold text-blue-navy text-md leading-6`;
export const CategoryTitle = styled.button(() => [
  tw`bg-blue-dusty my-4 rounded-sm py-2 px-3 text-blue-navy font-semibold text-mdsm flex items-center justify-between w-full`,
]);

export const HelpfulResourceIconWrapper = tw.span`flex items-center print:(hidden)`;
export const LabelWrapper = tw.span`inline-block ml-2`;
export const FilterButton = tw.button`px-3 py-3 w-full flex outline-none focus:outline-none items-center`;
export const FilterLabelWrapper = tw.div`px-4 pt-3 w-full flex`;
export const StickyFilterWrapper = styled.div(({
  active,
}: ActiveProps) => [
  tw`mt-4`,
  ...(active ? [] : [tw`hidden`]),
]);

export const FilterContainer = styled.div(({
  active,
}: ActiveProps) => [
  tw`overflow-hidden`,
  ...(active ? [tw`h-full p-4 pt-0`] : [tw`h-0`]),
]);
export const IconWrapper = styled.span(({
  active,
  colour = "pink",
}: IconProps) => [
  tw`block transition transform`,
  ...(active ? [tw`rotate-180`] : [tw`rotate-0`]),
  styles.colour[colour],
]);

export const CalendarBackground = styled.div(({ active }: ActiveProps) => [
  tw`bg-black bg-opacity-80 fixed inset-0 transition duration-500 z-70`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0 pointer-events-none`]),
]);
export const FilterOverlay = styled.div(({ active }: ActiveProps) => [
  tw`bg-black bg-opacity-80 fixed inset-0 w-full h-full transition duration-500 z-90`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0 pointer-events-none`]),
]);

export const Label = styled.span(({ hidden }: { hidden?: boolean }) => [
  tw`font-medium flex-1 text-left tracking-wider text-xxs text-blue-navy uppercase font-semibold`,
  ...(hidden ? [tw`hidden`] : []),
]);

export const SearchIconWrapper = tw.div`absolute left-3 inset-y-0 text-pink flex items-center pointer-events-none z-10`;
export const SearchButtonWrapper = tw.div`absolute right-3 inset-y-0 text-pink flex items-center z-10`;
export const SearchClearButton = tw.button`outline-none`;
export const SearchInputWrapper = styled.div(() => [
  tw`h-10 w-full rounded border-grey-light border relative bg-white`,
]);
export const SearchBackground = styled.div(({ active }: ActiveProps) => [
  tw`bg-white relative rounded-sm`,
  ...(active ? [tw`p-4 z-100`] : []),
]);
export const SearchInput = tw.input`w-full h-full bg-transparent focus:(outline-none) pl-8 text-sm`;
export const SearchSuggestion = tw.div`pt-2 px-4`;
export const Suggestion = tw.button`text-pink w-full text-left text-sm font-medium mt-4`;

export const StyledCard = styled(Card)(({ active }: ActiveProps) => [
  tw`transition-width`,
  ...(active ? [tw`absolute right-0 left-0`] : []),
]);

export const AddSymptomList = styled.button(() => [
  tw`w-full px-6 md:(px-20)`,
]);

export const AddSymptomButton = styled.button(() => [
  tw`outline-none focus:(outline-none) border-b border-grey-light text-blue-navy font-medium text-sm flex justify-between w-full py-3 md:(py-4) text-left`,
]);

export const AddSymptomButtonIcon = tw.span`w-6`;

export const SymptomCount = tw.span`bg-pink text-white inline-flex font-bold mr-2 rounded h-6 w-6 px-2 items-center justify-center`;

type IconProps = {
  active: boolean
  colour?: Colours
}

type ActiveProps = {
  active: boolean
}

export const SeverityLabelWrapper = tw.div`flex justify-between mt-2`;
export const RangeLabel = tw.label`text-xxs w-5 text-center text-blue-navy font-medium`;
export const SeverityLabel = tw.label`text-xs text-center text-blue-navy font-medium mt-2`;
export const ResponseWrapper = tw.div`py-4`;

export const RecommendedCardLink = styled(Link)(({
  items = "center",
  self = "none",
  justify = "none",
  direction = "none",
  background = "white",
  shadow = "pink",
}: RecommendedCardLinkProps) => [
  tw`rounded-md w-full flex relative p-4 transition-shadow hover:(shadow-md) mb-4`,
  styles.shadow[shadow],
  styles.background[background],
  styles.items[items],
  styles.self[self],
  styles.justify[justify],
  styles.direction[direction],
]);

export const RecommendedCardHyperLink = styled.a(({
  items = "center",
  self = "none",
  justify = "none",
  direction = "none",
  background = "white",
  shadow = "pink",
}: RecommendedCardLinkProps) => [
  tw`rounded-md w-full flex relative p-4 transition-shadow hover:(shadow-md) mb-4`,
  styles.shadow[shadow],
  styles.background[background],
  styles.items[items],
  styles.self[self],
  styles.justify[justify],
  styles.direction[direction],
]);

export const CardContent = tw.div`flex-grow`;
export const CardIcon = tw.div`flex-shrink-0`;

type RecommendedCardLinkProps = {
  direction?: Directions
  items?: Items
  self?: Selfs
  justify?: Justifys
  background?: Colours
  shadow?: Shadows
}
