import * as React from "react";
import { Toast } from "../components/Toast/Toast";

export const ToastContext = React.createContext<ToastContextState>({
  handleToast: () => {throw new Error();},
});

export const ToastProvider: React.FC = ({
  children,
}) => {
  const [message, setMessage] = React.useState<string>("");
  const [active, setActive] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  
  const handleToast = (value?: string, error?: boolean) => {
    if (error) {
      setError(true);
    } else {
      setError(false);
    }
    if (value) {
      setMessage(value);
      setActive(true);
    } else {
      setActive(false);
      setTimeout(() => {
        setMessage("");
      }, 150);
    }
  };

  return (
    <ToastContext.Provider
      value={{ 
      handleToast,
    }}
    >
      {children}
      <Toast 
        message={message} 
        active={active}
        error={error}
        onClose={() => handleToast()}
      />
    </ToastContext.Provider>
  );
};

export type ToastContextState = {
  handleToast: (value?: string, error?: boolean) => void;
};

