import { getDaysInMonth } from "date-fns";
import * as React from "react";

export const useDateInput = ({
  externalDate,
  onChange,
}: Props): DateInputProps => {
  const [date, setDate] = React.useState<Date | undefined>(externalDate);
  const [day, setDay] = React.useState<number | undefined>(externalDate?.getDate());
  const [month, setMonth] = React.useState<number | undefined>(externalDate?.getMonth());
  const [year, setYear] = React.useState<number | undefined>(externalDate?.getFullYear());

  React.useEffect(() => {
    if (onChange && date !== undefined) {
      onChange(date);
    }
  }, [date]);

  React.useEffect(() => {
    if (year !== undefined && month !== undefined && day !== undefined) {
      const daysInMonth = getDaysInMonth(new Date(year, month));
      if (daysInMonth < day) {
        setDay(daysInMonth);
      } else {
        const dateRaw = new Date(year, month, day);
        /** Remove time value from date (eg, 00:00:00.000Z) */
        const dateParsed = new Date(dateRaw.valueOf() - (dateRaw.getTimezoneOffset() * 60 * 1000));
        setDate(dateParsed);
      }
    } else {
      if (date !== undefined) {
        /** Set date to null to trigger validity error */
        setDate(undefined);
      }

    }
  }, [day, month, year]);

  return {
    day,
    month,
    year,
    setDay,
    setMonth,
    setYear,
  };
};

type Props = {
  externalDate?: Date;
  onChange?: (date: Date) => void
}

export type DateInputProps = {
  day?: number
  month?: number
  year?: number
  setDay: React.Dispatch<React.SetStateAction<number | undefined>>
  setMonth: React.Dispatch<React.SetStateAction<number | undefined>>
  setYear: React.Dispatch<React.SetStateAction<number | undefined>>
}
