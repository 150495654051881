import * as React from "react";
import {
  Label, CheckboxGroupWrapper, CheckboxTick, HiddenCheckbox, RadioGroupCheckbox, RadioTextWrapper,
} from "../Styled/Form";
import { CheckboxGroupData, withCheckboxGroup } from "./withCheckboxGroup";

import * as Components from "~/components";

export const CheckboxGroup = withCheckboxGroup((props: Props) => (
  <React.Fragment>
    {props.label && (
      <Label>
        <Components.TextVariation
          variation="label1"
        >
          {props.label}
        </Components.TextVariation>
      </Label>
    )}
    {props.data.map(({ label, description, value }, index) => (
      <CheckboxGroupWrapper
        key={value}
      >
        <HiddenCheckbox
          type="checkbox"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
          onBlur={() => props.onBlur?.(index)}
        />
        <RadioGroupCheckbox>
          <CheckboxTick
            checked={props.checked.includes(value)}
          />
        </RadioGroupCheckbox>
        <RadioTextWrapper>
          <Components.Row
            spacing="ogxxxxs"
          >
            <Components.TextVariation
              variation="paragraph1bold"
              color="blueNavy"
            >
              {label}
            </Components.TextVariation>
          </Components.Row>
          {description ? (
            <Components.Row
              spacing="ogxxxxs"
            >
              <Components.TextVariation
                variation="paragraph2"
                color="greyMedium"
              >
                {description}
              </Components.TextVariation>
            </Components.Row>
          ) : null}
        </RadioTextWrapper>
      </CheckboxGroupWrapper>
    ))}
  </React.Fragment>
));

type Props = {
  label?: string;
  data: Array<CheckboxGroupData>
  checked: Array<string>
  onChange: (index: string) => void
  onBlur?: (index: number) => void
}
