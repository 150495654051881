export const validEmail = (value: string): boolean => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

/**
 * Allow only alphabetic characters, hyphens, apostrophes and "special characters" like accents.
 * Prevent single character names.
 * Source: https://stackoverflow.com/a/45871742
 */
export const validName = (value: string): boolean => /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/i.test(value);

/**
 * Only allow 3 or 4 numbers
 */
export const validPostcode = (value: string): boolean => /^[0-9]{3,4}$/.test(value);

export const validPhone = (value: string): boolean => {
  if (typeof value !== "string") {
    return false;
  }

  const spaceMatches = value.match(/ /g);
  if (spaceMatches && spaceMatches.length > 3) {
    return false;
  }
  if (!/^(?:\+?61|0)/.test(value)) {
    return false;
  }
  const normalised = value.replace(/^\+/, "").replace(/ /g, "").replace(/^(?:\+?61|0)/, "");
  return normalised[0] === "4" && normalised.length === 9 && /^\d+$/.test(normalised);
};

export const invalidDescriptionPhone = (value: string): string => {
  if (/[a-zA-Z]/.test(value)) {
    return "Mobile number must not contain any letters";
  }

  const spaceMatches = value.match(/ /g);
  if (spaceMatches && spaceMatches.length > 3) {
    return "Too many spaces";
  }

  if (!/^(?:\+?61|0)/.test(value)) {
    return "Only AU country code is allowed";
  }

  const normalised = value.replace(/^\+/, "").replace(/ /g, "").replace(/^(?:\+?61|0)/, "");
  if (normalised[0] !== "4") {
    return "Not a mobile number";
  }

  if (/\D/.test(normalised)) {
    return "Mobile number must not contain any special characters";
  }

  if (normalised.length !== 9) {
    return "Incorrect length";
  }

  return "Invalid phone number";
};
