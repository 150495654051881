import * as React from "react";
import * as Store from "store";
import { useHistory } from "react-router-dom";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Providers from "~/providers";
import * as Components from "~/components";

export const PasswordForgot: React.FunctionComponent<PasswordForgotProps> = () => {

  const history = useHistory();
  const { handleToast } = React.useContext(Providers.ToastContext);
  const { patientPasswordForgot } = Hooks.usePatientApi();
  const [forgotValue, setForgotValue] = React.useState<string>("");
  const [forgotKey, setForgotKey] = React.useState<"email" | "phone">("email");
  const [forgotError, setForgotValueError] = React.useState<JSX.Element | string | undefined>();
  const [forgotValid, setForgotValueValid] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [formError, setFormError] = React.useState<FormError | undefined>();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setFormError(undefined);
    setSubmitting(true);

    try {
      Store.set("forgot_key", forgotKey);
      Store.set("forgot_value", forgotValue);
      const response = await patientPasswordForgot({
        key: forgotKey,
        value: forgotValue,
      });

      if (response.status === 200) {
        if (handleToast) {
          handleToast(`A password reset email has been sent to ${forgotValue}`);
        }
        if (forgotKey === "email") {
          history.push(Utils.USER_LOGIN);
        }
        if (forgotKey === "phone") {
          history.push(Utils.PASSWORD_RESET);
        }

      } else {
        setFormError({
          message: "An unexpected error ocurred. Please try again later",
        });
      }
    } catch (error) {
      setFormError({
        message: "Service unavailable. Please try again later.",
      });
      throw error;
    }
    setSubmitting(false);
  };

  const handleForgotValue = (value: string) => {
    const parsedValue = Utils.getFormattedPhoneOrEmail(value);

    setForgotValue(parsedValue);
    setForgotValueError(undefined);

    if (parsedValue === "") {
      setForgotValueError(`Please enter a valid ${forgotKey}`);
      setForgotValueValid(false);
      return;
    }

    if (forgotKey === "email") {
      const valid = Utils.validEmail(parsedValue);
      setForgotValueValid(valid);
      if (!valid) {
        setForgotValueError("Please enter a valid email");
      }
    }

    if (forgotKey === "phone") {
      const valid = Utils.validPhone(parsedValue);
      setForgotValueValid(valid);
      if (!valid) {
        setForgotValueError(Utils.invalidDescriptionPhone(forgotValue));
      }
    }
  };

  return (
    <Components.AppLayout
      headerVariation="standard"
    >
      <Components.Meta
        title="Reset your password"
        metaDescription="Reset your password for the My Journey app."
      />
      <Components.Row>
        <Components.Column>
          <Components.TextVariation
            variation="heading1"
            spacing="ogsm"
          >
            {"Reset your password"}
          </Components.TextVariation>
          <Components.Form
            onSubmit={handleSubmit}
            aria-label="Reset password"
          >
            {/* <Components.Column
              spacing="ogxxs"
            >
              <Components.Radio
                checked={forgotKey}
                onChange={(value) => setForgotKey(value as "email" | "phone")}
                data={[{
                  label: "By Email",
                  value: "email",
                },{
                  label: "By Mobile",
                  value: "phone",
                }]}
              />
            </Components.Column> */}
            <Components.Column
              spacing="ogxxs"
            >
              <Components.Input
                name="forgot"
                type="text"
                required
                hideRequired
                label={forgotKey === "email" ? "BCNA registered email address" : "BCNA registered mobile"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleForgotValue(e.target.value)}
                value={forgotValue}
                valid={forgotValid}
                error={forgotError}
              />
            </Components.Column>
            {formError ? (
              <Components.Column
                spacing="ogxxs"
              >
                <Components.ErrorContainer
                  {...formError}
                />
              </Components.Column>
            ) : null}
            <Components.Column
              justify="center"
            >
              <Components.ButtonVariation
                variation="primary"
                type="submit"
                disabled={submitting || !forgotValue}
                fullWidth
              >
                {submitting ? "Loading..." : forgotKey === "email" ? "Submit" : "Send SMS"}
              </Components.ButtonVariation>
            </Components.Column>
          </Components.Form>
        </Components.Column>
      </Components.Row>
    </Components.AppLayout>
  );
};

export type PasswordForgotProps = unknown;

type FormError = {
  message: string;
  action?: () => void
  actionText?: string
  CallToAction?: React.FC
}
