import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";
import { Colours, FontSizes, Global, TextAligns, Weights } from "~/components/Styled/Global";

const styles = {
  ...Global,
  padding: {
    default: tw`px-2 py-1.5`,
    tall: tw`py-4`,
    none: tw``,
  },
};

export const StyledLink = styled(Link)(({
  colour = "pink",
  padding = "none",
  align = "left",
  weight = "normal",
  fontSize = "none",
  underlined,
  full,
}: Props) => [
  styles.fontSize[fontSize],
  styles.colour[colour],
  styles.padding[padding],
  styles.textAlign[align],
  styles.weight[weight],
  ...(underlined ? [tw`underline`] : []),
  ...(full ? [tw`w-full`] : []),
  tw`tracking-tight hover:text-pink whitespace-nowrap inline-block`,
]);

export const StyledHyperlink = styled.a(({
  colour = "pink",
  padding = "none",
  align = "left",
  weight = "normal",
  underlined,
}: Props) => [
  styles.colour[colour],
  styles.padding[padding],
  styles.textAlign[align],
  styles.weight[weight],
  ...(underlined ? [tw`underline`] : []),
  tw`tracking-tight hover:text-pink hover:underline`,
]);

export const ErrorHyperlink = styled.a(() => [
  tw`tracking-tight underline whitespace-nowrap`,
]);

export const SmallLink = styled(Link)(({
  colour = "pink",
  align = "left",
}: Props) => [
  styles.colour[colour],
  styles.textAlign[align],
  tw`tracking-tight hover:text-pink hover:underline whitespace-nowrap text-ssm`,
]);


type Props = {
  colour?: Colours;
  padding?: "default" | "tall" | "none";
  align?: TextAligns;
  weight?: Weights;
  underlined?: boolean;
  full?: boolean;
  fontSize?: FontSizes;
}
