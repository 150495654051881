import { Badge, P, Small } from "../Styled";
import { withArticleCard } from "./withArticleCard";
import {
  CardLink, ReadMoreWrapper, ReadMore, ProgressBar, Progress, BookMarkButton, VideoContent, IconWrapper, StyledSmall, QuestionIcon,
} from "./styledArticleCard";
import ArrowRight from "~/../assets/icons/arrow-right.svg";
import BookmarkIcon from "~/../assets/icons/bookmark.svg";
import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";
import { getVideoImage } from "~/utils/functions";
import PlayIcon from "~/../assets/icons/play.svg";
import Truncate from "react-truncate";

export const ArticleCard = withArticleCard(({ article, articleUrl, bookmarked, handleBookmark, handleArticleClick }: Props) => article ? (
  <CardLink
    to={articleUrl}
    height="card"
    className="group"
    image={article.documentTypeAlias === "video" ? getVideoImage(article.videoUrl || "") : undefined}
    aria-label="Article card"
    onClick={handleArticleClick}
  >
    {article.documentTypeAlias === "video" ? (
      <VideoContent>
      <Small
        colour="white"
        leading="sm"
        spacing="xs"
      >
        <Truncate
          lines={2}
        >
          {article.name}
        </Truncate>
      </Small>
      <IconWrapper>
        <PlayIcon />
      </IconWrapper>
      <StyledSmall
        colour="white"
        leading="sm"
        spacing="none"
      >
        Go to video
      </StyledSmall>
      </VideoContent>
    ) : (
      <>
        <BookMarkButton
          active={bookmarked}
          aria-label="Bookmark"
          onClick={(e) => {
          e.preventDefault();
          handleBookmark();
        }}
        >
          <BookmarkIcon
            width="19"
            height="19"
          />
        </BookMarkButton>
        {article.documentTypeAlias === "questions" ? (
          <QuestionIcon />
        ) : null}
        {article.isFeatured ? (
          <Badge>
            Featured
          </Badge>
        ) : null}
        <Small
          colour="blueNavy"
          leading="sm"
        >
          {article.name}
        </Small>
        {!article?.readData?.progress && (
          <ReadMoreWrapper>
            <ReadMore
              aria-label="Read more"
            >
              Read article
            </ReadMore>
            <ArrowRight
              aria-label="Read more arrow"
            />
          </ReadMoreWrapper>
        )}
        {(article?.readData?.progress ?? 0) > 0 && (article?.readData?.progress ?? 0) < 1 && (
          <ReadMoreWrapper>
            <ReadMore
              aria-label="Continue reading"
            >
              Continue reading
            </ReadMore>
            <ArrowRight
              aria-label="Continue reading arrow"
            />
          </ReadMoreWrapper>
        )}
        {(article?.readData?.progress ?? 0) === 1 && (
          <ReadMoreWrapper>
            <ReadMore
              aria-label="Read again"
            >
              Read again
            </ReadMore>
            <ArrowRight
              aria-label="Read again arrow"
            />
          </ReadMoreWrapper>
        )}
        <ProgressBar>
          <Progress
            progress={article?.readData?.progress || 0}
          />
        </ProgressBar>
      </>
    )}
  </CardLink>
) : (
  <CardLink
    to=""
    height="card"
  >
    <P
      loading
    ></P>
  </CardLink>
));

type Props = {
  article?: MergedArticle
  articleUrl: string
  bookmarked: boolean
  handleBookmark: () => Promise<void>
  handleArticleClick: () => Promise<void>
}
