import * as React from "react";
import { css } from "twin.macro";

import { StyledContent, StyledTextTop, StyledTextBottom, IconWrapper, StyledVideo, VideoOverlay, VideoWrapper } from "./styledVideo";

import IconPlay from "~/../assets/icons/play.svg";
import IconDuration from "~/../assets/icons/icon-duration.svg";

import { getVideoImage } from "~/utils/functions";
import * as Components from "~/components";

export const Video: React.FunctionComponent<VideoProps> = (props) => {
  const [playing, setPlaying] = React.useState<boolean>(false);

  return (
    <VideoWrapper>
      <VideoOverlay
        image={getVideoImage(props.videoUrl, "h")}
        hidden={playing}
        onClick={() => setPlaying(true)}
      >
      <StyledContent>
        <IconWrapper>
          <IconPlay />
        </IconWrapper>
        <StyledTextTop>
          <Components.TextVariation
            variation="heading4"
            color="white"
          >
            {props.title}
          </Components.TextVariation>
        </StyledTextTop>
        <Components.TextVariation
          variation="paragraph2"
          color="white"
        >
          <StyledTextBottom>
          <Components.Row
            items="center"
            wrap="none"
            gutter="og1"
            css={css`
              white-space: nowrap;
            `}
          >
            <Components.Column
              gutter="og1"
            >
              {"Watch Video"}
            </Components.Column>
            {props.duration && (
              <React.Fragment>
                <Components.Column
                  gutter="og1"
                >
                  <IconDuration />
                </Components.Column>
                <Components.Column
                  gutter="og1"
                >
                  {props.duration}
                </Components.Column>
              </React.Fragment>
            )}
          </Components.Row>
          </StyledTextBottom>
        </Components.TextVariation>
      </StyledContent>
      </VideoOverlay>
      <StyledVideo
        url={props.videoUrl}
        playing={playing}
        height="100%"
        width="100%"
        controls
      />
    </VideoWrapper>
  );
};

export type VideoProps = {
  title?: string | JSX.Element;
  duration?: string;
  videoUrl?: string;
};
