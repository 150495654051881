import * as React from "react";
import { StyledHyperlink } from "../Styled";

export const EmailError: React.FC = () => (
  <>
    <strong>Mmm...</strong>
    <span> Looks like we might be missing a valid email address. Make sure it&apos;s only got a single @ symbol included and no spaces or extra periods. If you&apos;re still having trouble
    <StyledHyperlink
      colour="redValidation"
      underlined
      href="tel:1800500258"
    >
      contact our team
    </StyledHyperlink>.
    </span>
  </>
);
