import { StackedContent } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { UMBRACO_ASSET_URL } from "~/utils/constants";

export const ArticleImage: React.FC<Props> = ({ imageUrl, imageAltText }) => (
  <img
    src={`${UMBRACO_ASSET_URL}${imageUrl}`}
    alt={imageAltText}
  />
);

type Props = StackedContent.Image
