import * as React from "react";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Components from "~/components";

import IconPlus from "~/../assets/icons/icon-plus.svg";

export const TemplateDashboardResourceNotes: React.FunctionComponent<TemplateDashboardResourceNotesProps> = () => {
  const hookNotes = Hooks.useNotes();
  const hookDateRange = Hooks.useDateRange();

  return (
    <React.Fragment>
      <Components.Box
        spacing="og6"
      >
        <Components.TextVariation
          variation="heading1"
          spacing="og1"
          fullWidth
        >
          {"My notes"}
        </Components.TextVariation>
        <Components.TextVariation
          variation="heading3"
          fullWidth
        >
          {"Record notes for reference"}
        </Components.TextVariation>
      </Components.Box>
      <Components.Box
        spacing="og6"
      >
        <Components.ButtonVariation
          variation="primary"
          href={Utils.USER_NOTES_ADD}
          aria-label="Add note"
          iconLeft={<IconPlus />}
          fullWidth
        >
          {"Note"}
        </Components.ButtonVariation>
      </Components.Box>
      {!hookNotes.loading && (
        hookNotes.notes.length === 0 ? (
          <Components.Row>
            <Components.Column>
              <Components.TextVariation
                variation="heading3"
              >
                {"Add your first note above and keep all your questions and reminders in one place"}
              </Components.TextVariation>
            </Components.Column>
          </Components.Row>
        ) : (
          <React.Fragment>
            <Components.Box
              spacing="og6"
            >
              <Components.TextVariation
                variation="heading3"
                spacing="og4"
                fullWidth
              >
                {"Recent notes"}
              </Components.TextVariation>
              <Components.NoteList
                dateRange={hookDateRange.values[Hooks.DATE_RANGE.ALL_TIME]}
              />
            </Components.Box>
            <Components.ViewAllLink
              label="View all notes"
              href={Utils.USER_NOTES}
            />
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export type TemplateDashboardResourceNotesProps = unknown;
