import tw, { css, styled } from "twin.macro";

export const HorizontalScroller = styled.div(() =>
  css`
    overflow: auto;
    white-space: nowrap;
    padding: 1rem;
    padding-bottom: 1.5rem;

    ::-webkit-scrollbar {
      height: 4px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #F6EEEE;
    }
  `
);

export const StyledGlossaryItem = styled.span(() => [
  tw`px-og2`,
]);
