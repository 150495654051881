import { MergedArticle, StackedContent } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import parse from "html-react-parser";
import { ArticleAccordion } from "~/components/Accordion/ArticleAccordion";
import { addGlossary, getMediaLinks } from "~/utils/functions";

export const SubSections: React.FC<Props> = ({ sections, article }) => {
  const items = sections.map(({ body, title }) => ({ content: (
    <React.Fragment
      key={title}
    >{parse(getMediaLinks(addGlossary(body)))}</React.Fragment>
  ), label: title }));
  
  return (
    <ArticleAccordion
      articleTitle={article?.name || ""}
      items={items}
    />
  );
};

type Props = StackedContent.SubSections & {
  article?: MergedArticle
}
