import tw, { styled } from "twin.macro";
import { Colours, Global } from "./Global";

const styles = {
  ...Global,
  margin: {
    negative: tw`-mx-4 md:-mx-20 print:(mx-0)`,
    default: tw`py-8`,
    none: tw``,
  },
  padding: {
    none: tw``,
    sm: tw`px-4 md:px-20 py-8`,
    md: tw`px-4 md:px-20 py-12`,
  },
  spacing: {
    none: tw``,
    sm: tw`mb-2`,
    md: tw`mb-4`,
  },
};

export const Section = styled.section(({
  background = "none",
  margin = "default",
  padding = "none",
  spacing = "none",
  hidden,
}: SectionProps) => [
  styles.margin[margin],
  styles.background[background],
  styles.padding[padding],
  styles.spacing[spacing],
  ...(hidden ? [tw`hidden`] : [tw`block`]),
]);


type SectionProps = {
  background?: Colours
  margin?: "default" | "negative" | "none"
  padding?: "sm" | "md" | "none"
  spacing?: "sm" | "md" | "none"
  hidden?: boolean
}
