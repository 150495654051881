import * as React from "react";
import { Note, NoteResponse } from "myjourney-frontend/src/vendor/umbraco";
import { useSymptomTrackerApi } from "~/hooks/useSymptomTrackerApi";

export const withEditNote = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ displayName = "EditNote", ...props }: Props) => {
  Component.displayName = displayName;
  const { editNote } = useSymptomTrackerApi();
  const [title, setTitle] = React.useState<string>("");
  const [text, setText] = React.useState<string>("");
  const [date, setDate] = React.useState<Date>(new Date());
  const [error, setError] = React.useState<string | null>(null);
  const [textError, setTextError] = React.useState<string | null>(null);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [imageIds, setImageIds] = React.useState<Array<string>>([]);

  React.useEffect(() => {
    if (props.note) {
      setTitle(props.note.title);
      setText(props.note.text);
      setDate(new Date(props.note.year, props.note.month - 1, props.note.day));
    }
  }, [props.note]);

  const handleText = (value: string) => {
    setText(value);
    setTextError(null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setSaving(true);
    try {
      if (!props.id) {
        return;
      }
      const res = await editNote({
        noteId: props.id,
        additional: {
          title: title,
          text: text,
        },
        attachments: [],
        images: imageIds,
      });
      if (res.status === 200) {
        const json: NoteResponse = await res.json();
        props.setSaved(json);
        props.handleEditNoteId();
        if (props.getNotes) {
          props.getNotes();
        }
      } else {
        setError("An error occurred, please try again later.");
      }
    } catch {
      setError("An error occurred, please try again later.");
    }
    setSaving(false);
  };

  return (
    <Component
      { ...props as P & Props }
      title={title}
      text={text}
      error={error}
      imageIds={imageIds}
      handleSubmit={handleSubmit}
      handleText={handleText}
      setTitle={setTitle}
      setText={setText}
      date={date}
      setDate={setDate}
      setImageIds={setImageIds}
      saving={saving}
      textError={textError}
    />
  );
};

type Props = {
  displayName?: string
  id?: string
  handleEditNoteId: (id?: string) => void
  setSaved: React.Dispatch<React.SetStateAction<NoteResponse | null>>
  noteResponse: NoteResponse | null
  note: Note | null
  getNotes?: () => Promise<void>
}
