import * as React from "react";
import tw, { styled } from "twin.macro";
import {
  RadioGroupWrapper, RadioCircle, HiddenCheckbox, RadioGroupCheckbox, RadioTextWrapper,
} from "../Styled/Form";

import * as Components from "~/components";

const StyledWrapper = styled.div(() => [
  tw`-mt-4 -ml-4 -mr-10`,
]);

export const RadioGroup: React.FunctionComponent<RadioGroupProps> =(props) => {
  const [showDescription, setShowDescription] = React.useState<{ [key: string]: boolean; }>({});

  return (
    <StyledWrapper>
      <Components.HorizontalSlider
        itemWidth={75}
      >
        {props.data.map(({ label, description, value }, index) => (
          <RadioGroupWrapper
            key={value}
            checked={props.checked === value}
            interactive={props.interactive}
          >
            {props.interactive !== false && (
              <React.Fragment>
                <HiddenCheckbox
                  type="radio"
                  value={value}
                  onChange={(e) => props.onChange(e.target.value)}
                  onBlur={() => props.onBlur?.(index)}
                  checked={props.checked === value}
                />
                <RadioGroupCheckbox>
                  <RadioCircle
                    checked={props.checked === value}
                  />
                </RadioGroupCheckbox>
              </React.Fragment>
            )}
            <RadioTextWrapper>
              <Components.Row
                spacing="ogxxxxs"
              >
                <Components.TextVariation
                  variation="paragraph1bold"
                  color="blueNavy"
                >
                  {label}
                </Components.TextVariation>
              </Components.Row>
              {props.showDescription && showDescription[index] ? (
                <Components.Row
                  spacing="ogxxxxs"
                >
                  <Components.TextVariation
                    variation="paragraph2"
                    color="greyMedium"
                  >
                    {description}
                  </Components.TextVariation>
                </Components.Row>
              ) : null}
              {props.showDescription && (
                <Components.TextVariation
                  variation="textlink2"
                  onClick={() => setShowDescription({
                    ...setShowDescription,
                    [index]: !showDescription[index],
                  })}
                >
                  {showDescription[index] ? "View less" : "View definition"}
                </Components.TextVariation>
              )}
            </RadioTextWrapper>
          </RadioGroupWrapper>
        ))}
      </Components.HorizontalSlider>
    </StyledWrapper>
  );
};

export type RadioGroupProps = {
  interactive?: boolean;
  data: Array<RadioData>;
  checked: string;
  onChange: (index: string) => void;
  onBlur?: (index: number) => void;
  showDescription?: boolean;
};

export type RadioData = {
  label: string;
  description?: string;
  value: string;
};
