import tw, { styled } from "twin.macro";

export const CheckboxGroupWrapper = styled.label(() => [
  tw`flex mb-4 cursor-pointer select-none w-full items-center`,
]);

export const Checkbox = styled.span(({ checked }: CheckboxProps) => [
  tw`w-6 h-6 flex items-center justify-center flex-shrink-0 border transition`,
  ...(checked ? [tw`bg-pink border-pink`] : [tw`bg-white border-pink-light`]),
]);

export const CheckboxTick = styled.span(({ checked }: CheckboxProps) => [
  tw`border border-t-0 border-l-0 border-2 border-white w-2 h-4 transform rotate-45 -translate-y-0.5`,
  ...(checked ? [tw`opacity-100 visible`] : [tw`opacity-0 invisible`]),
]);

export const Label = tw.span`text-blue-navy block text-sm`;

type CheckboxProps = {
  checked: boolean;
}
