import * as React from "react";
import { useHistory } from "react-router-dom";
import { SIGNUP, USER_LOGIN } from "~/utils/constants";
import { ErrorContainer } from "../ErrorContainer/ErrorContainer";

import * as Components from "~/components";

export const EmailExistsError: React.FC = () => {
  const history = useHistory();
  return (
    <ErrorContainer
      message={(
        <>
          {"This email looks familiar..."}
          <br />
          {"Another account is using this email address. If you think this might be you"}
        </>
      )}
      action={() => history.push(USER_LOGIN)}
      actionText="Try logging in"
    >
      <Components.TextContainer
        align="center"
      >
        <Components.TextVariation
          variation="paragraph2"
          color="greyDark"
        >
          {"Or you can try "}
          <Components.TextLink
            textVariation="textlink2"
            href={SIGNUP}
          >
            {"re-setting your password."}
          </Components.TextLink>
          {"If you're still having trouble please contact the BCNA Helpline on "}
          <Components.TextLink
            textVariation="textlink2"
            href="tel:1800500258"
          >
            {"1800 500 258"}
          </Components.TextLink>
          {"."}
        </Components.TextVariation>
      </Components.TextContainer>
    </ErrorContainer>
  );
};
