import * as React from "react";
import { Diagnosis, DiagnosisTree } from "myjourney-frontend/src/vendor/umbraco";
import { useContentApi } from "./useContentApi";
import { PatientContext } from "../providers/PatientProvider";
import { isAfter, isBefore, sub } from "date-fns";

const mapData = (data: DiagnosisTree) => data.map(({ name, description, id }) => ({
  label: name,
  description,
  value: `${id}`,
}));

export const useDiagnosis = (): DiagnosisProps => {
  const patientContext = React.useContext(PatientContext);
  const { getDiagnosisTree } = useContentApi();

  const [diagnosis, setDiagnosis] = React.useState<string>(patientContext.auth?.patient_details?.diagnosis_ids?.[0]?.[0] || "");
  const [diagnosisTree, setDiagnosisTree] = React.useState<DiagnosisTree | null>(null);
  const [diagnosisData, setDiagnosisData] = React.useState<Array<RadioGroupData> | null>(null);
  const [diagnosisItem, setDiagnosisItem] = React.useState<Diagnosis | null | undefined>(null);
  const [situation, setSituation] = React.useState<string>(patientContext.auth?.patient_details?.diagnosis_ids?.[1]?.[0] || "");
  const [situationItem, setSituationItem] = React.useState<Diagnosis | null | undefined>(null);
  const [situationData, setSituationData] = React.useState<Array<RadioGroupData> | null>(null);
  const [treatmentItem, setTreatmentItem] = React.useState<Diagnosis | null | undefined>(null);
  const [treatmentData, setTreatmentData] = React.useState<Array<RadioGroupData> | null>(null);
  const [locationItems, setLocationItems] = React.useState<Array<Diagnosis> | null | undefined>(null);
  const [locationData, setLocationData] = React.useState<Array<RadioGroupData> | null>(null);
  const [treatment, setTreatment] = React.useState<string>(patientContext.auth?.patient_details?.diagnosis_ids?.[2]?.[0] || "");
  const [locations, setLocations] = React.useState<Array<string>>(patientContext.auth?.patient_details?.diagnosis_ids?.[3] || []);
  const [diagnosisDateError, setDiagnosisDateError] = React.useState<React.FC | string | null>(null);
  const [diagnosisDateValid, setDiagnosisDateValid] = React.useState<boolean>(false);
  const [diagnosisDate, setDiagnosisDate] = React.useState<Date | undefined>(patientContext.auth?.patient_details?.diagnosis_date ? new Date(patientContext.auth?.patient_details?.diagnosis_date) : undefined);
  const [otherSelected, setOtherSelected] = React.useState<boolean>(false);
  const [otherLocation, setOtherLocation] = React.useState<string>(patientContext.memberMetadata?.mbcSite || "");

  React.useEffect(() => {
    (async () => {
      const _diagnosisTree = await getDiagnosisTree();
      setDiagnosisTree(_diagnosisTree);
    })();
  }, []);

  React.useEffect(() => {
    if (patientContext.auth?.patient_details?.diagnosis_ids?.[0]?.[0] && patientContext.auth?.patient_details?.diagnosis_ids?.[0]?.[0] !== diagnosis) {
      setDiagnosis(patientContext.auth?.patient_details?.diagnosis_ids?.[0]?.[0]);
    }
    if (patientContext.auth?.patient_details?.diagnosis_ids?.[1]?.[0] && patientContext.auth?.patient_details?.diagnosis_ids?.[1]?.[0] !== situation) {
      setSituation(patientContext.auth?.patient_details?.diagnosis_ids?.[1]?.[0]);
    }
    if (patientContext.auth?.patient_details?.diagnosis_ids?.[2]?.[0] && patientContext.auth?.patient_details?.diagnosis_ids?.[2]?.[0] !== treatment) {
      setTreatment(patientContext.auth?.patient_details?.diagnosis_ids?.[2]?.[0]);
    }
    if (patientContext.auth?.patient_details?.diagnosis_ids?.[3].sort().join("") && patientContext.auth?.patient_details?.diagnosis_ids?.[3].sort().join("") !== locations.sort().join("")) {
      setLocations(patientContext.auth?.patient_details?.diagnosis_ids?.[3].sort());
    }
  }, [
    patientContext.auth?.patient_details?.diagnosis_ids?.[0]?.[0],
    patientContext.auth?.patient_details?.diagnosis_ids?.[1]?.[0],
    patientContext.auth?.patient_details?.diagnosis_ids?.[2]?.[0],
    patientContext.auth?.patient_details?.diagnosis_ids?.[3].sort().join(""),
  ]);

  const resetFromPatientDetails = (): void => {
    setDiagnosis(patientContext.auth?.patient_details?.diagnosis_ids?.[0]?.[0] || "");
    setSituation(patientContext.auth?.patient_details?.diagnosis_ids?.[1]?.[0] || "");
    setTreatment(patientContext.auth?.patient_details?.diagnosis_ids?.[2]?.[0] || "");
    setLocations(patientContext.auth?.patient_details?.diagnosis_ids?.[3] || []);
    setDiagnosisDate(patientContext.auth?.patient_details?.diagnosis_date ? new Date(patientContext.auth?.patient_details?.diagnosis_date) : undefined);
    setOtherLocation(patientContext.memberMetadata?.mbcSite || "");
  };

  React.useEffect(() => {
    if (locationItems) {
      const _otherSelected = !!locationItems.find(({ hpReferralTitle }) => hpReferralTitle === "Other");
      setOtherSelected(_otherSelected);
    }
  }, [patientContext]);

  React.useEffect(() => {
    if (diagnosisTree) {
      const _diagnosisData = mapData(diagnosisTree);
      setDiagnosisData(_diagnosisData);
      if (diagnosis) {
        const _diagnosis = diagnosisTree.find(({ id }) => `${id}` === diagnosis);
        setDiagnosisItem(_diagnosis);
        if (_diagnosis) {
          const _situationData = mapData(_diagnosis.children);
          setSituationData(_situationData);
          if (situation) {
            const _situation = _diagnosis.children.find(({ id }) => `${id}` === situation);
            setSituationItem(_situation);
            if (_situation) {
              const _treatmentData = mapData(_situation.children);
              setTreatmentData(_treatmentData);
              if (treatment) {
                const _treatment = _situation.children.find(({ id }) => `${id}` === treatment);
                setTreatmentItem(_treatment);
                if (_treatment) {
                  const _locationData = mapData(_treatment.children);
                  setLocationData(_locationData);
                  if (locations) {
                    const _locationsItems = _treatment.children.filter(({ id }) => locations.includes(`${id}`));
                    setLocationItems(_locationsItems);
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [diagnosisTree, diagnosis, situation, treatment, locations]);

  const handleDiagnosis = (value: string) => {
    setDiagnosis(value);
    setSituation("");
    setSituationItem(null);
    setTreatment("");
    setTreatmentItem(null);
    setLocations([]);
    setLocationItems([]);
  };
  const handleSituation = (value: string) => {
    setSituation(value);
    setTreatment("");
    setTreatmentItem(null);
    setLocations([]);
    setLocationItems([]);
  };
  const handleTreatment = (value: string) => {
    setTreatment(value);
    setLocations([]);
    setLocationItems([]);
  };
  const handleLocations = (value: string) => {
    setLocations((prev) => prev.indexOf(value) > -1 ? prev.filter((f) => f !== value) : [...prev, value]);
  };
  const handleOtherLocation = (value: string) => {
    setOtherLocation(value);
  };

  const handleDiagnosisDate = (date?: Date) => {
    setDiagnosisDate(date);
    if (!date) {
      setDiagnosisDateError("Required");
    } else {
      /** If their diagnosis date is older than 150 years return an error */
      if (isBefore(date, sub(new Date(), {
        years: 150,
      }))) {
        setDiagnosisDateError("It looks like you've entered an invalid date.");
      } else if (isAfter(date, new Date())) {
        /** If the date selected is later than today return an error */
        setDiagnosisDateError("The date you've enterered is in the future.");
      } else {
        setDiagnosisDateError(null);
        setDiagnosisDateValid(true);
      }
    }
  };

  const getDiagnosisById = (id: string, diagnosisTree: DiagnosisTree): Diagnosis | null => {
    return diagnosisTree.find((item) => item.id.toString() === id) || null;
  };

  return {
    getDiagnosisById,
    diagnosisData,
    diagnosisItem,
    situationData,
    situationItem,
    situation,
    treatmentData,
    treatmentItem,
    locationData,
    locationItems,
    diagnosisDate,
    handleLocations,
    handleDiagnosisDate,
    handleOtherLocation,
    diagnosisDateValid,
    diagnosisDateError,
    treatment,
    handleTreatment,
    handleSituation,
    handleDiagnosis,
    setDiagnosisDate,
    setDiagnosis,
    diagnosis,
    setSituation,
    locations,
    otherSelected,
    otherLocation,
    resetFromPatientDetails,
  };
};

export type DiagnosisProps = {
  getDiagnosisById: (id: string, diagnosisTree: DiagnosisTree) => Diagnosis | null;
  diagnosisData: Array<RadioGroupData> | null;
  diagnosisItem: Diagnosis | null | undefined;
  situationData: Array<RadioGroupData> | null;
  situationItem: Diagnosis | null | undefined;
  situation: string;
  treatmentData: Array<RadioGroupData> | null;
  treatmentItem: Diagnosis | null | undefined;
  treatment: string;
  locationData: Array<RadioGroupData> | null;
  locationItems: Array<Diagnosis> | null | undefined;
  locations: Array<string>;
  diagnosisDateError: FormError;
  diagnosisDateValid: boolean;
  diagnosisDate: Date | undefined;
  handleDiagnosisDate: (value: Date) => void;
  handleDiagnosis: (value: string) => void;
  handleSituation: (value: string) => void;
  handleTreatment: (value: string) => void;
  handleLocations: (value: string) => void;
  handleOtherLocation: (value: string) => void;
  setDiagnosisDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setDiagnosis: React.Dispatch<React.SetStateAction<string>>;
  setSituation: React.Dispatch<React.SetStateAction<string>>;
  diagnosis: string;
  otherSelected: boolean;
  otherLocation: string;
  resetFromPatientDetails: () => void;
}

type FormError = React.FC | string | null;

type RadioGroupData = {
  label: string;
  description?: string;
  value: string;
};
