import { WelcomeData } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useAuthentication } from "~/hooks/useAuthentication";
import { useContentApi } from "~/hooks/useContentApi";
import { PatientContext } from "~/providers/PatientProvider";
import { USER_ARTICLES_TOPICS } from "~/utils/constants";

export const withImportantInfo = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "ImportantInfo", ...props }: Props) => {
  Component.displayName = name;
  useAuthentication();
  const history = useHistory();
  const { getWelcomeData } = useContentApi();
  const { auth } = React.useContext(PatientContext);
  const [welcomeData, setWelcomeData] = React.useState<WelcomeData | null>(null);

  React.useEffect(() => {
    (async () => {
      const res = await getWelcomeData(auth);
      if (res?.importantInfo.length === 0) {
        history.push(USER_ARTICLES_TOPICS);
      }
      setWelcomeData(res);
    })();
  }, []);

  return (
    <Component
      { ...props as P & Props }
      welcomeData={welcomeData}
    />
  );
};

type Props = {
  name?: string
}
