import * as React from "react";
import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Providers from "~/providers";
import * as Components from "~/components";

import IconPlus from "~/../assets/icons/icon-plus.svg";
import IconTopic from "~/../assets/icons/icon-topic.svg";
import IconBookmark from "~/../assets/icons/icon-bookmark.svg";
import useGetMetastaticBreastCancerDiagnosisId from "~/hooks/useGetMetastaticBreastCancerDiagnosisId";

export const Feed: React.FunctionComponent<FeedProps> = () => {
  Hooks.useAuthentication();
  const { getArticles } = Hooks.useContentApi();
  const hookNotes = Hooks.useNotes();
  const hookSymptoms = Hooks.useSymptoms();
  const { auth, diagnosis, situation, treatment } = React.useContext(
    Providers.PatientContext
  );
  const [featuredArticles, setFeaturedArticles] = React.useState<
    Array<MergedArticle>
  >([]);

  const { metastaticDiagnosisId } = useGetMetastaticBreastCancerDiagnosisId();
  React.useEffect(() => {
    (async () => {
      const _articles = await getArticles();
      if (_articles) {
        setFeaturedArticles(_articles.featured);
      }
    })();
  }, []);

  return (
    <Components.AppLayout headerVariation="navigationUser">
      <Components.Meta title="Home" />
      <Components.Row>
        <Components.Column spacing="ogsm">
          <Components.TextVariation variation="heading1" spacing="og1">
            {`Welcome ${auth?.patient_details.first_name}`}
          </Components.TextVariation>
          <Components.TextVariation variation="heading3" spacing="ogxxs">
            Are your details up to date?
          </Components.TextVariation>
          <Components.Card color="pinkMedium" padding="ogxxs">
            <Components.Column spacing="ogxxs">
              <Components.TextVariation variation="heading4" spacing="ogxxxs">
                My diagnosis
              </Components.TextVariation>
              <Components.Input
                value={
                  diagnosis?.hpReferralTitle ? diagnosis.hpReferralTitle : ""
                }
                readonly
                ariaLabel="Diagnosis"
              />
            </Components.Column>
            <Components.Column spacing="ogxxs">
              <Components.TextVariation variation="heading4" spacing="ogxxxs">
                My situation
              </Components.TextVariation>
              <Components.Input
                value={
                  diagnosis?.id === metastaticDiagnosisId
                    ? treatment?.hpReferralTitle || ""
                    : situation?.hpReferralTitle
                    ? situation?.hpReferralTitle
                    : ""
                }
                readonly
                ariaLabel="Situation"
              />
            </Components.Column>
            <Components.Column>
              <Components.ButtonVariation
                variation="primary"
                href={Utils.USER_MY_ACCOUNT}
                aria-label="Update my profile"
                fullWidth
              >
                Update my profile
              </Components.ButtonVariation>
            </Components.Column>
          </Components.Card>
        </Components.Column>
        <Components.Column spacing="ogsm">
          <Components.TextVariation variation="heading1" spacing="og1">
            My articles
          </Components.TextVariation>
          <Components.TextVariation variation="heading3" spacing="ogxxs">
            Learn about what to expect
          </Components.TextVariation>
          <Components.Card color="pinkMedium" padding="ogxxs">
            <Components.Column spacing="ogxxs">
              <Components.SearchForm />
            </Components.Column>
            <Components.Column spacing="ogxxxs">
              <Components.ButtonVariation
                href={Utils.USER_ARTICLES}
                variation="square"
                iconLeft={<IconTopic />}
              >
                Articles by topic
              </Components.ButtonVariation>
            </Components.Column>

            <Components.Column spacing="ogxs">
              <Components.ButtonVariation
                href={Utils.USER_ARTICLES_BOOKMARKS}
                variation="square"
                iconLeft={<IconBookmark />}
              >
                Bookmarks
              </Components.ButtonVariation>
            </Components.Column>
            <Components.Column spacing="ogxxs">
              <Components.TextVariation variation="heading4" spacing="ogxxxs">
                Featured articles
              </Components.TextVariation>
              <Components.ArticleList
                articles={featuredArticles}
                link={Utils.APP_ARTICLES_CATEGORY.replace(
                  ":categoryId",
                  "featured"
                )}
                linkText={`View all featured articles (${
                  featuredArticles?.length || ""
                })`}
              />
            </Components.Column>
          </Components.Card>
        </Components.Column>
        <Components.Column spacing="ogsm">
          <Components.TextVariation variation="heading1" spacing="og1">
            My tracker
          </Components.TextVariation>
          <Components.TextVariation variation="heading3" spacing="ogxxs">
            Record how you are feeling
          </Components.TextVariation>
          <Components.Column spacing="sm">
            <Components.ButtonVariation
              variation="primary"
              href={Utils.USER_SYMPTOMS_ADD}
              aria-label="Add symptom"
              iconLeft={<IconPlus />}
              fullWidth
            >
              {"Symptom"}
            </Components.ButtonVariation>
          </Components.Column>
          {!hookSymptoms.loading &&
            (hookSymptoms.symptoms.length === 0 ? (
              <Components.Card
                color="pinkMedium"
                padding="ogxxs"
                aria-label="Symptoms card"
              >
                <Components.Row>
                  <Components.Column>
                    <Components.TextVariation variation="heading3">
                      {"Add your first symptom above so you can:"}
                    </Components.TextVariation>
                    <Components.TextVariation
                      variation="paragraph1"
                      color="blueNavy"
                    >
                      <ul>
                        <li>{"Track your progress over time"}</li>
                        <li>{"Share information with health professionals"}</li>
                        <li>{"Improve your treatment plan"}</li>
                      </ul>
                    </Components.TextVariation>
                  </Components.Column>
                </Components.Row>
              </Components.Card>
            ) : (
              <Components.Card
                color="pinkMedium"
                padding="md"
                aria-label="Symptoms card"
              >
                <Components.Row>
                  {hookSymptoms.graph && (
                    <Components.Column spacing="ssm">
                      <Components.H4 spacing="ssm">
                        {"30 day summary"}
                      </Components.H4>
                      <Components.SymptomGraphCombined />
                    </Components.Column>
                  )}
                  <Components.Column spacing="ogxxs">
                    <Components.TextVariation
                      variation="heading4"
                      spacing="ogxxxs"
                    >
                      {"Recent symptoms"}
                    </Components.TextVariation>
                    <Components.SymptomList />
                  </Components.Column>
                </Components.Row>
                <Components.ArrowScroller
                  link={Utils.USER_SYMPTOMS}
                  linkText={"View all symptoms"}
                />
              </Components.Card>
            ))}
        </Components.Column>

        <Components.Column>
          <Components.TextVariation variation="heading1" spacing="og1">
            {"My notes"}
          </Components.TextVariation>
          <Components.TextVariation variation="heading3" spacing="ogxxs">
            {"Record notes for reference"}
          </Components.TextVariation>
          <Components.Column spacing="sm">
            <Components.ButtonVariation
              variation="primary"
              href={Utils.USER_NOTES_ADD}
              aria-label="Add note"
              iconLeft={<IconPlus />}
              fullWidth
            >
              {"Note"}
            </Components.ButtonVariation>
          </Components.Column>
          {!hookNotes.loading &&
            (hookNotes.notes.length === 0 ? (
              <Components.Card
                color="pinkMedium"
                padding="md"
                aria-label="Symptoms card"
              >
                <Components.Row>
                  <Components.Column>
                    <Components.TextVariation variation="heading3">
                      {
                        "Add your first note above and keep all your questions and reminders in one place"
                      }
                    </Components.TextVariation>
                  </Components.Column>
                </Components.Row>
              </Components.Card>
            ) : (
              <Components.Card
                color="pinkMedium"
                padding="md"
                aria-label="Notes card"
              >
                <Components.Row>
                  <Components.Column spacing="ogxxs">
                    <Components.TextVariation
                      variation="heading4"
                      spacing="ogxxxs"
                    >
                      {"Recent notes"}
                    </Components.TextVariation>
                    <Components.NoteList />
                  </Components.Column>
                </Components.Row>
                <Components.ArrowScroller
                  link={Utils.USER_NOTES}
                  linkText={"View all notes"}
                />
              </Components.Card>
            ))}
        </Components.Column>
      </Components.Row>
    </Components.AppLayout>
  );
};

export type FeedProps = unknown;
