import * as React from "react";

import { HP_REGISTER_EXTERNAL } from "~/utils/constants";
import { ContentWrapper } from "../styledHp";
import { ValidateEvent, withHpLogin } from "./withHpLogin";

import * as Components from "~/components";

export const HpLogin = withHpLogin(
  ({
    handleSubmit,
    validateUserId,
    validateEmail,
    userIDError,
    emailError,
    loading,
    formError,
  }: Props) => (
    <ContentWrapper>
      <Components.Meta
        title="Health practitioner login"
        metaDescription="Log in to My Journey for health professionals and sign up your patients."
      />
      <Components.TextVariation variation="heading1" spacing="og1">
        {"Log In"}
      </Components.TextVariation>
      <Components.TextContainer spacing="ogxxs">
        <Components.TextVariation
          variation="paragraph1"
          color="greyDark"
          spacing="ogxxxs"
        >
          {
            "Please log in to begin the sign up process for your patient to gain access to BCNA's My Journey app. If you're not already registered as a health professional with BCNA you can "
          }
          <Components.StyledHyperlink
            weight="normal"
            href={HP_REGISTER_EXTERNAL}
            target="_blank"
            rel="noopener"
          >
            {"register here"}
          </Components.StyledHyperlink>
          {"."}
        </Components.TextVariation>
      </Components.TextContainer>
      {formError ? (
        <Components.Card color="blueDusty" spacing="md" padding="md">
          <Components.Small spacing="none">{formError}</Components.Small>
        </Components.Card>
      ) : null}
      <Components.Form onSubmit={(e) => handleSubmit(e)}>
        <Components.Row spacing="md">
          <Components.Column spacing="sm">
            <Components.Input
              label="BCNA registered userID"
              onBlur={validateUserId}
              onChange={validateUserId}
              error={userIDError}
              name="userId"
              required
            />
          </Components.Column>
          <Components.Column spacing="md">
            <Components.Input
              label="BCNA registered email address"
              onBlur={validateEmail}
              onChange={validateEmail}
              error={emailError}
              name="userEmail"
              type="email"
              required
            />
          </Components.Column>
          <Components.Column>
            <Components.ButtonVariation
              variation="primary"
              type="submit"
              disabled={loading}
              fullWidth
            >
              {loading ? "Loading..." : "Log in"}
            </Components.ButtonVariation>
          </Components.Column>
        </Components.Row>
      </Components.Form>
      <Components.TextContainer spacing="ogxxs">
        <Components.TextVariation variation="paragraph1" color="greyDark">
          {"Forgotten your login details? Call "}
          <Components.StyledHyperlink weight="normal" href="tel:1800500258">
            {"1800 500 258"}
          </Components.StyledHyperlink>
          {"."}
        </Components.TextVariation>
      </Components.TextContainer>
      <Components.Card color="blueDusty" padding="md">
        <Components.TextVariation variation="heading4" spacing="xxs">
          {"About the sign up process"}
        </Components.TextVariation>
        <Components.TextVariation variation="paragraph2" color="greyDark">
          {
            "Providing us with basic information (such us name, diagnosis and diagnosis date) allows us to present your patient with the most relevant information. Once you submit these details they will receive an SMS or email with instructions on completing the sign up process."
          }
        </Components.TextVariation>
      </Components.Card>
    </ContentWrapper>
  )
);

type Props = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  validateUserId: (event: ValidateEvent) => void;
  validateEmail: (event: ValidateEvent) => void;
  userIDError: JSX.Element | string | undefined;
  emailError: JSX.Element | string | undefined;
  loading: boolean;
  formError: JSX.Element | string | undefined;
};
