import * as React from "react";
import tw, { css, styled } from "twin.macro";
import { Link } from "react-router-dom";

import { Global, TextAligns, Colours, Spacings } from "~/components/Styled/Global";

export const TextVariations = {
  // heading
  headingdisplay: tw`text-36 leading-46 font-semibold text-blue-navy block`,
  headingdisplayshrink: tw`text-24 leading-36 md:text-36 md:leading-48 font-semibold text-blue-navy block`,
  heading1: tw`text-24 leading-36 font-semibold text-blue-navy block`,
  heading1sticky: tw`text-16 leading-24 font-semibold text-blue-navy block`,
  heading2: tw`text-22 leading-30 font-semibold text-blue-navy block`,
  heading3: tw`text-18 leading-24 font-medium text-blue-navy block`,
  heading4: tw`text-14 leading-20 font-semibold text-blue-navy block`,
  heading5: tw`text-14 leading-20 font-medium text-blue-navy block`,
  heading6: tw`text-11 leading-24 font-semibold uppercase text-blue-navy block`,

  // paragraph
  paragraph1: tw`text-16 leading-24 font-normal`,
  paragraph1bold: tw`text-16 leading-24 font-semibold`,
  paragraph2: tw`text-14 leading-22 font-medium`,
  paragraph2bold: tw`text-14 leading-22 font-semibold`,

  // textlink
  textlink1: tw`text-16 leading-24 font-medium text-pink underline cursor-pointer`,
  textlink2: tw`text-14 leading-20 font-medium text-pink underline cursor-pointer`,
  textlink2nounderline: tw`text-14 leading-20 font-medium text-pink cursor-pointer`,
  textlink3: tw`text-12 leading-12 font-medium text-pink underline cursor-pointer`,

  // label
  label1: tw`text-14 leading-20 font-semibold text-blue-navy`,
  label2: tw`text-14 leading-20 font-medium text-blue-navy`,
  label3: tw`text-11 leading-16 font-medium text-blue-navy`,

  // button
  button1: tw`text-16 leading-24 font-bold`,
  button2: tw`text-14 leading-20 font-medium`,
  button3: tw`text-10 leading-12 font-semibold`,

  navigation: tw`text-11 leading-14 font-normal text-blue-navy`,
  navigationbold: tw`text-11 leading-14 font-semibold text-blue-navy`,

  // utility
  bold: tw`font-bold`,
  semibold: tw`font-semibold`,
  none: tw``,
};

export const TextVariation = styled.span((props: TextVariationProps) => [
    TextVariations[props.variation],
    ...props.align ? [Global.textAlign[props.align]] : [],
    ...props.spacing ? [Global.spacing[props.spacing]] : [],
    ...props.color ? [Global.colour[props.color]] : [],
    ...props.loading ? [tw`rounded-sm bg-blue-dusty h-10 w-2/3`] : [],
    ...props.fullWidth ? [tw`w-full inline-block`] : [],
    css`
      ul {
        ${tw`list-disc`}
        margin: revert;
        padding: revert;
      }
    `,
  ]
);

export type TextVariationProps = {
  variation: keyof typeof TextVariations;
  align?: TextAligns;
  spacing?: Spacings;
  color?: Colours;
  loading?: boolean;
  fullWidth?: boolean;
};

export const TextContainer = styled.p(
  (props: TextContainerProps) => [
    ...props.align ? [Global.textAlign[props.align]] : [],
    ...props.spacing ? [Global.spacing[props.spacing]] : [],
  ],
  tw`w-full`,
);

export type TextContainerProps = {
  align?: TextAligns,
  spacing?: Spacings,
};

const StyledIconLeftWrapper = styled.span(() => [
  tw`inline-block pr-2`,
]);
const StyledIconRightWrapper = styled.span(() => [
  tw`inline-block pl-2`,
]);

export const TextLink: React.FunctionComponent<TextLinkProps> = (props) => (
  <Link
    to={{
      pathname: props.href,
    }}
    target={props.target}
  >
    <TextVariation
      variation={props.textVariation ?? "textlink1"}
    >
      {props.iconLeft && (
        <StyledIconLeftWrapper>
          {props.iconLeft}
        </StyledIconLeftWrapper>
      )}
      {props.children}
      {props.iconLeft && (
        <StyledIconRightWrapper>
          {props.iconRight}
        </StyledIconRightWrapper>
      )}
    </TextVariation>
  </Link>
);

export type TextLinkProps = {
  href: string;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  textVariation?: "none" | "textlink1" | "textlink2" | "textlink2nounderline" | "textlink3";
} & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
