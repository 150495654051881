import * as React from "react";
import { FileInputButton, IconWrapper, InputWrapper, Label } from "../Styled";
import { HiddenFileInput, RemoveButton, Thumb, ThumbContainer, ThumbObject, ThumbWrapper } from "./styledFileInput";
import { withFileInput } from "./withFileInput";
import RemoveIcon from "~/../assets/icons/remove.svg";
import CameraIcon from "~/../assets/icons/camera.svg";

import * as Components from "~/components";

export const FileInput = withFileInput((props: Props) => {
  const fileRef = React.useRef<HTMLInputElement>(null);
  return (
    <InputWrapper>
      <Label>
        <Components.TextVariation
          variation="label1"
        >
          {props.label}
        </Components.TextVariation>
      </Label>
      <HiddenFileInput
        ref={fileRef}
        type="file"
        accept="image/*"
        multiple={props.allowMultiple}
        onChange={props.handleImage}
      />
      <FileInputButton
        type="button"
        onClick={() => fileRef?.current?.click()}
      >
        {props.placeholder}
        <IconWrapper>
          <CameraIcon />
        </IconWrapper>
      </FileInputButton>
      {props.showThumbnail && (
        <ThumbContainer>
          {props.dataUrls.map((url, i) => (
            <ThumbWrapper
              key={url}
              status={props.status[url]}
            >
              <RemoveButton
                type="button"
                onClick={() => props.handleRemoveImage(i)}
              >
                <RemoveIcon />
              </RemoveButton>
              <ThumbObject>
                <Thumb
                  src={url}
                />
              </ThumbObject>
            </ThumbWrapper>
          ))}
        </ThumbContainer>
      )}
    </InputWrapper>
  );
});

type Props = {
  label: string
  placeholder: string
  /** Base64 strings */
  dataUrls: Array<string>
  handleRemoveImage: (index: number) => void
  handleImage: (event: React.ChangeEvent<HTMLInputElement>) => void
  status: Record<string, "uploading" | "uploaded" | "error">
  allowMultiple?: boolean
  showThumbnail?: boolean
}
