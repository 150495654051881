import * as React from "react";
import { withPasswordReset } from "./withPasswordReset";

import * as Components from "~/components";

export const PasswordReset = withPasswordReset(({ handleSubmit, submitting, code, handleCode, handlePassword, handleConfirmPassword, passwordValid, passwordError, confirmPasswordError, passwordRules, formError }: PasswordResetProps) => (
  <Components.AppLayout
    headerVariation="standard"
  >
    <Components.Meta
      title="Reset your password"
      metaDescription="Reset your password for the My Journey app."
    />
    <Components.Row>
      <Components.Column>
        <Components.TextVariation
          variation="heading1"
          spacing="og1"
        >
          {"Reset your password"}
        </Components.TextVariation>
        <Components.TextContainer
          spacing="ogxxs"
        >
          <Components.TextVariation
            variation="paragraph1"
            color="greyDark"
          >
            {"So that we can keep your information secure, your password must be at least 6 characters in length and contain at least one uppercase letter, number, and non-alphanumeric symbol (e.g. !@#$%^&*)"}
          </Components.TextVariation>
        </Components.TextContainer>
        <Components.Form
          onSubmit={handleSubmit}
        >
          <Components.Row>
            <Components.Column
              spacing="ogxxs"
              md="1/2"
            >
              <Components.Input
                label="Password"
                type="password"
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassword(e.target.value)}
                error={passwordError}
                hideRequired
              />
            </Components.Column>
            <Components.Column
              spacing="ogxxs"
              md="1/2"
            >
              <Components.Input
                label="Confirm Password"
                type="password"
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleConfirmPassword(e.target.value)}
                error={confirmPasswordError}
                hideRequired
              />
            </Components.Column>
            <Components.Column
              spacing="ogxxs"
            >
              <Components.PasswordHelper
                rules={passwordRules}
              />
            </Components.Column>
          </Components.Row>
          <Components.Row>
            <Components.Column
              spacing="ogxxs"
            >
              <Components.Input
                label="Verification Code"
                name="code"
                type="text"
                required
                hideRequired
                value={code}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCode(e.target.value)}
              />
            </Components.Column>
          </Components.Row>
          {formError ? (
            <Components.Column
              spacing="ogxxs"
            >
              <Components.ErrorContainer
                {...formError}
              />
            </Components.Column>
          ) : null}
          <Components.Column
            justify="center"
          >
            <Components.ButtonVariation
              variation="primary"
              type="submit"
              disabled={submitting || !passwordValid}
              fullWidth
            >
              {submitting ? "Loading..." : "Reset Password"}
            </Components.ButtonVariation>
          </Components.Column>
        </Components.Form>
      </Components.Column>
    </Components.Row>
  </Components.AppLayout>
));

export type PasswordResetProps = {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleCode: (value: string) => void;
  handlePassword: (value: string) => void;
  handleConfirmPassword: (value: string) => void;
  passwordRules: Record<string, boolean>;
  submitting: boolean;
  passwordError: FormError;
  confirmPasswordError: FormError;
  passwordValid: boolean;
  confirmPasswordValid: boolean;
  formError: {
    message: string;
    action?: () => void;
    actionText?: string;
    CallToAction?: React.FC;
  };
  code: string;
};

type FormError = JSX.Element | string | undefined;
