import * as React from "react";
import { format } from "date-fns";

import { NoteResponse } from "myjourney-frontend/src/vendor/umbraco";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Providers from "~/providers";
import * as Styled from "./styledNotesAdd";
import * as Components from "~/components";

export const NotesAdd: React.FunctionComponent<NotesAddProps> = () => {
  Hooks.useAuthentication();

  const { saveNote } = Hooks.useSymptomTrackerApi();
  const { trackTrackerLog } = Hooks.useAnalytics();
  const hookStickyHeader = Hooks.useStickyHeader();
  const { auth } = React.useContext(Providers.PatientContext);
  const [title, setTitle] = React.useState<string>("");
  const [text, setText] = React.useState<string>("");
  const [date, setDate] = React.useState<Date>(new Date());
  const [datePicker, setDatePicker] = React.useState<boolean>(false);
  const [saved, setSaved] = React.useState<NoteResponse | null>(null);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [textError, setTextError] = React.useState<string | null>(null);
  const [imageIds, setImageIds] = React.useState<Array<string>>([]);
  const [editNoteId, setEditNoteId] = React.useState<string>();

  const handleText = (value: string) => {
    setText(value);
    setTextError(null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSaving(true);
    setError(null);
    if (!text.length) {
      setError("Please provide a note");
      setTextError("Note is required");
      setSaving(false);
      return;
    }
    try {
      const res = await saveNote({
        title,
        text,
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        attachments: [],
        images: imageIds,
      });
      if (res.status === 200) {
        const json: NoteResponse = await res.json();
        trackTrackerLog({
          group: "Notes",
          attachments: imageIds.length ? ["Images"] : [],
          images: imageIds.length,
          files: 0,
          description: text.length > 0,
          actions: false,
          questions: false,
          note: {
            topic: title,
          },
        });
        setSaved(json);
      } else if (res.status === 401){
        setError("Unauthorized error. Please restart the application or login again.");
      } else if(res.status === 400){
        const json: NoteResponse = await res.json();
        if(json.errors){
          setError(`Error ${json.errors[0]}`);
        }
      } else {
        setError("An error occurred, please try again later.");
      }
    } catch {
      setError("An error occurred, please try again later.");
    }
    setSaving(false);
  };

  const handleAddAnotherNote = () => {
    setSaved(null);
    setTitle("");
    setText("");
    setImageIds([]);
  };

  return (
    <Components.AppLayout
      headerVariation="navigationBack"
    >
      <Components.Meta
        title="Add note"
      />
      <Components.StickyHeader
        stickyHeader={hookStickyHeader.stickyHeader}
        headerStuck={hookStickyHeader.headerStuck}
        title={auth ? `${auth?.patient_details.first_name}'s Notes` : ""}
        setDate={setDate}
        setDatePicker={setDatePicker}
        date={date}
        datePicker={datePicker}
      />
      <Components.Section
        background="white"
        margin="negative"
        padding="sm"
      >
        <Components.Row>
          <Components.Column
            xs="2/3"
            sm="1/2"
          >
            <Styled.NoteTitle>
              {"Add your note in the area below"}
            </Styled.NoteTitle>
          </Components.Column>
        </Components.Row>
      </Components.Section>
      <Styled.StyledSection
        margin="negative"
        padding="sm"
      >
        {saved ? (
          <Styled.ResponseWrapper>
            <Components.H4
              colour="pink"
              spacing="xs"
            >
              Your note has been saved
            </Components.H4>
            <Components.P
              colour="blueNavy"
            >
              {"For: "}
              <Components.Strong>
                {format(date, "d MMM yyyy")}
              </Components.Strong>
            </Components.P>
            <Components.Row
              spacing="xl"
            >
              <Components.Column
                spacing="xs"
              >
                <Components.ButtonVariation
                  variation="primary"
                  onClick={() => handleAddAnotherNote()}
                  fullWidth
                >
                  Add another note
                </Components.ButtonVariation>
              </Components.Column>
              <Components.Column
                spacing="xs"
              >
                <Components.ButtonVariation
                  variation="secondary"
                  onClick={() => setEditNoteId(saved.note.id)}
                  fullWidth
                >
                  Edit this note
                </Components.ButtonVariation>
              </Components.Column>
              <Components.Column
                spacing="xs"
              >
                <Components.StyledLink
                  underlined
                  align="center"
                  weight="medium"
                  fontSize="sm"
                  full
                  to={Utils.USER_NOTES}
                >
                  Back to dashboard
                </Components.StyledLink>
              </Components.Column>
            </Components.Row>
          </Styled.ResponseWrapper>
        ) : (
          <>
            <Components.NoteForm
              handleSubmit={handleSubmit}
              setTitle={setTitle}
              title={title}
              handleText={handleText}
              text={text}
              setImageIds={setImageIds}
              error={error}
              disabled={saving}
              textError={textError}
            />
            <Components.Row>
              <Components.Column
                spacing="xl"
              >
                <Components.ButtonVariation
                  variation="secondary"
                  href={Utils.USER_NOTES}
                  fullWidth
                >
                  Back to notes
                </Components.ButtonVariation>
              </Components.Column>
            </Components.Row>
          </>
        )}
      </Styled.StyledSection>
      {saved?.note && (
        <Components.EditNote
          id={editNoteId}
          handleEditNoteId={(id) => setEditNoteId(id)}
          setSaved={setSaved}
          noteResponse={saved}
          note={saved.note}
        />
      )}
      <Styled.CalendarBackground
        active={datePicker}
        onClick={() => setDatePicker(false)}
      />
    </Components.AppLayout>
  );
};

export type NotesAddProps = unknown;
