import * as React from "react";

import { APP_HOME } from "~/utils/constants";

import * as Components from "~/components";

export const Faq: React.FunctionComponent<FaqProps> = () => {
  return (
    <Components.PublicLayout>
      <Components.Meta
        title="Frequently asked questions"
        metaDescription="Frequently asked questions about the My Journey app."
      />
      <Components.AppHeader
        back={APP_HOME}
      />
      <Components.TextVariation
        variation="heading1"
      >
        {"Frequently asked questions"}
      </Components.TextVariation>
      <Components.P>
        Below you will find answers to questions that we are frequently asked and may help you understand the My Journey online tool. If you have any other questions or concerns please don&apos;t hesitate to contact us on <Components.PhoneLink /> or email us at <Components.EmailLink />.
      </Components.P>
      <Components.TextVariation
        variation="heading2"
      >
        {"What does the My Journey online tool do?"}
      </Components.TextVariation>
      <Components.P>
        {"My Journey provides up to date information and resources reviewed by health professionals and others with similar experiences. The information and resources on My Journey are tailored to your specific diagnosis and where you're at in your experience."}
      </Components.P>
      <Components.TextVariation
        variation="heading2"
      >
        {"Why is this tool not an App?"}
      </Components.TextVariation>
      <Components.P>
        {"It is important that the information on the tool is accessible to everyone, anywhere in Australia, including people without mobile devices. To achieve this we have developed a web based tool so that it can be viewed on computers as well as on mobile devices."}
      </Components.P>
      <Components.P>
        {"We are hoping to be able to develop an App sometime in the future. In the meantime if you are on a mobile device please add the My Journey icon to your home screen to make it easier to access."}
      </Components.P>
      <Components.TextVariation
        variation="heading2"
      >
        {"Why do I have to provide personal details such as diagnosis?"}
      </Components.TextVariation>
      <Components.P>
        {"The amount of information available about breast cancer can be confusing and overwhelming. By telling us your details we are able to ensure the information we provide is specific to your needs. Because of this it is important you update your details as they change."}
      </Components.P>
      <Components.P>
        {"BCNA uses information we gather to assist us in our work to improve services and to be able to report to funding bodies. The information is de-identified, so your personal details are not shared."}
      </Components.P>
      <Components.P>
        {"If you have any privacy concerns please read our privacy policy on the BCNA website or contact us on 1800 500 258 if you would like more information"}
      </Components.P>
      <Components.TextVariation
        variation="heading2"
      >
        {"Why is this digital and not a printed book?"}
      </Components.TextVariation>
      <Components.P>
        {"There are several advantages to a digital resource."}
      </Components.P>
      <Components.P>
        {"It is easy to update regularly so you are receiving the most up-to-date, evidence-based information and resources at the time you need them."}
      </Components.P>
      <Components.P>
        {"It can be customised to make sure the information provided to you is relevant to your situation."}
      </Components.P>
      <Components.P>
        {"It can be easily shared with your loved ones via email."}
      </Components.P>
      <Components.P>
        {"It helps us learn about peoples' needs so we can continue to develop new information that is helpful and we can use it to inform our advocacy priorities."}
      </Components.P>
      <Components.P>
        {"It opens up new opportunities to support people in practical ways, for example we are currently exploring introducing a financial tracker."}
      </Components.P>
      <Components.TextVariation
        variation="heading2"
      >
        {"What if I still want a printed book?"}
      </Components.TextVariation>
      <Components.P>
        {"We understand that for some people a printed book is more suited to their individual needs. You can order printed resources by calling the BCNA Helpline on "}
        <Components.PhoneLink />
        {"."}
      </Components.P>
      <Components.TextVariation
        variation="heading2"
      >
        {"What is the latest app verson?"}
      </Components.TextVariation>
      <Components.P>
        {"The latest app version is "}
        <strong>
          {packagejson.npm_package_version}
        </strong>
      </Components.P>
    </Components.PublicLayout>
  );
};

export type FaqProps = unknown;
