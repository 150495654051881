import tw, { styled } from "twin.macro";

export const Overlay = styled.div(({ active }: ActiveProps) => [
  tw`fixed inset-0 z-70 flex flex-col h-full transition`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0 pointer-events-none`]),
]);
export const ModalBackground = styled.div(({ active }: ActiveProps) => [
  tw`bg-black bg-opacity-80 z-10 fixed inset-0 w-full h-full transition duration-500`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0`]),
]);

export const Inner = tw.div`max-w-screen-md w-full mx-auto h-full flex z-20 flex-col shadow pt-8 px-2 md:(pt-8 px-4)`;
export const Header = tw.header`border-b border-pink-light pt-12 pb-4 px-4 flex justify-between items-center rounded-t-sm bg-white`;
export const Content = tw.textarea`flex-grow outline-none px-4 py-8 text-grey-dark placeholder-grey-light resize-none`;
export const Footer = tw.footer`border-t border-pink-light bg-pink-light flex justify-center py-2`;
export const OverlayTitle = tw.p`text-blue-navy font-bold text-sm`;
export const CloseButton = tw.button`text-grey-light outline-none focus:(outline-none)`;

type ActiveProps = {
  active: boolean
}
