import * as React from "react";
import tw, { css, styled } from "twin.macro";

import * as Components from "~/components";

import IconCross from "~/../assets/icons/cross.svg";

const StyledToastContainer = styled.div(({ active, error }: {
  active: boolean
  error?: boolean
}) => [
  tw`fixed z-80 p-og4 pr-og10 border border-pink-dusty rounded-sm shadow left-2 right-2 sm:(w-80 left-auto)`,
  ...(error ? [tw`bg-red-validation`] : [tw`bg-green-validation`]),
  ...(active ? [tw`translate-x-0 opacity-100`] : [tw`translate-x-full opacity-0`]),
  css`
    bottom: 5.5rem;
  `,
]);

const StyledCloseButton = styled.button(() => [
  tw`rounded-full flex items-center justify-center text-white h-10 w-10 absolute top-0 right-0 focus:outline-none`,
]);

export const Toast: React.FunctionComponent<ToastProps> = (props) => (
  <StyledToastContainer
    active={props.active}
    error={props.error}
    role="alert"
  >
    <StyledCloseButton
      type="button"
      onClick={props.onClose}
    >
      <IconCross />
    </StyledCloseButton>
    <Components.TextVariation
      variation="paragraph1"
      color="white"
    >
      {props.message}
    </Components.TextVariation>
  </StyledToastContainer>
);

export type ToastProps = {
  message: string;
  active: boolean;
  error?: boolean;
  onClose: () => void;
}
