import * as React from "react";
import { ButtonLink } from "../Styled";
import { Gradient, Wrapper } from "./styledReadMore";
import { useReadMore } from "./useReadMore";

export const ReadMore: React.FC = ({ children }) => {
  const { handleReadMore, wrapperRef, active } = useReadMore();
  return (
    <>
      <Wrapper
        ref={wrapperRef}
      >
        {children}
        <Gradient />
      </Wrapper>
      <ButtonLink
        onClick={() => handleReadMore()}
      >{active ? "Read Less" : "Read More"}</ButtonLink>
    </>
  );
};
