import { format, isValid, parseISO } from "date-fns";
import { debounce, DebouncedFunction } from "ts-debounce";
import { MergedArticle, ReadArticle, StackedContent } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useContentApi } from "~/hooks/useContentApi";
import { getDiagnosisParent } from "~/utils/functions";

import { RecommendedArticles } from "./RecommendedArticles";
import { SubSections } from "./SubSections";
import { TextEditor } from "./TextEditor";
import { ArticleImage } from "./ArticleImage";
import { HelpfulResources } from "./HelpfulResources";
import { Quotes } from "./Quotes";
import { Actions } from "./Actions";
import { VideoBlock } from "./VideoBlock";
import { PatientContext } from "~/providers/PatientProvider";
import { usePatientApi } from "~/hooks/usePatientApi";

let debouncedFunction: DebouncedFunction<() => void>;

export const withArticle = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "Article", ...props }: Props) => {
  Component.displayName = name;
  const { auth, handleMemberMetadata } = React.useContext(PatientContext);
  const { articleId } = useParams<{ articleId: string }>();
  const { getArticle, getDiagnosisTree, getMemberMetadata } = useContentApi();
  const { trackArticleView, trackArticlePrint } = useAnalytics();
  const history = useHistory();
  const { patientUpdate, updateArticleProgress } = usePatientApi();
  const [article, setArticle] = React.useState<MergedArticle>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [reviewedDate, setReviewedDate] = React.useState<string>();
  const [forDiagnosis, setForDiagnosis] = React.useState<Array<string>>();
  const [bookmarked, setBookmarked] = React.useState<boolean>(false);
  const [modal, setModal] = React.useState<boolean>(false);
  const [lastScroll, setLastScroll] = React.useState<number>(0);
  const [readArticle, setReadArticle] = React.useState<ReadArticle>();

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      if (article && `${article.id}` !== articleId) {
        setArticle(undefined);
      }
      const _article = await getArticle(articleId);
      setArticle(_article);
      setLoading(false);
      const parsedDate = parseISO(_article.updateDate);
      const _reviewedDate = isValid(parsedDate)
        ? format(parsedDate, "MMMM d, yyyy")
        : undefined;
      setReviewedDate(_reviewedDate);

      const diagnosisTree = await getDiagnosisTree();
      const _forDiagnosis = getDiagnosisParent(_article.diagnosis, diagnosisTree);
      setForDiagnosis(_forDiagnosis);

      const bookmarkIds = auth?.patient_details.bookmark_ids || [];
      setBookmarked(Boolean(bookmarkIds.find((bookmarkId) => bookmarkId === `${_article.id}`)));

      trackArticleView({
        articleTitle: _article.name,
        articleId: `${_article.id}`,
        tags: _article.tags.join(","),
      });
    })();
  }, [articleId]);

  React.useEffect(() => {
    (async () => {
      const metadata = await getMemberMetadata();
      if (handleMemberMetadata) {
        handleMemberMetadata(metadata);
      }
      if (metadata?.patient_preferences?.readArticles && article) {
        const _readArticle = metadata?.patient_preferences?.readArticles?.find((readArticle) => article.id === readArticle.id);
        setReadArticle(_readArticle);
      }
    })();
  }, [article]);

  React.useEffect(() => {
    if (article) {
      window.addEventListener("scroll", handleScroll);
    }

    /** removeEventListener when the component unmounts */
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [article]);

  const handlePrint = () => {
    trackArticlePrint(article?.name || "");
    window.print();
  };

  const handlePreviousLocation = () => {
    if (readArticle) {
      const documentHeight = document.body.clientHeight - window.innerHeight;
      window.scrollTo({
        left: 0,
        top: documentHeight * readArticle?.progress,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    if (article && auth) {
      debouncedFunction?.cancel?.();
      debouncedFunction = debounce(() => {
        const documentHeight = document.body.clientHeight - window.innerHeight;
        const bottomOfWindowFromTop = window.scrollY || window.pageYOffset;
        const percentScrolled = Math.round((bottomOfWindowFromTop / documentHeight) * 100) / 100;

        /** don't update if we're scrolling up the page */
        if (percentScrolled < lastScroll || percentScrolled === null || isNaN(percentScrolled)) {
          return;
        }

        if (percentScrolled > 0.9) {
          /** update if we're within 10% of the bottom */
          setLastScroll(1);
          updateArticleProgress(article, 1);
        } else if ((percentScrolled - lastScroll) < 0.1) {
          /** don't update if we've moved less than 10% down the page */
        } else {
          setLastScroll(percentScrolled);
          updateArticleProgress(article, percentScrolled);
        }
      }, 3000);
      debouncedFunction();
    }

  };

  const handleBack = () => history.goBack();
  const handleBookmark = async () => {
    if (article) {
      setBookmarked((prevState) => !prevState);
      if (!bookmarked) {
        await patientUpdate({
          patient_details: {
            bookmark_ids: [...auth?.patient_details.bookmark_ids ?? [], `${article.id}`],
          },
        });
      } else {
        await patientUpdate({
          patient_details: {
            bookmark_ids: auth?.patient_details.bookmark_ids.filter((bookmarkId) => bookmarkId !== `${article.id}`),
          },
        });
      }
    }
  };

  const renderContentBlock = (content: StackedContent): JSX.Element | null => {
    switch(content.name) {
      case "Sub-Sections":
        return <SubSections
          key={content.name}
          {...content}
          article={article}
               />;
      case "Recommended articles":
        return <RecommendedArticles
          key={content.name}
          articles={content.recommendedArticles}
               />;
      case "Text Editor":
        return <TextEditor
          key={content.name}
          {...content}
               />;
      case "Image":
        return <ArticleImage
          key={content.name}
          {...content}
               />;
      case "Helpful Resources":
        return <HelpfulResources
          key={content.name}
          {...content}
               />;
      case "Quotes":
        return <Quotes
          key={content.name}
          {...content}
               />;
      case "Actions you can take":
        return <Actions
          key={content.name}
          {...content}
               />;
      case "Video":
        return <VideoBlock
          key={content.name}
          {...content}
               />;
      default:
        return null;
    }
  };

  return (
    <Component
      { ...props as P & Props }
      article={article}
      loading={loading}
      reviewedDate={reviewedDate}
      forDiagnosis={forDiagnosis}
      handlePrint={handlePrint}
      handleBack={handleBack}
      handleBookmark={handleBookmark}
      handlePreviousLocation={handlePreviousLocation}
      renderContentBlock={renderContentBlock}
      bookmarked={bookmarked}
      modal={modal}
      setModal={setModal}
      readArticle={readArticle}
      auth={auth}
    />
  );
};

type Props = {
  name?: string
}
