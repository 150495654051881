import * as React from "react";

import * as Providers from "~/providers";
import * as Hooks from "~/hooks";
import * as Utils from "~/utils";

import ImageUserType5 from "~/../assets/images/user-type/user-type-5.jpg";
import ImageUserType6 from "~/../assets/images/user-type/user-type-6.jpg";
import ImageUserType7 from "~/../assets/images/user-type/user-type-7.jpg";
import ImageUserType10 from "~/../assets/images/user-type/user-type-10.jpg";
import ImageUserType15 from "~/../assets/images/user-type/user-type-15.jpg";

const userTypeImages: {
  [key: string]: string;
} = {
  "5": ImageUserType5,
  "6": ImageUserType6,
  "7": ImageUserType7,
  "10": ImageUserType10,
  "15": ImageUserType15,
};

const userTypeSort: Record<string, number> = {
  "6": 1,
  "15": 2,
  "7": 3,
  "5": 4,
};

export const useUserTypes = (): UserTypeProps => {
  const { getUserTypes } = Hooks.useContentApi();
  const { auth } = React.useContext(Providers.PatientContext);
  const [selectedUserTypes, setSelectedUserTypes] = React.useState<Array<string>>([]);
  const [userTypeData, setUserTypeData] = React.useState<Array<UserTypeItem>>([]);

  React.useEffect(() => {
    (async () => {
      try {
        const _userTypes = await getUserTypes();
        const data = _userTypes
          .filter(({ userTypeId }) => !Utils.HIDDEN_USER_TYPES.includes(Number(userTypeId)))
          .map((item) => ({
            id: item.id,
            label: item.name,
            image: userTypeImages[item.userTypeId] ?? "",
            value: item.userTypeId,
          }))
          .sort((itemA, itemB) => (userTypeSort[itemA.value] ?? Infinity) - (userTypeSort[itemB.value] ?? Infinity));
  
        setUserTypeData(data);
        if (auth) {
          const _selectedUserTypes = data.filter((item) => (auth.patient_details.usertype_ids || []).includes(item.value)).map(({ value }) => value);
          setSelectedUserTypes(_selectedUserTypes);
        }
      } catch (err) {
        console.log({err})
      }
    })();
  }, [auth]);

  const handleUserTypes = (value: string) => {
    setSelectedUserTypes((prev) => prev.indexOf(value) > -1 ? prev.filter((f) => f !== value) : [...prev, value]);
  };

  const resetFromPatientDetails = (): void => {
    setSelectedUserTypes(userTypeData.filter((item) => (auth?.patient_details.usertype_ids || []).includes(item.value)).map(({ value }) => value));
  };

  return {
    userTypeData,
    selectedUserTypes,
    handleUserTypes,
    resetFromPatientDetails,
  };
};

export type UserTypeProps = {
  userTypeData: Array<UserTypeItem>;
  selectedUserTypes: Array<string>;
  handleUserTypes: (value: string) => void;
  resetFromPatientDetails: () => void;
};

export type UserTypeItem = {
  label: string;
  image: string;
  description?: string;
  value: string;
};
