import { useEffect, useState } from "react";

const useGetMetastaticBreastCancerDiagnosisId = () => {
  const [metastaticDiagnosisId, setMetastaticDiagnosisId] = useState(1094);

  useEffect(() => {
    const getMetastaticBreastCancerDiagnosisId = async () => {
      try {
        const res = await fetch(
          `${runtimeconfig.koben_umbraco_domain}/umbraco/api/App/GetMetastaticBreastCancerDiagnosisId`
        );

        const id = await res.text();
        console.log(parseInt(id));
        setMetastaticDiagnosisId(parseInt(id) || 1094);
      } catch (err) {
        console.log(err);
        setMetastaticDiagnosisId(1094);
      }
    };

    getMetastaticBreastCancerDiagnosisId();
  }, []);

  return { metastaticDiagnosisId };
};

export default useGetMetastaticBreastCancerDiagnosisId;
