import tw, { styled } from "twin.macro";

export const Sticky = styled.div(({ stuck }: HeaderProps) => [
  tw`rounded-t-sm sticky z-80 top-0`,
  ...(stuck ? [tw`z-80`] : []),
]);

export const StickyInner = styled.div(({ stuck }: HeaderProps) => [
  tw`bg-pink-light -mx-4 px-4 py-8 md:(-mx-20 px-20)`,
  ...(stuck ? [tw`py-4 mb-og8`] : []),
]);

export const DateLink = styled.button(({ stuck }: HeaderProps) => [
  tw`text-pink underline font-medium outline-none focus:(outline-none) transition-font`,
  ...(stuck ? [tw`text-xs`] : []),
]);

export const CalendarWrapper = styled.div(({ active }: ActiveProps) => [
  tw`bg-white absolute top-full z-10 inset-x-0 p-4 -mx-4 md:(-mx-20)`,
  ...(active ? [] : [tw`hidden`]),
]);

export const FilterAndSearch = styled.div(({ active }: ActiveProps) => [
  tw`bg-white border-t rounded-b-sm border-pink-light p-4 -mx-4 md:(-mx-20 px-20)`,
  ...(active ? [tw``] : [tw`hidden`]),
]);

export const StickyFilterBg = styled.div(({ active }: ActiveProps) => [
  tw`bg-black bg-opacity-80 fixed inset-0 w-full h-full transition duration-500 z-60`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0 pointer-events-none`]),
]);

export const FilterDropdownButton = tw.button`bg-pink-light rounded-sm h-10 w-10 flex items-center justify-center outline-none focus:outline-none`;

type HeaderProps = {
  stuck: boolean
}

type ActiveProps = {
  active: boolean
}
