import * as React from "react";
import * as Sentry from "@sentry/react";
import { usePatientApi } from "~/hooks/usePatientApi";
import { PatientContext } from "~/providers/PatientProvider";
import { validName, validPostcode } from "~/utils/validation";
import { USER_MY_ACCOUNT } from "~/utils/constants";
import { useHistory } from "react-router-dom";
import { useAuthentication } from "~/hooks/useAuthentication";
import { ToastContext } from "~/providers/ToastProvider";

export const withPersonalDetails = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "PersonalDetails", ...props }: Props) => {
  Component.displayName = name;
  useAuthentication();
  const history = useHistory();
  const { patientUpdate } = usePatientApi();

  const { auth } = React.useContext(PatientContext);
  const { handleToast } = React.useContext(ToastContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [firstName, setFirstName] = React.useState<string>(auth?.patient_details?.first_name || "");
  const [lastName, setLastName] = React.useState<string>(auth?.patient_details?.last_name || "");
  const [postcode, setPostcode] = React.useState<string>(auth?.patient_details?.address_postcode || "");
  const [dob, setDOB] = React.useState<Date | undefined>(auth?.patient_details?.dob ? new Date(auth?.patient_details?.dob) : undefined);
  const [firstNameError, setFirstNameError] = React.useState<React.FC | string | null>(null);
  const [lastNameError, setLastNameError] = React.useState<React.FC | string | null>(null);
  const [dateOfBirthError, setDateOfBirthError] = React.useState<React.FC | string | null>(null);
  const [postcodeError, setPostcodeError] = React.useState<React.FC | string | null>(null);
  const [changed, setChanged] = React.useState<boolean>(false);
  const [formValid, setFormValid] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (
      firstName !== auth?.patient_details?.first_name ||
      lastName !== auth?.patient_details?.last_name ||
      postcode !== auth?.patient_details?.address_postcode ||
      dob !== new Date(auth?.patient_details?.dob)
    ) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [firstName, lastName, postcode, dob, changed]);

  React.useEffect(() => {
    if (firstNameError || lastNameError || dateOfBirthError || postcodeError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [firstNameError,lastNameError,dateOfBirthError,postcodeError]);

  const handleFirstName = (value: string) => {
    setFirstName(value);
    if (value === "") {
      setFirstNameError("First name required");
    } else if (!validName(value)) {
      setFirstNameError("Invalid first name");
    } else {
      setFirstNameError(null);
    }
  };

  const handleLastName = (value: string) => {
    setLastName(value);
    if (value === "") {
      setLastNameError("Last name required");
    } else if (!validName(value)) {
      setLastNameError("Invalid last name");
    } else {
      setLastNameError(null);
    }
  };

  const handleDateOfBirth = (date: Date) => {
    setDOB(date);
    if (!date) {
      setDateOfBirthError("Date of birth required");
    } else {
      setDateOfBirthError(null);
    }
  };

  const handlePostcode = (value: string) => {
    setPostcode(value);
    if (value === "" || value.length !== 4) {
      setPostcodeError("Postcode required");
    } else if (!validPostcode(value)) {
      setPostcodeError("Invalid postcode");
    } else {
      setPostcodeError(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await patientUpdate({
        patient_details: {
          first_name: firstName,
          last_name: lastName,
          dob: dob?.toISOString(),
          address_postcode: postcode,
        },
      });
      if (!res) {
        return;
      }
      history.push(USER_MY_ACCOUNT);
      handleToast?.("Profile updated successfully");
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
    setLoading(false);
  };

  return (
    <Component
      { ...props as P & Props }
      handleFirstName={handleFirstName}
      handleLastName={handleLastName}
      handleDateOfBirth={handleDateOfBirth}
      handlePostcode={handlePostcode}
      handleSubmit={handleSubmit}
      firstName={firstName}
      lastName={lastName}
      dob={dob}
      postcode={postcode}
      firstNameError={firstNameError}
      lastNameError={lastNameError}
      dateOfBirthError={dateOfBirthError}
      postcodeError={postcodeError}
      changed={changed}
      loading={loading}
      formValid={formValid}
    />
  );
};
type Props = {
  name?: string
}
