import * as React from "react";
import tw, { styled } from "twin.macro";

import IconFlagAboriginal from "~/../assets/icons/flag-aboriginal.svg";
import IconFlagTorresStraitIslander from "~/../assets/icons/flag-torres-strait-islander.svg";

export const StyledWrapper = styled.div(() => [
  tw`flex space-x-4`,
]);

export const CommunityFlags: React.FunctionComponent = () => (
  <StyledWrapper>
    <IconFlagAboriginal
      width="48px"
    />
    <IconFlagTorresStraitIslander
      width="48px"
    />
  </StyledWrapper>
);
