import tw, { styled } from "twin.macro";
import { Directions, Global, Gutters, Items, Justifys, Selfs, Spacings, Wraps } from "./Global";

const styles = {
  ...Global,
  xs: {
    default: tw`w-full`,
    full: tw`w-full`,
    "1/4": tw`w-1/4`,
    "3/4": tw`w-3/4`,
    "1/3": tw`w-1/3`,
    "1/5": tw`w-1/5`,
    "2/3": tw`w-2/3`,
    "1/2": tw`w-1/2`,
    "auto": tw`w-auto`,
  },
  sm: {
    default: tw``,
    full: tw`sm:w-full`,
    "1/4": tw`sm:w-1/4`,
    "3/4": tw`sm:w-3/4`,
    "1/3": tw`sm:w-1/3`,
    "1/5": tw`sm:w-1/5`,
    "2/3": tw`sm:w-2/3`,
    "1/2": tw`sm:w-1/2`,
    "auto": tw`sm:w-auto`,
  },
  md: {
    default: tw``,
    full: tw`md:w-full`,
    "1/4": tw`md:w-1/4`,
    "3/4": tw`md:w-3/4`,
    "1/3": tw`md:w-1/3`,
    "1/5": tw`md:w-1/5`,
    "2/3": tw`md:w-2/3`,
    "1/2": tw`md:w-1/2`,
    "auto": tw`md:w-auto`,
  },
  lg: {
    default: tw``,
    full: tw`lg:w-full`,
    "1/4": tw`lg:w-1/4`,
    "3/4": tw`lg:w-3/4`,
    "1/3": tw`lg:w-1/3`,
    "1/5": tw`lg:w-1/5`,
    "2/3": tw`lg:w-2/3`,
    "1/2": tw`lg:w-1/2`,
    "auto": tw`lg:w-auto`,
  },
  xl: {
    default: tw``,
    full: tw`xl:w-full`,
    "1/4": tw`xl:w-1/4`,
    "3/4": tw`xl:w-3/4`,
    "1/3": tw`xl:w-1/3`,
    "1/5": tw`xl:w-1/5`,
    "2/3": tw`xl:w-2/3`,
    "1/2": tw`xl:w-1/2`,
    "auto": tw`xl:w-auto`,
  },
};

export const Column = styled.div(({
  direction = "none",
  gutter = "none",
  items = "none",
  self = "none",
  justify = "none",
  xs = "default",
  sm = "default",
  md = "default",
  lg = "default",
  xl = "default",
  wrap = "none",
  spacing = "none",
  grow,
  shrink,
  relative,
}: Props) => [
  styles.direction[direction],
  styles.gutterPadding[gutter],
  styles.items[items],
  styles.self[self],
  styles.justify[justify],
  ...(grow || shrink ? [tw``] : [styles.xs[xs]]),
  styles.sm[sm],
  styles.md[md],
  styles.lg[lg],
  styles.xl[xl],
  styles.wrap[wrap],
  styles.spacing[spacing],
  ...(relative ? [tw`relative`] : []),
  ...(grow ? [tw`flex-grow`] : []),
  ...(shrink ? [tw`flex-shrink`] : []),
  ...([items, justify, wrap, direction].find((item) => item !== "none") ? [tw`flex`] : [tw``]),
]);

type Props = {
  direction?: Directions
  gutter?: Gutters
  items?: Items
  self?: Selfs
  justify?: Justifys
  xs?: Widths
  sm?: Widths
  md?: Widths
  lg?: Widths
  xl?: Widths
  wrap?: Wraps
  spacing?: Spacings
  grow?: boolean
  shrink?: boolean
  relative?: boolean
}

type Widths = "default" | "1/4" | "1/5" | "3/4" | "1/3" | "2/3" | "1/2" | "auto" | "full"
