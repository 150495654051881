import * as React from "react";
import { Checkbox } from "~/components/Checkbox/Checkbox";
import { Column, Form, StyledHyperlink } from "~/components/Styled";
import { ContentWrapper } from "../styledHp";
import { withHpStep2 } from "./withHpStep2";

import * as Components from "~/components";

export const HpStep2 = withHpStep2(({ handleAccept, handleSubmit, handleBack, accept }: Props) => (
  <ContentWrapper>
    <Form
      onSubmit={handleSubmit}
    >
      <Column
        spacing="md"
      >
        <Components.TextVariation
          variation="heading1"
          spacing="og1"
        >
          {"Privacy is important to us"}
        </Components.TextVariation>
        <Components.TextContainer
          spacing="ogxxs"
        >
          <Components.TextVariation
            variation="paragraph1"
            color="greyDark"
          >
            {"We are committed to protecting the privacy and confidentiality of the personal information that we hold. Breast cancer is a very personal experience and we do not want anyone to feel uncomfortable about the information we store on our secure database. Details about our privacy policy can be found "}
            <StyledHyperlink
              href="https://www.bcna.org.au/legal-information/privacy-policy/"
              target="_blank"
              rel="noopener"
            >
              {"on our website"}
            </StyledHyperlink>
            {"."}
            <br />
            <br />
            {"People who sign up to My Journey will automatically become BCNA members, at no cost. If they provide their email address we will send them our online magazine, The Beacon as well as information about other opportunities, including ongoing programs and support."}
            <br />
            <br />
            {"You must have your patient's consent to pass on their details before you can start the sign up process on their behalf."}
          </Components.TextVariation>
        </Components.TextContainer>
      </Column>
      <Column
        spacing="xs"
      >
        <Checkbox
          label="I have read the above privacy statement and have my patients' consent"
          checked={accept}
          onChange={handleAccept}
        />
      </Column>
      <Column
        spacing="xs"
      >
        <Components.ButtonVariation
          variation="primary"
          type="submit"
          disabled={!accept}
          fullWidth
        >
          {"Continue"}
        </Components.ButtonVariation>
      </Column>
      <Column
        spacing="xs"
      >
        <Components.ButtonVariation
          variation="secondary"
          onClick={handleBack}
          fullWidth
        >
          {"Back"}
        </Components.ButtonVariation>
      </Column>
    </Form>
  </ContentWrapper>
));

type Props = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  handleAccept: () => void
  handleBack: () => void
  accept: boolean
}
