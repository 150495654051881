import * as React from "react";
import * as DateFns from "date-fns";
import tw, { styled } from "twin.macro";

const StyledEntryDay = tw.span`block text-pink text-center text-xxs font-medium leading-5 mb-1`;
const StyledEntryDate = tw.span`block text-pink text-center text-lg font-medium leading-3 mb-1`;
const StyledDateWrapper = tw.div`bg-pink-medium px-4 pb-3 pt-2 rounded-sm`;
const StyledEntryContainer = tw.div`border-r border-grey-light inline-block pr-4`;
const StyledDetailWrapper = tw.button`flex items-start outline-none focus:outline-none w-full`;
const StyledEntryNumber = tw.span`text-pink text-xl block text-center font-semibold`;
const StyledEntryText = tw.span`text-blue-navy tracking-widest uppercase text-xxxs block w-full text-center font-semibold`;
const StyledTitleWrapper = tw.div`pl-4 flex-grow self-start text-left`;
const StyledViewMoreContainer = tw.div`flex-shrink-0 w-12`;
const StyledViewMore = tw.span`text-pink underline text-xs font-semibold block w-full flex justify-end`;
const StyledViewMoreIcon = tw.span`text-pink underline text-xs font-semibold block w-full flex justify-end mt-2`;

const StyledContentWrapper = styled.div(({ open }: { open: boolean }) => [
  tw`border-t border-grey-light mt-4 -mx-4 px-4 py-6`,
  ...(open ? [tw`block`] : [tw`hidden`]),
]);

const StyledIconWrapper = styled.span(({ active }: { active: boolean }) => [
  tw`inline-block transform transition`,
  ...(active ? [tw`rotate-180`] : [tw``]),
]);

import * as Components from "~/components";

import IconArrowDown from "~/../assets/icons/arrow-down.svg";

export const EntryCard: React.FunctionComponent<EntryCardProps> = (props) => {
  return (
    <Components.Card
      color="white"
      shadow="default"
      padding="og4"
    >
      <StyledDetailWrapper
        onClick={() => props.onOpenOrClose()}
      >
        {props.iconValue instanceof Date ? (
          <StyledEntryContainer>
            <StyledDateWrapper>
              <StyledEntryDay>
                {DateFns.format(props.iconValue, "MMM")}
              </StyledEntryDay>
              <StyledEntryDate>
                {DateFns.format(props.iconValue, "dd")}
              </StyledEntryDate>
            </StyledDateWrapper>
          </StyledEntryContainer>
        ) : (
          <StyledEntryContainer>
            <StyledEntryNumber>
              {props.iconValue}
            </StyledEntryNumber>
            <StyledEntryText>
              {props.iconLabel}
            </StyledEntryText>
          </StyledEntryContainer>
        )}
        <StyledTitleWrapper>
          <Components.TextVariation
            variation="heading4"
          >
            {props.title}
          </Components.TextVariation>
        </StyledTitleWrapper>
        <StyledViewMoreContainer>
          <StyledViewMore>
            {props.open ? "Close" : "View"}
          </StyledViewMore>
          {props.open ? null : (
            <StyledViewMore>
              {"or edit"}
            </StyledViewMore>
          )}
          <StyledViewMoreIcon>
            <StyledIconWrapper
              active={props.open}
            >
              <IconArrowDown
                width="14"
              />
            </StyledIconWrapper>
          </StyledViewMoreIcon>
        </StyledViewMoreContainer>
      </StyledDetailWrapper>
      <StyledContentWrapper
        open={props.open}
      >
        {props.children}
      </StyledContentWrapper>
    </Components.Card>
  );
};

export type EntryCardProps = {
  open: boolean;
  onOpenOrClose: () => void;
  title: string;
  iconValue: Date | number;
  iconLabel?: string;
}
