import * as React from "react";
import { CheckboxLabel, CheckboxSpan, HiddenCheckbox, VisibleCheckbox, CheckboxTick } from "../Styled";
import * as Components from "~/components";

export const Checkbox: React.FunctionComponent<CheckboxProps> = (props: CheckboxProps) => (
  <CheckboxLabel>
    <HiddenCheckbox
      type="checkbox"
      onChange={props.onChange}
      onBlur={props.onBlur}
      aria-label={props.label}
    />
    <VisibleCheckbox>
      <CheckboxTick
        checked={props.checked}
      />
    </VisibleCheckbox>
    <CheckboxSpan>
      <Components.TextVariation
        variation="label2"
      >
        {props.label}
      </Components.TextVariation>
    </CheckboxSpan>
  </CheckboxLabel>
);

export type CheckboxProps = {
  label: string
  checked: boolean
  onChange: () => void
  onBlur?: () => void
}
