import tw, { styled } from "twin.macro";
import { BackButton } from "~/components/AppHeader/styledAppHeader";

export const IconWrapper = tw.span`flex items-center print:(hidden)`;
export const LabelWrapper = tw.span`inline-block ml-2`;
export const VideoWrapper = tw.div`my-8`;
export const VideoBlockWrapper = tw.div`my-16`;
export const ArticleButton = styled(BackButton)(({ active }: { active?: boolean }) => [
  tw`ml-4 transition h-11`,
  ...(active ? [tw`bg-pink text-white hover:bg-pink`] : []),
]);

export const PrintButton = styled(ArticleButton)(() => [
  tw`hidden md:(inline-flex)`,
]);

export const ArticleSticky = styled.div(() => [
  tw`sticky top-0 -mt-9 py-9 z-20 print:(hidden)`,
]);

export const ButtonSpan = styled.span(() => [
  tw`hidden md:(inline)`,
]);
