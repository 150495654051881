import * as React from "react";
import { SymptomLogEntry, SymptomSummary } from "myjourney-frontend/src/vendor/umbraco";

import * as Utils from "../utils";
import * as Hooks from "../hooks";

const colours = [Utils.BLUE_COLOR, Utils.GREEN_COLOR, Utils.PALE_BLUE_COLOR, Utils.PALE_PINK_COLOR, Utils.PINK_COLOR];
export const useSymptomData = (dateRange?: { start: Date; end: Date; }): SymptomDataProps => {
  const hookDateRange = Hooks.useDateRange();

  const { getSymptomsByDateRange } = Hooks.useSymptomTrackerApi();
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [symptomSummary, setSymptomSummary] = React.useState<SymptomSummary>();
  const [graphData, setGraphData] = React.useState<GraphData>([]);
  const [symptomDetails, setSymptomDetails] = React.useState<SymptomDetailsData>([]);
  const [activeDates, setActiveDates] = React.useState<Array<Date>>([]);

  React.useEffect(() => {
    setLoading(true);
    getSymptoms();
  }, [dateRange?.start.toDateString(), dateRange?.end.toDateString()]);

  /**
   * Get symptom data and parse for graphs and lists
   */
  const getSymptoms = async () => {
    try {
      const selectedDateRange = dateRange ?? hookDateRange.default;
      const res = await getSymptomsByDateRange({
        FromDay: selectedDateRange.start.getDate(),
        FromMonth: selectedDateRange.start.getMonth() + 1, // Dates are not 0 indexed on the back end
        FromYear: selectedDateRange.start.getFullYear(),
        ToDay: selectedDateRange.end.getDate(),
        ToMonth: selectedDateRange.end.getMonth() + 1,
        ToYear: selectedDateRange.end.getFullYear(),
      });
      const _symptoms = await res.json() as SymptomSummary;
      const graphDataMap: Record<string, {
        name: string,
        severities: Array<number>
        dates: Array<Date>
        symptoms: Array<SymptomLogEntry>
        severityType: string
      }> = {};

      for (const symptom of _symptoms.symptoms) {
        graphDataMap[symptom.symptomType] = graphDataMap[symptom.symptomType] || {
          name: symptom.symptomType,
          severities: [],
          dates: [],
          symptoms: [],
        };
        graphDataMap[symptom.symptomType] = {
          name: symptom.symptomType,
          severities: [...graphDataMap[symptom.symptomType].severities, symptom.severity],
          dates: [...graphDataMap[symptom.symptomType].dates, new Date(symptom.year, symptom.month - 1, symptom.day)],
          symptoms: [...graphDataMap[symptom.symptomType].symptoms, symptom],
          severityType: symptom.severityType,
        };
      }

      const graphDataArr = Object.keys(graphDataMap).map((key) => ({
        severityType: graphDataMap[key].severityType,
        name: graphDataMap[key].name,
        points: graphDataMap[key].severities.map((s, i) => ({ y: s, x: graphDataMap[key].dates[i] })).sort((a, b) => a.x.getTime() - b.x.getTime()),
        symptoms: graphDataMap[key].symptoms,
      }));

      const graphDataArrAll = Object.keys(graphDataMap).map((key, index) => ({
        severityType: graphDataMap[key].severityType,
        name: graphDataMap[key].name,
        stroke: colours[index],
        points: graphDataMap[key].severities.map((s, i) => ({ y: s, x: graphDataMap[key].dates[i] })).sort((a, b) => a.x.getTime() - b.x.getTime()),
        symptoms: graphDataMap[key].symptoms,
      }));

      const _symptomDetails = graphDataArrAll.map((d) => ({
        name: d.name,
        count: d.points.length,
        symptoms: d.symptoms,
        data: d.symptoms.map((s) => ({
          x: new Date(s.year, s.month - 1, s.day),
          y: s.severity,
        })),
      }));

      setActiveDates(_symptoms.symptoms.map(({ year, month, day }) => new Date(year, month -1, day)));
      setSymptomDetails(_symptomDetails);
      setGraphData(
        graphDataArr
        .filter((f) => f.points.length > 1)
        .filter(({ severityType }) => severityType === "range")
        .slice(0, 5)
        .map((prev, index) => ({
          ...prev,
          stroke: colours[index],
        }))
      );
      setSymptomSummary(_symptoms);
    } catch (e) {
      setError("Unable to load your symptoms, please try again later");
    }
    setLoading(false);
  };

  return {
    loading,
    symptomSummary,
    graphData,
    symptomDetails,
    activeDates,
    error,
    getSymptoms,
  };
};

export type SymptomDataProps = {
  loading: boolean
  symptomSummary?: SymptomSummary
  graphData: GraphData
  symptomDetails: SymptomDetailsData
  activeDates: Array<Date>
  error: string | undefined
  getSymptoms: () => Promise<void>
}

export type GraphData = Array<{
  stroke: string
  name: string
  points: Array<{
    x: Date
    y: number
  }>
}>

export type SymptomDetailsData = Array<{
  name: string
  count: number
  symptoms: Array<SymptomLogEntry>
}>
