import * as React from "react";
import ErrorIcon from "~/../assets/icons/error.svg";
import { IconWrapper, StyledCard } from "./styledErrorContainer";

import * as Components from "~/components";

export const ErrorContainer: React.FunctionComponent<ErrorContainerProps> = (props) => (
  <StyledCard
    color="greyLightest"
    padding="lg"
    position="relative"
  >
    <IconWrapper>
      <ErrorIcon />
    </IconWrapper>
    <Components.TextContainer
      align="center"
      spacing="ogxxs"
    >
      <Components.TextVariation
        variation="paragraph2"
        color="greyDark"
      >
        {props.message}
      </Components.TextVariation>
    </Components.TextContainer>
    {props.action && props.actionText ? (
      <Components.Row
        spacing="ogxxs"
      >
        <Components.ButtonVariation
          variation="secondary"
          onClick={props.action}
          fullWidth
        >
          {props.actionText}
        </Components.ButtonVariation>
      </Components.Row>
    ) : null}
    {props.children}
  </StyledCard>
);

export type ErrorContainerProps = {
  message: string | JSX.Element;
  action?: () => void;
  actionText?: string | JSX.Element;
};
