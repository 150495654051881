import * as React from "react";
import tw, { styled } from "twin.macro";

import * as Hooks from "~/hooks";
import * as Utils from "~/utils";
import * as Components from "~/components";

import RemoveIcon from "~/../assets/icons/remove.svg";

export const StyledThumbWrapper = styled.div((props: { status: "uploading" | "uploaded" | "default" | "error" }) => [
  tw`relative mt-4 mr-4 border-2 rounded-sm`,
  ...props.status === "default" ? [tw`border-grey-medium`] : [],
  ...props.status === "uploading" ? [tw`opacity-50`] : [],
  ...props.status === "uploaded" ? [tw`border-grey-light`] : [],
  ...props.status === "error" ? [tw`border-red-validation`] : [],
]);

const StyledThumbObject = styled.div(() => [
  tw`rounded-sm w-20 h-20 bg-grey-light shadow-inner overflow-hidden`,
]);

const StyledThumb = styled.img(() => [
  tw`object-cover object-center h-20 w-full`,
]);

const StyledThumbContainer = styled.div(() => [
  tw`flex flex-wrap mt-2`,
]);

const StyledRemoveButton = styled.button(() => [
  tw`h-5 w-5 bg-grey-light rounded-full top-0 right-0 -mt-2.5 -mr-2.5 absolute z-10 shadow outline-none flex items-center justify-center focus:(outline-none)`,
]);

const HiddenFileInput = styled.input(() => [
  tw`hidden`,
]);

export const FileUpload: React.FunctionComponent<FileUploadProps> = (props) => {
  const { uploadImage } = Hooks.useSymptomTrackerApi();
  const [dataUrls, setDataUrls] = React.useState<Array<string>>(props.dataUrls ? props.dataUrls : []);
  const [status, setStatus] = React.useState<Record<string, "uploading" | "uploaded" | "error">>({});
  const [files, setFiles] = React.useState<Array<File>>([]);

  React.useEffect(() => {
    const getDataUrls = async (): Promise<void> => {
      if (files.length) {
        const data = await Promise.all(files.map(Utils.getBase64));
        /** Merge the existing selection with the new selection and filter out any images that have been selected twice */
        setDataUrls((prev) => [...prev, ...data].filter((value, index, self) => self.indexOf(value) === index));
        files.forEach(async (file, i) => {
          setStatus((prev) => ({
            ...prev,
            [data[i]]: "uploading",
          }));
          try {
            const res = await uploadImage(file);
            const json = await res.json();
            props.setFileId(json.uploadFile.id);
            if (res.status === 200) {
              setStatus((prev) => ({
                ...prev,
                [data[i]]: "uploaded",
              }));
            } else {
              setStatus((prev) => ({
                ...prev,
                [data[i]]: "error",
              }));
            }
          } catch {
            setStatus((prev) => ({
              ...prev,
              [data[i]]: "error",
            }));
          }

        });
      }
    };

    getDataUrls();
  }, [files]);

  React.useEffect(() => {
    setDataUrls([]);
    if (props?.files?.length) {
      props?.files?.forEach((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64 = reader.result as string;
          setDataUrls((prev) => [...prev, base64]);
        };

      });
    }
  }, [props.files]);

  const handleRemoveImage = (index: number) => {
    setDataUrls((prev) => prev.filter((_item, i) => i !== index));
  };

  const handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      setFiles(Array.from(event.target.files));
    }
  };

  const fileRef = React.useRef<HTMLInputElement>(null);

  return (
    <Components.InputWrapper>
      <HiddenFileInput
        ref={fileRef}
        type="file"
        accept="image/*"
        multiple={props.allowMultiple}
        onChange={handleImage}
      />
      <Components.ButtonVariation
        variation="secondary"
        type="button"
        onClick={() => fileRef?.current?.click()}
        fullWidth
      >
        {props.children}
      </Components.ButtonVariation>
      {props.showThumbnail && (
        <StyledThumbContainer>
          {dataUrls.map((url, i) => (
            <StyledThumbWrapper
              key={url}
              status={status[url]}
            >
              <StyledRemoveButton
                type="button"
                onClick={() => handleRemoveImage(i)}
              >
                <RemoveIcon />
              </StyledRemoveButton>
              <StyledThumbObject>
                <StyledThumb
                  src={url}
                />
              </StyledThumbObject>
            </StyledThumbWrapper>
          ))}
        </StyledThumbContainer>
      )}
    </Components.InputWrapper>
  );
};

type FileUploadProps = {
  setFileId: (value: string) => void;
  dataUrls?: Array<string>
  files?: Array<Blob>
  allowMultiple?: boolean
  showThumbnail?: boolean
}
