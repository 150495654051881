import { MergedArticle, ReadArticle, StackedContent } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { ButtonLink, Card, Column, H4, P, RichText, Row, Small } from "~/components/Styled";
import { withArticle } from "./withArticle";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";

import * as Providers from "~/providers";
import * as Components from "~/components";

import { addGlossary, getMediaLinks } from "~/utils/functions";

import { QuestionBlocks } from "./QuestionBlocks";
import { Video } from "./Video";

import { ArticleButton, ArticleSticky, ButtonSpan, PrintButton } from "./styledArticle";
import Share from "~/../assets/icons/share.svg";
import Print from "~/../assets/icons/print.svg";
import Bookmark from "~/../assets/icons/bookmark.svg";

import { Meta } from "~/components/Meta/Meta";
import { ArticleAlert } from "~/components/ArticleAlert/ArticleAlert";
import { UserAuth } from "~/providers";
import { USER_LOGIN } from "../../utils/constants";

export const Article = withArticle(({ 
  article, loading, reviewedDate, forDiagnosis, bookmarked, readArticle,
  handlePrint, renderContentBlock, handleBookmark, handlePreviousLocation, auth,
}: Props) => {
  const history = useHistory();
  const modalContext = React.useContext(Providers.ModalContext);
  return (
    <Components.AppLayout
      headerVariation="navigationBack"
    >
      <ArticleSticky>
        <Row
          spacing="none"
        >
          <Column
            spacing="none"
            justify="end"
          >
            <PrintButton
              onClick={() => handlePrint()}
            >
              <Print /><ButtonSpan>{"Print"}</ButtonSpan>
            </PrintButton>
            <ArticleButton
              // onClick={() => modalContext.toggleModal("shareArticle")}
              onClick={() => !auth ? history.push(USER_LOGIN) : modalContext.toggleModal("shareArticle")}
            >
              <Share /><ButtonSpan>{"Share"}</ButtonSpan>
            </ArticleButton>
            <ArticleButton
              active={bookmarked}
              // onClick={() => handleBookmark()}
              onClick={() => !auth ? history.push(USER_LOGIN) : handleBookmark()}
            >
              <Bookmark
                width="11"
                height="11"
              />
              <ButtonSpan>{bookmarked ? "Bookmarked" : "Bookmark"}</ButtonSpan>
            </ArticleButton>
          </Column>
        </Row>
      </ArticleSticky>
      {article?.alert ? (
        <ArticleAlert
          title={article.alert.title}
          body={article.alert.body}
        />
      ) : null}
      <Components.TextVariation
        variation="heading1"
        loading={loading}
      >
        {article?.name}
      </Components.TextVariation>
      <P
        loading={loading}
      >
        <time
          dateTime={article?.dateReviewed}
        >
          {reviewedDate ? `Reviewed by BCNA on ${reviewedDate}` : ""}
        </time>
      </P>

      {article?.documentTypeAlias === "video" ? (
        <Video
          videoUrl={article.videoUrl || ""}
        />
      ) : readArticle ? (
        <Card
          color="blueDusty"
          padding="sm"
          spacing="md"
        >
          <H4
            spacing="xxs"
          >Continue reading</H4>
          <Small
            spacing="xxs"
          >
            {"It looks like you didn't finish reading this article last time. Would you like to go back to where you left off?"}
          </Small>
          <ButtonLink
            onClick={() => handlePreviousLocation()}
          >
            {"Yes"}
          </ButtonLink>
        </Card>
      ) : null}

      <RichText
        weight="medium"
      >
        {article ? parse(getMediaLinks(addGlossary(article.intro))) : ""}
      </RichText>

      <H4
        spacing="xxs"
      >
        Relevant if diagnosed with:
      </H4>

      <Row
        spacing="md"
      >
        <Column>
          {forDiagnosis?.map((d) => (
            <Small
              spacing="xxs"
              key={d}
            >
              {d}
            </Small>
          ))}
        </Column>
      </Row>

      {article?.questionBlocks ? (
        <QuestionBlocks
          article={article}
        />
      ) : null}
      {article?.stackedContent?.map((content) => renderContentBlock(content))}
      {/*<ShareModal
        open={modal}
        setOpen={setModal}
        article={article}
        forDiagnosis={forDiagnosis}
      />*/}
      <Components.SwipeModalShareArticle
        active={modalContext.activeModal === "shareArticle"}
        onClose={() => modalContext.toggleModal("shareArticle")}
        article={article}
        forDiagnosis={forDiagnosis}
      />
      {article ? (
        <Meta
          title={article.name}
          metaDescription={article.metaDescription}
        >
          <meta
            property="og:title"
            content={article.name}
          />
          <meta
            property="og:description"
            content={article.metaDescription}
          />
          <meta
            property="og:url"
            content={window.location.href}
          />
          <meta
            property="og:image"
            content="https://ahrefs.com/blog/wp-content/uploads/2019/12/fb-how-to-become-an-seo-expert.png"
          />
          <meta
            property="og:type"
            content="article"
          />
          <meta
            property="og:locale"
            content="en_AU"
          />
        </Meta>
      ) : null}
    </Components.AppLayout>
  );
});

type Props = {
  article: MergedArticle
  loading: boolean
  reviewedDate: undefined | string
  forDiagnosis: Array<string>
  handlePrint: () => void
  handleBack: () => void
  handleBookmark: () => void
  handlePreviousLocation: () => void
  renderContentBlock: (name: StackedContent) => React.FC
  bookmarked: boolean
  modal: boolean
  setModal: React.Dispatch<React.SetStateAction<boolean>>
  readArticle: ReadArticle
  auth: UserAuth
}
