import * as React from "react";
import { InputError, InputWrapper, TextAreaLabel, FieldWrapper, IconWrapper, InputValidation, StyledTextArea } from "../Styled";

import TickIcon from "~/../assets/icons/tick.svg";
import { TextAreaOverlay } from "./TextAreaOverlay";
import { withTextArea } from "./withTextArea";

import * as Components from "~/components";

export const TextArea = withTextArea(({
  label, error, required, placeholder, value, readonly, disabled, hideLabel, name, valid, validating, hideRequired, icon, overlayLabel, overlayActive, overlayButton, handleOverlay, onChange,
}: Props) => (
  <InputWrapper>
    {label ?
      typeof label === "string" ? (
        <TextAreaLabel
          hidden={hideLabel}
        >
          <Components.TextVariation
            variation="label1"
          >
            {`${label}${hideRequired ? "" : required ? " *" : " (optional)"}`}
          </Components.TextVariation>
        </TextAreaLabel>
      ) : label()
    : null}
    <FieldWrapper>
      <StyledTextArea
        error={error}
        value={value}
        placeholder={placeholder}
        readOnly={readonly}
        disabled={disabled}
        name={name}
        valid={valid}
        rows={4}
        onChange={onChange}
        onFocus={() => handleOverlay(true)}
        aria-label={typeof label === "string" ? label : undefined}
      />
      {valid ? (
        <IconWrapper>
          <TickIcon />
        </IconWrapper>
      ) : null}
      {icon && (
        <IconWrapper
          colour="pink"
        >
          {icon}
        </IconWrapper>
      )}
    </FieldWrapper>
    {error && !validating ? (
      <InputError>
        {error}
      </InputError>
    ) : null}
    {validating ? (
      <InputValidation>
        Validating...
      </InputValidation>
    ) : null}
    {overlayLabel ? (
      <TextAreaOverlay
        active={overlayActive}
        overlayLabel={overlayLabel as string}
        overlayButton={overlayButton as string}
        handleOverlay={handleOverlay}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    ) : null}
  </InputWrapper>
));

type Props = {
  name?: string;
  label?: string | (() => React.ReactNode);
  error?: JSX.Element | string | undefined;
  hideRequired?: boolean;
  valid?: boolean;
  validating?: boolean;
  required?: boolean;
  value?: string;
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  icon?: React.ReactNode;
  overlayLabel?: string;
  overlayActive: boolean;
  overlayButton: string;
  handleOverlay(open: boolean): void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
