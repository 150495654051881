import { Thumb, ThumbContainer, ThumbObject } from "./styledUserImages";
import { withUserImages } from "./withUserImages";

export const UserImages = withUserImages(({ blobs }: Props) => (
  <ThumbContainer>
    {blobs.map((blob, i) => (
      <ThumbObject
        key={i}
      >
        <Thumb
          src={URL.createObjectURL(blob)}
        /> 
      </ThumbObject>
    ))}
  </ThumbContainer>
));

type Props = {
  blobs: Array<Blob>
}
