import * as React from "react";
import { P, Row } from "../Styled";
import { Dot, DotContainer, QuoteWrapper, SlideInner, Slider, SliderSlides, StyledCard } from "./styledArticleQuotes";
import { useArticleQuotes } from "./useArticleQuotes";
import UserIcon from "~/../assets/icons/icon-user.svg";

import * as Components from "~/components";

export const ArticleQuotes: React.FC<Props> = ({ title, data }) => {
  const { handleSlider, active } = useArticleQuotes();
  return (
    <QuoteWrapper>
      <Components.TextVariation
        variation="heading2"
        align="center"
      >
        {title}
      </Components.TextVariation>
      <Slider>
        <div>
          <SliderSlides
            slides={data.length}
            active={active}
          >
            {data.map(({ author, quote }) => (
              <SlideInner
                key={quote}
              >
                <StyledCard
                  color="white"
                  shadow="default"
                >
                  <div>
                    <Row
                      justify="center"
                      spacing="md"
                    >
                      <UserIcon />
                    </Row>
                    <blockquote>
                      <P
                        colour="greyDark"
                        spacing="none"
                        align="center"
                      >
                        {quote}
                      </P>
                      <P
                        spacing="none"
                        align="center"
                      >
                        {`— ${author}`}
                      </P>
                    </blockquote>
                  </div>
                </StyledCard>
              </SlideInner>
            ))}
          </SliderSlides>
        </div>
      </Slider>
      {data.length > 1 ? (
        <DotContainer>
          {data.map(({ quote }, i) => (
            <Dot
              key={quote}
              onClick={() => handleSlider(i)}
              active={i === active}
            />
          ))}
        </DotContainer>
      ) : null}

    </QuoteWrapper>
  );
};

type Props = {
  title: string
  data: Array<QuoteData>
}

export type QuoteData = {
  author: string
  quote: string
}
