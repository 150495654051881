import * as React from "react";
import tw, { css, styled } from "twin.macro";

import * as Providers from "~/providers";
import * as Components from "~/components";

import IconStar from "~/../assets/icons/icon-star.svg";

const StyledUserFooterWrapper = styled.div(() => [tw`-mx-4 overflow-hidden`]);

const StyledUserFooterImage = styled.div(
  (props: { backgroundImage?: string }) => [
    tw`bg-pink-medium bg-center bg-cover`,
    css`
      width: calc(100% + 48px);
      height: 120px;
      margin: 0px -24px;
      border-top-left-radius: 60%;
      border-top-right-radius: 60%;
      background-image: url(${props.backgroundImage});
    `,
  ]
);

const FooterVariations = {
  standard: () => (
    <Components.Row spacing="ogsm">
      <Components.BuildVersion />
    </Components.Row>
  ),
  dashboard: (props: FooterVariationPropsNested) => (
    <React.Fragment>
      <Components.Row>
        <Components.Column>
          <Components.Row spacing="ogsm">
            <Components.ButtonVariation
              variation="square"
              iconLeft={<IconStar />}
              onClick={() => props.modalContext.toggleModal("accountCustomise")}
            >
              {"Customise my content"}
            </Components.ButtonVariation>
          </Components.Row>
          <Components.Row spacing="ogxxs" justify="center">
            <Components.TextVariation
              variation="heading4"
              color="blueNavy"
              align="center"
              css={css`
                width: 75%;
              `}
            >
              {"BCNA is committed to providing support for all our communities"}
            </Components.TextVariation>
          </Components.Row>
          <Components.Row justify="center" spacing="ogsm">
            <Components.CommunityFlags />
          </Components.Row>
          <Components.Row justify="center" spacing="ogsm">
            <Components.ButtonVariation
              variation="secondary"
              onClick={() => props.patientContext.handleLogout?.()}
              aria-label="Log out"
            >
              Log out
            </Components.ButtonVariation>
          </Components.Row>
        </Components.Column>
      </Components.Row>
      <StyledUserFooterWrapper>
        <StyledUserFooterImage
          backgroundImage={
            props.patientContext.profileImageBackgroundContent ?? ""
          }
        />
      </StyledUserFooterWrapper>
    </React.Fragment>
  ),
};

export const FooterVariation: React.FunctionComponent<FooterVariationProps> = (
  props
) => {
  const patientContext = React.useContext(Providers.PatientContext);
  const modalContext = React.useContext(Providers.ModalContext);

  return FooterVariations[props.variation]({
    ...props,
    patientContext,
    modalContext,
  });
};

export type FooterVariationProps = {
  variation: keyof typeof FooterVariations;
};

export type FooterVariationPropsNested = FooterVariationProps & {
  patientContext: Providers.PatientContextState;
  modalContext: Providers.ModalContextState;
};
