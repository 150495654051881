import * as React from "react";

import * as Components from "~/components";

import { SignUpStepProps } from "../SignUp";

export const StepAccount: React.FunctionComponent<SignUpStepProps> = ({
  handleEmail, handlePhone, handlePassword, handleConfirmPassword, passwordRules, emailValid, emailValidating, emailError, phoneValid, phoneValidating, phoneError, passwordError, confirmPasswordError, email, phone, password, confirmPassword, referralId, referralLoading, referralPatientDetails,
}) => (
  <React.Fragment>
    <Components.TextVariation
      variation="headingdisplay"
      spacing="og6"
      fullWidth
    >
      {"Create my account"}
    </Components.TextVariation>
    <Components.TextContainer
      spacing="og4"
    >
      <Components.TextVariation
        variation="heading3"
        color="greyDark"
      >
        {"Please provide your email address and a phone number to create your account"}
      </Components.TextVariation>
    </Components.TextContainer>
    <Components.Grid
      cols="1"
      colsMd="2"
      gapX="4"
      gapY="6"
      spacing="og6"
    >
      <Components.Input
        label="Email"
        type="email"
        name="email"
        placeholder="My email"
        required
        onChange={(e) => handleEmail(e.target.value)}
        value={email}
        error={emailError}
        valid={emailValid}
        validating={emailValidating}
        disabled={Boolean(referralId && referralPatientDetails.email) || referralLoading}
      />
      <Components.Input
        label="Phone number"
        name="phone"
        placeholder="My mobile number"
        required
        onChange={(e) => handlePhone(e.target.value)}
        value={phone}
        error={phoneError}
        valid={phoneValid}
        validating={phoneValidating}
        disabled={Boolean(referralId && referralPatientDetails.phone) || referralLoading}
      />
    </Components.Grid>
    <Components.InfoDropDown
      label="Learn more about why we request your phone number"
    >
      <Components.TextContainer>
        {"Providing a phone number enables us to contact you easily via SMS to provide updates on information relevant to your circumstances. At any time you can have the phone number removed or updated."}
        <br />
        {"Find details of our Privacy Policy "}
        <Components.TextLink
          textVariation="textlink2"
          target="_blank"
          rel="noopener"
          href="https://www.bcna.org.au/legal-information/privacy-policy"
        >
          {"here"}
        </Components.TextLink>
      </Components.TextContainer>
    </Components.InfoDropDown>
    <Components.HorizontalRule />
    <Components.TextContainer
      spacing="og4"
    >
      <Components.TextVariation
        variation="heading3"
        color="greyDark"
      >
        {"Create a secure password"}
      </Components.TextVariation>
    </Components.TextContainer>
    <Components.Grid
      cols="1"
      colsMd="2"
      gapX="4"
      gapY="6"
      spacing="og6"
    >
      <Components.Input
        label="Password"
        type="password"
        name="password"
        placeholder="My password"
        value={password}
        required
        onChange={(e) => handlePassword(e.target.value)}
        error={passwordError}
        disabled={referralLoading}
      />
      <Components.Input
        label="Confirm Password"
        type="password"
        name="confirmPassword"
        placeholder="Re-enter password"
        required
        value={confirmPassword}
        onChange={(e) => handleConfirmPassword(e.target.value)}
        error={confirmPasswordError}
        disabled={referralLoading}
      />
    </Components.Grid>
    <Components.PasswordHelper
      rules={passwordRules}
    />
  </React.Fragment>
);
