import { EndpointPatientUpdate } from "@myjourney/shared";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useAuthentication } from "~/hooks/useAuthentication";
import { DiagnosisProps, useDiagnosis } from "~/hooks/useDiagnosis";
import { usePatientApi } from "~/hooks/usePatientApi";
import { PatientContext, UserAuth } from "~/providers/PatientProvider";

import { USER_MY_ACCOUNT } from "~/utils/constants";
import { ToastContext } from "~/providers/ToastProvider";
import useGetMetastaticBreastCancerDiagnosisId from "~/hooks/useGetMetastaticBreastCancerDiagnosisId";

export const withDiagnosis =
  <P extends Record<string, unknown>>(
    Component: React.ComponentType<P>
  ): React.FC<Props> =>
  ({ name = "Diagnosis", ...props }: Props) => {
    Component.displayName = name;
    const history = useHistory();
    useAuthentication();
    const diagnosis = useDiagnosis();
    const { patientUpdate } = usePatientApi();
    const { auth } = React.useContext(PatientContext);
    const { handleToast } = React.useContext(ToastContext);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [changed, setChanged] = React.useState<boolean>(false);

    const checkIfChanged = (diagnosis: DiagnosisProps, auth: UserAuth | null) =>
      diagnosis.diagnosis !==
        (auth?.patient_details.diagnosis_ids?.[0]?.[0] || "") ||
      diagnosis.situation !==
        (auth?.patient_details.diagnosis_ids?.[1]?.[0] || "") ||
      diagnosis.treatment !==
        (auth?.patient_details.diagnosis_ids?.[2]?.[0] || "") ||
      diagnosis.locations !==
        (auth?.patient_details.diagnosis_ids?.[3] || []) ||
      diagnosis.diagnosisDate?.toLocaleDateString() !==
        new Date(
          auth?.patient_details.diagnosis_date ?? ""
        ).toLocaleDateString();

    const { metastaticDiagnosisId } = useGetMetastaticBreastCancerDiagnosisId();

    const allFieldsValid = (diagnosis: DiagnosisProps) =>
      !(
        !diagnosis.diagnosis ||
        !diagnosis.situation ||
        !diagnosis.diagnosisDate ||
        (!diagnosis.treatment &&
          diagnosis.locations.length === 0 &&
          diagnosis.diagnosis === `${metastaticDiagnosisId}`)
      );

    React.useEffect(() => {
      if (checkIfChanged(diagnosis, auth) && allFieldsValid(diagnosis)) {
        setChanged(true);
      } else {
        setChanged(false);
      }
    }, [
      diagnosis.diagnosisDate,
      diagnosis.diagnosis,
      diagnosis.situation,
      diagnosis.treatment,
      diagnosis.locations,
    ]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      try {
        const res = await patientUpdate({
          patient_details: {
            diagnosis_date: diagnosis.diagnosisDate?.toISOString(),
            diagnosis_ids: [
              [diagnosis.diagnosis],
              [diagnosis.situation],
              [diagnosis.treatment],
              diagnosis.locations,
            ],
          },
        });

        if (!res) {
          return;
        }

        const json: EndpointPatientUpdate.Response["body"] = await res.json();

        if (json.success) {
          history.push(USER_MY_ACCOUNT);
          handleToast?.("Profile updated successfully");
        }
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
      setLoading(false);
    };

    return (
      <Component
        {...(props as P & Props)}
        {...diagnosis}
        loading={loading}
        changed={changed}
        handleSubmit={handleSubmit}
      />
    );
  };
type Props = {
  name?: string;
};
