import * as React from "react";
import { SwitchBackground, SwitchForeground, SwitchLabel, SwitchRow } from "./styledSwitch";

export const Switch: React.FC<Props> = ({ active, label, value, handleToggle }) => (
  <SwitchRow
    type="button"
    onClick={() => handleToggle(value)}
  >
    <SwitchBackground
      active={active}
    >
      <SwitchForeground
        active={active}
      />
    </SwitchBackground>
    <SwitchLabel>
      {label}
    </SwitchLabel>
  </SwitchRow>
);

type Props = {
  active: boolean
  label: string
  value: string
  handleToggle: (value: string) => void
}