import tw, { styled } from "twin.macro";

import { Global, Spacings } from "./Global";

const styles = {
  ...Global,
  cols: {
    "1": tw`grid-cols-1`,
    "2": tw`grid-cols-2`,
    "3": tw`grid-cols-3`,
    "4": tw`grid-cols-4`,
    "5": tw`grid-cols-5`,
    "6": tw`grid-cols-6`,
    "7": tw`grid-cols-7`,
    "8": tw`grid-cols-8`,
    "9": tw`grid-cols-9`,
  },
  colsMd: {
    "1": tw`md:grid-cols-1`,
    "2": tw`md:grid-cols-2`,
    "3": tw`md:grid-cols-3`,
    "4": tw`md:grid-cols-4`,
    "5": tw`md:grid-cols-5`,
    "6": tw`md:grid-cols-6`,
    "7": tw`md:grid-cols-7`,
    "8": tw`md:grid-cols-8`,
    "9": tw`md:grid-cols-9`,
  },
  gap: {
    none: tw``,
    "2": tw`gap-x-2 gap-y-2`,
    "4": tw`gap-x-4 gap-y-4`,
    "6": tw`gap-x-6 gap-y-6`,
    "8": tw`gap-x-8 gap-y-8`,
  },
  gapX: {
    none: tw``,
    "2": tw`gap-x-2`,
    "4": tw`gap-x-4`,
    "6": tw`gap-x-6`,
    "8": tw`gap-x-8`,
  },
  gapY: {
    none: tw``,
    "2": tw`gap-y-2`,
    "4": tw`gap-y-4`,
    "6": tw`gap-y-6`,
    "8": tw`gap-y-8`,
  },
  alignItems: {
    none: tw``,
    start: tw`items-start`,
    end: tw`items-end`,
    center: tw`items-center`,
    baseline: tw`items-baseline`,
    stretch: tw`items-stretch`,
  },
};

export const Grid = styled.div(({
  cols = "1",
  colsMd = cols,
  gap = "none",
  gapX = "2",
  gapY = "2",
  spacing = "none",
  alignItems = "start",
  relative,
}: GridProps) => [
  tw`grid w-full`,
  styles.cols[cols],
  ...(colsMd ? [styles.colsMd[colsMd]] : []),
  styles.gap[gap],
  ...(gap === "none" && gapX ? [styles.gapX[gapX]] : []),
  ...(gap === "none" && gapY ? [styles.gapY[gapY]] : []),
  styles.spacing[spacing],
  styles.alignItems[alignItems],
  ...(relative ? [tw`relative`] : []),
]);

type GridProps = {
  cols?: keyof typeof styles["cols"];
  colsMd?: keyof typeof styles["colsMd"];
  gap?: keyof typeof styles["gap"];
  gapX?: keyof typeof styles["gapX"];
  gapY?: keyof typeof styles["gapY"];
  spacing?: Spacings;
  alignItems?: keyof typeof styles["alignItems"];
  relative?: boolean;
};
