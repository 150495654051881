import * as React from "react";
import tw, { styled } from "twin.macro";

import * as Components from "~/components";

import IconViewAll from "~/../assets/icons/icon-view-all.svg";

const StyledWrapper = styled.div(() => [
  tw`w-full flex justify-between`,
]);

export const ViewAllLink: React.FunctionComponent<ViewAllLinkProps> =(props) => {
  return (
    <StyledWrapper>
      <Components.TextLink
        textVariation="textlink2nounderline"
        href={props.href}
      >
        {props.label}
      </Components.TextLink>
      <Components.TextLink
        textVariation="textlink2nounderline"
        href={props.href}
      >
        <IconViewAll />
      </Components.TextLink>
    </StyledWrapper>
  );
};

export type ViewAllLinkProps = {
  href: string;
  label: string;
};
