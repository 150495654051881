import { Button, Content, IconWrapper, Item, Items } from "./styledAccordion";
import { GlossaryAccordionItem, withGlossaryAccordion } from "./withGlossaryAccordion";
import ArrowDown from "~/../assets/icons/arrow-down.svg";
import { RichText, Section } from "../Styled";

import * as Components from "~/components";

export const GlossaryAccordion = withGlossaryAccordion(({ items, handleActive }: Props) => (
  <Section>
    <Items>
      {items.map(({ label, active, content }, index) => (
        <Item
          key={label}
          border="greyLight"
        >
          <Button
            onClick={() => handleActive(index)}
          >
            <Components.TextContainer
              align="left"
            >
              <Components.TextVariation
                variation="paragraph1bold"
                color="blueNavy"
              >
                {label}
              </Components.TextVariation>
            </Components.TextContainer>
            <IconWrapper
              active={!!active}
            >
              <ArrowDown
                width="10"
                height="10"
              />
            </IconWrapper>
          </Button>
          <Content
            active={!!active}
          >
            <RichText>
              {content}
            </RichText>
          </Content>
        </Item>
      ))}
    </Items>
  </Section>
));

type Props = {
  handleActive: (item: number) => void
  items: Array<GlossaryAccordionItem>
}
