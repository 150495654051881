import * as React from "react";
import ReactDOM from "react-dom";
import { Calendar } from "../Calendar/Calendar";
import { Modal } from "../Modal/Modal";
import { Row, Column, ButtonSmall } from "../Styled";

export const DatePicker: React.FC<Props> = ({ active, setActive, date, onChange, activeDates }) => {
  return ReactDOM.createPortal(
    (
      <Modal
        maxWidth="xs"
        padding="md"
        active={active}
        setActive={setActive}
        noClose
      >
        <Calendar
          date={date}
          maxDate={new Date()} // There is currently never any reason to select a date later than the current date
          handleDate={(val) => {
            if (onChange) {
              onChange(val as Date);
            }
          }}
          activeDates={activeDates}
        />
        <Row>
          <Column
            justify="end"
          >
            <ButtonSmall
              type="button"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setActive(false);
              }}
            >
              OK
            </ButtonSmall>
          </Column>
        </Row>
      </Modal>
    ), document.querySelector("#root") as Element
  );
};

type Props = {
  active: boolean
  setActive: React.Dispatch<React.SetStateAction<boolean>>
  date: Date
  onChange?: (date: Date) => void
  activeDates?: Array<Date>
}
