import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";
import { ArticleList } from "~/components/ArticleList/ArticleList";
import { withBookmarks } from "./withBookmarks";
import { Meta } from "~/components/Meta/Meta";

import * as Components from "~/components";

export const Bookmarks = withBookmarks((props: Props) => (
  <Components.AppLayout
    headerVariation="navigationBack"
  >
    <Meta
      title="Bookmarks"
    />
    <Components.Row>
      <Components.Column
        spacing="lg"
      >
        <Components.TextVariation
          variation="heading1"
          align="center"
        >
          Bookmarks
        </Components.TextVariation>
        <Components.P
          align="center"
        >When you bookmark an article for later it will appear here.</Components.P>
      </Components.Column>
      <Components.Column
        gutter="lg"
      >
        <ArticleList
          articles={props.articles}
        />
      </Components.Column>
    </Components.Row>
  </Components.AppLayout>
));

export type Props = {
  loading: boolean
  articles: Array<MergedArticle>
}
