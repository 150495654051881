import tw, { styled } from "twin.macro";
import { Colours, Global, MaxWidths, Positions, Shadows, Spacings } from "./Global";

const styles = {
  ...Global,
  padding: {
    container: tw`p-4 pb-12 md:(p-20 pb-12 pt-14)`,
    public: tw`p-4 md:p-20`,
    xs: tw`p-2 md:(p-4)`,
    sm: tw`p-4`,
    md: tw`p-6`,
    lg: tw`p-8.75`,
    none: tw``,

    ogxxxs: tw`p-ogxxxs`,
    ogxxs: tw`p-ogxxs`,
    ogxs: tw`p-ogxs`,
    ogsm: tw`p-ogsm`,
    ogmd: tw`p-ogmd`,
    oglg: tw`p-oglg`,
    ogxl: tw`p-ogxl`,

    og1: tw`p-og1`,
    og2: tw`p-og2`,
    og4: tw`p-og4`,
    og6: tw`p-og6`,
    og8: tw`p-og8`,
  },
};

export const Card = styled.div(({
  color = "white",
  shadow = "none",
  padding = "none",
  spacing = "none",
  maxWidth = "none",
  position = "none",
  overflowHidden,
}: Props) => [
  styles.maxWidth[maxWidth],
  styles.background[color],
  styles.shadow[shadow],
  styles.padding[padding],
  styles.spacing[spacing],
  styles.position[position],
  ...(overflowHidden ? [tw`overflow-hidden`] : []),
  tw`rounded-md w-full`,
]);

type Props = {
  maxWidth?: MaxWidths
  color?: Colours
  shadow?: Shadows
  spacing?: Spacings
  padding?: "none" | "container" | "public" | "og1" | "og2" | "og4" | "og6" | "og8" | "xs" | "sm" | "md" | "lg" | "ogxxxs" | "ogxxs" | "ogxs" | "ogsm" | "ogmd" | "oglg" | "ogxl";
  position?: Positions
  overflowHidden?: boolean
}
