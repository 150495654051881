import tw, { styled } from "twin.macro";

const styles = {
  status: {
    uploading: tw`opacity-50`,
    uploaded: tw`border-grey-light`,
    default: tw`border-grey-medium`,
    error: tw`border-red-validation`,
  },
};

export const ThumbWrapper = styled.div(({ status = "default" }: StatusProps) => [
  tw`relative mt-4 mr-4 border-2 rounded-sm`,
  styles.status[status],
]);
export const ThumbObject = tw.div`rounded-sm w-20 h-20 bg-grey-light shadow-inner overflow-hidden`;
export const Thumb = tw.img`object-cover object-center h-20 w-full`;
export const ThumbContainer = tw.div`flex flex-wrap mt-2`;
export const RemoveButton = tw.button`h-5 w-5 bg-grey-light rounded-full top-0 right-0 -mt-2.5 -mr-2.5 absolute z-10 shadow outline-none flex items-center justify-center focus:(outline-none)`;
export const HiddenFileInput = tw.input`hidden`;


type StatusProps = {
  status?: "uploading" | "uploaded" | "default" | "error"
}
