import * as React from "react";
import { format, isValid } from "date-fns";
import tw, { styled } from "twin.macro";

import * as Components from "~/components";

import { InputWrapper, Label, StyledInput, FieldWrapper, IconWrapper, PasswordToggle, ToggleText } from "../Styled";
import { withInput } from "./withInput";
import TickIcon from "~/../assets/icons/tick.svg";
import HideIcon from "~/../assets/icons/hide.svg";
import ShowIcon from "~/../assets/icons/show.svg";
import CalendarIcon from "~/../assets/icons/calendar.svg";
import { DatePicker } from "../DatePicker/DatePicker";

const StyledInputMessage = styled.div(() => [
  tw`pt-2`,
]);

export const Input = withInput(({
  label, ariaLabel, error, required, type, placeholder, value, readonly, disabled, hideLabel, name, valid, validating, passwordToggle, hideRequired, icon, dateModal,
  onBlur, onChange, handleToggle, setDateModal,
}: Props) => (
  <InputWrapper>
    {label ?
      typeof label === "string" ? (
        <Label
          hidden={hideLabel}
        >
          <Components.TextVariation
            variation="label1"
            spacing="ogxxxxxs"
            fullWidth
          >
            {`${label}${required ? hideRequired ? "" : " *" : " (optional)"}`}
          </Components.TextVariation>
        </Label>
      ) : label()
    : null}
    <FieldWrapper>
      {type === "date" ? (
        <IconWrapper
          colour="greyDark"
          align="left"
        >
          <CalendarIcon />
        </IconWrapper>
      ) : null}
      <StyledInput
        error={error}
        type={type === "date" ? "text" : type}
        value={type === "date" ? isValid(value) ? format(value as Date, "PPP") : "" : value as string}
        placeholder={placeholder}
        readOnly={readonly}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={type === "date" ? () => {
          setDateModal(true);
        } : undefined}
        name={name}
        valid={valid}
        password={passwordToggle}
        date={type === "date"}
        autoCorrect={type === "email" ? "off" : undefined}
        autoCapitalize={type === "email" ? "off" : undefined}
        aria-label={ariaLabel ?? (typeof label === "string" ? label : undefined)}
      />
      {passwordToggle ? (
        <PasswordToggle
          tabIndex={-1}
          type="button"
          onClick={handleToggle}
        >
          {type === "text" ? (
            <>
              <HideIcon /><ToggleText>Hide</ToggleText>
            </>
          ) : (
            <>
              <ShowIcon /><ToggleText>Show</ToggleText>
            </>
          )}
        </PasswordToggle>
      ) : valid ? (
        <IconWrapper>
          <TickIcon />
        </IconWrapper>
      ) : null}
      {icon && (
        <IconWrapper
          colour="pink"
        >
          {icon}
        </IconWrapper>
      )}
    </FieldWrapper>
    {error && !validating ? (
      <StyledInputMessage>
        <Components.TextVariation
          variation="paragraph2"
          color="redValidation"
        >
          {error}
        </Components.TextVariation>
      </StyledInputMessage>
    ) : null}
    {validating ? (
      <StyledInputMessage>
        <Components.TextVariation
          variation="paragraph2"
          color="greyMedium"
        >
          Validating...
        </Components.TextVariation>
      </StyledInputMessage>
    ) : null}
    {type === "date" ? (
      <DatePicker
        date={isValid(value) ? value as Date : new Date()}
        active={dateModal}
        setActive={setDateModal}
        onChange={onChange}
        aria-label={ariaLabel ?? (typeof label === "string" ? label : undefined)}
      />
    ) : null}
  </InputWrapper>
));

type Props = {
  name?: string
  label?: string | (() => React.ReactNode)
  ariaLabel?: string
  error?: JSX.Element | string | undefined;
  hideRequired: boolean
  valid?: boolean
  validating?: boolean
  required?: boolean
  type?: "text" | "email" | "password" | "tel" | "date"
  value: string | Date
  placeholder?: string
  readonly: boolean
  disabled: boolean
  hideLabel: boolean
  passwordToggle: boolean
  icon?: React.ReactNode
  handleToggle: () => void
  setType: React.Dispatch<React.SetStateAction<Props["type"]>>
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | Date) => void;
  setDateModal: React.Dispatch<React.SetStateAction<boolean>>
  dateModal: boolean
}
