import * as React from "react";

import * as Components from "~/components";

import { InputError, InputWrapper, Label, FieldWrapper, StyledSelect, IconWrapper } from "../Styled";

import DownChevron from "~/../assets/icons/arrow-down.svg";

export const Select: React.FunctionComponent<SelectProps> = ({
  label,
  error,
  required,
  value,
  disabled,
  hideLabel,
  onBlur,
  onChange,
  name,
  options,
  color = "default",
}: SelectProps) => (
  <InputWrapper>
    {label ?
      typeof label === "string" ? (
        <Label
          hidden={hideLabel}
        >
          <Components.TextVariation
            variation="label1"
            spacing="ogxxxxxs"
            fullWidth
          >
            {`${label}${required ? " *" : ""}`}
          </Components.TextVariation>
        </Label>
      ) : label()
    : null}
    <FieldWrapper>
      <StyledSelect
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        name={name}
        color={color}
      >
        <option
          disabled
          value=""
        ></option>
        {options.map(({ label, value }) => (
          <option
            value={value}
            key={value}
          >
            {label}
          </option>
        ))}
      </StyledSelect>
      <IconWrapper
        colour={color === "default" ? "pink" : "greyDark"}
      >
        <DownChevron
          width="14"
          height="14"
        />
      </IconWrapper>
    </FieldWrapper>
    {error ? (
      <InputError>
        {error}
      </InputError>
    ) : error}
  </InputWrapper>
);

type SelectProps = {
  name?: string;
  label?: string | (() => React.ReactNode);
  error?: JSX.Element | string | undefined;
  required?: boolean;
  value?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Array<{
    label: string;
    value: string;
  }>;
  color?: "default" | "pink" | "grey";
}
