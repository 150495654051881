import tw, { css, styled } from "twin.macro";
import { Link } from "react-router-dom";
import { FontSizes, Global, Spacings, TextAligns, Weights } from "./Global";

const styles = {
  ...Global,
  colour: {
    pink: {
      default: tw`bg-pink text-white border-pink hover:(shadow-md bg-pink)`,
      disabled: tw`bg-pink-light text-grey-light cursor-not-allowed pointer-events-none border-pink-light`,
    },
    "pink-light": {
      default: tw`bg-pink-light border-pink-light text-grey-dark hover:(bg-white text-pink shadow-md)`,
      disabled: tw`bg-pink-light border-pink-light text-grey-light cursor-not-allowed pointer-events-none`,
    },
    white: {
      default: tw`bg-white border-white text-pink shadow hover:(bg-pink-light shadow-md)`,
      disabled: tw`bg-pink-light border-pink-light text-grey-light cursor-not-allowed pointer-events-none`,
    },
  },
};

// export const Button = styled.button(({
//   colour = "pink",
//   spacing = "none",
//   disabled,
//   full,
// }: Props) => [
//   styles.spacing[spacing],
//   ...(full ? [tw`w-full`] : []),
//   ...(disabled ? [styles.colour[colour].disabled] : [styles.colour[colour].default]),
//   tw`inline-flex select-none text-center max-w-full outline-none focus:outline-none transition-all duration-200 items-center justify-center rounded font-sans text-base font-bold tracking-tight text-center outline-none py-4 px-10 leading-5 active:shadow-lg border`,
//   css`
//     * {
//       ${tw`pointer-events-none`}
//     }
//   `,
// ]);

export const ButtonSmall = styled.button(({
  colour = "pink",
  spacing = "none",
  disabled,
  full,
}: Props) => [
  styles.spacing[spacing],
  ...(full ? [tw`w-full`] : []),
  ...(disabled ? [styles.colour[colour].disabled] : [styles.colour[colour].default]),
  tw`inline-flex select-none text-center max-w-full outline-none focus:outline-none transition-all duration-200 items-center justify-center rounded font-sans text-xs font-bold tracking-tight text-center outline-none py-3 px-10 leading-5 active:shadow-lg border`,
  css`
    * {
      ${tw`pointer-events-none`}
    }
  `,
]);

export const ButtonExtraSmall = styled.button(({
  colour = "pink",
  spacing = "none",
  disabled,
  full,
}: Props) => [
  styles.spacing[spacing],
  ...(full ? [tw`w-full`] : []),
  ...(disabled ? [styles.colour[colour].disabled] : [styles.colour[colour].default]),
  tw`inline-flex select-none text-center max-w-full border font-semibold outline-none focus:outline-none transition-all duration-200 items-center justify-center rounded font-sans text-xxs tracking-tight text-center outline-none py-2 px-4 leading-5 active:shadow-lg`,
  css`
    * {
      ${tw`pointer-events-none`}
    }
  `,
]);

export const LabelSpan = tw.span`inline-block ml-1`;

export const ButtonCircle = styled.button(({
  colour = "pink-light",
  spacing = "none",
  full,
}: Props) => [
  styles.colour[colour].default,
  styles.spacing[spacing],
  ...(full ? [tw``] : [tw`sm:w-auto`]),
  tw`inline-flex whitespace-nowrap select-none text-center max-w-full outline-none focus:outline-none transition-all duration-200 items-center justify-center rounded font-sans text-base font-medium tracking-tight text-center outline-none leading-5 active:shadow-lg`,
  css`
    width: 2.75rem !important;
    height: 2.75rem;
    * {
      ${tw`pointer-events-none`}
    }
  `,
]);

export const ButtonSquare = styled(Link)(({
  colour = "white",
  spacing = "none",
}: Props) => [
  styles.colour[colour].default,
  styles.spacing[spacing],
  tw`w-full bg-white text-pink block rounded-sm py-4 px-6 transition text-center shadow relative hover:(bg-pink text-white) focus:(outline-none) active:(shadow-lg)`,
]);

export const ButtonSquareIconWrapper = tw.div`absolute left-0 inset-y-0 flex items-center pl-6`;
export const ButtonRoundIconWrapper = tw.span`pr-2`;

export const ButtonError = styled.button(({
  spacing = "none",
} : {
  spacing?: Spacings
}) => [
  styles.spacing[spacing],
  tw`inline-flex whitespace-nowrap select-none text-center max-w-full outline-none focus:outline-none items-center justify-center rounded-sm text-pink text-sm border border-pink py-4 px-10 font-medium w-full transition hover:bg-white`,
]);

export const ButtonLink = styled.button(({
  underlined,
  full,
  align = "left",
  weight = "normal",
  fontSize = "base",
} : {
  underlined?: boolean
  full?: boolean
  align?: TextAligns
  weight?: Weights
  fontSize?: FontSizes
}) => [
  tw`text-pink text-sm focus:outline-none`,
  styles.textAlign[align],
  styles.weight[weight],
  styles.fontSize[fontSize],
  ...(underlined ? [tw`underline`] : []),
  ...(full ? [tw`w-full`] : []),
]);

type Props = {
  colour?: "pink" | "pink-light" | "white";
  spacing?: Spacings
  full?: boolean
  disabled?: boolean
}
