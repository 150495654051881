import * as React from "react";
import tw, { styled } from "twin.macro";
import * as Sentry from "@sentry/react";

import * as Providers from "~/providers";
import * as Hooks from "~/hooks";
import * as Utils from "~/utils";
import * as Components from "~/components";

import { SwipeModal, SwipeModalProps } from "./SwipeModal";
import useGetMetastaticBreastCancerDiagnosisId from "~/hooks/useGetMetastaticBreastCancerDiagnosisId";

const StyledWrapperNegative = styled.div(() => [tw`md:-mx-og4 md:px-og12`]);

export const SwipeModalCustomise: React.FunctionComponent<
  SwipeModalCustomiseProps
> = (props) => {
  const patientContext = React.useContext(Providers.PatientContext);
  const modalContext = React.useContext(Providers.ModalContext);

  const userTypeHook = Hooks.useUserTypes();
  const paitentHook = Hooks.usePatientApi();
  const diagnosisHook = Hooks.useDiagnosis();

  const [fieldsValid, setFieldsValid] = React.useState<boolean>(false);
  const [formLoading, setFormLoading] = React.useState<boolean>(false);

  const { metastaticDiagnosisId } = useGetMetastaticBreastCancerDiagnosisId();
  React.useEffect(() => {
    if (
      !(
        !diagnosisHook.diagnosis ||
        !diagnosisHook.situation ||
        (!diagnosisHook.treatment &&
          diagnosisHook.locations.length === 0 &&
          diagnosisHook.diagnosis === `${metastaticDiagnosisId}`)
      )
    ) {
      setFieldsValid(true);
    } else {
      setFieldsValid(false);
    }
  }, [
    diagnosisHook.diagnosis,
    diagnosisHook.situation,
    diagnosisHook.treatment,
    userTypeHook.selectedUserTypes.join(""),
  ]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormLoading(true);

    try {
      const res = await paitentHook.patientUpdate({
        patient_details: {
          diagnosis_date: diagnosisHook.diagnosisDate?.toISOString(),
          diagnosis_ids: [
            [diagnosisHook.diagnosis],
            [diagnosisHook.situation],
            [diagnosisHook.treatment],
            diagnosisHook.locations,
          ],
          usertype_ids: Utils.generateSelectedUserTypeIds(
            userTypeHook.selectedUserTypes,
            new Date(patientContext.auth?.patient_details.dob ?? Date.now()),
            new Date(
              patientContext.auth?.patient_details.diagnosis_date ?? Date.now()
            )
          ),
        },
      });

      const json: {
        success: boolean;
      } = await res?.json();

      if (json.success) {
        modalContext.hideModal();
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
    setFormLoading(false);
  };

  return (
    <SwipeModal
      {...props}
      title="Customise my content"
      onClose={() => {
        userTypeHook.resetFromPatientDetails();
        diagnosisHook.resetFromPatientDetails();
        props.onClose?.();
      }}
    >
      <Components.Form onSubmit={handleSubmit}>
        <Components.TextVariation
          variation="heading3"
          color="greyDark"
          spacing="ogxxs"
          fullWidth
        >
          {"I'm interested in content related to:"}
        </Components.TextVariation>
        <Components.Grid cols="1" colsMd="2" gapX="4" gapY="6" spacing="og6">
          <Components.Select
            label="My diagnosis"
            options={diagnosisHook.diagnosisData ?? []}
            value={diagnosisHook.diagnosis}
            onChange={(event) =>
              diagnosisHook.handleDiagnosis(event.target.value)
            }
            color="pink"
          />
          {diagnosisHook.diagnosis !== `${metastaticDiagnosisId}` ? (
            <Components.Select
              label="My situation"
              options={diagnosisHook.situationData ?? []}
              value={diagnosisHook.situation}
              onChange={(event) =>
                diagnosisHook.handleSituation(event.target.value)
              }
              color="pink"
            />
          ) : (
            <React.Fragment>
              <Components.Select
                label="I have a previous breast cancer diagnosis"
                options={diagnosisHook.situationData ?? []}
                value={diagnosisHook.situation}
                onChange={(event) =>
                  diagnosisHook.handleSituation(event.target.value)
                }
                color="pink"
              />
              <Components.Select
                label="My situation"
                options={diagnosisHook.treatmentData ?? []}
                value={diagnosisHook.treatment}
                onChange={(event) =>
                  diagnosisHook.handleTreatment(event.target.value)
                }
                color="pink"
              />
            </React.Fragment>
          )}
        </Components.Grid>
        <StyledWrapperNegative>
          {userTypeHook.userTypeData?.length > 0 && (
            <Components.RadioGroupGraphic
              checked={userTypeHook.selectedUserTypes}
              onChange={userTypeHook.handleUserTypes}
              data={userTypeHook.userTypeData}
            />
          )}
        </StyledWrapperNegative>
        <Components.ButtonVariation
          variation="primary"
          type="submit"
          onClick={() => null}
          disabled={formLoading || fieldsValid === false}
          fullWidth
        >
          {formLoading ? "Loading..." : "Apply"}
        </Components.ButtonVariation>
      </Components.Form>
    </SwipeModal>
  );
};

export type SwipeModalCustomiseProps = SwipeModalProps & {
  children?: never;
};
