import * as React from "react";
import { useHistory } from "react-router-dom";
import { USER_LOGIN } from "../utils/constants";
import { PatientContext } from "../providers/PatientProvider";

/**
 * Redirect to login (or passed url) when the user is NOT authenticated.
 */
export const useAuthentication = (url: string = USER_LOGIN): void => {
  const history = useHistory();
  const { auth } = React.useContext(PatientContext);

  React.useEffect(() => {
    if (!auth) {
      history.push(url);
    }
  }, [auth]);
};
