import * as React from "react";
import { useHistory } from "react-router-dom";
import { H3, P, StyledHyperlink } from "~/components/Styled";
import { PatientContext } from "~/providers/PatientProvider";
import { USER_DASHBOARD } from "~/utils/constants";

export const withHome = <ParentProps extends Record<string, unknown>>(Component: React.ComponentType<ParentProps>): React.FC<Props> => ({ name = "Home", ...props }: Props) => {
  Component.displayName = name;
  const history = useHistory();
  const { auth } = React.useContext(PatientContext);
  const [activePopup, setActivePopup] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (auth) {
      history.push(USER_DASHBOARD);
    }
  }, []);

  const supporters = [
    {
      title: "I am at high risk of developing breast cancer/ have a family history",
      content: (
        <>
          <H3
            align="center"
          >At the moment, My Journey is for people diagnosed with breast cancer.</H3>
          <P
            align="center"
          >
            {"While some people do have an increased risk of breast cancer because of their family history and genetics, you may be suprised to know that 90-95 per cent of all breast cancers have nothing to do with family history. Visit BCNA's website for more information about "}<StyledHyperlink
              href="https://www.bcna.org.au/breast-health-awareness/risk-factors/"
                                                                                                                                                                                                                                                                                         >risk factors</StyledHyperlink> and <StyledHyperlink
                                                                                                                                                                                                                                                                                           href="https://www.bcna.org.au/understanding-breast-cancer/breast-cancer-in-the-family/"
                                                                                                                                                                                                                                                                                                                             >breast cancer in the family</StyledHyperlink>.
          </P>
        </>
      ),
      link: "high-risk",
    },
    {
      title: "I have noticed a change in my breast/s or am awaiting test results",
      content: (
        <>
          <H3
            align="center"
          >At the moment, My Journey is for people diagnosed with breast cancer.</H3>
          <P
            align="center"
          >{"A woman's breasts often change throughout her lifetime, due to changes in hormonal levels as part of aging. Although a breast change can be normal, many women worry that it might be a sign of breast cancer. If you have noticed a change in your breast, it's best to see your doctor to have it checked out."}</P>
          <P
            align="center"
          >You may find it helpful to read <StyledHyperlink
            href="https://www.bcna.org.au/understanding-breast-cancer/"
                                           >Understanding breast cancer</StyledHyperlink> on our website.</P>
        </>
      ),
      link: "friend-or-family",
    },
    {
      title: "I'm a family member, friend or colleague of someone diagnosed",
      content: (
        <>
          <H3
            align="center"
          >At the moment, My Journey is for people diagnosed with breast cancer.</H3>
          <P
            align="center"
          >{"It can be hard to know what to say and how to support a family member, firend or colleage diagnosed with breast cancer. Content for you may be available through the online tool soon. For now, you can find information on BCNA's website through the links below."}</P>
          <P
            align="center"
          >
            <StyledHyperlink
              href="https://www.bcna.org.au/understanding-breast-cancer/talking-to-family-and-friends/information-for-partners/"
            >
              Information for partners
            </StyledHyperlink><br />
            <StyledHyperlink
              href="https://www.bcna.org.au/understanding-breast-cancer/caring-for-someone-with-early-breast-cancer/"
            >
              Information for carers
            </StyledHyperlink><br />
            <StyledHyperlink
              href="https://www.bcna.org.au/understanding-breast-cancer/talking-to-family-and-friends/helping-a-friend-or-colleague-with-breast-cancer/"
            >
              Information for friends or colleagues
            </StyledHyperlink><br />
            <StyledHyperlink
              href="https://www.bcna.org.au/work-and-breast-cancer/colleague/"
            >
              Information for colleagues
            </StyledHyperlink>
          </P>
        </>
      ),
      link: "change-in-my-breasts",
    },
  ];

  const handleBackToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Component
      { ...props as ParentProps }
      supporters={supporters}
      handleBackToTop={handleBackToTop}
      activePopup={activePopup}
      setActivePopup={setActivePopup}
    />
  );
};

type Props = {
  name?: string
}

export type Supporter = {
  content: JSX.Element
  title: string
  link: string
}
