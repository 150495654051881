import * as React from "react";
import * as Sentry from "@sentry/react";
import tw, { css, styled } from "twin.macro";

import * as Providers from "~/providers";
import * as Hooks from "~/hooks";
import * as Components from "~/components";

import { SwipeModal, SwipeModalProps } from "./SwipeModal";

import IconClose from "~/../assets/icons/icon-close.svg";
import IconCamera from "~/../assets/icons/icon-camera.svg";

const StyledProfileImageExample = styled.div((props: { backgroundImage: string; }) => [
  tw`relative bg-center bg-cover rounded-full bg-blue-navy`,
  tw`flex justify-center items-center`,
  css`
    width: 110px;
    height: 110px;
    margin: 0 auto;
    background-image: url(${props.backgroundImage});
  `,
]);

const StyledProfileUserExampleIconCloseWrapper = styled.div(() => [
  tw`absolute p-2 cursor-pointer`,
  css`
    top: -0.5rem;
    right: -0.5rem;
  `,
]);

const StyledProfileBackgroundExample = styled.div((props: { backgroundImage: string; }) => [
  tw`relative bg-center bg-cover`,
  css`
    width: 75%;
    margin: 0 auto;
    min-height: 98px;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
    background-image: url(${props.backgroundImage});
  `,
]);

const StyledProfileBackgroundExampleIconCloseWrapper = styled.div(() => [
  tw`absolute top-0 right-0 p-2 -mt-4 -mr-4 cursor-pointer`,
]);

const StyledProfileBackgroundItem = styled.div((props: { backgroundImage: string; transparent: boolean; }) => [
  tw`relative w-full`,
  css`
    min-height: 50px;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
    background: ${props.transparent ? "linear-gradient(0deg, rgba(255, 255, 255, .75), rgba(255, 255, 255, .75))," : ""} url(${props.backgroundImage});
    background-size: cover;
    background-position: center;
  `,
]);

const StyledListIcon = styled.div(() => [
  tw`bg-pink text-white font-semibold rounded-full flex justify-center items-center mr-og2`,
  css`
    width: 28px;
    height: 28px;
  `,
]);

export const SwipeModalChooseLook: React.FunctionComponent<SwipeModalChooseLookProps> = (props) => {
  const patientContext = React.useContext(Providers.PatientContext);
  const modalContext = React.useContext(Providers.ModalContext);

  const symptomTrackerHook = Hooks.useSymptomTrackerApi();
  const paitentHook = Hooks.usePatientApi();

  const [formLoading, setFormLoading] = React.useState<boolean>(false);
  const [selectedProfileImageUserId, setSelectedProfileImageUserId] = React.useState<string>();
  const [selectedProfileImageUserContent, setSelectedProfileImageUserContent] = React.useState<string>();

  const [selectedProfileImageBackgroundId, setSelectedProfileImageBackgroundId] = React.useState<string>();
  const [selectedProfileImageBackgroundContent, setSelectedProfileImageBackgroundContent] = React.useState<string>();

  React.useEffect(() => {
    if (typeof patientContext.auth?.patient_preferences.profile_image_user !== "undefined") {
      setSelectedProfileImageUserId(patientContext.auth.patient_preferences.profile_image_user);
    }
    if (typeof patientContext.auth?.patient_preferences.profile_image_background !== "undefined") {
      setSelectedProfileImageBackgroundId(patientContext.auth.patient_preferences.profile_image_background);
    }
  }, [patientContext.auth?.patient_preferences.profile_image_user, patientContext.auth?.patient_preferences.profile_image_background]);

  React.useEffect(() => {
    (async () => {
      if (typeof selectedProfileImageUserId !== "undefined") {
        const imageContent = await symptomTrackerHook.getUserImage(selectedProfileImageUserId);
        const imageBlob = await imageContent.blob();
        setSelectedProfileImageUserContent(URL.createObjectURL(imageBlob));
      }
    })();
  }, [selectedProfileImageUserId]);

  React.useEffect(() => {
    (async () => {
      if (typeof selectedProfileImageBackgroundId !== "undefined") {
        if (selectedProfileImageBackgroundId.startsWith("default-")) {
          setSelectedProfileImageBackgroundContent(patientContext.profileBackgroundImages[selectedProfileImageBackgroundId]);
        } else {
          const imageContent = await symptomTrackerHook.getUserImage(selectedProfileImageBackgroundId);
          const imageBlob = await imageContent.blob();
          setSelectedProfileImageBackgroundContent(URL.createObjectURL(imageBlob));
        }
      }
    })();
  }, [selectedProfileImageBackgroundId]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormLoading(true);
    try {
      const res = await paitentHook.patientUpdate({
        patient_preferences: {
          profile_image_user: selectedProfileImageUserId,
          profile_image_background: selectedProfileImageBackgroundId,
        },
      });

      const json: {
        success: boolean;
      } = await res?.json();

      if (json.success) {
        modalContext.hideModal();
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
    setFormLoading(false);
  };

  return (
    <SwipeModal
      {...props}
      active={props.active}
      title="Choose my look"
    >
      <Components.Form
        onSubmit={handleSubmit}
      >
        <Components.Grid
          cols="1"
          gap="4"
          spacing="og10"
        >
          <Components.Box
            alignItems="center"
            fullWidth
          >
            <Components.Box>
              <StyledListIcon>
                {"1"}
              </StyledListIcon>
            </Components.Box>
            <Components.TextVariation
              variation="heading4"
              fullWidth
            >
              {"Profile image"}
            </Components.TextVariation>
          </Components.Box>
          <Components.TextVariation
            variation="paragraph2"
          >
            {"Upload an image of your choice to customise your home screen"}
          </Components.TextVariation>
          <StyledProfileImageExample
            backgroundImage={selectedProfileImageUserId ? selectedProfileImageUserContent ?? "" : ""}
          >
            {!selectedProfileImageUserId ? (
              <IconCamera />
            ) : (
              <StyledProfileUserExampleIconCloseWrapper
                onClick={() => setSelectedProfileImageUserId(undefined)}
              >
                <IconClose />
              </StyledProfileUserExampleIconCloseWrapper>
            )}
          </StyledProfileImageExample>
          <Components.FileUpload
            setFileId={(value) => setSelectedProfileImageUserId(value)}
          >
            {selectedProfileImageUserId ? "Change my image" : "Upload an image"}
          </Components.FileUpload>
        </Components.Grid>
        <Components.Grid
          cols="1"
          gap="4"
          spacing="og10"
        >
          <Components.Box
            alignItems="center"
            fullWidth
          >
            <Components.Box>
              <StyledListIcon>
                {"2"}
              </StyledListIcon>
            </Components.Box>
            <Components.TextVariation
              variation="heading4"
              fullWidth
            >
              {"Background image"}
            </Components.TextVariation>
          </Components.Box>
          <Components.TextVariation
            variation="paragraph2"
            fullWidth
          >
            {"Upload a background image to make this space yours"}
          </Components.TextVariation>
          {selectedProfileImageBackgroundId && (
            <React.Fragment>
              <StyledProfileBackgroundExample
                backgroundImage={selectedProfileImageBackgroundContent ?? ""}
              >
                <StyledProfileBackgroundExampleIconCloseWrapper
                  onClick={() => setSelectedProfileImageBackgroundId(undefined)}
                >
                  <IconClose />
                </StyledProfileBackgroundExampleIconCloseWrapper>
              </StyledProfileBackgroundExample>
            </React.Fragment>
          )}
          <Components.FileUpload
            setFileId={(value) => setSelectedProfileImageBackgroundId(value)}
          >
            {"Upload an image"}
          </Components.FileUpload>
          <Components.TextVariation
            variation="paragraph2"
            fullWidth
          >
            {"or select a pattern from below"}
          </Components.TextVariation>
          <Components.Grid
            cols="4"
          >
            {Object.entries(patientContext.profileBackgroundImages).map(([key, value]) => (
              <StyledProfileBackgroundItem
                key={key}
                backgroundImage={value}
                transparent={Boolean(selectedProfileImageBackgroundId && selectedProfileImageBackgroundId !== key)}
                onClick={() => setSelectedProfileImageBackgroundId(key)}
              />
            ))}
          </Components.Grid>
        </Components.Grid>
        <Components.ButtonVariation
          variation="primary"
          type="submit"
          onClick={() => null}
          disabled={formLoading}
          fullWidth
        >
          {formLoading ? "Loading..." : "Apply"}
        </Components.ButtonVariation>
      </Components.Form>
    </SwipeModal>
  );
};

export type SwipeModalChooseLookProps = SwipeModalProps & {
  children?: never;
};
