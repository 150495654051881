import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { debounce } from "ts-debounce";

import { USER_ARTICLES_SEARCH } from "../utils/constants";
import { useContentApi } from "./useContentApi";
import { useAnalytics } from "./useAnalytics";

export const useSearch = (): SearchProps => {
  const history = useHistory();
  const routeMatch = useRouteMatch<{ keyword: string; }>();
  const { searchArticlesByKeyword, searchAutoComplete } = useContentApi();
  const analytics = useAnalytics();

  const [keyword, setKeyword] = React.useState<string>(routeMatch.params.keyword);
  const [results, setResults] = React.useState<Array<MergedArticle>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [suggestions, setSuggestions] = React.useState<Array<string>>([]);

  React.useEffect(() => {
    (async () => {
      if (routeMatch.params.keyword) {
        setLoading(true);
        const search = await searchArticlesByKeyword(routeMatch.params.keyword);

        setResults(search);

        analytics.trackArticleSearchSubmit({
          term: routeMatch.params.keyword,
          resultCount: search.length,
        });

        analytics.trackArticleSearchView({
          term: routeMatch.params.keyword,
          resultCount: search.length,
          results: search.map((result, index) => ({
            label: result.name,
            position: index,
            type: result.documentTypeAlias,
          })),
        });
        setLoading(false);
      }
    })();
  }, [
    routeMatch.params.keyword,
  ]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    history.push(USER_ARTICLES_SEARCH.replace(":keyword", keyword));
  };

  const onChangeKeyword = (value: string) => {
    setKeyword(value);
    const debounced = debounce(async () => {
      const _suggestions = await searchAutoComplete(value);
      setSuggestions(_suggestions);
    }, 150);
    debounced();
  };

  const onClickSuggestion = (value: string) => {
    history.push(USER_ARTICLES_SEARCH.replace(":keyword", value));
  };

  const onClickResult = (article: MergedArticle) => {
    analytics.trackArticleSearchClick({
      label: article.name,
      term: routeMatch.params.keyword,
      resultCount: results.length,
      results: results.map((result, index) => ({
        label: result.name,
        position: index,
        type: result.documentTypeAlias,
      })),
    });
  };

  return {
    keywordInput: keyword,
    results,
    loading,
    onClickResult,
    onSubmit,
    onChangeKeyword,
    onClickSuggestion,
    keywordSearch: routeMatch.params.keyword,
    suggestions,
  };
};

export type SearchProps = {
  keywordInput: string;
  results: Array<MergedArticle>;
  loading: boolean;
  suggestions: Array<string>;
  keywordSearch: string;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  onChangeKeyword: (value: string) => void;
  onClickSuggestion: (value: string) => void
  onClickResult: (article: MergedArticle) => void;
}
