import tw, { styled } from "twin.macro";
import { Colours, Global } from "./Global";

import { TextVariations } from "~/components/Text";

const styles = {
  ...Global,
  full: tw`w-full block`,
};

export const Form = styled.form(() => [
  styles.full,
]);

export const Label = styled.label(({ hidden }: { hidden?: boolean }) => [
  styles.full,
  ...(hidden ? [tw`sr-only`] : []),
]);

export const TextAreaLabel = styled.label(({ hidden }: { hidden?: boolean }) => [
  styles.full,
  ...(hidden ? [tw`sr-only`] : []),
  tw`text-blue-navy mb-4`,
]);

export const InputWrapper = styled.div(() => [
  tw`w-full flex flex-wrap`,
]);
export const SearchInputWrapper = styled.div(() => [
  tw`transition-width w-full md:(w-40) focus-within:(w-full) relative`,
]);

export const StyledInput = styled.input(({ error, valid, password, disabled, readOnly, date }: StyledInputProps) => [
  styles.full,
  tw`rounded-sm border py-3 outline-none appearance-none min-h-6 pl-4 pr-4 placeholder-grey-medium`,
  ...(error ? [tw`border-red-validation bg-pink-light`] : [tw`border-pink-dusty bg-white`]),
  ...(valid ? [tw`pr-12`] : []),
  ...(password ? [tw`pr-20`] : []),
  ...(disabled ? [tw`bg-grey-light`] : []),
  ...(readOnly ? [tw`cursor-default`] : [tw`focus:shadow`]),
  ...(date ? [tw`pl-12`] : []),
]);

export const FileInputButton = styled.button(() => [
  styles.full,
  tw`rounded-sm border border-pink-dusty bg-white py-3 outline-none appearance-none min-h-6 pl-4 pr-4 text-left text-grey-medium relative focus:(shadow outline-none)`,
]);

export const StyledTextArea = styled.textarea(({ error, valid }: StyledTextAreaProps) => [
  styles.full,
  tw`rounded-lg border py-3 focus:shadow outline-none appearance-none min-h-6 placeholder-grey-medium resize-none`,
  ...(error ? [tw`border-red-validation bg-pink-light`] : [tw`border-pink-dusty bg-white`]),
  ...(valid ? [tw`pl-4 pr-12`] : [tw`px-4`]),
]);

export const StyledSearchInput = styled.input(() => [
  styles.full,
  tw`rounded border border-pink-dusty py-3 px-4 outline-none appearance-none`,
]);

export const PasswordToggle = styled.button(() => [
  tw`text-pink text-xs absolute inset-y-0 right-0 flex items-center px-4 focus:outline-none`,
]);

export const ToggleText = tw.span`underline inline-block ml-1`;

const selectStyles = {
  base: tw`px-4 py-3 outline-none appearance-none truncate pr-12`,
  default: {
    error: tw`border-red-validation bg-pink-light`,
    // default: tw`border-transparent bg-pink-medium`,
    default: tw`border-pink-dusty bg-white text-blue-navy`,
    base: tw`border rounded-sm focus:shadow font-semibold`,
  },
  pink: {
    error: tw`border-red-validation bg-pink-light`,
    default: tw`border-transparent bg-pink-medium`,
    base: tw`border rounded-sm focus:shadow font-normal`,
  },
  grey: {
    error: tw`border-red-validation bg-pink-light`,
    default: tw`border-grey-medium bg-grey-light`,
    base: tw`border rounded-sm text-grey-dark text-sm font-normal`,
  },
};

export const StyledSelect = styled.select(({ error, color = "default" }: StyledInputProps) => [
  TextVariations.paragraph1,
  styles.full,
  selectStyles.base,
  selectStyles[color].base,
  ...(error ? [selectStyles[color].error] : [selectStyles[color].default]),
]);

export const IconWrapper = styled.div(({
  colour = "greenValidation",
  align = "right",
} : { 
  colour?: Colours
  align?: "right" | "left"
}) => [
  tw`pointer-events-none absolute inset-y-0 flex items-center px-4`,
  styles.colour[colour],
  ...(align === "right" ? [tw`right-0`] : [tw`left-0`]),
]);

export const HiddenCheckbox = styled.input(() => [
  tw`opacity-0 absolute`,
]);

export const FieldWrapper = styled.div(() => [
  styles.full,
  tw`relative z-10`,
]);

export const DateFieldWrapper = styled.div(() => [
  styles.full,
  tw`relative z-10 flex items-center space-x-1`,
]);

export const VisibleCheckbox = styled.span(() => [
  tw`w-8 h-8 border-pink-dusty border rounded-full flex items-center justify-center flex-shrink-0`,
]);

export const CheckboxLabel = styled.label(() => [
  tw`inline-flex items-center text-blue-navy cursor-pointer select-none`,
]);

export const CheckboxSpan = styled.span(() => [
  tw`ml-4 select-none`,
]);

export const InputError = styled.div(() => [
  styles.full,
  tw`text-red-validation text-mdsm py-2`,
]);
export const InputValidation = styled.div(() => [
  styles.full,
  tw`text-grey-dark text-mdsm py-2`,
]);

export const RadioCheckbox = styled.span(() => [
  tw`w-8 h-8 border-pink-dusty border rounded-full flex items-center justify-center flex-shrink-0`,
]);

export const RadioGroupCheckbox = styled.span(() => [
  tw`w-8 h-8 rounded-full flex items-center justify-center border border-pink-dusty flex-shrink-0`,
]);

export const RadioWrapper = styled.label(() => [
  tw`rounded-sm flex mb-og2 cursor-pointer`,
]);

export const RadioGroupWrapper = styled.label(({interactive }: CheckboxProps) => [
  tw`rounded-sm p-4 flex mb-4 select-none w-full shadow bg-white`,
  ...(interactive !== false ? [tw`hover:shadow focus-within:shadow cursor-pointer`] : []),
]);

export const CheckboxGroupWrapper = styled.label(() => [
  tw`rounded-sm p-4 hover:shadow focus-within:shadow flex mb-4 cursor-pointer select-none w-full shadow bg-white`,
]);

export const RadioTextWrapper = styled.div(() => [
  tw`ml-4 self-center`,
]);

export const RadioLabel = styled.p(() => [
  tw`text-grey-dark`,
]);

export const RadioDescription = styled.p(() => [
  tw`text-grey-dark text-sm`,
]);

export const CheckboxTick = styled.span(({ checked }: CheckboxProps) => [
  tw`border border-t-0 border-l-0 border-2 border-pink w-1.5 h-3.5 transform rotate-45 -translate-y-0.5`,
  ...(checked ? [tw`opacity-100 visible`] : [tw`opacity-0 invisible`]),
]);

export const RadioCircle = styled.span(({ checked }: CheckboxProps) => [
  tw`w-3.5 h-3.5 bg-pink rounded-full`,
  ...(checked ? [tw`opacity-100 visible`] : [tw`opacity-0 invisible`]),
]);


type StyledInputProps = {
  error?: JSX.Element | string | undefined;
  valid?: boolean;
  password?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  date?: boolean;
  color?: "default" | "pink" | "grey";
};

type StyledTextAreaProps = {
  error?: JSX.Element | string | undefined;
  valid?: boolean;
  password?: boolean;
};

type CheckboxProps = {
  checked: boolean;
  interactive?: boolean;
};
