import * as React from "react";
import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";

import * as Components from "~/components";

const CardLink = styled.span(() => [
  tw`bg-pink-medium text-blue-navy rounded-sm w-full hover:(shadow bg-white) relative focus:outline-none p-4 block`,
]);

export const AccountCard: React.FunctionComponent<AccountCardProps> = (props) => {
  const items = props.items.filter((item) => item.value).map((item, index) => (
    <React.Fragment
      key={index}
    >
      <Components.TextVariation
        variation="paragraph2"
        color="greyDark"
        spacing="xs"
      >
        <Components.TextVariation
          variation="paragraph2bold"
          color="blueNavy"
        >
          {item.label}&nbsp;&nbsp;
        </Components.TextVariation>
        {item.value}
      </Components.TextVariation>
      {index < props.items.length - 1 && (
        <br />
      )}
    </React.Fragment>
  ));

  return (
    <>
      <Components.Row
        items="center"
        spacing="ogxxxs"
      >
        <Components.Column
          xs="2/3"
        >
          <Components.TextVariation
            variation={props.titleVariation ?? "heading3"}
            color={props.titleColor ?? "greyDark"}
          >
            {props.title}
          </Components.TextVariation>
        </Components.Column>
        {props.linkText ? (
          <Components.Column
            xs="1/3"
            justify="end"
          >
            {props.link && (
              <Components.TextVariation
                variation="textlink2nounderline"
                onClick={props.onClick}
                aria-label={props.linkText}
              >
                <Link
                  to={props.link}
                  aria-label={props.linkText}
                >
                  {props.linkText}
                </Link>
              </Components.TextVariation>
            )}
            {props.onClick && (
              <Components.TextVariation
                variation="textlink2nounderline"
                onClick={props.onClick}
                aria-label={props.linkText}
              >
                {props.linkText}
              </Components.TextVariation>
            )}
          </Components.Column>
        ) : null}
      </Components.Row>
      <Components.Row
        spacing="ogxs"
        items="center"
        aria-label={`Account card (${props.title})`}
      >
        <Components.Column>
          <Components.TextContainer
            align="left"
            onClick={props.onClick}
          >
            <CardLink
              aria-label="Card link"
            >
              {props.link ? (
                <Link
                  to={props.link}
                >
                  {items}
                </Link>
              ) : items}
            </CardLink>
          </Components.TextContainer>
        </Components.Column>
      </Components.Row>
    </>
  );
};

export type AccountCardProps = {
  title: string;
  titleVariation?: Components.TextVariationProps["variation"];
  titleColor?: Components.TextVariationProps["color"];
  link?: string;
  onClick?: () => void;
  linkText?: string;
  items: Array<{
    label: JSX.Element | string;
    value: JSX.Element | string | undefined;
  }>;
};
