import * as React from "react";
import { useAnalytics } from "~/hooks/useAnalytics";

export const withArticleAccordion = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "ArticleAccordion", active = [], items: list, articleTitle, ...props }: Props) => {
  Component.displayName = name;
  const { trackAccordionClick } = useAnalytics();

  const [isActive, setActive] = React.useState<Array<number>>(active);

  const handleActive = (index: number) => setActive((prev) => {
    const i = prev.indexOf(index);
    const tracking = {
      group: articleTitle,
      label: list[index].label,
      position: index + 1,
    };
    if (i > -1) {
      trackAccordionClick({
        ...tracking,
        event: "close",
      });
      return [];
    }
    trackAccordionClick({
      ...tracking,
      event: "open",
    });
    return [index];
  });

  const items = list?.map((item, index) => ({
    ...item,
    active: isActive.includes(index),
  }));

  return (
    <Component
      { ...props as P }
      handleActive={handleActive}
      items={items}
    />
  );
};

type Props = {
  name?: string
  active?: Array<number>
  items: Array<ArticleAccordionItem>
  articleTitle: string
  location?: "Article" | "Welcome"
}

export type ArticleAccordionItem = {
  active?: boolean
  content: React.FC | JSX.Element | Array<JSX.Element>
  label: string
}
