import tw, { css, styled } from "twin.macro";

export const HeaderLogoWrapper = styled.div(() => [
  css`
    width: 180px;
    @media (min-width: 600px) {
      width: 220px;
    }
  `,
]);

export const Nav = tw.ul`hidden xl:flex items-center w-full justify-end`;
export const NavItem = tw.li`ml-3.5`;
export const MobileNav = tw.nav`xl:hidden border-t pt-6 mt-6`;
