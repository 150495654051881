import tw, { css, styled } from "twin.macro";

export const StyledSelect = styled.select(() => [
  tw`text-pink bg-transparent appearance-none font-medium p-0 m-0 text-right focus:outline-none`,
  css`
    text-align-last: right;
  `,
]);
export const InputWrapper = tw.div`flex flex-wrap justify-end`;
export const Label = tw.label`text-blue-navy font-medium w-full text-right mb-1`;
