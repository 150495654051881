import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";

export const Header = styled.header(() => [
  tw`px-6 pb-6 pt-10 mb-4 flex`,
]);

export const StyledLink = styled(Link)(() => [
  tw`block`,
]);

export const RouteWrapper = tw.div`relative overflow-x-hidden`;
