import * as React from "react";
import { usePatientApi } from "~/hooks/usePatientApi";
import { ToastContext } from "~/providers/ToastProvider";

export const withStyleGuide = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "StyleGuide", ...props }: Props) => {
  Component.displayName = name;
  const { patientUpdateMetadata } = usePatientApi();
  const { handleToast } = React.useContext(ToastContext);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [radio, setRadio] = React.useState<string>("");
  const [range, setRange] = React.useState<number>(3);
  const [radioGroup, setRadioGroup] = React.useState<string>("");
  const [checkboxGroup, setCheckboxGroup] = React.useState<Array<string>>([]);
  const [popup, setPopup] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<Date>();
  const [resetLoading, setResetLoading] = React.useState<boolean>(false);

  const handleCheckbox = () => setChecked(prev => !prev);
  const handleRadio = (value: string) => setRadio(value);
  const handleRange = (value: number) => setRange(value);
  const handleRadioGroup = (value: string) => setRadioGroup(value);
  const handleCheckboxGroup = (value: string) => setCheckboxGroup((prev) => prev.indexOf(value) > -1 ? prev.filter((f) => f !== value) : [...prev, value]);

  React.useEffect(() => {
    if (popup) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "";
    }
  }, [popup]);

  document.body.style.backgroundColor = "white";

  const resetUser = async () => {
    setResetLoading(true);
    await patientUpdateMetadata({
      acceptedCookies: false,
      seenTrackerWelcome: false,
    });
    setResetLoading(false);
  };
  

  return (
    <Component
      { ...props as P }
      checked={checked}
      handleCheckbox={handleCheckbox}
      radio={radio}
      handleRadio={handleRadio}
      range={range}
      handleRange={handleRange}
      radioGroup={radioGroup}
      handleRadioGroup={handleRadioGroup}
      checkboxGroup={checkboxGroup}
      handleCheckboxGroup={handleCheckboxGroup}
      popup={popup}
      setPopup={setPopup}
      handleToast={handleToast}
      date={date}
      setDate={setDate}
      resetUser={resetUser}
      resetLoading={resetLoading}
      setResetLoading={setResetLoading}
    />
  );
};

type Props = {
  name?: string
}
