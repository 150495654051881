import * as React from "react";
import { useContentApi } from "~/hooks/useContentApi";
import { usePatientApi } from "~/hooks/usePatientApi";
import { PatientContext } from "~/providers/PatientProvider";

export const withCookiesNotification = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "CookiesNotification", ...props }: Props) => {
  Component.displayName = name;
  const { auth, memberMetadata, handleMemberMetadata, saving, setSaving } = React.useContext(PatientContext);
  const { patientUpdateMetadata } = usePatientApi();
  const { getMemberMetadata } = useContentApi();
  const [accepted, setAccepted] = React.useState<boolean>(false);

  const handleAccept = async () => {
    if (setSaving) {
      setSaving(true);
    }
    if (setAccepted) {
      setAccepted(true);
    }
    const res = await patientUpdateMetadata({
      acceptedCookies: true,
    });
    if (res.success) {
      const _metadata = await getMemberMetadata();
      if (handleMemberMetadata) {
        handleMemberMetadata(_metadata);
      }
      
    }
  };

  return (
    <Component
      { ...props as P & Props }
      active={(auth && memberMetadata && !memberMetadata?.patient_preferences?.acceptedCookies) && !accepted && !saving}
      handleAccept={handleAccept}
    />
  );
};

type Props = {
  name?: string
}
