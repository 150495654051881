import * as React from "react";
import { withRouter } from "react-router-dom";

import * as Providers from "~/providers";

/**
 * Scroll to top on route change otherwise mobile gets very confusing for the user
 */
export const RouteChanger = withRouter(({ history }) => {
  const modalContext = React.useContext(Providers.ModalContext);

  React.useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      window.scrollTo(0, 0);
      window.prevRoute = pathname;
      modalContext.toggleModal(undefined);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
});

declare global {
  interface Window {
    prevRoute: string;
  }
}
