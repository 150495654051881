import { sub } from "date-fns";

export const useDateRange = (): {
  values: {
    [key in DATE_RANGE]: {
      label: string;
      start: Date;
      end: Date;
    }
  };
  options: Array<{
    label: string;
    value: DATE_RANGE;
  }>;
  default: {
    label: string;
    start: Date;
    end: Date;
  }
} => {
  const values: {
    [key in DATE_RANGE]: {
      label: string;
      start: Date;
      end: Date;
    }
  } = {
    [DATE_RANGE.PAST_7_DAYS]: {
      label: "Past 7 days",
      start: sub(new Date(), {
        days: 7,
      }),
      end: new Date(),
    },
    [DATE_RANGE.PAST_30_DAYS]: {
      label: "Past 30 days",
      start: sub(new Date(), {
        days: 30,
      }),
      end: new Date(),
    },
    [DATE_RANGE.PAST_3_MONTHS]: {
      label: "Past 3 months",
      start: sub(new Date(), {
        months: 3,
      }),
      end: new Date(),
    },
    [DATE_RANGE.PAST_6_MONTHS]: {
      label: "Past 6 months",
      start: sub(new Date(), {
        months: 6,
      }),
      end: new Date(),
    },
    [DATE_RANGE.PAST_12_MONTHS]: {
      label: "Past 12 months",
      start: sub(new Date(), {
        months: 12,
      }),
      end: new Date(),
    },
    [DATE_RANGE.ALL_TIME]: {
      label: "All time",
      start: new Date(2016, 0, 1),
      end: new Date(),
    },
  };
  const options:Array<{
    label: string;
    value: DATE_RANGE;
  }> = (Object.keys(values) as Array<keyof typeof values>).map((key) => ({
    label: values[key].label,
    value: key,
  }));

  return {
    values: values,
    options: options,
    default: values[DATE_RANGE.DEFAULT],
  };
};

export enum DATE_RANGE {
  DEFAULT = "past_30_days",
  PAST_7_DAYS = "past_7_days",
  PAST_30_DAYS = "past_30_days",
  PAST_3_MONTHS = "past_3_months",
  PAST_6_MONTHS = "past_6_months",
  PAST_12_MONTHS = "past_12_months",
  ALL_TIME = "all_time",
}
