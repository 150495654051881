import tw, { styled } from "twin.macro";

export const SwitchRow = tw.button`flex items-center py-1.5 cursor-pointer outline-none focus:outline-none w-full`;
export const SwitchBackground = styled.div(({ active }: Props) => [
  tw`rounded h-4 w-8 border border-pink-light relative transition`,
  ...(active ? [tw`bg-pink`] : [tw`bg-pink-light bg-opacity-50`]),
]);
export const SwitchForeground = styled.div(({ active }: Props) => [
  tw`rounded h-3.5 w-3.5 bg-white absolute transition-transform transform shadow left-0`,
  ...(active ? [tw`translate-x-4`] : [tw``]),
]);
export const SwitchLabel = styled.label(() => [
  tw`text-blue-navy ml-2 text-xs cursor-pointer`,
]);

type Props = {
  active: boolean
}
