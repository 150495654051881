import * as React from "react";
import { Route, RouteChildrenProps, Switch, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import * as Sentry from "@sentry/react";

import * as Utils from "~/utils";
import * as Providers from "~/providers";
import * as Styled from "./styledHpLayout";
import * as Components from "~/components";

import Logo from "~/../assets/icons/logo.svg";

export const HpLayout: React.FunctionComponent<HpLayoutProps> = (props) => {
  const location = useLocation();

  return (
    <Providers.HpProvider>
      <Components.Container
        maxWidth="md"
        spacing="none"
      >
        <Styled.Header>
          <Styled.StyledLink
            to="/"
          >
            <Logo
              width="220px"
            />
          </Styled.StyledLink>
        </Styled.Header>
        <Components.Card
          shadow="md:default"
          spacing="md"
        >
          <Components.HPHeader />
          <Styled.RouteWrapper>
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                classNames="slide"
                timeout={600}
              >
                <Switch
                  location={location}
                >
                  {Utils.hpRoutes.map((route) => (
                    <Route
                      path={`${props.match?.url}${route.path.replace("/hp", "")}`}
                      exact
                      key={route.path}
                    >
                      <div>
                        <Sentry.ErrorBoundary
                          fallback={(props) => <Components.ErrorDetail
                            {...props}
                                               />}
                          beforeCapture={(scope) => {
                            scope.setTag("route", route.path);
                          }}
                        >
                          {/*// @ts-expect-error todo: fix route props */}
                          <route.component />
                        </Sentry.ErrorBoundary>
                      </div>
                    </Route>
                  ))}
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </Styled.RouteWrapper>
        </Components.Card>
      </Components.Container>
    </Providers.HpProvider>
  );
};

export type HpLayoutProps = RouteChildrenProps;
