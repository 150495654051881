import * as React from "react";
import { TempButton, Wrapper, TextWrapper, Celcius, Temp } from "./styledTemperaturePicker";

export const TemperaturePicker: React.FC<Props> = ({ value, setValue }) => (
  <Wrapper>
    <TempButton
      type="button"
      onClick={() => setValue(value - 1)}
    >
      <svg
        fill="none"
        width="12"
        height="4"
        viewBox="0 0 12 4"
      >
        <path
          fill="#fff"
          d="M4.9697.8125H1.1875C.531662.8125 0 1.34416 0 2s.531662 1.1875 1.1875 1.1875h9.625C11.4683 3.1875 12 2.65584 12 2S11.4683.8125 10.8125.8125H4.9697z"
        />
      </svg>
    </TempButton>
    <TextWrapper>
      <Temp>
        {`${(value / 10).toFixed(1)}`}
      </Temp>
      <Celcius>
        {"°C"}
      </Celcius>
    </TextWrapper>
    <TempButton
      type="button"
      onClick={() => setValue(value + 1)}
    >
      <svg
        fill="none"
        width="12"
        height="12"
        viewBox="0 0 12 12"
      >
        <path
          fill="#fff"
          d="M4.8 4.8H1.2C.537258 4.8 0 5.33726 0 6s.537257 1.2 1.2 1.2h3.6v3.6c0 .6627.53726 1.2 1.2 1.2s1.2-.5373 1.2-1.2V7.2h3.6c.6627 0 1.2-.53726 1.2-1.2s-.5373-1.2-1.2-1.2H7.2V1.2C7.2.537258 6.66274 0 6 0S4.8.537257 4.8 1.2v3.6z"
        />
      </svg>
    </TempButton>
  </Wrapper>
);

type Props = {
  value: number
  setValue: React.Dispatch<React.SetStateAction<number>>
}