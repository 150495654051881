import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";
import { Form } from "../Styled";

export const TopicButton = styled(Link)(() => [
  tw`w-full bg-white text-pink block rounded-sm py-4 px-6 transition text-center shadow relative hover:(bg-pink text-white) focus:(outline-none) active:(shadow-lg)`,
]);

export const SearchForm = styled(Form)(() => [tw`relative`]);
export const IconWrapper = tw.div`absolute left-0 inset-y-0 flex items-center pl-6`;
export const SuggestionList = styled.ul(({ active }: { active: boolean }) => [
  tw`bg-white pt-8 -mt-8 absolute left-0 right-0 z-5 shadow rounded-b-sm overflow-hidden`,
  ...(active ? [tw`visible`] : [tw`invisible`]),
]);
export const SuggestionListItem = tw.li``;
export const Suggestion = tw.button`w-full text-left py-2 px-4 text-sm hover:bg-grey-light`;
