import * as React from "react";

export const useArticleQuotes = (): {
  active: number
  handleSlider: (slide: number) => void
} => {
  const [active, setActive] = React.useState<number>(0);

  const handleSlider = (slide: number) => setActive(slide);

  return {
    active,
    handleSlider,
  };
};
