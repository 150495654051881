import * as React from "react";
import { format } from "date-fns";

import { withPDF } from "./withPDF";

import * as Hooks from "~/hooks";
import * as Providers from "~/providers";
import * as Styled from "./styledPDF";
import * as Components from "~/components";

import IconArrowRight from "~/../assets/icons/date-arrow.svg";
import Logo from "~/../assets/icons/logo.svg";

export const PDF = withPDF(({
  handleSymptoms, handleInformation, handleDownload, checkedSymptoms, checkedInformation, symptomData, informationData, setPdfModal, pdfModal, ...props
}: Props) => {
    return (
      <Components.Container>
        <Components.Row>
          <Components.Column>
            <Components.TextVariation
              variation="heading1"
            >
              {"PDF Summary"}
            </Components.TextVariation>
          </Components.Column>
        </Components.Row>
        <Styled.HeaderOuter
          active={props.datePicker}
        >
          <Styled.SymptomsHeader
            ref={props.stickyHeader}
            stuck={props.headerStuck}
          >
            <Components.Row>
              <Components.Column>
                {props.headerStuck ? null : (
                  <Components.H4>Date range:</Components.H4>
                )}
              </Components.Column>
            </Components.Row>
            <Components.Row>
              {props.headerStuck ? (
                <Components.Column>
                  <Components.P
                    colour="blueNavy"
                    weight="semibold"
                    spacing="none"
                  >
                    {props.auth ? `${props.auth.patient_details.first_name}'s Tracker` : ""}
                  </Components.P>
                  <Components.Row
                    spacing="none"
                  >
                    <Components.Column>
                      <Styled.DateText
                        onClick={() => props.setDatePicker((prev) => !prev)}
                      >
                        {format(props.fromDate, "dd MMMM yyyy")}
                      </Styled.DateText>
                      <Styled.ArrowWrapper>
                        <IconArrowRight
                          width="14"
                          style={{ display: "inline-block" }}
                        />
                      </Styled.ArrowWrapper>
                      <Styled.DateText
                        onClick={() => props.setDatePicker((prev) => !prev)}
                      >
                        {format(props.toDate, "dd MMMM yyyy")}
                      </Styled.DateText>
                    </Components.Column>
                  </Components.Row>
                </Components.Column>
              ) : (
                <Components.Column
                  shrink
                >
                  <Styled.DateRow>
                    <Styled.DateLabel>From:</Styled.DateLabel>
                    <Styled.DateText
                      onClick={() => props.setDatePicker((prev) => !prev)}
                    >
                      {format(props.fromDate, "dd MMMM yyyy")}
                    </Styled.DateText>
                  </Styled.DateRow>
                  <Styled.DateRow>
                    <Styled.DateLabel>To:</Styled.DateLabel>
                    <Styled.DateText
                      onClick={() => props.setDatePicker((prev) => !prev)}
                    >
                      {format(props.toDate, "dd MMMM yyyy")}
                    </Styled.DateText>
                  </Styled.DateRow>
                </Components.Column>
              )}
              <Components.Column
                justify="end"
                grow
              >
                <Components.DateButton
                  active={props.datePicker}
                  onClick={() => props.setDatePicker((prev) => !prev)}
                />
              </Components.Column>
              <Styled.RelativeContainer>
                <Styled.CalendarWrapper
                  active={props.datePicker}
                >
                  <Components.Calendar
                    date={[props.fromDate, props.toDate]}
                    minDate={new Date(2000, 0, 1)}  // Backend does not accept dates earlier than 2000
                    handleDate={(val) => {
                      if (props.dateRange === 0) {
                        props.setFromDate(val as Date);
                        props.setDateRange(1);
                      } else if (props.dateRange === 1) {
                        props.setToDate(val as Date);
                        props.setDateRange(0);
                        props.setDatePicker(false);
                      }
                    }}
                    activeDates={props.activeDates}
                  />
                </Styled.CalendarWrapper>
              </Styled.RelativeContainer>
            </Components.Row>
          </Styled.SymptomsHeader>
        </Styled.HeaderOuter>
        <Components.Section
          background="white"
          margin="negative"
          padding="sm"
        >
          <Components.Row>
            <Components.Column
              xs="1/2"
            >
              <Components.H4
                spacing="none"
              >
                Select details to include in summary
              </Components.H4>
            </Components.Column>
          </Components.Row>
          <Components.HorizontalRule
            spacing="row"
          />
          <Components.Row
            spacing="xs"
          >
            <Components.Column>
              <Components.H6
                spacing="xs"
              >
                Symptoms to include
              </Components.H6>
            </Components.Column>
            <Components.Column>
              <Components.NewCheckboxGroup
                checked={checkedSymptoms}
                onChange={handleSymptoms}
                data={symptomData}
              />
            </Components.Column>
          </Components.Row>
          <Components.Row
            spacing="sm"
          >
            <Components.Column>
              <Components.H6
                spacing="xs"
              >
                {"Information to Include"}
              </Components.H6>
            </Components.Column>
            <Components.Column>
              <Components.NewCheckboxGroup
                checked={checkedInformation}
                onChange={handleInformation}
                data={informationData}
              />
            </Components.Column>
          </Components.Row>
          <Components.Row>
            <Components.Column
              spacing="xs"
            >
              <Components.ButtonVariation
                variation="primary"
                onClick={handleDownload}
              >
                {"Download this summary"}
              </Components.ButtonVariation>
            </Components.Column>
            <Components.Column>
              <Components.P
                size="sm"
                colour="blueNavy"
                align="center"
              >
                Your chosen summary details will be emailed to your MJOT account email as a PDF document.
              </Components.P>
            </Components.Column>
          </Components.Row>
        </Components.Section>
        <Styled.CalendarBackground
          active={props.datePicker}
          onClick={() => props.setDatePicker(false)}
        />
        <Components.Modal
          active={pdfModal}
          setActive={setPdfModal}
        >
          <Components.H2>You are about to email this summary for:</Components.H2>
          <Components.Row
            spacing="xs"
          >
            <Components.Column
              justify="start"
            >
              <Styled.DateSpan>
                {format(props.fromDate, "dd MMMM yyyy")}
              </Styled.DateSpan>
              <Styled.ArrowWrapper>
                <IconArrowRight
                  width="14"
                  style={{ display: "inline-block" }}
                />
              </Styled.ArrowWrapper>
              <Styled.DateSpan>
                {format(props.toDate, "dd MMMM yyyy")}
              </Styled.DateSpan>
            </Components.Column>
          </Components.Row>
          <Components.H2>to your account email:</Components.H2>
          <Components.P
            colour="pink"
            weight="medium"
          >{props.auth?.patient_details.email}</Components.P>
          <Components.Small>
            This is your personal and confidential information.Please take care when sharing.
          </Components.Small>
          <Components.Small>
            Check with your health professional before forwarding this summary to them.
          </Components.Small>
          <Components.ButtonVariation
            variation="secondary"
            aria-label="Add note"
            onClick={() => setPdfModal(false)}
          >
            {"Edit summary"}
          </Components.ButtonVariation>
        </Components.Modal>
        <div
          id="PDFDownloadContainer"
          style={{ width: 1075, height: 1120, display: "none" }}
        >
          <Components.Container
            maxWidth="xl"
          >
            <Components.Row
              spacing="xl"
            >
              <Components.Column>
                <Logo
                  width="100px"
                />
              </Components.Column>
            </Components.Row>
            <Components.Row>
              <Components.Column
                xs="1/2"
              >
                <Components.TextVariation
                  variation="heading1"
                >
                  {"Symptoms summary"}
                </Components.TextVariation>
              </Components.Column>
              <Components.Column
                xs="1/2"
                justify="end"
              >
                <Components.Small>
                  {"for: "}
                  <Components.Strong>{`${props.auth.patient_details.first_name} ${props.auth.patient_details.last_name}`}</Components.Strong>
                </Components.Small>
              </Components.Column>
            </Components.Row>
            <Components.Row
              spacing="xl"
            >
              <Styled.DateText>
                {format(props.fromDate, "dd MMMM yyyy")}
              </Styled.DateText>
              <Styled.ArrowWrapper>
                <IconArrowRight
                  width="14"
                  style={{ display: "inline-block" }}
                />
              </Styled.ArrowWrapper>
              <Styled.DateText>
                {format(props.toDate, "dd MMMM yyyy")}
              </Styled.DateText>
            </Components.Row>
            <Components.Row
              spacing="xl"
            >
              <Components.SymptomGraphCombined />
            </Components.Row>
            {props.symptomDetails.map(({ name, count }) => (
              <Styled.EntryTitle
                key={name}
              >
                <Styled.EntryContainer>
                  <Styled.EntryNumber>
                    {count}
                  </Styled.EntryNumber>
                  <Styled.EntryText>
                    Entries
                  </Styled.EntryText>
                </Styled.EntryContainer>
                <Styled.TitleWrapper>
                  <Styled.Title>
                    {name}
                  </Styled.Title>
                </Styled.TitleWrapper>
              </Styled.EntryTitle>
            ))}
          </Components.Container>
        </div>
      </Components.Container>
    );
  });

type Props = Hooks.DashboardProps & Hooks.SymptomDataProps & {
  checkedInformation: Array<string>
  handleInformation: (val: string) => void
  checkedSymptoms: Array<string>
  handleSymptoms: (val: string) => void
  handleDownload: () => Promise<void>
  symptomData: CheckboxData
  informationData: CheckboxData
  setPdfModal: React.Dispatch<React.SetStateAction<boolean>>
  pdfModal: boolean
  auth: Providers.UserAuth
}

type CheckboxData = Array<{
  value: string
  label: string
}>
