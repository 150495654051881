import { PatientInfo, ReferralInfo } from "../providers/HpProvider";
import { API_HP_LOGIN, API_HP_REFER } from "../utils/constants";

export const useHpApi = (): {
  hpLogin: typeof hpLogin
  hpRefer: typeof hpRefer
} => {

  const hpLogin = async (data: LoginData) => await fetch(`${runtimeconfig.application_domain}${API_HP_LOGIN}`, {
    method: "POST",
    body: JSON.stringify({
      data: {
        health_professional_credentials: data,
      },
    }),
  });

  const hpRefer = async ({ patientInfo, referralInfo }: ReferData) => await fetch(`${runtimeconfig.application_domain}${API_HP_REFER}`, {
    method: "POST",
    body: JSON.stringify({
      data: {
        application_token: referralInfo.application_token,
        health_professional_referral: {
          referral_id: referralInfo.referral_id,
          referral_notification: patientInfo.activationNotification,
        },
        patient_details: {
          first_name: patientInfo.firstName,
          last_name: patientInfo.lastName,
          email: patientInfo.email,
          phone: patientInfo.mobile,
          diagnosis_ids: [[patientInfo.diagnosis]],
          diagnosis_date: patientInfo.diagnosisDate,
        },
      },
    }),
  });

  return {
    hpLogin,
    hpRefer,
  };
};

type LoginData = {
  email: string
  username: string
}

type ReferData = {
  patientInfo: PatientInfo;
  referralInfo: ReferralInfo;
}
