import * as React from "react";

export const withTextArea = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ displayName = "TextArea", ...props }: Props) => {
  Component.displayName = displayName;
  const [overlayActive, setOverlayActive] = React.useState<boolean>(false);

  const handleOverlay = (open: boolean) => {
    setOverlayActive(open);
  };

  return (
    <Component
      { ...props as P & Props }
      overlayActive={overlayActive}
      handleOverlay={handleOverlay}
    />
  );
};

type Props = {
  displayName?: string
  name?: string
  label?: string | (() => React.ReactNode)
  error?: string | React.FC | null
  hideRequired?: boolean
  valid?: boolean
  validating?: boolean
  required?: boolean
  value?: string
  placeholder?: string
  readonly?: boolean
  disabled?: boolean
  hideLabel?: boolean
  icon?: React.ReactNode
  overlayLabel?: string
  overlayButton?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}
