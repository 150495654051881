import tw, { css, styled } from "twin.macro";

import { Global, TextAligns, Colours, Weights, Spacings, Leadings, Trackings, FontSizes } from "./Global";

const styles = {
  ...Global,
  headings: tw`tracking-tight`,
  body: tw`tracking-wide`,
  small: tw`tracking-wide block w-full`,
};

export const TextStyle = styled.span(({
  color = "greyDark",
  weight = "normal",
  tracking = "body",
  size = "base",
}: {
  color?: Colours,
  weight?: Weights,
  tracking?: Trackings,
  size?: FontSizes,
}) => [
  styles.colour[color],
  styles.weight[weight],
  styles.tracking[tracking],
  styles.fontSize[size],
]);

export const Text = styled.span(({
  align = "left",
  colour = "greyDark",
  weight = "normal",
  spacing = "sm",
  tracking = "body",
  leading = "md",
  size = "base",
  loading,
}: Props) => [
  styles.textAlign[align],
  styles.colour[colour],
  styles.weight[weight],
  styles.spacing[spacing],
  styles.tracking[tracking],
  styles.leading[leading],
  styles.fontSize[size],
  ...(loading ? [tw`rounded-sm bg-grey-light h-5 w-1/2`] : []),
]);

export const BannerText = styled.h1(() => [
  styles.colour.greyDark,
  styles.textAlign.left,
  styles.maxWidth.xl,
  tw`text-xl xl:text-2xl leading-9 xl:leading-extraloose mx-auto`,
]);

export const H2 = styled.h2(({
  align = "left",
  colour = "blueNavy",
  weight = "semibold",
  spacing = "xs",
  leading = "none",
}: Props) => [
  styles.leading[leading],
  styles.headings,
  styles.textAlign[align],
  styles.colour[colour],
  styles.weight[weight],
  styles.spacing[spacing],
  tw`text-lg`,
]);

export const H3 = styled.h3(({
  align = "left",
  colour = "blueNavy",
  weight = "medium",
  spacing = "sm",
  leading = "none",
}: Props) => [
  styles.leading[leading],
  styles.headings,
  styles.textAlign[align],
  styles.colour[colour],
  styles.weight[weight],
  styles.spacing[spacing],
  tw`text-md`,
]);

export const H4 = styled.h4(({
  align = "left",
  colour = "blueNavy",
  weight = "semibold",
  spacing = "sm",
  leading = "none",
}: Props) => [
  styles.leading[leading],
  styles.headings,
  styles.textAlign[align],
  styles.colour[colour],
  styles.weight[weight],
  styles.spacing[spacing],
  tw`text-base`,
]);

export const H5 = styled.h5(({
  align = "left",
  colour = "blueNavy",
  weight = "medium",
  spacing = "sm",
  leading = "none",
}: Props) => [
  styles.leading[leading],
  styles.headings,
  styles.textAlign[align],
  styles.colour[colour],
  styles.weight[weight],
  styles.spacing[spacing],
  tw`text-base`,
]);

export const H6 = styled.h6(({
  align = "left",
  colour = "blueNavy",
  weight = "medium",
  spacing = "sm",
  leading = "none",
}: Props) => [
  styles.leading[leading],
  styles.headings,
  styles.textAlign[align],
  styles.colour[colour],
  styles.weight[weight],
  styles.spacing[spacing],
  tw`text-sm`,
]);

export const P = styled.p(({
  align = "left",
  colour = "greyDark",
  weight = "normal",
  spacing = "sm",
  tracking = "body",
  leading = "md",
  size = "base",
  loading,
}: Props) => [
  styles.textAlign[align],
  styles.colour[colour],
  styles.weight[weight],
  styles.spacing[spacing],
  styles.tracking[tracking],
  styles.leading[leading],
  styles.fontSize[size],
  ...(loading ? [tw`rounded-sm bg-grey-light h-5 w-1/2`] : []),
  css`
    ul {
      ${tw`list-disc`}
      margin: revert;
      padding: revert;
    }
  `,
]);

export const Strong = styled.strong(({
  colour = "none",
  weight = "semibold",
  spacing = "none",
}: Props) => [
  styles.colour[colour],
  styles.weight[weight],
  styles.spacing[spacing],
]);

export const Small = styled.small(({
  align = "left",
  colour = "greyDark",
  spacing = "sm",
  leading = "xs",
  weight = "normal",
}: Props) => [
  styles.small,
  styles.textAlign[align],
  styles.colour[colour],
  styles.spacing[spacing],
  styles.leading[leading],
  styles.weight[weight],
  tw`text-sm`,
]);

export const MediumSmall = styled.small(({
  align = "left",
  colour = "greyDark",
  spacing = "sm",
  leading = "xs",
  weight = "normal",
}: Props) => [
  styles.small,
  styles.textAlign[align],
  styles.colour[colour],
  styles.spacing[spacing],
  styles.leading[leading],
  styles.weight[weight],
  tw`text-mdsm`,
]);

export const ExtraSmall = styled.small(({
  align = "left",
  colour = "greyDark",
  spacing = "sm",
  leading = "none",
}: Props) => [
  styles.small,
  styles.textAlign[align],
  styles.colour[colour],
  styles.leading[leading],
  styles.spacing[spacing],
  tw`text-xs`,
]);

export const RichText = styled.div(({
  align = "left",
  colour = "greyDark",
  weight = "normal",
  spacing = "none",
}: Props) => [
  styles.body,
  styles.textAlign[align],
  styles.colour[colour],
  styles.weight[weight],
  styles.spacing[spacing],
  tw`text-base`,
  css`
    > div > * {
      ${tw`mb-6 last:mb-0`}
    }

    p {
      ${tw`text-mdsm md:text-base text-grey-dark tracking-wide mb-6`}
    }

    ol,
    ul {
      ${tw`list-none mb-6`}
    }

    ol {
      counter-reset: item;
      ${tw`print:(list-decimal)`}
      li {
        ${tw`relative pl-4.5 print:(pl-0)`}
        counter-increment: item;
        &::before {
          content: counter(item) ".";
          ${tw`text-pink font-semibold  absolute left-0`}
        }
      }
    }

    ul {
      ${tw`print:(list-disc)`}
      li {
        ${tw`relative pl-4.5 print:(pl-0)`}
        &::before {
          content: "";
          ${tw`h-1.5 w-1.5 mt-2 bg-pink rounded-full absolute left-0`}
        }
      }
    }

    li {
      ${tw`mb-3 last:mb-0`}
    }

    a {
      ${tw`text-pink hover:(underline)`}
    }

    .glossary {
      ${tw`border-b border-pink border-dashed cursor-help print:(border-b-0)`}
    }
  `,
]);

export const Pre = styled.div(() => [
  tw`bg-white rounded-sm break-words text-sm overflow-auto max-w-full`,
]);

export const List = styled.ul(() => [
  tw``,
]);

export const ListItem = styled.li(() => [
  tw`ml-4.5 mb-4`,
]);

type Props = {
  loading?: boolean;
  align?: TextAligns;
  colour?: Colours;
  weight?: Weights;
  spacing?: Spacings;
  leading?: Leadings;
  tracking?: Trackings;
  size?: FontSizes;
}
