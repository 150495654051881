import * as React from "react";
import { AlertContainer } from "./styledArticleAlert";

import * as Components from "~/components";

export const ArticleAlert: React.FC<Props> = (props) => {
  if (!props.title || !props.body) {
    return null;
  }

  return (
    <AlertContainer>
      <Components.TextVariation
        variation="heading2"
        spacing="ogxxs"
        align="center"
        color="white"
      >
        {props.title}
      </Components.TextVariation>
      <Components.TextContainer>
        <Components.TextVariation
          variation="paragraph2"
          align="center"
          color="white"
        >
          {props.body}
        </Components.TextVariation>
      </Components.TextContainer>
    </AlertContainer>
  );
};

type Props = {
  title: string
  body: string
}
