import tw, { styled } from "twin.macro";
import { Global, Spacings } from "./Global";

const styles = {
  ...Global,
  space: {
    "none": tw``,
    "2": tw`space-x-2`,
    "4": tw`space-x-4`,
    "6": tw`space-x-6`,
  },
};

export const Box = styled.div(({
  spacing = "none",
  space = "none",
  inline,
  flex,
  flexDirection,
  flexWrap,
  justifyContent,
  justifySelf,
  alignItems,
  fullWidth,
  fullHeight,
  textAlign,
}: BoxProps) => [
  styles.spacing[spacing],
  styles.space[space],
  ...inline ? [tw`inline-block`] : [],
  ...flex || flexDirection || flexWrap || justifyContent || alignItems ? [tw`flex`] : [],
  ...flexDirection === "row" ? [tw`flex-row`] : [],
  ...flexDirection === "col" ? [tw`flex-col`] : [],
  ...justifyContent === "start" ? [tw`justify-start`] : [],
  ...justifyContent === "end" ? [tw`justify-end`] : [],
  ...justifyContent === "center" ? [tw`justify-center`] : [],
  ...justifyContent === "between" ? [tw`justify-between`] : [],
  ...justifySelf === "start" ? [tw`justify-self-start`] : [],
  ...justifySelf === "end" ? [tw`justify-self-end`] : [],
  ...justifySelf === "center" ? [tw`justify-self-center`] : [],

  ...alignItems === "center" ? [tw`items-center`] : [],
  ...flexWrap ? [tw`flex-wrap`] : [],
  ...fullWidth ? [tw`w-full`] : [],
  ...fullHeight ? [tw`h-full`] : [],
  ...textAlign === "center" ? [tw`text-center`] : [],
  ...textAlign === "left" ? [tw`text-left`] : [],
  ...textAlign === "right" ? [tw`text-right`] : [],
]);

type BoxProps = {
  spacing?: Spacings;
  space?: keyof typeof styles.space;
  inline?: boolean;
  flex?: boolean;
  flexDirection?: "row" | "col";
  flexWrap?: boolean;
  justifyContent?: "start" | "end" | "center" | "between";
  justifySelf?: "start" | "end" | "center";
  alignItems?: "center";
  fullWidth?: boolean;
  fullHeight?: boolean;
  textAlign?: "center" | "left" | "right";
};
