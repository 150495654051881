import { getDaysInMonth } from "date-fns";
import * as React from "react";

import * as Components from "~/components";

import { InputWrapper, Label, StyledInput, InputError, InputValidation, DateFieldWrapper } from "../Styled";
import { useDateInput } from "./useDateInput";

export const DateInput: React.FunctionComponent<Props> = (props) => {
  const { day, month, year, setDay, setMonth, setYear } = useDateInput({ onChange: props.onChange, externalDate: props.value });

  const [validationError, setValidationError] = React.useState<Error>();

  React.useEffect(() => {
    if (typeof year !== "undefined" && typeof month !== "undefined" && typeof day !== "undefined") {
      const date = new Date(year, month, day);

      if (props.min) {
        if (date < props.min || year < props.min.getFullYear()) {
          setValidationError(new Error(`Date must be after ${props.min.toLocaleDateString()}`));
          return;
        }
      }
      if (props.max) {
        if (date > props.max) {
          setValidationError(new Error(`Date must be before ${props.max.toLocaleDateString()}`));
          return;
        }
      }
    }
    setValidationError(undefined);
  }, [day, month, year]);

  React.useEffect(() => {
    if (props.hideDayInput) {
      setDay(1);
    }
  }, [props.hideDayInput]);

  return (
    <InputWrapper>
      {props.label ?
        typeof props.label === "string" ? (
          <Label
            hidden={props.hideLabel}
          >
            <Components.TextVariation
              variation="label1"
              spacing="ogxxxxxs"
              fullWidth
            >
              {`${props.label}${props.hideRequired ? "" : props.required ? " *" : " (optional)"}`}
            </Components.TextVariation>
          </Label>
        ) : props.label()
      : null}
      <DateFieldWrapper>
        {props.hideDayInput !== true && (
          <React.Fragment>
            <StyledInput
              error={props.error || validationError?.message}
              type={"number"}
              value={day ? `${`${day}`.length === 1 ? "0" : ""}${day}` : undefined}
              placeholder={"DD"}
              readOnly={props.readonly}
              disabled={props.disabled}
              onBlur={props.onBlur}
              max={year && month ? getDaysInMonth(new Date(year, month)) : 31}
              min={1}
              name={props.name}
              onChange={(e) => {
                const val = Number(e.target.value);
                setDay(val === 0 ? undefined : val);
              }}
              aria-label={props.ariaLabel ?? (typeof props.label === "string" ? props.label : undefined)}
            />
            <Components.TextVariation
              variation="paragraph1"
              color="greyDark"
            >
              {"/"}
            </Components.TextVariation>
          </React.Fragment>
        )}
        <StyledInput
          error={props.error || validationError?.message}
          type={"number"}
          value={month !== undefined && month > 11 ? 12 : month !== undefined && `${month}`.length > 0 ? `${`${month + 1}`.length === 1 ? "0" : ""}${month + 1}` : undefined}
          placeholder={"MM"}
          readOnly={props.readonly}
          disabled={props.disabled}
          onBlur={props.onBlur}
          max={12}
          min={1}
          name={props.name}
          onChange={(e) => {
            const val = Number(e.target.value) - 1;
            setMonth(val === -1 ? undefined : val);
          }} 
          aria-label={props.ariaLabel ?? (typeof props.label === "string" ? props.label : undefined)}
        />
        <Components.TextVariation
          variation="paragraph1"
          color="greyDark"
        >
          {"/"}
        </Components.TextVariation>
        <StyledInput
          error={props.error || validationError?.message}
          type={"number"}
          value={year ? year : ""}
          placeholder={"YYYY"}
          readOnly={props.readonly}
          disabled={props.disabled}
          onBlur={props.onBlur}
          max={props.max?.getFullYear() ?? 9999}
          min={props.min?.getFullYear() ?? 0}
          name={props.name}
          onChange={(e) => {
            const val = Number(e.target.value);
            setYear(val === 0 ? undefined : val);
          }}
          aria-label={props.ariaLabel ?? (typeof props.label === "string" ? props.label : undefined)}
        />
      </DateFieldWrapper>
      {(props.error || validationError) && !props.validating ? (
        <InputError>
          {props.error ? props.error : validationError?.message}
        </InputError>
      ) : null}
      {props.validating ? (
        <InputValidation>
          Validating...
        </InputValidation>
      ) : null}
    </InputWrapper>
  );
};

type Props = {
  name?: string
  label?: string | (() => React.ReactNode)
  ariaLabel?: string
  error?: JSX.Element | string | undefined;
  hideRequired?: boolean
  valid?: boolean
  validating?: boolean
  required?: boolean
  readonly?: boolean
  disabled?: boolean
  hideLabel?: boolean
  min?: Date
  max?: Date
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (date: Date) => void;
  value?: Date;
  hideDayInput?: boolean;
}
