import * as React from "react";
import { DateInput } from "~/components/DateInput/DateInput";
import { Input } from "~/components/Input/Input";
import { Meta } from "~/components/Meta/Meta";
import { Form } from "~/components/Styled";
import { USER_MY_ACCOUNT } from "~/utils/constants";
import { withPersonalDetails } from "./withPersonalDetails";

import * as Components from "~/components";

export const PersonalDetails = withPersonalDetails(({
  firstNameError, lastNameError, dateOfBirthError, postcodeError, firstName, lastName, dob, postcode, changed, loading,
  handleFirstName, handleLastName, handleDateOfBirth, handleSubmit, handlePostcode, formValid,
}: Props) => (
  <Components.AppLayout
    headerVariation="navigationBack"
  >
    <Meta
      title="Update your profile details"
    />
    <Components.Row>
      <Components.Column
        spacing="og2"
      >
        <Components.TextVariation
          variation="heading1"
          spacing="og1"
        >
          {"Update your profile details"}
        </Components.TextVariation>
      </Components.Column>
    </Components.Row>
    <Form
      onSubmit={handleSubmit}
      aria-label="Personal details form"
    >
      <Components.Grid
        cols="1"
        colsMd="2"
        gapX="4"
        gapY="6"
        spacing="og6"
      >
        <Input
          label="First name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFirstName(e.target.value)}
          error={firstNameError}
          value={firstName}
          required
        />
        <Input
          label="Last name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLastName(e.target.value)}
          error={lastNameError}
          value={lastName}
          required
        />
        <DateInput
          label="Date of birth"
          onChange={handleDateOfBirth}
          error={dateOfBirthError}
          value={dob}
          max={new Date()}
          required
        />
        <Input
          label="Postcode"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePostcode(e.target.value)}
          error={postcodeError}
          value={postcode}
          required
        />
      </Components.Grid>
      <Components.Grid
        cols="1"
        colsMd="2"
        gapX="4"
        gapY="6"
      >
        <Components.ButtonVariation
          variation="secondary"
          href={USER_MY_ACCOUNT}
          aria-label="Cancel"
          fullWidth
        >
          {"Cancel"}
        </Components.ButtonVariation>
        <Components.ButtonVariation
          variation="primary"
          disabled={!formValid || !changed || loading}
          aria-label="Save"
          fullWidth
        >
          {loading ? "Updating..." : "Confirm & save"}
        </Components.ButtonVariation>
      </Components.Grid>
    </Form>
  </Components.AppLayout>
));

type Props = {
  handleFirstName: (value: string) => void
  handleLastName: (value: string) => void
  handleDateOfBirth: (date: Date) => void
  handlePostcode: (value: string) => void
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  firstName: string
  lastName: string
  dob: Date
  postcode: string
  firstNameError: FormError
  lastNameError: FormError
  dateOfBirthError: FormError
  postcodeError: FormError
  changed: boolean
  loading: boolean
  formValid: boolean;
}

type FormError = JSX.Element | string | undefined
