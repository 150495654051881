import tw, { styled } from "twin.macro";

export const TabHeader = tw.div`flex border-b z-50 relative bg-pink-light -mx-4 px-4 md:(-mx-20 px-20)`;

export const TabItem = styled.button(({ active }: { active?: boolean }) => [
  tw`w-full py-4 border-b-4 font-medium text-18 text-blue-navy transition outline-none focus:outline-none`,
  ...(active ? [tw`border-blue-navy opacity-100`] : [tw`border-transparent opacity-70`]),
]);

export const TabContent = tw.div``;
