import { withTrackerWelcome } from "./withTrackerWelcome";
import { SpeechBubble } from "~/components/SpeechBubble/SpeechBubble";

import * as Components from "~/components";

export const TrackerWelcome = withTrackerWelcome(({ handleContinue, loading }: Props) => (
  <Components.AppLayout
    headerVariation="navigationUser"
  >
    <Components.TextVariation
      variation="heading1"
    >
      {"Welcome to the BCNA My Journey Tracker"}
    </Components.TextVariation>
    <Components.Row
      spacing="sm"
    >
      <Components.Column>
        <SpeechBubble>
          <Components.P
            colour="pink"
            weight="medium"
            spacing="none"
          >
            {"Track your symptoms and take notes to help you rememberand feel well prepared for your appointments."}
          </Components.P>
        </SpeechBubble>
      </Components.Column>
    </Components.Row>
    <Components.Row
      spacing="xl"
    >
     <Components.Column
       spacing="xl"
     >
      <Components.P
        spacing="xxs"
      >
        {"Information provided on your personal tracker is for general information and does not take the place of medical advice."}
      </Components.P>
      <Components.P
        spacing="xxs"
      >
        {"By using this tracker, you accept the:"}
      </Components.P>
      <Components.P
        spacing="lg"
      >
        <Components.StyledHyperlink
          underlined
          href="https://www.bcna.org.au/my-journey-terms-of-use/"
        >
          My Journey Online tool Terms of Use
        </Components.StyledHyperlink>
      </Components.P>
      <Components.ButtonVariation
        variation="primary"
        onClick={() => handleContinue()}
        disabled={loading}
      >
        {loading ? "Loading tracker" : "Continue"}
      </Components.ButtonVariation>
     </Components.Column>
    </Components.Row>
  </Components.AppLayout>
));

type Props = {
  handleContinue: () => void
  loading: boolean
}
