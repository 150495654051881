import * as React from "react";
import ReactDOM from "react-dom";
import { ButtonExtraSmall } from "../Styled";
import { Content, Footer, Header, Overlay, OverlayTitle, CloseButton, Inner, ModalBackground } from "./styledTextArea";
import CloseIcon from "~/../assets/icons/cross.svg";

export const TextAreaOverlay: React.FC<Props> = ({
  overlayLabel, value, placeholder, active, handleOverlay, onChange,
  overlayButton = "Add to symptom",
}) => {
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  React.useEffect(() => { 
    if (active === true) {
      textAreaRef.current?.focus();
    } else {
      textAreaRef.current?.blur();
    }
  }, [active]);
  return ReactDOM.createPortal(
    (
      <Overlay
        active={active}
      >
        <ModalBackground
          active={active}
        />
        <Inner>
          <Header>
            <OverlayTitle>
              {overlayLabel}
            </OverlayTitle>
            <CloseButton
              onClick={() => handleOverlay(false)}
            >
              <CloseIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
              />
            </CloseButton>
          </Header>
          <Content
            ref={textAreaRef}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
          <Footer>
            <ButtonExtraSmall
              onClick={() => handleOverlay(false)}
            >
              {overlayButton}
            </ButtonExtraSmall>
          </Footer>
        </Inner>
      </Overlay>
    ), document.querySelector("#root") as Element
  );
};

type Props = {
  overlayLabel: string
  overlayButton?: string
  active: boolean
  value?: string
  placeholder?: string
  handleOverlay: (open: boolean) => void
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}
  
