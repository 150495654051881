import tw, { styled } from "twin.macro";

export const Overlay = styled.div(({
  active,
}: {
  active: boolean
}) => [
  ...(active ? [tw`opacity-100`] : [tw`opacity-0 pointer-events-none`]),
  tw`flex bg-opacity-50 bg-white z-20 fixed inset-0 items-center justify-center transition-all duration-200`,
]);
