import * as React from "react";
import { MergedArticle } from "~/../vendor/umbraco";
import { withArticleList } from "./withArticleList";
import { ArticleGrid } from "./styledArticleList";
import { ArticleCard } from "../ArticleCard/ArticleCard";
import { getArrayRange } from "~/utils/functions";

export const ArticleList = withArticleList((props: Props) => (
  <ArticleGrid
    dir="ltr"
    ref={props.wrapperRef}
  >
    {props.articles ? (
      props.articles.map((article) => (
        <ArticleCard
          key={article.id}
          article={article}
          onClick={() => props.onClickArticle?.(article)}
        />
      ))
    ) : (
      getArrayRange(0, 7).map((i) => (
        <ArticleCard
          key={i}
        />
      ))
    )}
  </ArticleGrid>
));

type Props = {
  articles: Array<MergedArticle>
  wrapperRef: React.RefObject<HTMLDivElement>
  link?: string
  linkText?: string
  onClickArticle?: (article: MergedArticle) => void;
}
