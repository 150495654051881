import tw, { styled } from "twin.macro";
import { Container, P } from "~/components/Styled";
import { Global, Weights } from "~/components/Styled/Global";

const styles = {
  ...Global,
};

export const Header = styled(Container)(() => [
  tw`py-8 px-6 md:px-8 bg-pink-light`,
]);

export const StyledP = styled(P)(() => [
  tw`whitespace-nowrap`,
]);

export const StyledH1 = styled.h1(() => [
 tw`text-2xl leading-10`,
]);

export const Section = tw.section`bg-white px-6 pt-11 pb-2`;

export const StickyButtonWrapper = styled.div(() => [
  tw`fixed shadow-lg bottom-0 inset-x-0 bg-white p-4 flex md:(static shadow-none border-t border-pink-light px-0 py-6) z-40`,
]);

export const StyledButton = styled.button(({ weight = "medium" }: { weight?: Weights }) => [
  tw`text-pink focus:outline-none`,
  styles.weight[weight],
]);
