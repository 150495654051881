import * as React from "react";

import * as Components from "~/components";

export const NoteForm: React.FunctionComponent<NoteFormProps> = ({
  handleSubmit,
  setTitle,
  title,
  handleText,
  text,
  setImageIds,
  error,
  disabled,
  textError,
}) => (
<Components.Form
  onSubmit={handleSubmit}
>
  <Components.Row>
    <Components.Column
      spacing="md"
    >
      <Components.Input
        label="Add a title"
        placeholder="Title..."
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
        value={title}
        hideRequired
      />
    </Components.Column>
    <Components.Column
      spacing="md"
    >
      <Components.TextArea
        label="Add your notes"
        placeholder="Notes..."
        required
        hideRequired
        value={text}
        error={textError}
        onChange={(e) => handleText(e.target.value)}
      />
    </Components.Column>
    <Components.Column
      spacing="md"
    >
      <Components.FileInput
        placeholder="Save a photo"
        label="Your photos"
        setFileIds={setImageIds}
        allowMultiple
      />
    </Components.Column>
    {error ? (
      <Components.Column
        spacing="md"
      >
        <Components.ErrorContainer
          message={error}
        />
      </Components.Column>
    ) : null}
    <Components.Column
      spacing="xs"
    >
      <Components.ButtonVariation
        variation="primary"
        type="submit"
        disabled={disabled}
        fullWidth
      >
        {"Save note"}
      </Components.ButtonVariation>
    </Components.Column>
  </Components.Row>
</Components.Form>
);

export type NoteFormProps = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  handleText: (value: string) => void
  setTitle: React.Dispatch<React.SetStateAction<string>>
  title: string
  text: string
  setImageIds: React.Dispatch<React.SetStateAction<Array<string>>>
  error: string | null
  disabled?: boolean
  textError: string | null
}
