/** HP (Health Professional) routes */
export const HP_INTRO = "/hp/intro";
export const HP_EXPLORE = "/hp/explore";
export const HP_LOGIN = "/hp/login";
export const HP_STEP_1 = "/hp/step1";
export const HP_STEP_2 = "/hp/step2";
export const HP_STEP_3 = "/hp/step3";
export const HP_STEP_4 = "/hp/step4";
export const HP_STEP_5  = "/hp/step5";

export const HP_REGISTER_EXTERNAL = "https://www.bcna.org.au/about-us/information-for-health-professionals/register-as-a-health-professional";
export const BCNA_PRIVACY_POLICY = "https://www.bcna.org.au/legal-information/privacy-policy";
export const BCNA_TERMS_OF_USE = "https://www.bcna.org.au/my-journey-terms-of-use";
export const FACEBOOK_URL = "https://www.facebook.com/BreastCancerNetworkAustralia";
export const INSTAGRAM_URL = "https://www.instagram.com/bcnapinklady";

/** User routes */
export const USER_FEED = "/feed";
export const USER_DASHBOARD = "/dashboard";
export const USER_LOGIN = "/login";
export const USER_MY_ACCOUNT = "/user/myaccount";
export const USER_ACCOUNT_DETAILS = "/user/account-details";
export const USER_PERSONAL_DETAILS = "/user/personal-details";
export const USER_SYMPTOMS_WELCOME = "/tracker/welcome";
export const USER_SYMPTOMS = "/tracker";
export const USER_SYMPTOMS_ADD = "/tracker/add";
export const USER_NOTES = "/notes";
export const USER_NOTES_ADD = "/notes/add";
export const USER_PDF = "/tracker/pdf";
export const USER_ABOUT_ME = "/user/about-me";
export const USER_DIAGNOSIS = "/user/diagnosis";
export const USER_DIAGNOSIS_SITUATION = "/user/diagnosis/situation";
export const USER_DIAGNOSIS_LOCATION = "/user/diagnosis/location";
export const USER_WELCOME = "/welcome";
export const USER_IMPORTANT_INFO = "/important";
export const USER_ARTICLES = "/articles";
export const USER_ARTICLES_SEARCH = "/articles/search/:keyword";
export const USER_ARTICLES_BOOKMARKS = "/articles/bookmarks";
export const USER_ARTICLES_TOPICS = "/articles/topics";
export const USER_STORIES = "/stories";

/** password forgot / reset routes */
export const PASSWORD_FORGOT = "/forgot-your-login-details";
export const PASSWORD_RESET = "/resetpassword";

/** signup routes */
export const SIGNUP = "/signup";
export const SIGNUP_INTRO = "/signup/:referralId?";
export const SIGNUP_SUPPORTERS = "/signup/supporters/*";
export const SIGNUP_SUPPORTERS_FRIEND_OR_FAMILY = "/signup/supporters/friend-or-family";
export const SIGNUP_SUPPORTERS_HIGH_RISK = "/signup/supporters/high-risk";
export const SIGNUP_SUPPORTERS_CHANGE_IN_MY_BREASTS = "/signup/supporters/change-in-my-breasts";
export const SIGNUP_SUPPORTERS_TESTS = "/signup/supporters/tests";
export const SIGNUP_DIAGNOSIS = "/signup/diagnosis";
export const SIGNUP_SITUATION = "/signup/situation";
export const SIGNUP_USER_SIGNUP = "/signup/create-your-account";
export const SIGNUP_ACCOUNT = "/signup/account";

/** app routes */
export const APP_HOME = "/";
export const APP_WELCOME = "/welcome";
export const APP_ABOUT = "/about";
export const APP_CHOOSE_YOUR_CATEGORIES = "/choose-your-topics";
export const APP_ARTICLES_CATEGORY = "/articles/category/:categoryId";
export const APP_ARTICLE = "/article/:articleId";
export const APP_CONFIRM_DETAILS = "/confirm-your-details";
export const APP_FAKE_ARTICLE = "/article-placeholder";
export const APP_CHANGE_DIAGNOSIS = "/update-your-diagnosis";
export const APP_CHANGE_DIAGNOSIS_SITUATION = "/update-your-diagnosis/situation";
export const APP_CHANGE_DIAGNOSIS_LOCATION = "/update-your-diagnosis/location";
export const APP_FORGOTTEN_LOGIN_DETAILS = "/forgot-your-login-details";
export const APP_RESET_PASSWORD = "/resetpassword";
export const APP_FAQS = "/faqs";
export const APP_GLOSSARY = (letter = ":letter"): string => `/glossary/${letter}`;

/** dev routes */
export const DEV_STYLEGUIDE = "/style-guide";
export const DEV_ANALYTICS = "/analytics";

/** API endpoints */
/** Umbraco content api */
export const UMBRACO_ASSET_URL = "https://api.myjourney.org.au";
export const API_GET_DIAGNOSIS_TREE = "/Categories/GetDiagnosisTree";
export const API_GET_USER_TYPES = "/Categories/GetUserTypes";
export const API_GET_MEMBER_METADATA = "/App/GetMemberMetadata";
export const API_GET_ARTICLES = "/Articles/GetArticles";
export const API_GET_CATEGORIES = "/Categories/GetCategories";
export const API_GET_ARTICLE = "/Articles/GetArticle";
export const API_SEARCH_ARTICLES_BY_KEYWORD = "/Articles/SearchArticlesByKeyword";
export const API_WELCOME_CONTENT = "/App/GetWelcomePage";
export const API_SEARCH_AUTCOMPLETE = "/Articles/AutocompleteQuery";
export const API_GET_SYMPTOMS = "/Symptom/GetSymptoms";
export const API_GET_SYMPTOM_CATEGORIES = "/Symptom/GetSymptomCategories";
export const API_GET_SYMPTOM_ARTICLES_BY_ID = "/Symptom/GetArticlesBySymptomId";

/** Symptom tracker api endpoints */
export const API_POST_ATTACHMENT_IMAGE = "/attachments/upload-image";
export const API_GET_IMAGE = "/attachments/image-content";
export const API_POST_TRACK_SYMPTOM = "/symptoms/track";
export const API_POST_SYMPTOM_EDIT = "/symptoms/update";
export const API_GET_SYMPTOM = "/symptoms/:id";
export const API_GET_SYMPTOM_MONTH = "/symptoms/get-by-month";
export const API_GET_SYMPTOM_BY_DATE = "/symptoms/get-by-date";
export const API_GET_SYMPTOM_BY_DATE_RANGE = "/symptoms/get-by-date-range";
export const API_GET_NOTES_BY_DATE_RANGE = "/notes/get-by-date-range";
export const API_POST_NOTES_ADD = "/notes/add-note";
export const API_POST_NOTES_UPDATE = "/notes/update-note";

const API_HP_PATH = "/api/health-professional";
const API_PATIENT_PATH = "/api/patient";


/** Health professional api */
export const API_HP_LOGIN = `${API_HP_PATH}/sign-in`;
export const API_HP_REFER = `${API_HP_PATH}/refer`;

/** Patient api */
export const API_PATIENT_LOGIN = `${API_PATIENT_PATH}/sign-in`;
export const API_PATIENT_SEARCH = `${API_PATIENT_PATH}/search`;
export const API_PATIENT_SIGNUP = `${API_PATIENT_PATH}/sign-up`;
export const API_PATIENT_PASSWORD_FORGOT = `${API_PATIENT_PATH}/password-forgot`;
export const API_PATIENT_PASSWORD_RESET = `${API_PATIENT_PATH}/password-reset`;
export const API_PATIENT_REFERRAL = `${API_PATIENT_PATH}/referral`;
export const API_PATIENT_UPDATE = `${API_PATIENT_PATH}/update`;

export const API_SHARE_PATH = "/api/share";

/** Used in the graphs */
export const DARK_GREY_COLOR = "#4E4E4E";
export const BLUE_COLOR = "#1C7FC0";
export const DARK_BLUE_COLOR = "#01385e";
export const GREEN_COLOR = "#3FA49E";
export const PINK_COLOR = "#DD1D80";
export const PALE_BLUE_COLOR = "#8EBFDF";
export const PALE_PINK_COLOR = "#EE8FBF";
export const DUSTY_PINK_COLOR = "#dfd4d4";
