import * as React from "react";
import tw, { styled } from "twin.macro";

import * as Components from "~/components";

import IconQuote from "~/../assets/icons/quote.svg";

const StyledIconWrapper = styled.div(() => [
  tw`text-pink`,
]);

export const Blockquote: React.FunctionComponent<BlockquoteProps> = ({ children, author }) => (
  <Components.Grid
    cols="1"
    gapY="4"
  >
    <StyledIconWrapper>
      <IconQuote />
    </StyledIconWrapper>
    <Components.TextVariation
      variation="heading1"
      color="blueNavy"
    >
      {children}
    </Components.TextVariation>
    <Components.TextVariation
      variation="paragraph1"
      color="greyDark"
    >
      {`— ${author}`}
    </Components.TextVariation>
  </Components.Grid>
);

export type BlockquoteProps = {
  author: string;
};
