import * as React from "react";
import Slider from "react-input-slider";

import { Symptom } from "myjourney-frontend/src/vendor/umbraco";

import { SymptomFormProps } from "~/hooks/useSymptomForm";

import * as Utils from "~/utils";
import * as Styled from "./styledSymptomForm";
import * as Components from "~/components";

export const SymptomForm: React.FC<Props> = (props) => {
  return (
    <>
      {props.symptom ? (
        <Components.Form
          onSubmit={props.handleSubmit}
          aria-label="Symptom form"
        >
          {props.symptom.severityRanges.length && props.symptom.severityRanges[props.symptom.severityRanges.length - 1].maxRange > 0 ? props.symptom.severityRanges[0].minRange === 0 ? (
            <Components.Column
              spacing="md"
            >
              <Components.Label>
                {"How severe is this symptom"}
              </Components.Label>
              <Slider
                axis="x"
                xstep={1}
                xmin={props.symptom.severityRanges[0].minRange}
                xmax={props.symptom.severityRanges[props.symptom.severityRanges.length - 1].maxRange}
                x={props.severity}
                onChange={props.handleSeverity}
                styles={{
                  track: {
                    backgroundColor: "#dfd4d4",
                    width: "100%",
                    height: "4px",
                  },
                  active: {
                    backgroundColor: "transparent",
                    backgroundImage: "linear-gradient(90deg, rgba(221, 29, 128, 0.5) 0%, #DD1D80 100%);",
                  },
                  thumb: {
                    width: 23,
                    height: 23,
                    border: "3px solid #DD1D80",
                    boxShadow: "var(--shadow-md)",
                  },
                }}
              />
              <Styled.SeverityLabelWrapper>
                {Utils.getArrayRange(props.symptom.severityRanges[0].minRange, props.symptom.severityRanges[props.symptom.severityRanges.length - 1].maxRange).map((value) => (
                  <Styled.RangeLabel
                    key={value}
                  >
                    {value}
                  </Styled.RangeLabel>
                ))}
              </Styled.SeverityLabelWrapper>
              <Styled.SeverityLabelWrapper>
                <Styled.SeverityLabel>{props.symptom.severityLowerLabel}</Styled.SeverityLabel>
                <Styled.SeverityLabel>{props.symptom.severityUpperLabel}</Styled.SeverityLabel>
              </Styled.SeverityLabelWrapper>
            </Components.Column>
          ) : (
            <Components.TemperaturePicker
              value={props.temperature}
              setValue={props.setTemperature}
            />
          ) : null}
          <Components.Column
            spacing="md"
          >
            <Components.TextArea
              label="Description (site, time of day, appearance)"
              placeholder="Notes..."
              hideRequired
              value={props.description}
              onChange={(e) => props.handleDescription(e.target.value)}
            />
          </Components.Column>
          <Components.Column
            spacing="md"
          >
            <Components.TextArea
              label="Actions you've taken"
              placeholder="Notes..."
              hideRequired
              value={props.actions}
              onChange={(e) => props.handleActions(e.target.value)}
            />
          </Components.Column>
          <Components.Column
            spacing="md"
          >
            <Components.TextArea
              label="Questions you have or things you need"
              placeholder="Notes..."
              hideRequired
              value={props.questions}
              onChange={(e) => props.handleQuestions(e.target.value)}
            />
          </Components.Column>
          <Components.Column
            spacing="md"
          >
            <Components.FileInput
              label="Your photos"
              placeholder="Save a photo"
              setFileIds={props.setImageIds}
              dataUrls={props.dataUrls}
              files={props.files}
              allowMultiple
            />
          </Components.Column>
          {props.error ? (
            <Components.Column
              spacing="md"
            >
              <Components.ErrorContainer
                message={props.error}
              />
            </Components.Column>
          ) : null}
          <Components.Column
            spacing="xs"
          >
            <Components.ButtonVariation
              variation="primary"
              disabled={props.saving}
              fullWidth
            >
              {props.saving ? "Saving symptom" : "Save symptom"}
            </Components.ButtonVariation>
          </Components.Column>
          <Components.Column>
            <Components.ButtonVariation
              variation="secondary"
              onClick={() => props.handleBackToDashboard()}
              fullWidth
            >
              {"Back to dashboard"}
            </Components.ButtonVariation>
          </Components.Column>
        </Components.Form>
      ) : null}
    </>
  );
};

type Props = SymptomFormProps & {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  setImageIds: React.Dispatch<React.SetStateAction<Array<string>>>
  saving: boolean
  symptom?: Symptom;
  error: string | null
  dataUrls?: Array<string>
  handleBackToDashboard: () => void
}
