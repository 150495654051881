import * as React from "react";
import { useHistory } from "react-router-dom";

import { HpContext } from "~/providers/HpProvider";
import { HP_AUTH_NOT_LOGGED_IN } from "~/utils/config";
import { HP_LOGIN } from "~/utils/constants";

export const withHpStep5 = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<P & Props> => ({ name = "HpStep5", ...props }: Props) => {
  Component.displayName = name;
  const history = useHistory();
  const { hpAuthStatus } = React.useContext(HpContext);

  React.useEffect(() => {
    if (hpAuthStatus === HP_AUTH_NOT_LOGGED_IN) {
      history.push(HP_LOGIN);
    }
  }, []);

  return (
    <Component
      { ...props as P }
    />
  );
};

type Props = {
  name?: string
}
