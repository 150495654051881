import * as React from "react";
import { PatientContext } from "~/providers/PatientProvider";
import { useAuthentication } from "~/hooks/useAuthentication";
import { useDashboard } from "~/hooks/useDashboard";
import { downloadPdf } from "~/utils/functions";
import { useSymptomData } from "~/hooks/useSymptomData";

const symptomData = [
  {
    label: "Bladder irritation",
    value: "Bladder irritation",
  },
  {
    label: "Fatigue (tiredness)",
    value: "Fatigue (tiredness)",
  },
  {
    label: "Nausea (vomiting)",
    value: "Nausea (vomiting)",
  },
  {
    label: "Anxiety",
    value: "Anxiety",
  },
  {
    label: "Difficulty concentrating",
    value: "Difficulty concentrating",
  },
  {
    label: "All",
    value: "All",
  },
];

const informationData = [
  {
    label: "Photos",
    value: "All",
  },
];

export const withPDF = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "PDF", ...props }: Props) => {
  Component.displayName = name;
  useAuthentication();
  const dashboardProps = useDashboard();
  const symptomDataProps = useSymptomData();
  const { auth } = React.useContext(PatientContext);
  const [pdfModal, setPdfModal] = React.useState<boolean>(false);
  const [checkedInformation, setCheckedInformation] = React.useState<Array<string>>(informationData.map(({ value }) => value));
  const [checkedSymptoms, setCheckedSymptoms] = React.useState<Array<string>>(symptomData.map(({ value }) => value));

  const handleDownload = () => {
    const elem = document.getElementById("PDFDownloadContainer") as HTMLDivElement;
    elem.style.display = "block";
    downloadPdf(elem);
    elem.style.display = "none";
  };

  const handleInformation = (value: string) => {
    setCheckedInformation((prev) => prev.indexOf(value) > -1 ? prev.filter((f) => f !== value) : [...prev, value]);
  };

  const handleSymptoms = (value: string) => {
    if (value === "All") {
      setCheckedSymptoms((prev) => {
        if (prev.includes("All")) {
          return [];
        } else {
          return symptomData.map(({ value }) => value);
        }
      });
    } else {
      setCheckedSymptoms((prev) => {
        return prev.indexOf(value) > -1 ? prev.filter((f) => f !== value && f !== "All") : [...prev, value].filter((f) => f !== "All");
      });
    }
  };

  return (
    <Component
      { ...props as P & Props }
      { ...dashboardProps }
      { ...symptomDataProps }
      auth={auth}
      symptomData={symptomData}
      informationData={informationData}
      handleDownload={handleDownload}
      handleSymptoms={handleSymptoms}
      handleInformation={handleInformation}
      checkedInformation={checkedInformation}
      checkedSymptoms={checkedSymptoms}
      pdfModal={pdfModal}
      setPdfModal={setPdfModal}
    />
  );
};

type Props = {
  name?: string
};
