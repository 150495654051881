import tw, { css, styled } from "twin.macro";
import { Card } from "../Styled";

export const DotContainer = tw.div`flex justify-center mb-4 space-x-2`;
export const Dot = styled.button(({ active }: DotProps) => [
  tw`rounded-full h-3 w-3 block transition focus:outline-none`,
  ...(active ? [tw`bg-pink`] : [tw`bg-pink-light`]),
]);

export const Slider = tw.div`overflow-hidden relative flex flex-col mb-4`;
export const SliderSlides = styled.div(({ slides, active }: SlidesProps) => [
  tw`flex transition-transform duration-500`,
  css`
    width: ${slides * 100}%;
    transform: translateX(-${(100 / slides) * active}%);
  `,
]);

export const SlideInner = tw.div`flex px-4 py-8 flex-grow w-full items-center`;

export const StyledCard = styled(Card)(() => [
  tw`h-full flex items-center px-4 py-8 md:(py-12 px-8)`,
]);

export const TestimonialWrapper = tw.div`bg-pink-light py-20 px-4 mb-8 -mx-4 md:(mx-0)`;

type SlidesProps = {
  slides: number
  active: number
}

type DotProps = {
  active: boolean
}
