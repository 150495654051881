import * as Utils from "../utils";
import * as Templates from "../templates";

/**
 * All routes that are under the /hp path
 */
export const hpRoutes = [
  {
    path: Utils.HP_LOGIN,
    component: Templates.HpLogin,
    exact: true,
  },
  {
    path: Utils.HP_STEP_1,
    component: Templates.HpStep1,
    exact: true,
  },
  {
    path: Utils.HP_STEP_2,
    component: Templates.HpStep2,
    exact: true,
  },
  {
    path: Utils.HP_STEP_3,
    component: Templates.HpStep3,
    exact: true,
  },
  {
    path: Utils.HP_STEP_4,
    component: Templates.HpStep4,
    exact: true,
  },
  {
    path: Utils.HP_STEP_5,
    component: Templates.HpStep5,
    exact: true,
  },
];

export const routes = [
  {
    path: Utils.APP_HOME,
    component: Templates.Home,
  },
  {
    path: Utils.APP_ABOUT,
    component: Templates.About,
    back: Utils.APP_HOME,
  },
  {
    path: Utils.APP_FAQS,
    component: Templates.Faq,
  },
  {
    path: Utils.SIGNUP_SUPPORTERS,
    component: Templates.SignUpSupporters,
  },
  {
    path: Utils.SIGNUP,
    component: Templates.SignUp,
    exact: true,
  },
  {
    path: Utils.USER_MY_ACCOUNT,
    component: Templates.MyAccount,
    exact: true,
  },
  {
    path: Utils.USER_PERSONAL_DETAILS,
    component: Templates.PersonalDetails,
    back: Utils.USER_MY_ACCOUNT,
    backText: "Cancel",
    exact: true,
  },
  {
    path: Utils.USER_ACCOUNT_DETAILS,
    component: Templates.AccountDetails,
    back: Utils.USER_MY_ACCOUNT,
    backText: "Cancel",
    exact: true,
  },
  {
    path: Utils.USER_DIAGNOSIS,
    component: Templates.Diagnosis,
    back: Utils.USER_MY_ACCOUNT,
    backText: "Cancel",
    exact: true,
  },
  {
    path: Utils.USER_ABOUT_ME,
    component: Templates.AboutMe,
    back: Utils.USER_MY_ACCOUNT,
    backText: "Cancel",
    exact: true,
  },
  {
    path: Utils.USER_SYMPTOMS_WELCOME,
    component: Templates.TrackerWelcome,
    exact: true,
  },
  {
    path: Utils.USER_NOTES,
    component: Templates.Notes,
    exact: true,
  },
  {
    path: Utils.USER_NOTES_ADD,
    component: Templates.NotesAdd,
    exact: true,
  },
  {
    path: Utils.USER_SYMPTOMS,
    component: Templates.Symptoms,
    exact: true,
  },
  {
    path: Utils.USER_SYMPTOMS_ADD,
    component: Templates.SymptomsAdd,
    exact: true,
  },
  {
    path: Utils.USER_PDF,
    component: Templates.PDF,
    exact: true,
  },
  {
    path: Utils.PASSWORD_FORGOT,
    component: Templates.PasswordForgot,
    exact: true,
  },
  {
    path: Utils.PASSWORD_RESET,
    component: Templates.PasswordReset,
    exact: true,
  },
  {
    path: Utils.USER_LOGIN,
    component: Templates.Login,
    exact: true,
  },
  {
    path: Utils.DEV_STYLEGUIDE,
    component: Templates.StyleGuide,
    back: Utils.APP_HOME,
    exact: true,
  },
  // {
  //   path: Utils.DEV_ANALYTICS,
  //   component: Templates.Analytics,
  //   exact: true,
  // },
  {
    path: Utils.APP_GLOSSARY(),
    component: Templates.Glossary,
    back: "back",
  },
  {
    path: Utils.USER_FEED,
    component: Templates.Feed,
    exact: true,
  },
  {
    path: Utils.USER_DASHBOARD,
    component: Templates.TemplateDashboard,
    exact: true,
  },
  {
    path: Utils.USER_WELCOME,
    component: Templates.TemplateWelcome,
    exact: true,
  },
  {
    path: Utils.USER_IMPORTANT_INFO,
    component: Templates.ImportantInfo,
    exact: true,
  },
  {
    path: Utils.USER_ARTICLES,
    component: Templates.Articles,
    exact: true,
  },
  {
    path: Utils.USER_ARTICLES_SEARCH,
    component: Templates.ArticlesSearch,
    exact: true,
  },
  {
    path: Utils.APP_ARTICLES_CATEGORY,
    component: Templates.Category,
    back: "back",
  },
  {
    path: Utils.APP_ARTICLE,
    component: Templates.Article,
  },
  {
    path: Utils.USER_ARTICLES_BOOKMARKS,
    component: Templates.Bookmarks,
    exact: true,
  },
  {
    path: Utils.USER_ARTICLES_TOPICS,
    component: Templates.Topics,
    exact: true,
  },
  {
    path: Utils.USER_STORIES,
    component: Templates.Stories,
    exact: true,
  },
];

/**
 * 404 route
 */
export const noRoute = [
  {
    path: "*",
    component: Templates.NotFound,
    exact: true,
  },
];
