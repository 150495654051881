import tw, { styled } from "twin.macro";

import * as Components from "~/components";

export const StyledSection = styled(Components.Section)(() => [
  tw`border-t border-pink-light`,
]);

export const ResponseWrapper = tw.div`py-4`;

export const NoteTitle = tw.h4`font-semibold text-blue-navy text-md leading-6`;

export const CalendarBackground = styled.div(({ active }: ActiveProps) => [
  tw`bg-black bg-opacity-80 fixed inset-0 transition duration-500 z-40`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0 pointer-events-none`]),
]);

type ActiveProps = {
  active: boolean
}
