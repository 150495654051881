import { DiagnosisTree } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { useContentApi } from "../hooks/useContentApi";
import { HP_AUTH_NOT_LOGGED_IN } from "../utils/config";
import { useHistory } from "react-router-dom";

export const HpContext = React.createContext<HpContextState>({
  diagnosisTree: null,
  hpAuthStatus: HP_AUTH_NOT_LOGGED_IN,
  setHpAuthStatus: null,
  patientInfo: {},
  referralInfo: {},
  setPatientInfo: null,
  setReferralInfo: null,
  handleBack: null,
});

export const HpProvider: React.FC = ({
  children,
}) => {
  const history = useHistory();
  const { getDiagnosisTree } = useContentApi();
  const [diagnosisTree, setDiagnosisTree] = React.useState<DiagnosisTree | null>(null);
  const [hpAuthStatus, setHpAuthStatus] = React.useState<number>(HP_AUTH_NOT_LOGGED_IN);
  const [patientInfo, setPatientInfo] = React.useState<HpContextState["patientInfo"]>({});
  const [referralInfo, setReferralInfo] = React.useState<HpContextState["referralInfo"]>({});

  React.useEffect(() => {
    (async () => {
      const _diagnosisTree = await getDiagnosisTree();
      setDiagnosisTree(_diagnosisTree);
    })();
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  return (
    <HpContext.Provider
      value={{
      diagnosisTree,
      hpAuthStatus,
      setHpAuthStatus,
      patientInfo,
      referralInfo,
      setPatientInfo,
      setReferralInfo,
      handleBack,
    }}
    >
      {children}
    </HpContext.Provider>
  );
};

export const HpConsumer = HpContext.Consumer;

export type HpContextState = {
  diagnosisTree: DiagnosisTree | null
  hpAuthStatus: number
  setHpAuthStatus: React.Dispatch<React.SetStateAction<number>> | null
  patientInfo: Partial<PatientInfo>
  referralInfo: Partial<ReferralInfo>
  setPatientInfo: React.Dispatch<React.SetStateAction<Partial<PatientInfo>>> | null
  setReferralInfo: React.Dispatch<React.SetStateAction<Partial<ReferralInfo>>> | null
  handleBack: null | (() => void)
}

export type PatientInfo = {
  firstName: string
  lastName: string
  diagnosisDate: Date
  diagnosisName: string
  diagnosis: string
  email: string
  mobile: string
  activationNotification: boolean
}

export type ReferralInfo = {
  application_token: string,
  referral_id: string,
}
