import { Symptom, SymptomCategory } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";

export const SymptomContext = React.createContext<SymptomContextState>({
  symptoms: [],
  symptomCategories: [],
  setSymptomCategories: null,
  setSymptoms: null,
});

export const SymptomProvider: React.FC = ({
  children,
}) => {
  const [symptomCategories, setSymptomCategories] = React.useState<Array<SymptomCategory>>([]);
  const [symptoms, setSymptoms] = React.useState<Array<Symptom>>([]);

  return (
    <SymptomContext.Provider
      value={{
        symptoms,
        symptomCategories,
        setSymptomCategories,
        setSymptoms,
      }}
    >
      {children}
    </SymptomContext.Provider>
  );
};

export const SymptomConsumer = SymptomContext.Consumer;

type Symptoms = Array<Symptom>
type SymptomCategories = Array<SymptomCategory>

export type SymptomContextState = {
  symptomCategories: SymptomCategories
  symptoms: Symptoms
  setSymptomCategories: React.Dispatch<React.SetStateAction<SymptomCategories>> | null
  setSymptoms: React.Dispatch<React.SetStateAction<Symptoms>> | null
}
