import { Article, MergedArticle } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { ArticleList } from "~/components/ArticleList/ArticleList";
import { H2, Section } from "~/components/Styled";
import { useContentApi } from "~/hooks/useContentApi";

export const RecommendedArticles: React.FC<Props> = ({ articles }) => {
  const { getMergedArticles } = useContentApi();
  const [mergedArticles, setMergedArticles] = React.useState<Array<MergedArticle>>();
  React.useEffect(() => {
    (async () => {
      if (articles) {
        const _mergedArticles = await getMergedArticles(articles);
        setMergedArticles(_mergedArticles);
      }
    })();
  }, [articles]);
  return (
    <Section
      background="pinkMedium"
      margin="negative"
      padding="md"
      spacing="md"
    >
      <H2>Recommended Articles</H2>
      {mergedArticles ? (
        <ArticleList
          articles={mergedArticles}
        />
      ) : null}
    </Section>
  );
};

type Props = {
  articles: Array<Article>
}
