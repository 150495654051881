import tw, { styled } from "twin.macro";

export const TabWrapper = tw.div`pb-4`;

export const EntryDay = tw.span`block text-pink text-center text-xxs font-medium leading-5 mb-1`;
export const EntryDate = tw.span`block text-pink text-center text-lg font-medium leading-3 mb-1`;
export const DateWrapper = tw.div`bg-pink-light px-4 pb-3 pt-2 rounded-sm`;
export const EntryContainer = tw.div`border-r border-pink-light inline-block pr-4`;
export const DetailWrapper = tw.button`flex items-start outline-none focus:outline-none w-full`;
export const EntryNumber = tw.span`text-pink text-xl block text-center font-medium`;
export const EntryText = tw.span`text-blue-navy tracking-widest uppercase text-xxxs block w-full text-center font-semibold`;
export const TitleWrapper = tw.div`pl-4 flex-grow self-start`;
export const Title = tw.p`text-blue-navy font-medium text-left`;
export const ViewMoreContainer = tw.div`flex-shrink-0 w-12`;
export const ViewMore = tw.span`text-pink underline text-xs font-semibold block w-full flex justify-end`;
export const ViewMoreIcon = tw.span`text-pink underline text-xs font-semibold block w-full flex justify-end mt-2`;

export const ContentWrapper = styled.div(() => [
  tw`border-b border-grey-light mb-og4`,
]);

export const IconWrapper = styled.span(({ active }: { active: boolean }) => [
  tw`inline-block transform transition`,
  ...(active ? [tw`rotate-180`] : [tw``]),
]);
