import * as React from "react";
import ReactDOM from "react-dom";
import { format } from "date-fns";
import { Symptom } from "myjourney-frontend/src/vendor/umbraco";
import { Content, Header, Overlay, OverlayTitle, CloseButton, Inner } from "./styledEditSymptom";
import CloseIcon from "~/../assets/icons/cross.svg";
import { withEditSymptom } from "./withEditSymptom";
import { SymptomForm } from "~/components";
import { SymptomFormProps } from "~/hooks/useSymptomForm";
import { ButtonLink, Column, Row, Strong } from "../Styled";
import { DatePicker } from "../DatePicker/DatePicker";
import { DateButton } from "../DateButton/DateButton";

export const EditSymptom = withEditSymptom(({
  editSymptomId, handleEditSymptomId, handleSubmit, setImageIds, setDatePicker, onDateChange, datePicker, date, symptom, error, saving, files, handleBackToDashboard, ...symptomProps
}: Props) => {
  return ReactDOM.createPortal(
    (
      <Overlay
        active={Boolean(editSymptomId)}
      >
        <Inner>
          <Header>
            <Row>
              <Column
                spacing="xs"
                shrink
              >
                <OverlayTitle>
                  <Strong
                    weight="bold"
                  >{"Editing: "}</Strong>{symptom.name}
                </OverlayTitle>
                <ButtonLink
                  fontSize="xs"
                  weight="medium"
                  underlined
                  onClick={() => setDatePicker(true)}
                >
                  <Strong
                    weight="bold"
                  >{"Symptom date: "}</Strong>{format(date, "dd MMMM yyyy")}
                </ButtonLink>
              </Column>
              <Column>
                <DateButton
                  padding="xs"
                  active={datePicker}
                  onClick={() => setDatePicker((prev) => !prev)}
                />
              </Column>
            </Row>
            <CloseButton
              onClick={() => handleEditSymptomId()}
            >
              <CloseIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
              />
            </CloseButton>
          </Header>
          <Content>
            <SymptomForm
              {...symptomProps}
              handleSubmit={handleSubmit}
              saving={saving}
              setImageIds={setImageIds}
              symptom={symptom}
              error={error}
              files={files}
              handleBackToDashboard={handleBackToDashboard}
            />
          </Content>
        </Inner>
        <DatePicker 
          active={datePicker}
          setActive={setDatePicker}
          date={date}
          onChange={onDateChange}
        />
      </Overlay>
    ), document.querySelector("#root") as Element
  );
});

type Props = SymptomFormProps & {
  id: string | null
  handleEditSymptomId: (id?: string) => void
  editSymptomId?: string
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  setImageIds: React.Dispatch<React.SetStateAction<Array<string>>>
  imageIds: Array<string>
  saving: boolean
  error: string | null
  symptom: Symptom
  date: Date
  handleBackToDashboard: () => void
  datePicker: boolean
  setDatePicker: React.Dispatch<React.SetStateAction<boolean>>
  onDateChange: (date: Date) => void;
}
