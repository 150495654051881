import * as React from "react";

export const withInput = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ displayName = "Input", ...props }: Props) => {
  Component.displayName = displayName;
  /**
   * Set the input type as state so that it can be toggled to text for password fields
   */
  const [type, setType] = React.useState<Props["type"]>(props.type || "text");
  const [dateModal, setDateModal] = React.useState<boolean>(false);
  const handleToggle = () => {
    setType((prev) => prev === "text" ? "password" : "text");
  };

  return (
    <Component
      { ...props as P & Props }
      type={type}
      setType={setType}
      passwordToggle={props.type === "password"}
      handleToggle={handleToggle}
      dateModal={dateModal}
      setDateModal={setDateModal}
    />
  );
};

type Props = {
  displayName?: string
  name?: string
  error?: JSX.Element | string | undefined;
  valid?: boolean
  validating?: boolean
  hideRequired?: boolean
  label?: string | (() => React.ReactNode)
  ariaLabel?: string;
  required?: boolean
  type?: "text" | "email" | "password" | "tel" | "date"
  value?: string | Date
  placeholder?: string
  readonly?: boolean
  disabled?: boolean
  hideLabel?: boolean
  icon?: React.ReactNode
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
