import * as React from "react";
import ReactPlayer from "react-player";
import { VideoWrapper } from "./styledArticle";

export const Video: React.FC<Props> = ({
  videoUrl,
}) => (
  <VideoWrapper>
    <ReactPlayer
      url={videoUrl}
      width="100%"
    />
  </VideoWrapper>
);

type Props = {
  videoUrl: string
}
