import * as React from "react";

export const useStickyHeader = (): StickyHeaderProps => {
  const [headerStuck, setHeaderStuck] = React.useState<boolean>(false);
  const stickyHeader = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function stickHeader() {
      if (stickyHeader?.current) {
        const { top } = stickyHeader.current.getBoundingClientRect();
        if (window.scrollY >= (document.documentElement.scrollTop + top - 10)) {
          setHeaderStuck(true);
        } else {
          setHeaderStuck(false);
        }
      }
    }
    stickHeader();
    window.addEventListener("scroll", stickHeader);
    return function cleanup() {
      window.removeEventListener("scroll", stickHeader);
    };
  }, []);

  return {
    stickyHeader,
    headerStuck,
    setHeaderStuck,
  };
};

export type StickyHeaderProps = {
  stickyHeader: React.RefObject<HTMLDivElement>
  headerStuck: boolean
  setHeaderStuck: React.Dispatch<React.SetStateAction<boolean>>
}
