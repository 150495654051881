import * as React from "react";
import tw, { styled } from "twin.macro";

import * as Components from "~/components";

import IconInfoCircle from "~/../assets/icons/icon-info-circle.svg";
import IconArrowDown from "~/../assets/icons/arrow-down.svg";

const StyledWrapper = styled.div(() => [
  tw`w-full mb-4`,
]);
const StyledLabelWrapper = styled.div(() => [
  tw`flex items-center cursor-pointer`,
]);
const StyledTextWrapper = styled.div(() => [
  tw`w-full px-2`,
]);
const StyledContentWrapper = styled.div(() => [
  tw`w-full px-6 mt-2 cursor-default`,
]);

export const InfoDropDown: React.FunctionComponent<InfoDropDownProps> = (props) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <StyledWrapper>
      <StyledLabelWrapper
        onClick={() => setOpen(!open)}
      >
        <Components.TextVariation
          variation="none"
          color="pink"
        >
          <IconInfoCircle/>
        </Components.TextVariation>
        <StyledTextWrapper>
          <Components.TextVariation
            variation="paragraph2bold"
            color="greyDark"
          >
            {props.label}
          </Components.TextVariation>
        </StyledTextWrapper>
        <Components.TextVariation
          variation="none"
          color="pink"
        >
          <IconArrowDown />
        </Components.TextVariation>
      </StyledLabelWrapper>

      {open && (
        <StyledContentWrapper>
          <Components.TextVariation
            variation="paragraph2"
            color="greyMedium"
          >
            {props.children}
          </Components.TextVariation>
        </StyledContentWrapper>
      )}
    </StyledWrapper>
  );
};

export type InfoDropDownProps = {
  label: string | JSX.Element;
};
