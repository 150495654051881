export const getBreadcrumbPath = (targetElement: Element, labels: Array<string> = []): string => {
  // skip <svg /> elements
  const targetElementSvgParent = targetElement.closest("svg")?.parentElement;
  if (targetElementSvgParent) {
    return getBreadcrumbPath(targetElementSvgParent, labels);
  }
  // skip <p /> elements
  const targetElementParagraphParent = targetElement.closest("p")?.parentElement;
  if (targetElementParagraphParent) {
    return getBreadcrumbPath(targetElementParagraphParent, labels);
  }

  if (targetElement.getAttribute("aria-label")) {
    labels.unshift(`${targetElement.nodeName.toLowerCase()}[aria-label="${targetElement.getAttribute("aria-label") ?? ""}"]`);

    const closestElement = targetElement.parentElement?.closest("[aria-label]");

    if (closestElement) {
      return getBreadcrumbPath(closestElement, labels);
    }
  }

  return labels.join(" > ");
};
