import { Button, Content, IconWrapper, Item, Items } from "./styledAccordion";
import { GlossaryAccordionItem, withGlossaryAccordion } from "./withGlossaryAccordion";
import ArrowDown from "~/../assets/icons/arrow-down.svg";

import * as Components from "~/components";

export const SymptomDetailAccordion = withGlossaryAccordion(({ items, handleActive }: Props) => (
  <Items>
    {items.map((item, index) => (
      <Item
        key={item.label}
        border="greyLight"
      >
        <Button
          onClick={() => handleActive(index)}
        >
          <Components.Row
            justify="between"
            full
          >
            <Components.TextVariation
              variation="heading4"
              color="greyMedium"
            >
              {item.label}
            </Components.TextVariation>
            <IconWrapper
              active={!!item.active}
              colour="pink"
            >
              <ArrowDown
                width="14"
                height="14"
              />
            </IconWrapper>
          </Components.Row>
        </Button>
        <Content
          active={!!item.active}
        >
          {item.content}
        </Content>
      </Item>
    ))}
  </Items>
));

type Props = {
  handleActive: (item: number) => void
  items: Array<GlossaryAccordionItem>
}
