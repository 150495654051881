import * as FirebaseApp from "firebase/app";

export const firebaseApp = FirebaseApp.initializeApp({
  apiKey: runtimeconfig.firebase_api_key,
  authDomain: runtimeconfig.firebase_auth_domain,
  projectId: runtimeconfig.firebase_project_id,
  storageBucket: runtimeconfig.firebase_storage_bucket,
  messagingSenderId: runtimeconfig.firebase_messaging_sender_id,
  appId: runtimeconfig.firebase_app_id,
  measurementId: runtimeconfig.firebase_measurement_id,
});
