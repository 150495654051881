import * as React from "react";

import * as Components from "~/components";

import { SignUpStepProps } from "../SignUp";

export const StepCustomize: React.FunctionComponent<SignUpStepProps> = (
  props
) => (
  <React.Fragment>
    <Components.TextVariation
      variation="headingdisplay"
      spacing="og6"
      fullWidth
    >
      {"Customise"}
      <br />
      {"My Journey"}
    </Components.TextVariation>
    <Components.DiagnosisForm
      {...props}
      diagnosis={props.diagnosis}
      diagnosisDate={props.diagnosisDate}
      treatment={props.treatment}
      diagnosisDateValid={props.diagnosisDateValid}
      situation={props.situation}
      locations={props.locations}
      onBlur={(field, index = 1) => {
        let id: string | undefined;
        let value: undefined | string | Array<string>;
        switch (field) {
          case "diagnosis":
            id = "diagnosis";
            value = props.diagnosisItem?.name;
            break;
          case "situation":
            id = "situation";
            value = props.situationItem?.name;
            break;
          case "treatment":
            id = "situation";
            value = props.treatmentItem?.name;
            break;
          case "location":
            id = "situation";
            value = props.locationItems?.map(
              (locationItem) => locationItem.hpReferralTitle
            );
            break;
        }
        if (id && value) {
          if (Array.isArray(value)) {
            for (const item of value) {
              props.handleFieldTracking(field, id, index, item);
            }
          } else {
            props.handleFieldTracking(field, id, index, value);
          }
        }
      }}
    />
    {props.stepValid && (
      <React.Fragment>
        <Components.HorizontalRule />
        <Components.TextVariation
          variation="heading3"
          color="greyDark"
          spacing="og4"
        >
          {
            "I'm interested in additional information relating to particular groups (optional)"
          }
        </Components.TextVariation>

        {props.userTypeData?.length > 0 && (
          <React.Fragment>
            <Components.TextVariation
              variation="label1"
              color="pink"
              spacing="og4"
              fullWidth
            >
              {"Select all that apply:"}
            </Components.TextVariation>
            <Components.RadioGroupGraphic
              data={props.userTypeData}
              checked={props.selectedUserTypes}
              onChange={props.handleUserTypes}
              onBlur={() =>
                props.selectedUserTypes.forEach((userTypeId) => {
                  const userTypeIndex =
                    props.userTypeData?.findIndex(
                      (userType) => userType.value === userTypeId
                    ) ?? -1;
                  if (props.userTypeData && userTypeIndex > -1) {
                    props.handleFieldTracking(
                      "User types",
                      "usertypes",
                      userTypeIndex,
                      props.userTypeData[userTypeIndex].label
                    );
                  }
                })
              }
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )}
  </React.Fragment>
);
