import * as React from "react";
import tw, { styled } from "twin.macro";

import { Topic } from "myjourney-frontend/src/vendor/umbraco";

import * as Utils from "~/utils";
import * as Components from "~/components";

const StyledTopicCardInner = styled.div(() => [
  tw`flex-1`,
]);

export const TopicCard: React.FunctionComponent<TopicCardProps> = ({ topic }) => (
  <Components.CardLink
    to={{
      pathname: topic ? Utils.APP_ARTICLES_CATEGORY.replace(":categoryId", `${topic.id}`) : "",
      state: {
        topic,
      },
    }}
    padding="md"
    direction="column"
    items="start"
    background="pinkMedium"
  >
    <StyledTopicCardInner>
      <Components.TextVariation
        variation="heading5"
        align="left"
      >
        {topic?.name}
      </Components.TextVariation>
    </StyledTopicCardInner>
    <Components.TextVariation
      variation="textlink2"
      align="left"
    >
      View
    </Components.TextVariation>
  </Components.CardLink>
);

export type TopicCardProps = {
  topic?: Topic
};
