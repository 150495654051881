import * as React from "react";

import { AnalyticsContext } from "../providers/AnalyticsProvider";

export const useAnalytics = (): {
  trackPatientSignUp: typeof trackPatientSignUp
  trackPatientLogin: typeof trackPatientLogin
  trackPatientAuthenticationInfo: typeof trackPatientAuthenticationInfo
  trackPatientAuthenticationData: typeof trackPatientAuthenticationData
  trackArticleView: typeof trackArticleView
  trackArticlePrint: typeof trackArticlePrint
  trackArticleShare: typeof trackArticleShare
  trackArticleFileDownload: typeof trackArticleFileDownload
  trackArticleClick: typeof trackArticleClick
  trackRecommendedArticleClick: typeof trackRecommendedArticleClick
  trackArticleSearchSubmit: typeof trackArticleSearchSubmit
  trackArticleSearchView: typeof trackArticleSearchView
  trackArticleSearchClick: typeof trackArticleSearchClick
  trackTopicView: typeof trackTopicView
  trackBookmarkToggle: typeof trackBookmarkToggle
  trackNavigationMenuClick: typeof trackNavigationMenuClick
  trackRouteChange: typeof trackRouteChange
  trackFormInteraction: typeof trackFormInteraction
  trackFormStart: typeof trackFormStart
  trackFormError: typeof trackFormError
  trackFormStepView: typeof trackFormStepView
  trackFormStepComplete: typeof trackFormStepComplete
  trackFormFieldComplete: typeof trackFormFieldComplete
  trackAccordionClick: typeof trackAccordionClick
  trackTrackerView: typeof trackTrackerView
  trackTrackerTab: typeof trackTrackerTab
  trackTrackerFilters: typeof trackTrackerFilters
  trackTrackerSearch: typeof trackTrackerSearch
  trackTrackerLog: typeof trackTrackerLog
  trackTrackerAccordions: TrackTrackerAccordions
} => {
  const { gtm } = React.useContext(AnalyticsContext);
  const generateRandomID = () => `PID_${Math.floor(Math.random() * 1000000000) + 10000}`;
  const timeStarted = Date.now();

  /**
   * Track when a patient registers
   */
  const trackPatientSignUp = () => {
    const dataLayer = {
      event: "user",
      action: "registration",
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when a patient logs in
   */
  const trackPatientLogin = () => {
    const dataLayer = {
      event: "user",
      action: "login",
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when a patient is successfully authenticated
   */
  const trackPatientAuthenticationInfo = (user: UserInfo) => {
    const dataLayer = {
      user,
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when a patient is successfully authenticated
   */
  const trackPatientAuthenticationData = (user: UserData, postcode: string) => {
    const dataLayer = {
      user,
      geo: {
        postcode, // Patient postcode
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when data for an article is successfully loaded
   */
  const trackArticleView = (content: ArticleContent) => {
    const dataLayer = {
      event: "custom.article.view",
      content,
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when the print button is clicked for an article
   * @param title
   */
  const trackArticlePrint = (title: string) => {
    const dataLayer = {
      event: "custom.cta.click",
      label: "Print",
      data: {
        value: title, // Article title
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when the share button is clicked for an article
   */
  const trackArticleShare = (title: string) => {
    const dataLayer = {
      event: "custom.cta.click",
      label: "Share",
      data: {
        value: title, // Article title
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when the download button is clicked for an article
   */
  const trackArticleFileDownload = ({ group, label, url }: ArticleFileDownload) => {
    switch (group) {
      case "Factsheet":
      case "Booklet":
      case "Podcast":
        gtm.dataLayer({
          dataLayer: {
            event: "custom.file.click",
            group, // File resource type
            label, // File link name
            info: {
              url, // File link URL
              location: "Resources",
            },
          },
        });
      break;
    }
  };

  /**
   * Track when an article is clicked on
   */
  const trackArticleClick = ({
    label, parentLabel, url, id, position, value,
  }: ArticleClick) => {
    const dataLayer = {
      event: "custom.tile.click",
      group: "Topics",
      label,
      parentLabel,
      info: {
        url,
        id,
        position,
      },
      data: {
        value,
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when a recommended article is clicked on
   */
  const trackRecommendedArticleClick = ({ label, url, location }: ArticleRecommendedClick) => {
    const dataLayer = {
      event: "custom.tile.click",
      group: "Recommended articles",
      label,
      info: {
        url,
        location,
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when the article search form is submitted
   */
  const trackArticleSearchSubmit = ({ resultCount, term }: ArticleSearchSubmit) => {
    const dataLayer = {
      event: "custom.search.submit",
      group: "My Journey Search",
      search: {
        term,
        resultCount,
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when the article search page is viewed
   */
  const trackArticleSearchView = ({
    term, results, resultCount,
  }: ArticleSearchView) => {
    const dataLayer = {
      event: "custom.search.results.view",
      group: "My Journey Search",
      search: {
        term,
        results,
        resultCount,
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when the article in the search results is clicked
   */
  const trackArticleSearchClick = ({ label, term, results, resultCount }: ArticleSearchClick) => {
    const dataLayer = {
      event: "custom.search.results.click",
      group: "My Journey Search",
      label,
      search: {
        term,
        results,
        resultCount,
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when the data for a topic is downloaded
   */
  const trackTopicView = (topic: string) => {
    const dataLayer = {
      content: {
        topic, // Topic title
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when an article bookmark is toggled
   */
  const trackBookmarkToggle = ({ parentLabel, value }: BookmarkToggle) => {
    const dataLayer = {
      event: "custom.cta.click",
      label: "Bookmark",
      parentLabel,
      data: {
        value, // Article title
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when clicking a navigation item in the main “tab bar” navigation
   */
  const trackNavigationMenuClick = ({ label, url }: NavigationClick) => {
    const dataLayer = {
      event: "custom.navigation.link.click",
      group: "main menu",
      label,
      info: {
        url, // Relative URL
        location: "main",
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when successfully loading a route
   */
  const trackRouteChange = (route: RouteChange) => {
    let type;
    if (route.current.indexOf("category") > -1) {
      type = "topic";
    } else if (route.current.indexOf("article") > -1) {
      type = "article";
    } else if (route.current.indexOf("feed") > -1) {
      type = "feed";
    }
    const dataLayer = {
      event: "custom.route.change",
      route,
      content: {
        type, // Area before change
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track any form action: Events related to usage, validation and progression of
   * all forms throughout the application
   */
  const trackFormInteraction = ({
    event,
    group,
    step,
  }: FormInteraction) => {
    const dataLayer = {
      event: `custom.form.${event}`,
      group,
      step,
      info: {
        time: (Date.now() - timeStarted) / 1000,
      },
      processId: generateRandomID(),
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track any form start
   */
  const trackFormStart = ({
    group,
    label,
  }: FormStart) => {
    const dataLayer = {
      event: "custom.form.start",
      group,
      step: {
        sequence: 1,
        label,
      },
      info: {
        time: (Date.now() - timeStarted) / 1000,
      },
      processId: generateRandomID(),
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track any form error
   */
  const trackFormError = ({
    label,
    group,
    step,
  }: FormError) => {
    const dataLayer = {
      event: "custom.error.validation.view",
      group,
      label,
      step,
      info: {
        time: (Date.now() - timeStarted) / 1000,
      },
      processId: generateRandomID(),
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track form step view
   */
  const trackFormStepView = ({
    group,
    step,
  }: FormView) => {
    const dataLayer = {
      event: "custom.form.step.view",
      group,
      step,
      info: {
        time: (Date.now() - timeStarted) / 1000,
      },
      processId: generateRandomID(),
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track form step completion
   */
  const trackFormStepComplete = ({
    parentGroup,
    step,
  }: FormStepComplete) => {
    const dataLayer = {
      event: "custom.form.step.complete",
      group: undefined,
      parentGroup,
      parentGroupType: "Form",
      step,
      info: {
        time: (Date.now() - timeStarted) / 1000,
      },
      processId: generateRandomID(),
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track form step completion
   */
  const trackFormFieldComplete = ({
    group,
    label,
    step,
    id,
    position,
    value,
  }: FormFieldComplete) => {
    const dataLayer = {
      event: "custom.form.field.complete",
      group,
      parentGroupType: "Form",
      label,
      step,
      info: {
        id,
        position,
        time: (Date.now() - timeStarted) / 1000,
      },
      data: {
        value,
      },
      processId: generateRandomID(),
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track any expanding or collapsing of an accordion section
   */
  const trackAccordionClick = ({
    event,
    group,
    label,
    location = "Article",
    position,
  }: AccordionToggle) => {
    const dataLayer = {
      event: `custom.accordion.${event}`,
      group,
      label,
      info: {
        location,
        position,
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when the tracker dashboard/home screen successfully loads
   */
  const trackTrackerView = () => {
    const dataLayer = {
      event: "custom.tracker.view",
      group: undefined,
      category: "tracker",
      label: "view",
      data: undefined,
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when a tracker tab is selected
   */
  const trackTrackerTab = ({
    label,
  }: {
    label: "Symptoms" | "Notes"
  }) => {
    const dataLayer = {
      event: "custom.tab.click",
      group: undefined,
      category: "tracker",
      label,
      info: {
        location: "Dashboard",
        position: undefined,
      },
      data: undefined,
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when a user opens or closes a tracker accordion
   */
  const trackTrackerAccordions = ({
    action,
    group,
    label,
    position,
  }: {
    action: "open" | "close"
    group: "Symptoms" | "Notes"
    location: "Symptom Listing" | "Notes Listing"
    label: string
    position: number
  }) => {
    const dataLayer = {
      event: `custom.accordion.${action}`,
      category: "tracker",
      group,
      label,
      info: {
        location: "Dashboard",
        position,
      },
      data: undefined,
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when a user applies a filter
   */
  const trackTrackerFilters = ({
    label,
  }: {
    label: string
  }) => {
    const dataLayer = {
      event: "custom.filter.set",
      category: "tracker",
      group: "Symptoms",
      label: label,
      data: undefined,
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when a user searches for a symptom
   */
  const trackTrackerSearch = ({
    term,
    predictive,
  } : {
    term: string
    predictive?: string
  }) => {
    const dataLayer = {
      event: "custom.search.submit",
      category: "tracker",
      group: "Symptoms",
      label: undefined,
      data: undefined,
      search: {
        term,
        predictive,
      },
    };
    gtm.dataLayer({ dataLayer });
  };

  /**
   * Track when a user successfully logs a symptom or note
   */
  const trackTrackerLog = ({
    group,
    attachments,
    images,
    files,
    description,
    actions,
    questions,
    symptom,
    note,
  } : {
    group: "Symptoms" | "Notes"
    attachments: Array<string>
    images: number
    files: number
    description: boolean
    actions: boolean
    questions: boolean
    symptom?: {
      name: string
      type: string
      severity: string
    }
    note?: {
      topic: string
    }
  }) => {
    const dataLayer = {
      event: "custom.tracker.log.complete",
      category: "tracker",
      group,
      label: undefined,
      data: {
        attachments,
        images,
        files,
        description,
        actions,
        questions,
      },
      symptom,
      note,
    };
    gtm.dataLayer({ dataLayer });
  };

  return {
    trackPatientSignUp,
    trackPatientLogin,
    trackPatientAuthenticationInfo,
    trackPatientAuthenticationData,
    trackArticleView,
    trackArticlePrint,
    trackArticleShare,
    trackArticleFileDownload,
    trackArticleClick,
    trackRecommendedArticleClick,
    trackArticleSearchSubmit,
    trackArticleSearchView,
    trackArticleSearchClick,
    trackTopicView,
    trackBookmarkToggle,
    trackNavigationMenuClick,
    trackRouteChange,
    trackFormInteraction,
    trackFormStart,
    trackFormError,
    trackFormStepView,
    trackFormStepComplete,
    trackFormFieldComplete,
    trackAccordionClick,
    trackTrackerView,
    trackTrackerTab,
    trackTrackerAccordions,
    trackTrackerFilters,
    trackTrackerSearch,
    trackTrackerLog,
  };
};

type UserData = {
  diagnosis: string // Patient diagnosis
  situation: string // Patient situation
  diagnosis_type: string // Patient diagnosis type
  diagnosis_location: string // Patient diagnosis location
  type: string // Patient user types (eg, “1, 9, 5”)
}

type UserInfo = {
  id: string // Unknown
  dob: string // Patient year of birth “YYYY”
}

type ArticleContent = {
  articleTitle: string // Article title
  articleId: string // Article ID
  tags: string // Article tags
}

type ArticleClick = {
  label: string // Article title
  parentLabel: string // Topic title
  url: string // Article relative URL (eg; "/article/999")
  id: number // Article ID
  position: number // Article index in list
  value: string // Article type
}

type ArticleFileDownload = {
  group: "Factsheet" | "Booklet" | "Podcast" | "App" | "Website";
  label: string
  url: string
}

type ArticleRecommendedClick = {
  label: string // Article title
  url: string // Article relative URL (eg "/article/999")
  location: string // Browser URL pathname
}

type ArticleSearchSubmit = {
  term: string // Search value
  resultCount: number // Number of results
}

type ArticleSearchView = {
  term: string // Search value
  results: Array<{
    label: string // Article title
    position: number // Article index in list
    type: string // Article type
  }>
  resultCount: number // Search result total
}

type ArticleSearchClick = {
  label: string; // Clicked article title
  term: string; // Search value
  results: Array<{
    label: string; // Article title
    position: number; // Article index in list
    type: string; // Article type
  }>;
  resultCount: number; // Search result total
}

type BookmarkToggle = {
  parentLabel: "Added" | "Removed"
  value: string
}

type NavigationClick = {
  label: string;
  url: string
}

type RouteChange = {
  previous: string // Browser URL pathname (before change)
  current: string // Browser URL pathname (after change)
  title: string // Page title (after change)
}

type FormInteraction = {
  event: "start" | "submit" | "complete" | "error"
  group: string // Form title
  step: {
    sequence: number // Form step number
    label: string // Form step name
  }
}

type FormView = {
  group: string // Form title
  step: {
    sequence: number // Form step number
    label: string // Form step name
  }
}

type FormStart = {
  group: string // Form title
  label: string // Form step name
}

type FormError = FormInteraction & {
  label: string
}

type FormFieldComplete = {
  group: string // Form title
  label: string // Field title
  value: string // Field value
  id: string // Field ID
  position: number // Field tab index ??
  step: {
    sequence: number // Form step number
    label: string // Form step name
  }
}

type FormStepComplete = {
  parentGroup: string
  step: {
    sequence: number // Form step number
    label: string // Form step name
  }
}

type AccordionToggle = {
  event: "open" | "close"
  /** Article title */
  group: string
  /** Accordion label */
  label: string
  /** Accordion item index starting at 1 */
  position: number
  /** Type of page that the accordion is on */
  location?: "Article" | "Welcome"
}

export type TrackTrackerAccordions = ({ action, group, label, position }: {
  action: "open" | "close";
  group: "Symptoms" | "Notes";
  location: "Symptom Listing" | "Notes Listing";
  label: string;
  position: number;
}) => void
