import * as React from "react";
import { format } from "date-fns";
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLine } from "victory";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Styled from "./styledSymptomGraphCombined";
import * as Components from "~/components";

const graphStyles = {
  title: {
    textAnchor: "start",
    verticalAnchor: "end",
    fill: Utils.DARK_BLUE_COLOR,
    fontFamily: "inherit",
    fontSize: "11px",
    fontWeight: "600",
  },
  verticalAxis: {
    axis: {
      stroke: "transparent",
      padding: 0,
    },
  },
  crossAxis: {
    axis: {
      stroke: Utils.DUSTY_PINK_COLOR,
      background: "#fff",
    },
  },
  dateLabel: {
    textTransform: "uppercase",
    fill: Utils.DARK_BLUE_COLOR,
    fontFamily: "var(--font-sans)",
    fontWeight: "600",
  },
};

export const SymptomGraphCombined: React.FunctionComponent<SymptomGraphCombinedProps> = (props) => {
  const hookSymptomData = Hooks.useSymptomData(props.dateRange);

  return (
    <Components.Card
      color="white"
      shadow="default"
    >
      <Components.Row
        spacing="sm"
      >
        <Components.Column>
          <VictoryChart
            padding={{
              top: 0,
              right: 0,
              bottom: 50,
              left: 0,
            }}
            domainPadding={{
              y: 50,
            }}
            scale={{ y: "linear", x: "time" }}
            domain={{
              y: [0, 10],
            }}
          >
            {/* Create stylistic elements */}
            <g
              width="450"
              height="300"
            >
              <rect
                x="0"
                y="150"
                width="450"
                height="125"
                fill="#FBF7F7"
                transform="translate(0 -25)"
              />
            </g>

            <VictoryAxis
              dependentAxis
              tickCount={10}
              style={graphStyles.verticalAxis}
            />
            <VictoryAxis
              crossAxis
              style={graphStyles.crossAxis}
              tickFormat={(t) => `${format(t, "dd")}\n${format(t, "MMM")}`}
              tickLabelComponent={(
                <VictoryLabel
                  style={[graphStyles.dateLabel, {
                    ...graphStyles.dateLabel,
                    fontSize: "8px",
                  }]}
                />
              )}
            />

            {/* Define labels */}
            <VictoryLabel
              x={25}
              y={25}
              style={graphStyles.title}
              text="Extreme"
            />
            <VictoryLabel
              x={25}
              y={225}
              style={graphStyles.title}
              text="OK"
            />

            {hookSymptomData.graphData.map(({ points, stroke }) => (
              <VictoryLine
                key={stroke}
                data={points}
                padding={0}
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 },
                }}
                standalone={false}
                interpolation="natural"
                scale={{ x: "time", y: "linear" }}
                style={{
                  data: {
                    stroke,
                    strokeWidth: 1.5,
                    paddingBottom: 50,
                  },
                }}
              />
            ))}
          </VictoryChart>
        </Components.Column>
      </Components.Row>
      <Components.Row>
        {hookSymptomData.graphData.map(({ name, stroke }) => (
          <Components.Column
            xs="1/2"
            gutter="xl"
            spacing="sm"
            key={stroke}
          >
            <Styled.SwatchWrapper>
              <Styled.Swatch
                style={{ borderColor: stroke }}
              >
                <Styled.SwatchInner
                  style={{ background: stroke }}
                />
              </Styled.Swatch>
              <Components.ExtraSmall
                colour="greyDark"
                spacing="none"
              >{name}</Components.ExtraSmall>
            </Styled.SwatchWrapper>
          </Components.Column>
        ))}
      </Components.Row>
    </Components.Card>
  );
};

export type SymptomGraphCombinedProps = {
  dateRange?: {
    start: Date;
    end: Date;
  };
}
