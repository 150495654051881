import * as React from "react";
import { format, parseISO } from "date-fns";

import * as Utils from "~/utils";
import * as Providers from "~/providers";
import * as Components from "~/components";

import { SwipeModal, SwipeModalProps } from "./SwipeModal";

export const SwipeModalProfile: React.FunctionComponent<SwipeModalProfileProps> = (props) => {
  const patientContext = React.useContext(Providers.PatientContext);
  const modalContext = React.useContext(Providers.ModalContext);

  return (
    <SwipeModal
      {...props}
      title="My profile"
    >
      <Components.Row>
        <Components.Column>
          <Components.AccountCard
            title="My account"
            titleVariation="heading4"
            titleColor="blueNavy"
            linkText="Edit details"
            link={Utils.USER_ACCOUNT_DETAILS}
            items={[{
              label: "Email",
              value: patientContext.auth?.patient_details.email,
            },{
              label: "Phone number",
              value: patientContext.auth?.patient_details.phone,
            },{
              label: "Password",
              value: "***********",
            }]}
          />
          <Components.AccountCard
            title="My details"
            titleVariation="heading4"
            titleColor="blueNavy"
            linkText="Edit details"
            link={Utils.USER_PERSONAL_DETAILS}
            items={[{
              label: "First name",
              value: patientContext.auth?.patient_details.first_name,
            },{
              label: "Last name",
              value: patientContext.auth?.patient_details.last_name,
            },{
              label: "Date of birth",
              value: patientContext.auth ? format(parseISO(patientContext.auth.patient_details.dob), "dd / MM / yyyy") : undefined,
            },{
              label: "Postcode",
              value: patientContext.auth?.patient_details.address_postcode,
            }]}
          />
        </Components.Column>
        <Components.Column
          spacing="ogxxxs"
        >
          <Components.ButtonVariation
            variation="primary"
            href={Utils.USER_MY_ACCOUNT}
            fullWidth
          >
            {"Edit my details"}
          </Components.ButtonVariation>
        </Components.Column>
        <Components.Column>
          <Components.ButtonVariation
            variation="secondary"
            onClick={() => modalContext.toggleModal("accountChooseLook")}
            fullWidth
          >
            {"Choose my look"}
          </Components.ButtonVariation>
        </Components.Column>
      </Components.Row>
    </SwipeModal>
  );
};

export type SwipeModalProfileProps = SwipeModalProps & {
  children?: never;
};
