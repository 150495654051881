import tw, { styled } from "twin.macro";

import { Directions, Global, Gutters, Items, Justifys, Reverses, Spacings, Wraps } from "./Global";

const styles = {
  ...Global,
  print: {
    none: tw``,
    hidden: tw`print:(hidden)`,
  },
};

export const Row = styled.div(({
  direction = "none",
  wrap = "xs",
  nowrap = "none",
  gutter = "none",
  items = "start",
  justify = "none",
  spacing = "none",
  reverse = "none",
  print = "none",
  relative,
}: Props) => [
  tw`w-full flex`,
  ...(relative ? [tw`relative`] : []),
  styles.direction[direction],
  styles.wrap[wrap],
  styles.nowrap[nowrap],
  styles.gutterMargin[gutter],
  styles.items[items],
  styles.justify[justify],
  styles.spacing[spacing],
  styles.reverse[reverse],
  styles.print[print],
]);

type Props = {
  direction?: Directions
  wrap?: Wraps,
  nowrap?: Wraps,
  gutter?: Gutters,
  items?: Items,
  justify?: Justifys,
  spacing?: Spacings;
  reverse?: Reverses;
  print?: "none" | "hidden"
  full?: boolean
  relative?: boolean
}
