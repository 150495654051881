import * as React from "react";
import { useHistory } from "react-router-dom";

import { ErrorHyperlink } from "~/components/Styled";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useHpApi } from "~/hooks/useHpApi";
import { HpContext } from "~/providers/HpProvider";
import { HP_AUTH_LOGGED_IN } from "~/utils/config";
import { HP_STEP_1 } from "~/utils/constants";
import { validEmail } from "~/utils/validation";


export const withHpLogin = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<P & Props> => ({ name = "HpLogin", ...props }: Props) => {
  Component.displayName = name;
  const { hpAuthStatus, setHpAuthStatus, setReferralInfo } = React.useContext(HpContext);
  const { trackFormInteraction, trackFormStepComplete } = useAnalytics();
  const { hpLogin } = useHpApi();
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [userIDError, setUserIDError] = React.useState<React.FC | null>(null);
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [formError, setFormError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (hpAuthStatus === HP_AUTH_LOGGED_IN) {
      history.push(HP_STEP_1);
    }
  }, [hpAuthStatus]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    setFormError(null);
    if (emailError || userIDError) {
      setLoading(false);
      return;
    }

    trackFormInteraction({
      event: "submit",
      group: "hpReferral",
      step: {
        sequence: 1,
        label: "hp-login-form",
      },
    });

    const target = e.target as HTMLFormElement;
    const [userIdInput, emailInput] = target.elements as unknown as [HTMLInputElement, HTMLInputElement];
    const username = userIdInput.value;
    const email = emailInput.value;

    try {
      const res = await hpLogin({
        username: username.trim(),
        email: email.trim(),
      });
      const json = await res.json();
      if (res.status === 200) {
        trackFormStepComplete({
          parentGroup: "hpReferral",
          step: {
            sequence: 1,
            label: "hp-login-form",
          },
        });
        if (setReferralInfo) {
          setReferralInfo({
            application_token: json.data.application_token,
            referral_id: json.data.salesforce_id,
          });
        }
        if (setHpAuthStatus) {
          setHpAuthStatus(HP_AUTH_LOGGED_IN);
        }
      } else {
        setFormError("Sorry, your username and password are incorrect.");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setFormError("Service unavailable. Please try again later.");
      throw error;
    }

  };

  const validateUserId = (e: ValidateEvent) => {
    if (e.target.value === "") {
      setUserIDError(UserIDError);
      return;
    }
    setUserIDError(null);
  };

  const validateEmail = (e: ValidateEvent) => {
    if (e.target.value === "") {
      setEmailError("Please enter a valid email");
      return;
    }
    if (!validEmail(e.target.value)) {
      setEmailError("Please enter a valid email");
      return;
    }
    setEmailError(null);
  };

  return (
    <Component
      { ...props as P }
      handleSubmit={handleSubmit}
      validateUserId={validateUserId}
      validateEmail={validateEmail}
      userIDError={userIDError}
      emailError={emailError}
      formError={formError}
      loading={loading}
    />
  );
};

const UserIDError: React.FC = () => (
  <>
    Please enter your user ID.
    <br />
    <ErrorHyperlink
      href="tel:1800500258"
    >
      I have forgotten my user ID
    </ErrorHyperlink>
  </>
);

type Props = {
  name?: string
}

export type ValidateEvent = React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>
