import * as React from "react";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import parse from "html-react-parser";

import { Article, StackedContent, Symptom, SymptomResponse, SymptomCategory } from "myjourney-frontend/src/vendor/umbraco";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Providers from "~/providers";
import * as Styled from "./styledSymptomsAdd";
import * as Components from "~/components";

import { FilterAndSearch, FilterDropdownButton } from "~/components/StickyHeader/styledStickyHeader";

import IconArrowDown from "~/../assets/icons/arrow-down.svg";
import IconSearch from "~/../assets/icons/magnifying-glass.svg";
import IconAdd from "~/../assets/icons/add.svg";
import IconFilterClose from "~/../assets/icons/filter-close.svg";
import IconFilterOpen from "~/../assets/icons/filter-open.svg";
import IconCross from "~/../assets/icons/search-cross.svg";
import IconArrowRight from "~/../assets/icons/right-arrow.svg";
import IconBooklet from "~/../assets/icons/resource-booklet.svg";

const createCategories = (arr: Array<Symptom>) => arr
  .map(({ parentCategory }) => (parentCategory))
  .filter((item, pos, self) => self.indexOf(item) == pos)
  .map((name) => ({
    name,
    symptoms: arr.filter(({ parentCategory }) => name === parentCategory),
  }));

export const SymptomsAdd: React.FunctionComponent<SymptomsAddProps> = () => {
  Hooks.useAuthentication();

  const { auth } = React.useContext(Providers.PatientContext);
  const { getSymptoms, getSymptomCategories } = Hooks.useContentApi();
  const { getSymptomsByDate } = Hooks.useSymptomTrackerApi();
  const { trackTrackerFilters, trackTrackerSearch } = Hooks.useAnalytics();
  const hookStickyHeader = Hooks.useStickyHeader();
  const [symptomCounts, setSymptomCounts] = React.useState<Record<string, number>>({});
  const [loading, setLoading] = React.useState<boolean>(true);
  const [datePicker, setDatePicker] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<Date>(new Date());
  const [symptomCategories, setSymptomCategories] = React.useState<Array<SymptomCategory>>([]);
  const [categories, setCategories] = React.useState<Array<MergedCategories>>([]);
  const [symptoms, setSymptoms] = React.useState<Array<Symptom>>([]);
  const [stickyFilters, setStickyFilters] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<Array<string>>([]);
  const [filterDropdown, setFilterDropdown] = React.useState<boolean>(true);
  const [suggestions, setSuggestions] = React.useState<Array<Symptom>>([]);
  const [activeCategories, setActiveCategories] = React.useState<Array<string>>([]);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [searchedValue, setSearchedValue] = React.useState<string>("");
  const [addSymptom, setAddSymptom] = React.useState<Symptom>();
  const [stickyFilterDropdown, setStickyFilterDropdown] = React.useState<boolean>(false);

  const staticFilters = React.useRef<HTMLDivElement | null>(null);
  const searchInput = React.useRef<HTMLInputElement | null>(null);
  const stickySearchInput = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    (async () => {
      const _symptoms = await getSymptoms();
      const _symptomCategories = await getSymptomCategories();
      setSymptoms(_symptoms);
      setSymptomCategories(_symptomCategories);
      const _categories = createCategories(_symptoms);
      setCategories(_categories);
      setLoading(false);
    })();

    function stickHeader() {
      if (staticFilters?.current) {
        const { bottom } = staticFilters.current.getBoundingClientRect();
        // less than the height of the sticky header
        if (bottom <= 128) {
          setStickyFilters(true);
        } else {
          setStickyFilters(false);
        }
      }
    }
    stickHeader();
    window.addEventListener("scroll", stickHeader);
    return function cleanup() {
      window.removeEventListener("scroll", stickHeader);
    };
  }, []);

  React.useEffect(() => {
    const getDateSymptoms = async () => {
      const dateSymptomsRes = await getSymptomsByDate({
        Day: date.getDate(),
        Month: date.getMonth() + 1, // Dates are not 0 indexed on the back end
        Year: date.getFullYear(),
      });
      const _dateSymptoms = await dateSymptomsRes.json();
      const symptomCount: Record<string, number> = {};
      for (const symptom of _dateSymptoms.symptoms) {
        symptomCount[symptom.symptomType] = symptomCount[symptom.symptomType] ? (symptomCount[symptom.symptomType] + 1) : 1;
      }
      setSymptomCounts(symptomCount);
    };
    getDateSymptoms();
  }, [date]);

  React.useEffect(() => {
    let _symptoms = symptoms;

    if (filters.length) {
      _symptoms = _symptoms.filter((s) => filters.some((f) => s.categories.map(({ name }) => name).includes(f)));
    }

    if (searchedValue.length) {
      _symptoms = _symptoms.filter((s) => s.name.toLowerCase().includes(searchedValue.toLowerCase()));
    }

    const _categories = createCategories(_symptoms);
    setCategories(_categories);
    const _activeCategories = _symptoms.map(({ parentCategory }) => parentCategory);
    setActiveCategories(_activeCategories);
  }, [filters, searchedValue]);

  const handleFilters = (value: string) => {
    if (value === "all") {
      trackTrackerFilters({
        label: "all",
      });
      setFilters([]);
    } else {
      setFilters((prev) => {
        const remove = prev.indexOf(value) > -1;
        if (!remove) {
          trackTrackerFilters({
            label: value,
          });
        }
        return remove ? prev.filter((f) => f !== value) : [...prev, value];
      });
    }
  };

  const handleFilterDropdown = (val?: boolean) => {
    if (val === true || val === false) {
      setFilterDropdown(val);
    } else {
      setFilterDropdown((prev) => !prev);
    }
  };

  const handleCategoryDropdown = (value: string) => {
    setActiveCategories((prev) => {
      const i = prev.indexOf(value);
      if (i > -1) {
        return prev.filter((f) => f !== value);
      }
      return [...prev, value];
    });
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);

    if (value.length < 3) {
      setSuggestions([]);
      setSearchedValue("");
      return;
    }
    const filtered = symptoms?.filter((s) => s.name.toLowerCase().includes(value.toLowerCase()));
    if (filtered) {
      setSuggestions(filtered);
    }
  };

  const handleSuggestion = (value: string) => {
    setSearchValue((prev) => {
      trackTrackerSearch({
        term: prev,
        predictive: value,
      });
      return value;
    });
    setSuggestions([]);
    setSearchedValue(value);
  };

  const handleSearch = () => {
    setSuggestions([]);
    setSearchedValue(searchValue);
    searchInput?.current?.blur();
    trackTrackerSearch({
      term: searchValue,
      predictive: undefined,
    });
  };

  const handleStickyFilterDropdown = () => {
    setStickyFilterDropdown((prev) => !prev);
  };


  const history = useHistory();
  const { getArticlesBySymptomId } = Hooks.useContentApi();
  const { saveSymptom } = Hooks.useSymptomTrackerApi();
  const { trackTrackerLog } = Hooks.useAnalytics();
  const symptomFormProps = Hooks.useSymptomForm();
  const [saving, setSaving] = React.useState<boolean>(false);
  const [saved, setSaved] = React.useState<SymptomResponse & { feedback?: string }>();
  const [relatedArticles, setRelatedArticles] = React.useState<Array<Article> | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [imageIds, setImageIds] = React.useState<Array<string>>([]);
  const [notificationDismissed, setNotificationDismissed] = React.useState<boolean>(false);
  const [showArticles, setShowArticles] = React.useState<number>(1);
  const [showHelpfulResources, setShowHelpfulResources] = React.useState<number>(1);
  const [editSymptomId, setEditSymptomId] = React.useState<string>();

  React.useEffect(() => {
    setSaved(undefined);
    setRelatedArticles(null);
    setShowArticles(1);
    setShowHelpfulResources(1);
  }, [date]);

  const handleEditSymptomId = (id?: string) => {
    setEditSymptomId(id);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!addSymptom) {
      return;
    }
    e.preventDefault();
    setSaving(true);
    setError(null);
    try {
      const { severityRanges } = addSymptom;
      const _severity = severityRanges.length ? severityRanges[0].minRange === 0 ? severityRanges[severityRanges.length - 1].maxRange === 0 ? 0 : symptomFormProps.severity : symptomFormProps.temperature : 0;
      const severityType = severityRanges.length ? severityRanges[0].minRange === 0 ? severityRanges[severityRanges.length - 1].maxRange === 0 ? "none" : "range" : "temperature" : "none";
      const res = await saveSymptom({
        symptomType: addSymptom.name,
        symptomTypeId: addSymptom.id,
        severity: _severity,
        severityType,
        description: symptomFormProps.description,
        actionsTaken: symptomFormProps.actions,
        questions: symptomFormProps.questions,
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        images: imageIds,
      });
      if (res.status === 200) {
        const json: SymptomResponse = await res.json();
        trackTrackerLog({
          group: "Symptoms",
          attachments: imageIds.length ? ["Images"] : [],
          images: imageIds.length,
          files: 0,
          description: symptomFormProps.description.length > 0,
          actions: symptomFormProps.actions.length > 0,
          questions: symptomFormProps.questions.length > 0,
          symptom: {
            name: addSymptom.name,
            type: addSymptom.parentCategory,
            severity: _severity.toString(),
          },
        });
        const tempSeverity = severityType === "temperature" ? _severity / 10 : _severity;
        const feedback = severityRanges.find(({ minRange, maxRange }) => minRange <= tempSeverity && maxRange >= tempSeverity)?.feedbackMessage;
        setSaved({ ...json, feedback });
        setSaving(false);
        const _relatedArticles = await getArticlesBySymptomId(addSymptom.id);
        setRelatedArticles(_relatedArticles);
      } else {
        setError("An error occurred, please try again later.");
        setSaving(false);
      }
    } catch(error) {
      console.error(error);
      setError("An error occurred, please try again later.");
      setSaving(false);
    }
  };

  const handleBackToDashboard = () => {
    history.push(Utils.USER_SYMPTOMS);
    setAddSymptom(undefined);
  };

  const helpfulResources = (relatedArticles ?? [])
    .flatMap((relatedArticle) => relatedArticle.stackedContent)
    .filter((stackedContent) => stackedContent.name === "Helpful Resources")
    .flatMap((stackedContent) => (stackedContent as StackedContent.HelpfulResources).items);

  return (
    <Components.AppLayout
      headerVariation="navigationBack"
    >
      <Components.StickyHeader
        stickyHeader={hookStickyHeader.stickyHeader}
        headerStuck={hookStickyHeader.headerStuck}
        title={auth ? `${auth?.patient_details.first_name}'s Symptoms` : ""}
        setDate={setDate}
        setDatePicker={setDatePicker}
        date={date}
        datePicker={datePicker}
      />
      <FilterAndSearch
        active={Boolean(stickyFilters)}
      >
        <Components.Row
          nowrap="xs"
        >
          <Components.Column
            grow
          >
            <Components.Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch();
              }}
              aria-label="Symptom search form"
            >
              <Styled.SearchInputWrapper>
                <Styled.SearchIconWrapper>
                  <IconSearch
                    width="12"
                    height="12"
                  />
                </Styled.SearchIconWrapper>
                <Styled.SearchInput
                  onChange={(e) => handleSearchChange(e.target.value)}
                  value={searchValue}
                  placeholder="Search for a symptom"
                  ref={stickySearchInput}
                />
                <Styled.SearchButtonWrapper>
                  {searchValue.length ? (
                    <Styled.SearchClearButton
                      type="button"
                      onClick={() => handleSearchChange("")}
                    >
                      <IconCross />
                    </Styled.SearchClearButton>
                  ) : null}
                </Styled.SearchButtonWrapper>
              </Styled.SearchInputWrapper>
              {suggestions.length ? (
                <Styled.SearchSuggestion>
                  {suggestions.map(({ name }) => {
                    const index = name.toLowerCase().indexOf(searchValue.toLowerCase());
                    return (
                      <Styled.Suggestion
                        key={name}
                        type="button"
                        onClick={() => handleSuggestion(name)}
                      >
                        <span>{name.substring(0, index)}</span>
                        <strong>{name.substring(index, index + searchValue.length)}</strong>
                        <span>{name.substring(searchValue.length + index, name.length)}</span>
                      </Styled.Suggestion>
                    );
                  })}
                </Styled.SearchSuggestion>
              ) : null}
            </Components.Form>
          </Components.Column>
          <Components.Column
            shrink
          >
            <FilterDropdownButton
              onClick={() => handleStickyFilterDropdown()}
            >
              {stickyFilterDropdown ? (
                <IconFilterClose
                  width="16"
                  height="16"
                />
              ) : (
                <IconFilterOpen
                  width="16"
                  height="16"
                />
              )}
            </FilterDropdownButton>
          </Components.Column>
        </Components.Row>
        <Styled.StickyFilterWrapper
          active={stickyFilterDropdown}
        >
          <Components.Card
            color="pinkMedium"
          >
            <Styled.FilterLabelWrapper>
              <Styled.Label>Filter by treatment type:</Styled.Label>
            </Styled.FilterLabelWrapper>
            <Styled.FilterContainer
              active
            >
              <Components.SwitchGroup
                handleToggle={handleFilters}
                selected={filters}
                displayAll
                options={symptomCategories.map(({ name }) => ({
                  label: name, value: name,
                }))}
              />
            </Styled.FilterContainer>
          </Components.Card>
        </Styled.StickyFilterWrapper>
      </FilterAndSearch>
      <Components.Section
        background="white"
        margin="negative"
        padding="sm"
      >
        <Components.Row
          spacing="sm"
        >
          <Components.Column
            sm="1/2"
          >
            <Styled.SymptomTitle>
              {"Search for a symptom or filter by treatment type"}
            </Styled.SymptomTitle>
          </Components.Column>
        </Components.Row>
        <Components.Row
          spacing="sm"
        >
          <Components.Column>
            <Components.Form
              onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
            >
              <Styled.SearchInputWrapper>
                <Styled.SearchIconWrapper>
                  <IconSearch
                    width="12"
                    height="12"
                  />
                </Styled.SearchIconWrapper>
                <Styled.SearchInput
                  ref={searchInput}
                  onChange={(e) => handleSearchChange(e.target.value)}
                  value={searchValue}
                  placeholder="Search for a symptom"
                />
                <Styled.SearchButtonWrapper>
                  {searchValue.length ? (
                    <Styled.SearchClearButton
                      type="button"
                      onClick={() => handleSearchChange("")}
                    >
                      <IconCross />
                    </Styled.SearchClearButton>
                  ) : null}
                </Styled.SearchButtonWrapper>
              </Styled.SearchInputWrapper>
              {suggestions.length && !stickyFilters ? (
                <Styled.SearchSuggestion>
                  {suggestions.map(({ name }) => {
                    const index = name.toLowerCase().indexOf(searchValue.toLowerCase());
                    return (
                      <Styled.Suggestion
                        key={name}
                        type="button"
                        onClick={() => handleSuggestion(name)}
                      >
                        <span>{name.substring(0, index)}</span>
                        <strong>{name.substring(index, index + searchValue.length)}</strong>
                        <span>{name.substring(searchValue.length + index, name.length)}</span>
                      </Styled.Suggestion>
                    );
                  })}
                </Styled.SearchSuggestion>
              ) : null}
            </Components.Form>
          </Components.Column>
        </Components.Row>
        <Components.Row
          ref={staticFilters}
        >
          <Components.Column>
            <Components.Card
              color="pinkMedium"
            >
              <Styled.FilterButton
                onClick={() => handleFilterDropdown()}
              >
                <Styled.Label>Filter by treatment type:</Styled.Label>
                <Styled.IconWrapper
                  active={filterDropdown}
                >
                  <IconArrowDown
                    width="10"
                    height="10"
                  />
                </Styled.IconWrapper>
              </Styled.FilterButton>
              <Styled.FilterContainer
                active={filterDropdown}
              >
                <Components.SwitchGroup
                  handleToggle={handleFilters}
                  selected={filters}
                  displayAll
                  options={symptomCategories.map(({ name }) => ({
                    label: name, value: name,
                  }))}
                />
              </Styled.FilterContainer>
            </Components.Card>
          </Components.Column>
        </Components.Row>
        <Components.Row
          spacing="xl"
        >
          <Components.Column>
            {categories.length ? categories.map(({ name, symptoms }) => (
              <React.Fragment
                key={name}
              >
                <Styled.CategoryTitle
                  onClick={() => handleCategoryDropdown(name)}
                >
                  <span>{name}</span>
                  <Styled.IconWrapper
                    colour="blueNavy"
                    active={activeCategories.includes(name)}
                  >
                    <IconArrowDown
                      width="10"
                      height="10"
                    />
                  </Styled.IconWrapper>
                </Styled.CategoryTitle>
                <Components.Section
                  margin="negative"
                  hidden={!activeCategories.includes(name)}
                >
                  <Styled.AddSymptomList>
                    {symptoms.map((symptom) => (
                      <Styled.AddSymptomButton
                        onClick={() => setAddSymptom(symptom)}
                        key={symptom.id}
                      >
                        <span>
                          {symptomCounts[symptom.name] ? (
                            <Styled.SymptomCount>
                              {symptomCounts[symptom.name]}
                            </Styled.SymptomCount>
                          ) : null}
                          {symptom.name}
                        </span>
                        <Styled.AddSymptomButtonIcon>
                          <IconAdd />
                        </Styled.AddSymptomButtonIcon>
                      </Styled.AddSymptomButton>
                    ))}
                  </Styled.AddSymptomList>
                </Components.Section>
              </React.Fragment>
            )) : (
              <Components.H3
                align="center"
              >
                {loading ? "Loading..." : "We're sorry, no symptoms were found."}
              </Components.H3>
            )}
          </Components.Column>
        </Components.Row>
      </Components.Section>
      <Components.SymptomModal
        active={Boolean(addSymptom)}
        handleClose={() => setAddSymptom(undefined)}
        prefix={addSymptom?.name}
        date={date}
      >
        {saved ? (
          <Styled.ResponseWrapper>
            <Components.H4
              colour="pink"
              spacing="xs"
            >Your symptom has been saved</Components.H4>
            <Components.P
              colour="blueNavy"
            >
              {"For: "}
              <Components.Strong>
                {format(new Date(saved.symptomLogEntry.year, saved.symptomLogEntry.month - 1, saved.symptomLogEntry.day), "d MMM yyyy")}
              </Components.Strong>
            </Components.P>
            {saved.feedback && !notificationDismissed ? (
              <Components.Row
                spacing="md"
              >
                <Components.Column>
                  <Components.Section
                    background="blueNavy"
                    margin="negative"
                    padding="sm"
                  >
                    <Components.SpeechBubble
                      onClick={() => setNotificationDismissed(true)}
                    >
                      <Components.P
                        colour="pink"
                        weight="medium"
                        spacing="xs"
                      >
                        {parse(saved.feedback)}
                      </Components.P>
                    </Components.SpeechBubble>
                  </Components.Section>
                </Components.Column>
              </Components.Row>
            ) : null}
            <Components.Row>
              <Components.Column
                spacing="xs"
              >
                <Components.ButtonVariation
                  variation="primary"
                  onClick={() => {
                    setSaved(undefined);
                    setAddSymptom(undefined);
                    symptomFormProps.handleAddNew();
                  }}
                  fullWidth
                >
                  Add another symptom
                </Components.ButtonVariation>
              </Components.Column>
              <Components.Column
                spacing="xs"
              >
                <Components.ButtonVariation
                  variation="secondary"
                  onClick={() => {
                    handleEditSymptomId(saved.symptomLogEntry.id);
                  }}
                  fullWidth
                >
                  Edit this symptom
                </Components.ButtonVariation>
              </Components.Column>
              <Components.Column
                spacing="xs"
              >
                <Components.ButtonLink
                  underlined
                  align="center"
                  weight="medium"
                  fontSize="sm"
                  full
                  onClick={() => handleBackToDashboard()}
                >
                  {"Back to dashboard"}
                </Components.ButtonLink>
              </Components.Column>
              {relatedArticles?.length ? (
                <>
                  <Components.Column
                    spacing="none"
                  >
                    <Components.HorizontalRule />
                  </Components.Column>
                  <Components.Column
                    spacing="xs"
                  >
                    <Components.P
                      weight="medium"
                      colour="blueNavy"
                      spacing="xs"
                    >Recommended articles</Components.P>
                    {relatedArticles?.slice(0, showArticles).map(({ id, name }) => (
                      <Styled.RecommendedCardLink
                        to={Utils.APP_ARTICLE.replace(":articleId", `${id}`)}
                        key={id}
                      >
                        <Styled.CardContent>
                          <Components.Small
                            spacing="none"
                            colour="blueNavy"
                            weight="medium"
                          >
                            {name}
                          </Components.Small>
                        </Styled.CardContent>
                        <Styled.CardIcon>
                          <IconArrowRight
                            width="18"
                            height="18"
                          />
                        </Styled.CardIcon>
                      </Styled.RecommendedCardLink>
                    ))}
                  </Components.Column>
                  {showArticles < relatedArticles.length ? (
                    <Components.Column
                      spacing="sm"
                    >
                      <Components.ButtonLink
                        type="button"
                        underlined
                        align="center"
                        weight="medium"
                        fontSize="sm"
                        full
                        onClick={() => setShowArticles((prev) => prev + 3)}
                      >
                        {"View more recommended articles"}
                      </Components.ButtonLink>
                    </Components.Column>
                  ) : null}
                  {helpfulResources.length ? (
                    <Components.Column
                      spacing="xs"
                    >
                      <Components.P
                        weight="medium"
                        colour="blueNavy"
                        spacing="xs"
                      >Helpful Resources</Components.P>
                      {helpfulResources.slice(0, showHelpfulResources).map((helpfulResource) => (
                        <Styled.RecommendedCardHyperLink
                          background="pinkMedium"
                          shadow="none"
                          target={helpfulResource.link.target}
                          href={Utils.getMediaLink(helpfulResource.link)}
                          key={helpfulResource.name}
                        >
                          <Styled.CardContent>
                            <Components.H4
                              colour="pink"
                              spacing="xs"
                            >
                              <Styled.HelpfulResourceIconWrapper>
                                <IconBooklet />
                                <Styled.LabelWrapper>{helpfulResource.resourceType}</Styled.LabelWrapper>
                              </Styled.HelpfulResourceIconWrapper>
                            </Components.H4>
                            <Components.H4
                              spacing="none"
                              colour="blueNavy"
                              weight="medium"
                            >
                              {helpfulResource.name}
                            </Components.H4>
                          </Styled.CardContent>
                          <Styled.CardIcon>
                            <IconArrowRight
                              width="18"
                              height="18"
                            />
                          </Styled.CardIcon>
                        </Styled.RecommendedCardHyperLink>
                      ))}
                    </Components.Column>
                  ) : null}
                  {helpfulResources && showHelpfulResources < helpfulResources.length ? (
                    <Components.Column>
                      <Components.ButtonLink
                        type="button"
                        underlined
                        align="center"
                        weight="medium"
                        fontSize="sm"
                        full
                        onClick={() => setShowHelpfulResources((prev) => prev + 3)}
                      >
                        {"View more helpful resources"}
                      </Components.ButtonLink>
                    </Components.Column>
                  ) : null}
                </>
              ) : null}
            </Components.Row>
            {addSymptom ? (
              <Components.EditSymptom
                handleEditSymptomId={handleEditSymptomId}
                editSymptomId={editSymptomId}
                symptom={addSymptom}
                setSaved={setSaved}
                symptomResponse={saved}
                date={new Date(saved.symptomLogEntry.year, saved.symptomLogEntry.month, saved.symptomLogEntry.day)}
              />
            ) : null}
          </Styled.ResponseWrapper>
        ) : (
          <Components.SymptomForm
            {...symptomFormProps}
            setImageIds={setImageIds}
            saving={saving}
            handleSubmit={handleSubmit}
            symptom={addSymptom}
            error={error}
            handleBackToDashboard={handleBackToDashboard}
          />
        )}
      </Components.SymptomModal>
      <Styled.CalendarBackground
        active={datePicker}
        onClick={() => setDatePicker(false)}
      />
    </Components.AppLayout>
  );
};

export type SymptomsAddProps = unknown;

export type MergedCategories = {
  name: string
  symptoms: Array<Symptom>
}
