import React from "react";
import { StyledHyperlink } from "../Styled";

export const PhoneLink: React.FC = () => (
  <StyledHyperlink
    href="tel:1800500258"
  >1800 500 258</StyledHyperlink>
);

export const EmailLink: React.FC = () => (
  <StyledHyperlink
    href="mailto:helpline@bcna.org.au "
  >helpline@bcna.org.au</StyledHyperlink>
);

export const PrivacyPolicyLink: React.FC = () => (
  <StyledHyperlink
    href="https://www.bcna.org.au/legal-information/privacy-policy/"
    target="_blank"
    rel="noopener"
  >privacy policy</StyledHyperlink>
);