import React from "react";
import tw, { styled } from "twin.macro";

export const StyledText = styled.div(() => [
  tw`w-full text-center opacity-10 text-xs mt-4`,
]);

export const BuildVersion: React.FunctionComponent = () => (
  <StyledText>{packagejson.npm_package_version.replace("-build.", "-")}</StyledText>
);
