import * as React from "react";
import { css } from "twin.macro";

import { withStyleGuide } from "./withStyleGuide";
import {
  Column,
  Container,
  Row,
  H2,
  H4,
  P,
  TextStyle,
  Small,
  HorizontalRule,
  Card,
  StyledLink,
} from "~/components/Styled";

import * as Utils from "~/utils";
import * as Components from "~/components";

import IconBack from "~/../assets/icons/icon-back.svg";
import IconStar from "~/../assets/icons/icon-star.svg";
import IconBookmark from "~/../assets/icons/icon-bookmark.svg";
import IconPrinter from "~/../assets/icons/icon-print.svg";
import IconShare from "~/../assets/icons/icon-share.svg";
import IconDate from "~/../assets/icons/icon-date.svg";
import IconTopic from "~/../assets/icons/icon-topic.svg";

import { Radio } from "~/components/Radio/Radio";
import { CheckboxGroup } from "~/components/CheckboxGroup/CheckboxGroup";
import { ArticleAccordion } from "~/components/Accordion/ArticleAccordion";
import { GlossaryAccordion } from "~/components/Accordion/GlossaryAccordion";
import { Checkbox } from "~/components/Checkbox/Checkbox";
import { Input } from "~/components/Input/Input";
import { ColourCaption, ColourSwatch } from "./styledStyleGuide";
import { Modal } from "~/components/Modal/Modal";
import { ReadMore } from "~/components/ReadMore/ReadMore";
import { Meta } from "~/components/Meta/Meta";

export const StyleGuide = withStyleGuide(({
  checked,
  handleCheckbox,
  radio,
  handleRadio,
  range,
  handleRange,
  checkboxGroup,
  handleCheckboxGroup,
  popup,
  setPopup,
  handleToast,
  date,
  setDate,
  resetUser,
  resetLoading,
}: Props) => (
  <>
    <Container
      maxWidth="lg"
    >
      <Column>
        <Components.TextVariation
          variation="heading1"
        >
          {"01 Colour"}
        </Components.TextVariation>
        <H4>Primary</H4>
        <Row>
          <Column
            md="1/2"
          >
            <ColourSwatch
              colour={"pink"}
            >
              <ColourCaption>Pink</ColourCaption>
            </ColourSwatch>
          </Column>
          <Column
            md="1/2"
          >
            <ColourSwatch
              colour={"blueNavy"}
            >
              <ColourCaption>Blue Navy</ColourCaption>
            </ColourSwatch>
          </Column>
        </Row>
        <H4>Secondary</H4>
        <Row>
          <Column
            md="1/4"
          >
            <ColourSwatch
              colour={"pinkMedium"}
            >
              <ColourCaption>Pink Medium</ColourCaption>
            </ColourSwatch>
          </Column>
          <Column
            md="1/4"
          >
            <ColourSwatch
              colour={"pinkLight"}
            >
              <ColourCaption>Pink Light</ColourCaption>
            </ColourSwatch>
          </Column>
          <Column
            md="1/4"
          >
            <ColourSwatch
              colour={"pinkDusty"}
            >
              <ColourCaption>Pink Dusty</ColourCaption>
            </ColourSwatch>
          </Column>
          <Column
            md="1/4"
          >
            <ColourSwatch
              colour={"blueDusty"}
            >
              <ColourCaption>Blue Dusty</ColourCaption>
            </ColourSwatch>
          </Column>
        </Row>
        <H4>Neutral</H4>
        <Row>
          <Column
            md="1/4"
          >
            <ColourSwatch
              colour={"greyDark"}
            >
              <ColourCaption>Grey Dark</ColourCaption>
            </ColourSwatch>
          </Column>
          <Column
            md="1/4"
          >
            <ColourSwatch
              colour={"greyMedium"}
            >
              <ColourCaption>Grey Medium</ColourCaption>
            </ColourSwatch>
          </Column>
          <Column
            md="1/4"
          >
            <ColourSwatch
              colour={"greyLight"}
            >
              <ColourCaption>Grey Light</ColourCaption>
            </ColourSwatch>
          </Column>
          <Column
            md="1/4"
          >
            <ColourSwatch
              colour={"white"}
            >
              <ColourCaption>White</ColourCaption>
            </ColourSwatch>
          </Column>
        </Row>
        <H4>Neutral</H4>
        <Row>
          <Column
            md="1/4"
          >
            <ColourSwatch
              colour={"redValidation"}
            >
              <ColourCaption>Red Validation</ColourCaption>
            </ColourSwatch>
          </Column>
          <Column
            md="1/4"
          >
            <ColourSwatch
              colour={"greenValidation"}
            >
              <ColourCaption>Green Validation</ColourCaption>
            </ColourSwatch>
          </Column>
        </Row>
      </Column>
      <HorizontalRule />

      <Column>
        <Components.TextVariation
          variation="heading1"
        >
          {"02 Typography"}
        </Components.TextVariation>
        <H4>Montserrat</H4>
        <Row>
          <Column
            md="1/4"
          >
            <TextStyle
              weight="normal"
              size="3xl"
            >
              Aa
            </TextStyle>
            <Row>
              <TextStyle>
                Regular
              </TextStyle>
            </Row>
          </Column>
          <Column
            md="1/4"
          >
            <TextStyle
              weight="medium"
              size="3xl"
            >
              Aa
            </TextStyle>
            <Row>
              <TextStyle>
                Medium
              </TextStyle>
            </Row>
          </Column>
          <Column
            md="1/4"
          >
            <TextStyle
              weight="semibold"
              size="3xl"
            >
              Aa
            </TextStyle>
            <Row>
              <TextStyle>
                Semibold
              </TextStyle>
            </Row>
          </Column>
          <Column
            md="1/4"
          >
            <TextStyle
              weight="bold"
              size="3xl"
            >
              Aa
            </TextStyle>
            <Row>
              <TextStyle>
                Bold
              </TextStyle>
            </Row>
          </Column>
        </Row>
      </Column>
      <HorizontalRule />

      <Column>
        <Components.TextVariation
          variation="heading1"
        >
          {"03 Type Styles"}
        </Components.TextVariation>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="heading1"
          >
            Heading 1
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="heading1sticky"
          >
            Heading 1 sticky
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="heading2"
          >
            Heading 2
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="heading3"
          >
            Heading 3
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="heading4"
          >
            Heading 4
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="heading5"
          >
            Heading 5
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="heading6"
          >
            Heading 6
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="paragraph1"
          >
            Paragraph 1
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="paragraph2"
          >
            Paragraph 2
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="textlink1"
          >
            Text link 1
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="textlink2"
          >
            Text link 2
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="textlink2nounderline"
          >
            Text link 2 no underline
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="textlink3"
          >
            Text link 3
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="label1"
          >
            Label 1
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="label2"
          >
            Label 2
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="label3"
          >
            Label 3
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="button1"
          >
            Button 1
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="button2"
          >
            Button 2
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="button3"
          >
            Button 3
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="navigation"
          >
            Navigation
          </Components.TextVariation>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.TextVariation
            variation="navigationbold"
          >
            Navigation bold
          </Components.TextVariation>
        </Row>
      </Column>
      <HorizontalRule />

      <Column>
        <Components.TextVariation
          variation="heading1"
        >
          {"07 General components"}
        </Components.TextVariation>
        <H4>Header</H4>
        {[(
          <Components.HeaderVariation
            key={1}
            variation="centered"
          />
        ),(
          <Components.HeaderVariation
            key={2}
            variation="navigationLogin"
          />
        ),(
          <Components.HeaderVariation
            key={3}
            variation="navigationUser"
          />
        ),(
          <Components.HeaderVariation
            key={4}
            variation="navigationHome"
          />
        ),(
          <Components.HeaderVariation
            key={5}
            variation="navigationBack"
          />
        )].map((content, index) => (
          <Row
            spacing="ogxxxs"
            key={index}
          >
            <div
              css={
                css`
                  background-color: white;
                  width: 375px;
                  padding-top: 30px;
                `
              }
            >
              {content}
            </div>
          </Row>
        ))}

        <H4>Footer</H4>
        {[(
          <Components.NavigationVariation
            key={1}
            variation="standard"
            nowrap
            pathname={Utils.USER_DASHBOARD}
          />
        ),(
          <Components.NavigationVariation
            key={2}
            variation="standard"
            nowrap
            pathname={Utils.USER_ARTICLES}
          />
        ),(
          <Components.NavigationVariation
            key={3}
            variation="standard"
            nowrap
            pathname={Utils.USER_SYMPTOMS}
          />
        ),(
          <Components.NavigationVariation
            key={4}
            variation="standard"
            nowrap
            pathname={Utils.USER_NOTES}
          />
        )].map((content, index) => (
          <Row
            spacing="ogxxxs"
            key={index}
          >
            <div
              css={
                css`
                  background-color: white;
                  width: 375px;
                  padding-top: 20px;
                `
              }
            >
              {content}
            </div>
          </Row>
        ))}

        <H4>Buttons</H4>
        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Components.ButtonVariation
              variation="primary"
            >
              Submit &amp; continue
            </Components.ButtonVariation>
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Components.ButtonVariation
              variation="primary"
              disabled
            >
              Submit &amp; continue
            </Components.ButtonVariation>
          </Column>
        </Row>

        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Components.ButtonVariation
              variation="secondary"
            >
              Back
            </Components.ButtonVariation>
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Components.ButtonVariation
              variation="secondary"
              disabled
            >
              Back
            </Components.ButtonVariation>
          </Column>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="secondarysmall"
              iconLeft={<IconBookmark />}
            >
              Bookmark
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="secondarysmall"
              iconLeft={<IconPrinter />}
            >
              Print
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="secondarysmall"
              iconLeft={<IconShare />}
            >
              Share
            </Components.ButtonVariation>
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="secondarysmall"
              disabled
              iconLeft={<IconBookmark />}
            >
              Bookmark
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="secondarysmall"
              disabled
              iconLeft={<IconPrinter />}
            >
              Print
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="secondarysmall"
              disabled
              iconLeft={<IconShare />}
            >
              Share
            </Components.ButtonVariation>
          </Column>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="primarysmall"
              iconLeft={<IconBack />}
            >
              Home
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="secondarysmall"
              iconLeft={<IconBack />}
            >
              Cancel
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="secondarysmall"
              iconLeft={<IconBack />}
            >
              Back
            </Components.ButtonVariation>
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="primarysmall"
              disabled
              iconLeft={<IconBack />}
            >
              Home
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="secondarysmall"
              disabled
              iconLeft={<IconBack />}
            >
              Cancel
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="secondarysmall"
              disabled
              iconLeft={<IconBack />}
            >
              Back
            </Components.ButtonVariation>
          </Column>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="secondarytiny"
              iconRight={<IconDate />}
            >
              Change<br />Date
            </Components.ButtonVariation>
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="secondarytiny"
              disabled
              iconRight={<IconDate />}
            >
              Change<br />Date
            </Components.ButtonVariation>
          </Column>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="secondarysmall"
              iconLeft={<IconBookmark />}
            />
            <Components.ButtonVariation
              variation="secondarysmall"
              iconLeft={<IconPrinter />}
            />
            <Components.ButtonVariation
              variation="secondarysmall"
              iconLeft={<IconShare />}
            />
            <Components.ButtonVariation
              variation="secondarysmall"
              iconLeft={<IconDate />}
            />
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="secondarysmall"
              disabled
              iconLeft={<IconBookmark />}
            />
            <Components.ButtonVariation
              variation="secondarysmall"
              disabled
              iconLeft={<IconPrinter />}
            />
            <Components.ButtonVariation
              variation="secondarysmall"
              disabled
              iconLeft={<IconShare />}
            />
            <Components.ButtonVariation
              variation="secondarysmall"
              disabled
              iconLeft={<IconDate />}
            />
          </Column>
        </Row>

        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="square"
              iconLeft={<IconTopic />}
            >
              Articles by topic
            </Components.ButtonVariation>
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="square"
              disabled
              iconLeft={<IconBookmark />}
            >
              Articles by topic
            </Components.ButtonVariation>
          </Column>
        </Row>

        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="square"
              iconLeft={<IconBookmark />}
            >
              Bookmarks
            </Components.ButtonVariation>
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="square"
              disabled
              iconLeft={<IconBookmark />}
            >
              Bookmarks
            </Components.ButtonVariation>
          </Column>
        </Row>

        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="square"
              iconLeft={<IconStar />}
            >
              Update my preferences
            </Components.ButtonVariation>
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
            justify="between"
          >
            <Components.ButtonVariation
              variation="square"
              disabled
              iconLeft={<IconStar />}
            >
              Update my preferences
            </Components.ButtonVariation>
          </Column>
        </Row>
        <H4>Input fields</H4>
        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Input
              label="Label"
              placeholder="Notes..."
            />
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Input
              label="Label"
              placeholder="Notes..."
              disabled
            />
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Input
              label="Label"
              type="text"
              error="Error message"
              required
            />
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Input
              label="Label"
              type="text"
              value="john@example.com"
              disabled
              required
            />
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Input
              label="Password"
              type="password"
              required
              hideRequired
            />
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Input
              label="Password"
              type="password"
              value="123456"
              required
              hideRequired
              disabled
            />
          </Column>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Components.DateInput
              label="Date (inline)"
              value={date}
              onChange={(val) => setDate(val)}
              min={new Date("1/1/1970")}
              max={new Date("1/1/2025")}
              required
            />
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Components.DateInput
              label="Date (inline)"
              onChange={(val) => setDate(val)}
              value={new Date()}
              max={new Date("1/1/2025")}
              required
              disabled
            />
          </Column>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
          <Components.Select
            label="Select"
            value="1"
            onChange={() => null}
            options={[{
              label: "Past 30 days",
              value: "1",
            },{
              label: "Past 60 days",
              value: "2",
            },{
              label: "Past 90 days",
              value: "3",
            },{
              label: "Past 120 days",
              value: "4",
            },{
              label: "Past 6 months",
              value: "5",
            },{
              label: "Past 12 months",
              value: "6",
            },{
              label: "Past 24 months",
              value: "7",
            },{
              label: "All time",
              value: "8",
            }]}
          />
          </Column>
          <Column
            md="1/2"
            spacing="ogxxxs"
          >
            <Components.FileInput
              placeholder="Save a photo"
              label="File input"
              setFileIds={() => null}
            />
          </Column>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Column
            md="full"
            spacing="ogxxxs"
          >
            <Components.TextArea
              label="Multiline"
              placeholder="Notes..."
              required
              hideRequired
            />
          </Column>
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.Range
            label="Range"
            value={range}
            onChange={handleRange}
            min={1}
            max={10}
            minLabel="Slightly..."
            maxLabel="Extremely..."
            hideIncrementLabel
          />
        </Row>
        <H4>Option fields (radio)</H4>
        <Row
          spacing="ogxxxs"
        >
          <Radio
            checked={radio}
            onChange={handleRadio}
            data={[
              {
                label: "Breast Care Nurse",
                value: "1",
              },
              {
                label: "Breast Screen",
                value: "2",
              },
              {
                label: "Surgeon",
                value: "3",
              },
            ]}
          />
        </Row>
        <H4>Option fields (radio card)</H4>
        <Row
          spacing="ogxxxs"
        >
          <Components.RadioGroupCard
            checked={radio}
            onChange={handleRadio}
            data={[
              {
                label: "I have DCIS (ductal carcinoma in situ)",
                description: "Abnormal cells in the milk ducts, which have not spread into the breast tissue.",
                value: "1",
              },
              {
                label: "I have early breast cancer",
                description: "The breast cancer is contained to the breast and/or lymph nodes in the armpit. Early breast cancer includes: invasive ductal carcinoma in situ, invasive lobular carcinoma in situ and other rarer forms of breast cancer.",
                value: "2",
              },
              {
                label: "I have metastatic breast cancer",
                description: "The breast cancer has spread beyond the breast and lymph nodes to another part of the body.",
                value: "3",
              },
            ]}
          />
        </Row>
        <H4>Option fields (radio timeline)</H4>
        <Row
          spacing="ogxxxs"
        >
          <Components.RadioGroupTimeline
            checked={radio}
            onChange={handleRadio}
            data={[
              {
                label: "I have DCIS (ductal carcinoma in situ)",
                value: "1",
              },
              {
                label: "I have early breast cancer",
                value: "2",
              },
              {
                label: "I have metastatic breast cancer",
                value: "3",
              },
            ]}
          />
        </Row>
        <H4>Option fields (checkbox)</H4>
        <Row
          spacing="ogxxxs"
        >
          <Checkbox
            checked={checked}
            onChange={handleCheckbox}
            label="I agree to the terms and conditions"
          />
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <CheckboxGroup
            checked={checkboxGroup}
            onChange={handleCheckboxGroup}
            data={[
              {
                label: "I identify as Aboriginal and/or Torres Strait Islander",
                value: "aboriginal",
              },
              {
                label: "I am living in a remote or rural area",
                value: "rural",
              },
              {
                label: "I am a man with breast cancer",
                value: "man",
              },
              {
                label: "English is my second language",
                value: "english",
              },
            ]}
          />
        </Row>
      </Column>
      <HorizontalRule />

      <Column>
        <Components.TextVariation
          variation="heading1"
        >
          {"08 Card components"}
        </Components.TextVariation>
        <H4>Topic cards</H4>
        <Row
          spacing="ogxxxs"
        >
          <Components.TopicCard
            topic={{
              id: 9,
              name: "Topic",
              description: "",
              updateDate: "",
              dateReviewed: "",
            }}
            key="topic"
          />
        </Row>
        <H4>Entry cards</H4>
        <Row
          spacing="ogxxxs"
        >
          <Components.EntryCard
            title="Number entry"
            iconValue={10}
            iconLabel="Entries"
            open={false}
            onOpenOrClose={() => null}
          />
        </Row>
        <Row
          spacing="ogxxxs"
        >
          <Components.EntryCard
            title="Date entry"
            iconValue={new Date()}
            open={false}
            onOpenOrClose={() => null}
          />
        </Row>
        <H4>Status cards</H4>
        <Row
          spacing="ogxxxs"
        >
          <Components.RadioGroup
            interactive={false}
            checked=""
            onChange={() => null}
            data={[
              {
                label: "I have DCIS (ductal carcinoma in situ)",
                description: "Abnormal cells in the milk ducts, which have not spread into the breast tissue.",
                value: "dcis",
              },
            ]}
          />
        </Row>
      </Column>

      <Row>
        <Column>
        </Column>
      </Row>
      <Row>
        <Column>
        </Column>
      </Row>
      <Row>
        <Column>
        </Column>
      </Row>
      <Row>
        <Column>
          <H2>Glossary Accordions</H2>
          <HorizontalRule />
          <GlossaryAccordion
            items={[
              {
                label: "Ablation",
                content: <p>The removal or destruction of an area of tissue or part of the body by surgery or by other means such laser, cryo or thermal therapy</p>,
                active: false,
              },
              {
                label: "Adjuvant treatment",
                content: <p>The treatments after breast surgery that help prevent breast cancer returning, for example chemotherapy, radiotherapy, hormone-blocking therapy and targeted therapy</p>,
                active: false,
              },
              {
                label: "Alopecia",
                content: <p>Hair loss on the head or body</p>,
                active: false,
              },
            ]}
          />
          <H2>Article Accordions</H2>
          <HorizontalRule />
          <ArticleAccordion
            articleTitle="Style Guide"
            items={[
              {
                label: "Ablation",
                content: (<p>The removal or destruction of an area of tissue or part of the body by surgery or by other means such laser, cryo or thermal therapy</p>),
                active: false,
              },
              {
                label: "Adjuvant treatment",
                content: (<p>The treatments after breast surgery that help prevent breast cancer returning, for example chemotherapy, radiotherapy, hormone-blocking therapy and targeted therapy</p>),
                active: false,
              },
              {
                label: "Alopecia",
                content: (<p>Hair loss on the head or body</p>),
                active: false,
              },
            ]}
          />
          <H2>Read more accordion</H2>
          <HorizontalRule />
          <ReadMore>
            <P>{"The first couple of weeks after your diagnosis can be overwhelming. You might be feeling anxious and uncertain as you wait for test results and appointments."}</P>
            <P>{"As someone who identifies as Aboriginal or Torres Strait Islander, you may have trouble talking about your breast cancer. People in your community may not talk about cancer very often, or you may not feel comfortable discussing it with others."}</P>
            <P>{"You may also be worried about whether doctors and other medical professionals will understand the importance of your connection to country, community, culture and identity."}</P>
            <P>{"Being in your twenties, it's likely that a diagnosis of metastatic breast cancer has had a powerful impact on you. You may be just starting to build your career, having children or travelling and exploring, and feel as though these opportunities are being taken away from you."}</P>
            <P>{"While there are many different options for treatment of metastatic breast cancer, you may reach a point where treatment doesn't seem to be very effective any more, or side effects are more than you feel you can manage. If this happens, you may decide to limit treatment or stop treatment altogether and focus instead on the things you can do to help you live well."}</P>
          </ReadMore>
        </Column>
      </Row>
      <Row>
        <Column
          spacing="ogxxxs"
        >
          <H2>Reset User</H2>
          <HorizontalRule />
          <P>Clicking the below button will reset the user preferences for whether the user has seen the tracker welcome and cookies notification.</P>
          <Components.ButtonVariation
            variation="primary"
            onClick={() => resetUser()}
            disabled={resetLoading}
          >
            {resetLoading ? "Resetting" : "Reset user"}
          </Components.ButtonVariation>
        </Column>
      </Row>
      <Row>
        <Column
          spacing="ogxxxs"
        >
          <H2>Modal</H2>
          <HorizontalRule />
          <Components.ButtonVariation
            variation="primary"
            onClick={() => setPopup(true)}
          >
            Display Modal
          </Components.ButtonVariation>
        </Column>
      </Row>
      <Row>
        <Column
          spacing="ogxxxs"
        >
          <H2>Toast</H2>
          <HorizontalRule />
          <Components.ButtonVariation
            variation="primary"
            onClick={() => handleToast("Message")}
          >
            Display Toast
          </Components.ButtonVariation>
        </Column>
      </Row>
      <Row>
        <Column
          spacing="ogxxxs"
        >
          <H2>Links</H2>
          <HorizontalRule />
          <StyledLink
            to="/pagenotfound"
          >
            Go to 404
          </StyledLink>
          <StyledLink
            to="/"
          >
            Go to Home
          </StyledLink>
        </Column>
      </Row>
      <Row>
        <Column
          spacing="ogxxxs"
        >
          <H2>Cards</H2>
          <HorizontalRule />
          <H4>Article Cards</H4>
          <Card
            color="pinkMedium"
            padding="md"
          >
          </Card>
        </Column>
        <Column>
          <H4>Topic Cards</H4>
        </Column>
      </Row>
    </Container>
    <Modal
      active={popup}
      setActive={setPopup}
    >
      <H2
        align="center"
      >Welcome back George.</H2>
      <P
        align="center"
        spacing="md"
      >Please update your diagnosis or situation so we can provide you with the right information and support. It only takes a few seconds.</P>
      <H4
        spacing="ogxs"
      >Your diagnosis</H4>
      <Card
        color="pinkMedium"
        padding="sm"
        spacing="ogxxxs"
      >
        <H4
          spacing="xxs"
          colour="greyDark"
        >I have early breast cancer</H4>
        <Small
          spacing="none"
        >The breast cancer is contained to the breast and/or lymph nodes in the armpit. Early breast cancer includes: invasive ductal carcinoma in situ, invasive lobular carcinoma in situ and other rarer forms of breast cancer.</Small>
      </Card>
      <H4
        spacing="ogxs"
      >Your situation</H4>
      <Card
        color="pinkMedium"
        padding="sm"
        spacing="default"
      >
        <H4
          spacing="xxs"
          colour="greyDark"
        >
          {"Started treatment"}
        </H4>
        <Small
          spacing="none"
        >
          {"I'm having surgery, chemotherapy or radiotherapy"}
        </Small>
      </Card>
      <Row>
        <Column
          spacing="ogxs"
        >
          <Components.ButtonVariation
            variation="primary"
            onClick={() => setPopup(false)}
          >
            Update now
          </Components.ButtonVariation>
        </Column>
        <Column>
          <Components.ButtonVariation
            variation="secondary"
            onClick={() => setPopup(false)}
          >
            Continue to My Journey
          </Components.ButtonVariation>
        </Column>
      </Row>
    </Modal>
    <Meta
      title="Style guide"
    />
  </>
));

type Props = {
  checked: boolean;
  handleCheckbox: () => React.Dispatch<React.SetStateAction<boolean>>
  checkboxGroup: Array<string>
  handleCheckboxGroup: () => React.Dispatch<React.SetStateAction<Array<string>>>
  radio: string;
  handleRadio: (value: string) => React.Dispatch<React.SetStateAction<string>>
  range: number;
  handleRange: (value: number) => React.Dispatch<React.SetStateAction<number>>
  radioGroup: string;
  handleRadioGroup: (value: string) => React.Dispatch<React.SetStateAction<string>>
  popup: boolean;
  setPopup: React.Dispatch<React.SetStateAction<boolean>>
  handleToast: (value: string | null) => void
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  resetUser: () => Promise<void>
  resetLoading: boolean
}
