import * as React from "react";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useHpApi } from "~/hooks/useHpApi";
import { HpContext, PatientInfo, ReferralInfo } from "~/providers/HpProvider";
import { HP_AUTH_LOGGED_IN } from "~/utils/config";
import { HP_LOGIN, HP_STEP_5 } from "~/utils/constants";

const step = {
  sequence: 5,
  label: "step4",
};
const group = "hpReferral";
export const withHpStep4 = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<P & Props> => ({ name = "HpStep4", ...props }: Props) => {
  Component.displayName = name;
  const history = useHistory();
  const { hpRefer } = useHpApi();
  const { trackFormStepComplete, trackFormStepView, trackFormInteraction } = useAnalytics();
  const { patientInfo, hpAuthStatus, referralInfo, handleBack } = React.useContext(HpContext);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (hpAuthStatus === HP_AUTH_LOGGED_IN) {
      trackFormStepView({
        group,
        step,
      });
    } else {
      trackFormInteraction({
        event: "complete",
        group,
        step,
      });
      history.push(HP_LOGIN);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    trackFormInteraction({
      event: "submit",
      group,
      step,
    });
    setLoading(true);

    try {
      const _patientInfo = patientInfo as PatientInfo;
      const _referralInfo = referralInfo as ReferralInfo;
      const res = await hpRefer({
        referralInfo: _referralInfo,
        patientInfo: _patientInfo,
      });

      // const json = await res.json();
      if (res.status === 200) {
        trackFormStepComplete({
          parentGroup: group,
          step,
        });
        trackFormInteraction({
          event: "complete",
          group,
          step,
        });
        setLoading(false);
        history.push(HP_STEP_5);
      } else {
        setLoading(false);
        history.push(HP_STEP_5);
      }

    } catch (error) {
      console.error(error);
      setLoading(false);
      throw error;
    }

    trackFormStepComplete({
      parentGroup: group,
      step,
    });
  };
  
  return (
    <Component
      { ...props as P }
      handleBack={handleBack}
      handleSubmit={handleSubmit}
      patientInfo={patientInfo}
      loading={loading}
    />
  );
};

type Props = {
  name?: string
}
