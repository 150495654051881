import * as React from "react";
import { format } from "date-fns";
import ReactCalendar from "react-calendar";
import tw, { css, styled } from "twin.macro";

import LeftChevron from "~/../assets/icons/left-chevron.svg";
import RightChevron from "~/../assets/icons/right-chevron.svg";

const StyledCalendar = styled(ReactCalendar)(() => [
  tw`text-blue-navy`,
  css`
    abbr[title] {
      ${tw`no-underline text-center text-xs w-full block pb-2 font-medium`}
    }
    .react-calendar__navigation {
      ${tw`mb-7`}
    }
    .react-calendar__navigation__arrow[disabled] {
      ${tw`opacity-50`}
    }
    .react-calendar__navigation__prev-button {
      ${tw`pr-4 outline-none focus:outline-none`}
    }
    .react-calendar__navigation__next-button {
      ${tw`pl-4 outline-none focus:outline-none`}
    }
    .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
      ${tw`text-md font-medium`}
    }
    .react-calendar__month-view__weekdays {
      ${tw`border-b border-pink-light mb-4`}
    }
    .react-calendar__tile {
      ${tw`pt-1 pb-2 mb-2 text-sm outline-none focus:outline-none`}
    }
    .react-calendar__tile[disabled] {
      ${tw`opacity-50`}
    }
    .react-calendar__tile--active {
      ${tw`bg-pink-medium rounded-md font-semibold`}
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      ${tw`invisible opacity-0`}
    }
  `,
]);

const StyledActiveDateWrapper = tw.div`flex justify-center`;

const StyledActiveDateDot = styled.div(({
  active,
}: { active: boolean }) => [
  tw`h-2 w-2 inline-block rounded-full bg-pink`,
  ...(active ? [tw`bg-pink`] : [tw`bg-transparent`]),
]);

export const Calendar: React.FunctionComponent<CalendarProps> = ({ date, handleDate, maxDate, minDate, activeDates }) => {
  return (
    <StyledCalendar
      // Setting the calendar type to US starts the day of the week as Sunday
      calendarType="US"
      onChange={handleDate}
      value={date}
      prevLabel={<LeftChevron />}
      nextLabel={<RightChevron />}
      // Remove the year switching arrows
      prev2Label={null}
      next2Label={null}
      formatShortWeekday={(_locale, date) => format(date, "EEEEE")}
      formatMonthYear={(_locale, date) => format(date, "MMM yyyy")}
      minDate={minDate}
      // There is currently no reason for the user to pick a date in the future
      maxDate={maxDate}
      tileContent={(d) => (
        <StyledActiveDateWrapper>
          <StyledActiveDateDot
            active={Boolean(activeDates?.some((e) => +e === +d.date ))}
          />
        </StyledActiveDateWrapper>
      )}
    />
  );
};

export type CalendarProps = {
  date: Array<Date> | Date
  maxDate?: Date
  minDate?: Date
  handleDate: (date: Date | Date[]) => void
  activeDates?: Array<Date>
}
