import * as React from "react";
import { useLocation } from "react-router-dom";

const steps: {
  [key: string]: Step
} = {
  step5: {
    sequence: 5,
    text: "Complete",
  },
  intro: {
    sequence: -1,
    text: "Log in to get started",
  },
  login: {
    sequence: 0,
    text: "Log in to get started",
  },
  step1: {
    sequence: 1,
    text: "Step 1 of 4",
  },
  step2: {
    sequence: 2,
    text: "Step 2 of 4",
  },
  step3: {
    sequence: 3,
    text: "Step 3 of 4",
  },
  step4: {
    sequence: 4,
    text: "Step 4 of 4",
  },
};

export const withHPHeader = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "HPHeader", ...props }: Props) => {
  Component.displayName = name;
  const location = useLocation();

  const url = location.pathname.split("/").slice(-1)[0];
  const step = steps[url];

  return (
    <Component
      { ...props as P }
      step={step}
    />
  );
};


type Props = {
  name?: string
}

export type Step = {
  sequence: number
  text: string
}
