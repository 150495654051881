import * as React from "react";
import { useLocation, useParams } from "react-router-dom";

import { MergedArticle, Topic } from "myjourney-frontend/src/vendor/umbraco";

import * as Hooks from "~/hooks";
import * as Components from "~/components";

export const Category: React.FunctionComponent<CategoryProps> = () => {
  const location = useLocation();
  const { getCategory, getArticles } = Hooks.useContentApi();
  const { categoryId } = useParams<{ categoryId: string }>();
  const [topic, setTopic] = React.useState<Topic | FeaturedTopic>();
  const [articles, setArticles] = React.useState<Array<MergedArticle>>([]);
  const [featuredVideo, setFeaturedVideo] = React.useState<MergedArticle>();

  const sortArticles = (articles: Array<MergedArticle>, order: string = "most_recent"): Array<MergedArticle> => articles.sort((a, b) => {
    const dateA = new Date(a.updateDate);
    const dateB = new Date(b.updateDate);
    if (order === "most_recent") {
      return dateA < dateB ? 1 : -1;
    } else {
      return dateA > dateB ? 1 : -1;
    }
  });

  React.useEffect(() => {
    (async () => {
      /**
       * If the topic is passed with the route state access it from there
       */
      if (categoryId === "featured") {
        setTopic({
          name: "Featured articles",
          description: "The articles most relevant to your situation and preferences",
          id: "featured",
        });
        const allArticles = await getArticles();
        if (allArticles?.featured) {
          setArticles(allArticles.featured);
        }
      } else {
        const locationState = location.state as { topic?: Topic };
        if (locationState?.topic) {
          setTopic(locationState.topic);
        } else {
          const _topic = await getCategory(categoryId);
          setTopic(_topic);
        }
        const allArticles = await getArticles();
        const _articles = allArticles?.[categoryId];
        if (_articles) {
          setArticles(_articles);
        }

        const videoArticles = _articles?.filter((a) => a.documentTypeAlias === "video");
        if (videoArticles) {
          const sortedVideos = sortArticles(videoArticles);
          setFeaturedVideo(sortedVideos[0]);
        }
      }


    })();
  }, [location, categoryId]);

  const handleSort = (value: string) => {
    setArticles((prev) => sortArticles((prev || []).slice() , value));
  };

  return (
    <Components.AppLayout
      headerVariation="navigationBack"
    >
      <Components.Meta
        title={`${topic?.name || ""} | Category`}
      />
      <Components.SearchInput />
      <Components.Row>
        <Components.Column>
          <Components.H2>
            {topic?.name}
          </Components.H2>
          <Components.P>
            {topic?.description}
          </Components.P>
          <Components.Row>
            {featuredVideo ? (
              <Components.Column
                spacing="md"
              >
                <Components.Video
                  title={featuredVideo.name}
                  videoUrl={featuredVideo.videoUrl || ""}
                />
              </Components.Column>
            ) : null}
          </Components.Row>
          {topic?.name === "Featured articles" ? (
            <Components.Row
              spacing="sm"
              items="end"
            >
              <Components.Column
                xs="1/2"
                spacing="none"
              >
                <Components.H3>
                  {`All articles (${articles?.length ? articles?.length : ""})`}
                </Components.H3>
              </Components.Column>
              <Components.Column
                xs="1/2"
                spacing="none"
              >
                <Components.FeaturedSelect
                  label="Sort by"
                  onChange={(e) => handleSort(e.target.value)}
                  options={[
                    {
                      label: "Most Recent",
                      value: "most_recent",
                    },
                    {
                      label: "Least Recent",
                      value: "least_recent",
                    },
                  ]
                }
                />
              </Components.Column>
            </Components.Row>
          ) : (
            <Components.H3>
              {`All articles (${articles?.length ? articles?.length : ""})`}
            </Components.H3>
          )}
          <Components.Card
            color="pinkMedium"
            padding="xs"
            aria-label="Articles card"
          >
            <Components.ArticleList
              articles={articles}
            />
          </Components.Card>
        </Components.Column>
      </Components.Row>
    </Components.AppLayout>
  );
};

export type CategoryProps = unknown;

export type FeaturedTopic = {
  name: string
  description: string
  id: "featured"
}
