import { PatientInfo } from "~/providers/HpProvider";
import { ContentWrapper } from "../styledHp";
import { Span } from "./styledHpStep4";
import { withHpStep4 } from "./withHpStep4";
import { format } from "date-fns";

import * as Components from "~/components";

export const HpStep4 = withHpStep4(({ patientInfo, handleBack, handleSubmit, loading }: Props) => (
  <ContentWrapper>
    <Components.Form
      onSubmit={handleSubmit}
    >
      <Components.Row
        spacing="xl"
      >
        <Components.Column>
          <Components.TextVariation
            variation="heading1"
            spacing="og1"
          >
            {"Confirm your patient's details"}
          </Components.TextVariation>
          <Components.TextVariation
            variation="heading3"
            spacing="og1"
          >
            {"Basic information"}
          </Components.TextVariation>
          <Components.TextContainer
            spacing="ogxxs"
          >
            <Components.TextVariation
              variation="paragraph1"
            >
              <Span>
                {"First name"}
              </Span>
              {patientInfo?.firstName}
              <br />
              <Span>
                {"Last name"}
              </Span>
              {patientInfo?.lastName}
            </Components.TextVariation>
          </Components.TextContainer>
          <Components.TextVariation
            variation="heading3"
            spacing="og1"
          >
            {"Basic details"}
          </Components.TextVariation>
          <Components.TextContainer
            spacing="ogxxs"
          >
            <Components.TextVariation
              variation="paragraph1"
              spacing="xxs"
            >
              <Span>
                {"Diagnosis type"}
              </Span>
              {patientInfo?.diagnosisName}
              <br />
              <Span>
                {"Diagnosis date"}
              </Span>
              {patientInfo?.diagnosisDate ? format(patientInfo.diagnosisDate, "MMMM d, yyyy") : null}
            </Components.TextVariation>
          </Components.TextContainer>
          <Components.TextVariation
            variation="heading3"
            spacing="og1"
          >
            {"Contact details"}
          </Components.TextVariation>
          <Components.TextContainer
            spacing="ogxxs"
          >
            <Components.TextVariation
              variation="paragraph1"
              spacing="xxs"
            >
              <Span>
                {"Email address"}
              </Span>
              {patientInfo?.email}
              <br />
              <Span>
                {"Mobile number"}
              </Span>
              {patientInfo?.mobile}
            </Components.TextVariation>
          </Components.TextContainer>
          <Components.TextVariation
            variation="heading3"
            spacing="og1"
          >
            {"Notification"}
          </Components.TextVariation>
          <Components.TextContainer
            spacing="ogxxs"
          >
            <Components.TextVariation
              variation="paragraph1"
              spacing="xxs"
            >
              <Span>
                {"Patient activation"}
              </Span>
              {patientInfo?.activationNotification ? "Yes" : "No"}
            </Components.TextVariation>
          </Components.TextContainer>
        </Components.Column>
      </Components.Row>
      <Components.Row>
        <Components.Column>

          <Components.TextVariation
            variation="heading3"
            spacing="og1"
          >
            {"Are these details correct?"}
          </Components.TextVariation>
          <Components.Row>
            <Components.Column
              spacing="xs"
            >
              <Components.ButtonVariation
                variation="primary"
                type="submit"
                disabled={loading}
                fullWidth
              >
                {loading ? "Loading..." : "Yes, send My Journey referral"}
              </Components.ButtonVariation>
            </Components.Column>
            <Components.Column>
              <Components.ButtonVariation
                variation="secondary"
                onClick={handleBack}
                fullWidth
              >
                {"No, go back"}
              </Components.ButtonVariation>
            </Components.Column>
          </Components.Row>
        </Components.Column>
      </Components.Row>
    </Components.Form>
  </ContentWrapper>
));

type Props = {
  patientInfo: PatientInfo
  handleBack: () => void
  handleSubmit: () => void
  loading: boolean
}
