import { BCNA_PRIVACY_POLICY } from "~/utils/constants";
import { SpeechBubble } from "../SpeechBubble/SpeechBubble";
import { Column, Container, P, Row, Section, StyledHyperlink } from "../Styled";
import { withCookiesNotification } from "./withCookiesNotification";

export const CookiesNotification = withCookiesNotification(({ active, handleAccept }: Props) => active ? (
  <Section
    background="blueNavy"
  >
    <Container
      maxWidth="md"
      spacing="none"
    >
      <Row
        spacing="none"
      >
        <Column
          spacing="none"
        >
          <SpeechBubble
            onClick={handleAccept}
          >
            <P
              colour="pink"
              weight="medium"
              spacing="xs"
            >
              {"We use cookies to help personalise content and provide you with a better experience. By clicking OK  you agree to this as outlined in our:"}
            </P>
            <P
              colour="pink"
              weight="medium"
              spacing="none"
            >
              <StyledHyperlink
                underlined
                href={BCNA_PRIVACY_POLICY}
                target="_blank"
                weight="medium"
              >
                Privacy Policy
              </StyledHyperlink>
            </P>
          </SpeechBubble>
        </Column>
      </Row>
    </Container>
  </Section>
) : null);

type Props = {
  active: boolean;
  handleAccept: () => void
}
