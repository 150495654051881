import * as React from "react";

import { Note } from "myjourney-frontend/src/vendor/umbraco";

import * as Hooks from "~/hooks";
import * as Components from "~/components";

export const NoteList: React.FC<NoteListProps> = (props) => {
  const hookDateRange = Hooks.useDateRange();
  const { getNotesByDateRange } = Hooks.useSymptomTrackerApi();
  const [notes, setNotes] = React.useState<Array<Note>>([]);

  const getNotes = async () => {
    const selectedDateRange = props.dateRange ?? hookDateRange.default;
    const res = await getNotesByDateRange({
      FromDay: selectedDateRange.start.getDate(),
      FromMonth: selectedDateRange.start.getMonth() + 1,
      FromYear: selectedDateRange.start.getFullYear(),
      ToDay: selectedDateRange.end.getDate(),
      ToMonth: selectedDateRange.end.getMonth() + 1,
      ToYear: selectedDateRange.end.getFullYear(),
    });
    if (res.status === 200) {
      const notesRes = await res.json();
      setNotes(notesRes.notes.sort((a: { created: string; }, b: { created: string; }) => new Date(b.created).getTime() - new Date(a.created).getTime()));
    }
  };

  React.useEffect(() => {
    getNotes();
  }, [props.dateRange?.start.toDateString(), props.dateRange?.end.toDateString()]);

  return (
    <Components.Grid
      cols="1"
      gap="2"
    >
      {notes?.map((note, index) => (
        <Components.NoteListItem
          key={note.id}
          name={note.title}
          date={new Date(note.year, note.month - 1, note.day)}
          content={(
            <Components.P
              colour="greyDark"
              size="sm"
            >
              {note.text}
            </Components.P>
          )}
          note={note}
          index={index}
          getNotes={getNotes}
        />
      ))}
    </Components.Grid>
  );
};

export type NoteListProps = {
  dateRange?: {
    start: Date;
    end: Date;
  };
};

