import * as React from "react";
import tw, { styled } from "twin.macro";

import * as Components from "~/components";

import InvalidIcon from "~/../assets/icons/invalid.svg";
import ValidIcon from "~/../assets/icons/valid.svg";

export const StyledListItem = tw.li`flex items-center w-full`;

export const StyledListIcon = styled.div(({ valid }: { valid: boolean }) => [
  tw`mr-og4 bg-pink-light rounded-full transition`,
  ...(valid ? [tw`bg-opacity-0 text-green-validation`] : [tw`bg-opacity-40 text-red-validation`]),
]);

export const StyledValidWrapper = tw.div`bg-white rounded-full mr-2 text-green-validation`;

export const PasswordHelper: React.FC<Props> = ({
  rules,
}) => {
  const keys = Object.keys(rules);
  const anyFalse = keys.find((key) => rules[key] === false);
  return anyFalse ? (
    <Components.Grid
      cols="1"
      colsMd="2"
      gapX="4"
      gapY="2"
    >
      {keys.map((key) => (
        <StyledListItem
          key={key}
        >
          <StyledListIcon
            valid={rules[key]}
          >
            {rules[key] ? (
              <ValidIcon />
            ) : (
              <InvalidIcon />
            )}
          </StyledListIcon>
          <Components.Small
            colour="blueNavy"
            spacing="none"
          >
            {key}
          </Components.Small>
        </StyledListItem>
      ))}
    </Components.Grid>
  ) : (
    <Components.Card
      color="blueDusty"
      padding="sm"
    >
      <Components.Column
        justify="start"
      >
        <StyledValidWrapper>
          <ValidIcon />
        </StyledValidWrapper>
        <Components.TextVariation
          variation="paragraph2"
          fullWidth
        >
          Your password is secure
        </Components.TextVariation>
      </Components.Column>
    </Components.Card>
  );
};

type Props = {
  rules: Record<string, boolean>
}
