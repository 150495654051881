import * as React from "react";

import { Symptom } from "myjourney-frontend/src/vendor/umbraco";

import * as Hooks from "~/hooks";
import * as Components from "~/components";

export const SymptomList: React.FC<SymptomListProps> = (props) => {
  const hookDateRange = Hooks.useDateRange();
  const dateRange = props.dateRange ?? hookDateRange.default;
  const symptomDataProps = Hooks.useSymptomData(dateRange);
  const contentApi = Hooks.useContentApi();
  const [symptoms, setSymptoms] = React.useState<Array<Symptom>>([]);

  React.useEffect(() => {
    (async () => {
      const _symptoms = await contentApi.getSymptoms();
      setSymptoms(_symptoms);
    })();
  }, []);

  return (
    <Components.Grid
      cols="1"
      gap="2"
    >
      {symptomDataProps.symptomDetails.map((symptom, index) => {
        return (
          <Components.SymptomListItem
            key={symptom.name}
            allSymptoms={symptoms}
            index={index}
            {...symptom}
          />
        );
      })}
    </Components.Grid>
  );
};

export type SymptomListProps = {
  dateRange?: {
    start: Date;
    end: Date;
  };
};

