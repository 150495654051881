import tw, { css, styled } from "twin.macro";

export const TooltipContainer = tw.div`relative`;
const positions = {
  top: [tw`top-auto border-b-0`, css`border-top-color: #fff;`],
  right: [
    tw`right-auto border-l-0`,
    css`
      border-right-color: #fff;
      left: -4px;
    `,
  ],
  left: [
    tw`left-auto border-r-0`, 
    css`
      border-left-color: #fff;
      right: -4px;
    `,
  ],
  bottom: [tw`bottom-auto border-t-0`, css`border-bottom-color: #fff;`],
};
export const TooltipInner = styled.div(() => [
  tw`w-80 bg-white p-3 rounded-sm shadow-tooltip text-mdsm text-grey-dark`,
]);
export const TooltipElement = styled.div(({ position }: Props) => [
  tw`absolute z-70 cursor-default py-2 px-1 animate-fadeIn`,
  css`
  &::after {
      content: '';
      border: 9px solid transparent;
      ${tw`w-0 h-0 m-auto block absolute inset-0`}
      ${positions[position]}
    }
  `,
]);

type Props = {
  position: "top" | "right" | "bottom" | "left"
}