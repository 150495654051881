import * as React from "react";
import parse from "html-react-parser";
import { RichText } from "~/components/Styled";
import { StackedContent } from "myjourney-frontend/src/vendor/umbraco";

export const TextEditor: React.FC<Props> = ({ content }) => (
  <RichText>
    {parse(content)}
  </RichText>
);

type Props = StackedContent.TextEditor
