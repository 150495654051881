import * as React from "react";
import tw, { styled } from "twin.macro";
import { useHistory, useLocation } from "react-router-dom";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Providers from "~/providers";

import IconNavigationHome from "~/../assets/icons/icon-navigation-home.svg";
import IconNavigationStories from "~/../assets/icons/icon-navigation-stories.svg";
import IconNavigationArticles from "~/../assets/icons/icon-navigation-articles.svg";
import IconNavigationTracker from "~/../assets/icons/icon-navigation-tracker.svg";
import IconNavigationNotes from "~/../assets/icons/icon-navigation-notes.svg";

export const StyledNavigationWrapper = styled.nav(() => [
  tw`fixed bottom-0 left-0 right-0 sm:rounded-t-sm md:-mt-20 print:(hidden) flex justify-center z-60`,
]);

export const StyledNavigationContent = styled.div(() => [
  tw` max-w-screen-md mx-auto w-full flex flex-row justify-around items-center bg-white h-20 border-t-2 border-blue-dusty`,
]);

const StyledNavigationLink = styled.button((props: { active: boolean }) => [
  tw`h-full w-full flex flex-col justify-end items-center pb-4 text-xxs text-pink focus:outline-none`,
  ...(props.active ? [tw`text-pink`] : [tw`text-blue-navy`]),
]);

const NavigationVariations = {
  standard: (props: NavigationVariationProps) => {
    const history = useHistory();
    const location = useLocation();
    const { trackNavigationMenuClick } = Hooks.useAnalytics();

    const handleNavigation = (label: string, link: string) => {
      trackNavigationMenuClick({ label, url: link });
      history.push(link);
    };

    const pathname = props.pathname ?? location.pathname;

    const content = (
      <StyledNavigationContent>
        <StyledNavigationLink
          active={pathname === Utils.USER_DASHBOARD}
          onClick={() => handleNavigation("Home", Utils.USER_DASHBOARD)}
          aria-label="Home"
        >
          <IconNavigationHome />
          {"Home"}
        </StyledNavigationLink>
        <StyledNavigationLink
          active={pathname === Utils.USER_STORIES}
          onClick={() => handleNavigation("Stories", Utils.USER_STORIES)}
          aria-label="Stories"
        >
          <IconNavigationStories />
          {"Stories"}
        </StyledNavigationLink>
        <StyledNavigationLink
          active={pathname === Utils.USER_ARTICLES}
          onClick={() => handleNavigation("Articles", Utils.USER_ARTICLES)}
          aria-label="Articles"
        >
          <IconNavigationArticles />
          {"Articles"}
        </StyledNavigationLink>
        <StyledNavigationLink
          active={pathname === Utils.USER_SYMPTOMS}
          onClick={() => handleNavigation("Tracker", Utils.USER_SYMPTOMS)}
          aria-label="Tracker"
        >
          <IconNavigationTracker />
          {"Tracker"}
        </StyledNavigationLink>
        <StyledNavigationLink
          active={pathname === Utils.USER_NOTES}
          onClick={() => handleNavigation("Notes", Utils.USER_NOTES)}
          aria-label="Notes"
        >
          <IconNavigationNotes />
          {"Notes"}
        </StyledNavigationLink>
      </StyledNavigationContent>
    );

    if (props.nowrap) {
      return content;
    }

    return (
      <StyledNavigationWrapper
        aria-label="Navigation"
      >
        {content}
      </StyledNavigationWrapper>
    );
  },
};

export const NavigationVariation: React.FunctionComponent<NavigationVariationProps> = (props) => {
  const { auth } = React.useContext(Providers.PatientContext);

  if (!auth) {
    return null;
  }

  const Component = NavigationVariations[props.variation];

  return (
    <Component
      {...props}
    />
  );
};

export type NavigationVariationProps = {
  variation: keyof typeof NavigationVariations;
  pathname?: string;
  nowrap?: boolean;
};
