import { WelcomeData } from "myjourney-frontend/src/vendor/umbraco";
import parse from "html-react-parser";
import { withImportantInfo } from "./withImportantInfo";
import { Meta } from "~/components/Meta/Meta";

import * as Utils from "~/utils";
import * as Components from "~/components";

export const ImportantInfo = withImportantInfo(({ welcomeData }: Props) => (
  <Components.AppLayout
    headerVariation="navigationUser"
  >
    <Components.TextVariation
      variation="heading1"
      spacing="lg"
    >
      {"Important information you should know before getting started."}
    </Components.TextVariation>
    <Components.Row
      spacing="lg"
    >
      <Components.Column>
        {welcomeData?.importantInfo.map(({ title, copy }) => (
          <Components.RichText
            key={title}
          >
            <Components.H3
              leading="md"
              spacing="xs"
            >{title}</Components.H3>
            <>{parse(copy)}</>
          </Components.RichText>
        ))}
      </Components.Column>
    </Components.Row>
    <Components.Row
      spacing="xl"
    >
      <Components.Column
        spacing="lg"
      >
        <Components.ButtonVariation
          variation="primary"
          href={Utils.USER_DASHBOARD}
        >
          {"Continue"}
        </Components.ButtonVariation>
      </Components.Column>
    </Components.Row>
    <Meta
      title="Important information"
    />
  </Components.AppLayout>
));

type Props = {
  welcomeData: WelcomeData
}
