import * as React from "react";

import * as Styled from "./styledAbout";
import * as Components from "~/components";

import LogoAustralianGovernment from "~/../assets/images/australian-government-logo.png";
import LogoFoundationPartners from "~/../assets/images/foundation-partners-logo.png";
import LogoMajorPartners from "~/../assets/images/major-partners-logo.png";
import LogoBerlei from "~/../assets/images/berlei.png";
import LogoSussan from "~/../assets/images/sussan.png";
import LogoFocusOn from "~/../assets/images/focuson.png";
import LogoRedEnergy from "~/../assets/images/redenergy.png";
import LogoReflex from "~/../assets/images/reflex.png";

export const About: React.FunctionComponent<AboutProps> = () => {
  return (
    <Components.PublicLayout>
      <Components.Row
        spacing="lg"
      >
        <Components.Column
          spacing="lg"
        >
          <Components.TextVariation
            variation="heading1"
            spacing="lg"
          >
            {"About this tool"}
          </Components.TextVariation>
          <Components.P>BCNA acknowledges and thanks the many people diagnosed with breast cancer who contributed to the development of <Styled.Italic>My Journey</Styled.Italic>.</Components.P>
          <Components.P>We also acknowledge and appreciate the many health professionals who supported its development.</Components.P>
          <Components.P><Styled.Italic>My Journey</Styled.Italic> is a Cancer Australia <Styled.Italic>Supporting Women in Rural Areas Diagnosed With Breast Cancer</Styled.Italic> ‌Program initiative, funded by the Australian Government.</Components.P>
          <Styled.Image
            width="188px"
            src={LogoAustralianGovernment}
          />
        </Components.Column>
        <Components.Column
          spacing="md"
        >
          <Components.TextVariation
            variation="heading2"
          >
            We thank our partners
          </Components.TextVariation>
          <Components.TextVariation
            variation="paragraph1"
          >
            Foundation partner
          </Components.TextVariation>
          <Styled.Image
            width="180px"
            src={LogoFoundationPartners}
          />
        </Components.Column>
        <Components.Column>
          <Components.P>Major partners</Components.P>
          <Components.ShowDesktop>
            <Styled.Image
              width="76%"
              src={LogoMajorPartners}
            />
          </Components.ShowDesktop>
          <Components.HideDesktop>
            <Styled.Image
              src={LogoBerlei}
              alt="Berlei logo"
              style={{ height: "30px" }}
            />
            <Styled.Image
              src={LogoSussan}
              alt="Sussan logo"
              style={{ height: "22px" }}
            />
            <Styled.Image
              src={LogoFocusOn}
              alt="Focuson logo"
              style={{ height: "45px" }}
            />
            <Styled.Image
              src={LogoRedEnergy}
              alt="Red Energy logo"
              style={{ height: "57px" }}
            />
            <Styled.Image
              src={LogoReflex}
              alt="Reflex log"
              style={{ height: "45px" }}
            />
          </Components.HideDesktop>
        </Components.Column>
      </Components.Row>
      <Components.Meta
        title="About My Journey"
        metaDescription="Information about the My Journey app for people who have been diagnosed with breast cancer or DCIS."
      />
    </Components.PublicLayout>
  );
};

export type AboutProps = unknown;
