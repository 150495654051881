import * as React from "react";
import { format, parseISO } from "date-fns";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Providers from "~/providers";
import * as Components from "~/components";
import useGetMetastaticBreastCancerDiagnosisId from "~/hooks/useGetMetastaticBreastCancerDiagnosisId";

export const MyAccount: React.FunctionComponent<MyAccountProps> = () => {
  Hooks.useAuthentication();
  const { auth, diagnosis, situation, treatment, about } = React.useContext(
    Providers.PatientContext
  );

  const { metastaticDiagnosisId } = useGetMetastaticBreastCancerDiagnosisId();

  return (
    <Components.AppLayout headerVariation="navigationUser">
      <Components.Meta title="Profile" />
      <Components.Row>
        <Components.Column>
          <Components.TextVariation variation="heading1" spacing="og1">
            {"Profile"}
          </Components.TextVariation>
          <Components.AccountCard
            title="My account"
            linkText="Change"
            link={Utils.USER_ACCOUNT_DETAILS}
            items={[
              {
                label: "Email",
                value: auth?.patient_details.email,
              },
              {
                label: "Phone number",
                value: auth?.patient_details.phone,
              },
              {
                label: "Password",
                value: "***********",
              },
            ]}
          />
          <Components.AccountCard
            title="My details"
            linkText="Change"
            link={Utils.USER_PERSONAL_DETAILS}
            items={[
              {
                label: "First name",
                value: auth?.patient_details.first_name,
              },
              {
                label: "Last name",
                value: auth?.patient_details.last_name,
              },
              {
                label: "Mon / Year of birth",
                value:
                  auth && auth.patient_details.dob
                    ? format(parseISO(auth.patient_details.dob), "MM / yyyy")
                    : undefined,
              },
              {
                label: "Postcode",
                value: auth?.patient_details.address_postcode,
              },
            ]}
          />
          {diagnosis && (
            <Components.AccountCard
              title="My diagnosis"
              linkText="Change"
              link={Utils.USER_DIAGNOSIS}
              items={[
                {
                  label: "Diagnosis",
                  value: auth?.patient_details.first_name,
                },
                {
                  label: "Mon / Year of diagnosis",
                  value:
                    auth && auth.patient_details.diagnosis_date
                      ? format(
                          parseISO(auth.patient_details.diagnosis_date),
                          "MM / yyyy"
                        )
                      : undefined,
                },
                ...(diagnosis.id !== metastaticDiagnosisId
                  ? [
                      {
                        label: "Scenario",
                        value: situation?.name,
                      },
                    ]
                  : [
                      {
                        label: "Treatment",
                        value: treatment?.name,
                      },
                    ]),
                {
                  label: "Interested in information for ",
                  value: about?.map((item) => item.name).join(", "),
                },
              ]}
            />
          )}
        </Components.Column>
      </Components.Row>
    </Components.AppLayout>
  );
};

export type MyAccountProps = unknown;
