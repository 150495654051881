import * as React from "react";
import CalendarIcon from "~/../assets/icons/small-calendar.svg";
import CalendarCloseIcon from "~/../assets/icons/calendar-close.svg";
import { Wrapper, Label, Button } from "./styledDateButton";

export const DateButton: React.FC<Props> = ({ onClick, active, full, padding }) => (
  <Button
    onClick={onClick}
    full={full}
    padding={padding}
  >
    <Wrapper>
      <Label>{"Change"}</Label>
      <Label>{"Date"}</Label>
    </Wrapper>
    {active ? (
      <CalendarCloseIcon />
    ) : (
      <CalendarIcon />
    )}
  </Button>
);

type Props = {
  onClick: () => void
  active: boolean
  full?: boolean
  padding?: "none" | "xs"
}
