import * as React from "react";
import { useLocation } from "react-router-dom";

import * as Components from "~/components";
import * as Styled from "./styledAppLayout";

export const AppLayout: React.FunctionComponent<AppLayoutProps> = (props) => {
  const location = useLocation();

  return (
    <React.Fragment>
      {location.pathname.includes("feed") && (
        <Components.CookiesNotification />
      )}
      <Components.Container
        minHeight="screen"
        maxWidth="md"
      >
        <Styled.StyledCard
          padding="container"
          shadow="md:default"
        >
          <Components.HeaderVariation
            variation={props.headerVariation}
          />
          {props.children}
          <Components.FooterVariation
            variation={props.footerVariation ?? "standard"}
          />
        </Styled.StyledCard>
        <Components.NavigationVariation
          variation="standard"
        />
      </Components.Container>
    </React.Fragment>
  );
};

export type AppLayoutProps = {
  headerVariation: Components.HeaderVariationProps["variation"];
  footerVariation?: Components.FooterVariationProps["variation"];
};
