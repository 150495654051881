import { ArticleButton, ArticleContent, IconWrapper, Item, Items, Label } from "./styledAccordion";
import { ArticleAccordionItem, withArticleAccordion } from "./withArticleAccordion";
import ArrowDown from "~/../assets/icons/arrow-down.svg";
import { RichText, Section } from "../Styled";

export const ArticleAccordion = withArticleAccordion(({ items, handleActive }: Props) => (
  <Section
    background="pinkMedium"
    margin="negative"
  >
    <Items>
      {items.map(({ label, active, content }, index) => (
        <Item
          key={label}
        >
          <ArticleButton
            onClick={() => handleActive(index)}
          >
            <Label
              colour="pink"
            >
              {label}
            </Label>
            <IconWrapper
              active={!!active}
            >
              <ArrowDown
                width="10"
                height="10"
              />
            </IconWrapper>
          </ArticleButton>
          <ArticleContent
            active={!!active}
            padding="md"
          >
            <RichText>
              {content}
            </RichText>
          </ArticleContent>
        </Item>
      ))}
    </Items>
  </Section>
));

type Props = {
  handleActive: (item: number) => void
  items: Array<ArticleAccordionItem>
}
