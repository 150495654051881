import * as React from "react";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Components from "~/components";

import IconPlus from "~/../assets/icons/icon-plus.svg";

export const TemplateDashboardResourceTracker: React.FunctionComponent<TemplateDashboardResourceTrackerProps> = () => {
  const hookSymptoms = Hooks.useSymptoms();
  const hookDateRange = Hooks.useDateRange();

  return (
    <React.Fragment>
      <Components.Box
        spacing="og6"
      >
        <Components.TextVariation
          variation="heading1"
          spacing="og1"
          fullWidth
        >
          {"My symptom tracker"}
        </Components.TextVariation>
        <Components.TextVariation
          variation="heading3"
          fullWidth
        >
          {"How are you feeling today?"}
        </Components.TextVariation>
      </Components.Box>
      <Components.Box
        spacing="og6"
      >
        <Components.ButtonVariation
          variation="primary"
          href={Utils.USER_SYMPTOMS_ADD}
          aria-label="Add symptom"
          iconLeft={<IconPlus />}
          fullWidth
        >
          {"Symptom"}
        </Components.ButtonVariation>
      </Components.Box>
      {!hookSymptoms.loading && (
        hookSymptoms.symptoms.length === 0 ? (
          <Components.Row>
            <Components.Column>
              <Components.TextVariation
                variation="heading3"
              >
                {"Add your first symptom above so you can:"}
              </Components.TextVariation>
              <Components.TextContainer>
                <Components.TextVariation
                  variation="paragraph1"
                  color="blueNavy"
                >
                  <ul>
                    <li>{"Track your progress over time"}</li>
                    <li>{"Share information with health professionals"}</li>
                    <li>{"Improve your treatment plan"}</li>
                  </ul>
                </Components.TextVariation>
              </Components.TextContainer>
            </Components.Column>
          </Components.Row>
        ) : (
          <React.Fragment>
            {hookSymptoms.graph && (
              <Components.Box
                spacing="og6"
              >
                <Components.TextVariation
                  variation="heading3"
                  spacing="og4"
                  fullWidth
                >
                  {"30 day summary"}
                </Components.TextVariation>
                <Components.SymptomGraphCombined
                  dateRange={hookDateRange.values[Hooks.DATE_RANGE.DEFAULT]}
                />
              </Components.Box>
            )}
            <Components.Box
              spacing="og6"
            >
              <Components.TextVariation
                variation="heading3"
                spacing="og4"
                fullWidth
              >
                {"Recent symptoms"}
              </Components.TextVariation>
              <Components.SymptomList
                dateRange={hookDateRange.values[Hooks.DATE_RANGE.DEFAULT]}
              />
            </Components.Box>
            <Components.ViewAllLink
              label="View all symptoms"
              href={Utils.USER_SYMPTOMS}
            />
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export type TemplateDashboardResourceTrackerProps = unknown;
