
import tw, { styled } from "twin.macro";

export const ModalOuter = styled.div(({ active }: { active: boolean }) => [
  tw`fixed inset-0 transition transform h-full w-full z-30 duration-500 print:(hidden)`,
  ...(active ? [tw`translate-y-0`] : [tw`-translate-y-full pointer-events-none`]),
]);

export const ModalBackground = styled.div(({ active }: ActiveProps) => [
  tw`bg-white fixed inset-0 w-full h-full transition duration-500`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0`]),
]);

export const ModalInner = tw.div`flex flex-col h-full items-center justify-center`;
export const ModalContent = styled.div(() => [
  tw`bg-white max-w-screen-md px-20 pt-4 w-full z-30 relative mx-auto shadow-md overflow-y-auto h-full`,
]);

type ActiveProps = {
  active: boolean
}
