import tw, { styled } from "twin.macro";
import { Wraps } from "../Styled/Global";

const styles = {
  view: {
    "none": tw``,
    "xs": tw`hidden`,
    "sm": tw`sm:hidden`,
    "md": tw`md:hidden`,
    "lg": tw`lg:hidden`,
  },
};

export const ArrowContainer = styled.div(({
  view = "md",
}: ArrowProps) => [
  tw`flex justify-end w-full`,
  styles.view[view],
]);
export const ArrowButton = styled.button(() => [
  tw`text-pink focus:outline-none`,
]);

type ArrowProps = {
  view?: Wraps
}
