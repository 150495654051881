import * as React from "react";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { usePatientApi } from "~/hooks/usePatientApi";
import { useUserTypes } from "~/hooks/useUserTypes";
import { USER_MY_ACCOUNT } from "~/utils/constants";
import { PatientContext } from "~/providers/PatientProvider";
import { useAuthentication } from "~/hooks/useAuthentication";
import { generateSelectedUserTypeIds } from "~/utils/functions";
import { parse } from "date-fns";
import { ToastContext } from "~/providers/ToastProvider";

export const withAboutMe = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "AboutMe", ...props }: Props) => {
  Component.displayName = name;
  useAuthentication();
  const { auth } = React.useContext(PatientContext);
  const history = useHistory();
  const userTypeProps = useUserTypes();
  const { patientUpdate } = usePatientApi();
  const { handleToast } = React.useContext(ToastContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [changed, setChanged] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (userTypeProps.selectedUserTypes !== auth?.patient_details.usertype_ids) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [userTypeProps.selectedUserTypes]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await patientUpdate({
        patient_details: {
          usertype_ids: generateSelectedUserTypeIds(
            userTypeProps.selectedUserTypes,
            auth?.patient_details?.dob ? parse(auth?.patient_details?.dob.split("T")[0], "yyyy-MM-dd", new Date()) : new Date(),
            auth?.patient_details?.diagnosis_date ? 
            parse(auth?.patient_details.diagnosis_date.split("T")[0], "yyyy-MM-dd", new Date()) : new Date(),
          ),
        },
      });
      if (!res) {
        return;
      }
      const json = await res.json();
      if (json.success) {
        history.push(USER_MY_ACCOUNT);
        handleToast?.("Profile updated successfully");
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
    setLoading(false);
  };

  return (
    <Component
      { ...props as P & Props }
      {...userTypeProps}
      loading={loading}
      changed={changed}
      handleSubmit={handleSubmit}
    />
  );
};
type Props = {
  name?: string
}
