import * as React from "react";
import { StyledSelect, InputWrapper, Label } from "./styledFeaturedSelect";

export const FeaturedSelect: React.FC<Props> = ({
  label,
  value,
  onBlur,
  onChange,
  name,
  options,
}: Props) => (
  <InputWrapper>
    {label ? (
      <Label>
        {label}
      </Label>
    ) : null}
    <StyledSelect
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      name={name}
    >
      {options.map(({ label, value }) => (
        <option
          value={value}
          key={value}
        >
          {label}
        </option>
      ))}
    </StyledSelect>
  </InputWrapper>
);

type Props = {
  name?: string
  label?: string
  value?: string
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Array<{
    label: string
    value: string
  }>
}
