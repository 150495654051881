import tw, { css, styled } from "twin.macro";
import { Spacings, Global } from "../Styled/Global";

const styles = {
  ...Global,
  colour: {
    default: tw`bg-white border-white text-pink shadow hover:(shadow-md)`,
    disabled: tw`bg-pink-light border-pink-light text-grey-light cursor-not-allowed pointer-events-none`,
  },
  padding: {
    none: tw`py-2 px-5`,
    xs: tw`py-2 px-14`,
  },
};

export const Wrapper = tw.div`mr-2`;
export const Label = tw.span`w-full block text-right text-xxs leading-3 font-semibold`;

export const Button = styled.button(({
  spacing = "none",
  disabled,
  full,
  padding = "none",
}: Props) => [
  styles.spacing[spacing],
  styles.padding[padding],
  ...(full ? [tw`w-full`] : []),
  ...(disabled ? [styles.colour.disabled] : [styles.colour.default]),
  tw`inline-flex select-none text-center max-w-full outline-none focus:outline-none transition-all duration-200 items-center justify-center rounded font-sans text-xs font-bold tracking-tight text-center outline-none leading-5 active:shadow-lg border`,
  css`
    * {
      ${tw`pointer-events-none`}
    }
  `,
]);

type Props = {
  spacing?: Spacings
  full?: boolean
  disabled?: boolean
  padding?: "none" | "xs"
}
