import React from "react";
import * as DateFns from "date-fns";
import tw, { styled } from "twin.macro";
import { CalendarWrapper, DateLink, Sticky, StickyInner } from "./styledStickyHeader";

import * as Hooks from "~/hooks";
import * as Components from "~/components";

const StyledActiveDateDot = styled.div(() => [
  tw`h-2 w-2 inline-block rounded-full bg-pink mr-og2`,
]);

export const StickyHeader: React.FC<Props> = ({ stickyHeader, headerStuck, title, setDate, setDatePicker, date, datePicker }) => {
  const hookSymptoms = Hooks.useSymptoms();

  const activeDates = hookSymptoms.symptoms.map((item) => DateFns.parse(`${item.day}/${item.month}/${item.year}`, "d/M/y", new Date()));

  return (
    <Sticky
      ref={stickyHeader}
      stuck={headerStuck}
    >
      <StickyInner
        stuck={headerStuck}
      >
        <Components.Row>
          <Components.Column
            grow
          >
            <Components.TextVariation
              variation={headerStuck ? "heading2" : "heading3"}
              spacing="og2"
            >
              {title}
            </Components.TextVariation>
            <DateLink
              stuck={headerStuck}
              onClick={() => setDatePicker((prev) => !prev)}
            >
              {DateFns.format(date, "dd MMMM y")}
            </DateLink>
          </Components.Column>
          <Components.Column
            shrink
            justify="end"
          >
            <Components.DateButton
              onClick={() => setDatePicker((prev) => !prev)}
              active={datePicker}
            />
          </Components.Column>
        </Components.Row>
        <CalendarWrapper
          active={datePicker}
        >
          <Components.Calendar
            date={date}
            minDate={new Date(2000, 0, 1)}  // Backend does not accept dates earlier than 2000
            maxDate={new Date()}
            activeDates={activeDates}
            handleDate={(val) => {
              setDatePicker(false);
              setDate(val as Date);
            }}
          />
          <Components.TextVariation
            variation="label2"
          >
            <StyledActiveDateDot />
            {"Dates with recorded info"}
          </Components.TextVariation>
        </CalendarWrapper>
      </StickyInner>
    </Sticky>
  );
};

type Props = {
  stickyHeader: React.RefObject<HTMLDivElement>
  headerStuck: boolean
  setDate: React.Dispatch<React.SetStateAction<Date>>
  date: Date
  setDatePicker: React.Dispatch<React.SetStateAction<boolean>>
  datePicker: boolean
  title: string
}
