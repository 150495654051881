import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { useScrollContainer } from "~/hooks/useScrollContainer";

export const withArticleList = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "ArticleList", ...props }: Props) => {
  Component.displayName = name;
  const [wrapperRef, handleScroll] = useScrollContainer(props?.articles?.length || 8);
  return (
    <Component
      { ...props as P & Props }
      wrapperRef={wrapperRef}
      handleScroll={handleScroll}
    />
  );
};
type Props = {
  name?: string
  articles: Array<MergedArticle>
  link?: string
  linkText?: string
  onClickArticle?: (article: MergedArticle) => void;
}
