import * as React from "react";
import { CheckboxGroupData } from "../CheckboxGroup/withCheckboxGroup";
import {
  RadioDescription, HiddenCheckbox,  RadioTextWrapper,
} from "../Styled/Form";
import { Checkbox, CheckboxGroupWrapper, CheckboxTick, Label } from "./styledNewCheckboxGroup";

export const NewCheckboxGroup: React.FC<Props> = ({ data, onChange, checked }: Props) => (
  <React.Fragment>
    {data.map(({ label, description, value }) => (
      <CheckboxGroupWrapper
        key={value}
      >
        <HiddenCheckbox
          type="checkbox"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        />
        <Checkbox
          checked={checked.includes(value)}
        >
          <CheckboxTick
            checked={checked.includes(value)}
          />
        </Checkbox>
        <RadioTextWrapper>
          <Label>
            {label}
          </Label>
          {description ? (
            <RadioDescription>
              {description}
            </RadioDescription>
          ) : null}
        </RadioTextWrapper>
      </CheckboxGroupWrapper>
    ))}
  </React.Fragment>
);

type Props = {
  data: Array<CheckboxGroupData>
  checked: Array<string>
  onChange: (index: string) => void
}
