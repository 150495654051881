import * as React from "react";
import { TooltipContainer, TooltipElement, TooltipInner } from "./styledTooltip";

export const Tooltip: React.FC<Props> = ({
  attribute = "data-tooltip",
}) => {
  const [target, setTarget] = React.useState<HTMLElement | null>(null);
  const [content, setContent] = React.useState<string>("");
  const [top, setTop] = React.useState<number>();
  const [left, setLeft] = React.useState<number>();
  const [position, setPosition] = React.useState<Dir>("top");
  const containerRef = React.useRef<HTMLDivElement>(null);
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    document.addEventListener("mouseover", toggleTooltip);
    return function cleanup() {
      document.removeEventListener("mouseover", toggleTooltip);
    };
  }, []);

  React.useEffect(() => {
    if (target) {
      const data = getTargetData(target);
      if (!data) {
        return;
      }
      setContent(data.content as string);
      setTop(data.top);
      setLeft(data.left);
      setPosition(data.at);
    }
  }, [target]);

  const toggleTooltip = (event: MouseEvent) => {
    const _target = getTarget(event.target);
    setTarget(_target);
  };

  const getTarget = (eventTarget: EventTarget | null) => {
    let el = eventTarget as HTMLElement;
    while (el) {
      if (el.hasAttribute && el.hasAttribute(attribute)) return el;
      el = el.parentNode as HTMLElement;
    }
    return null;
  };

  const getTargetData = (el: HTMLElement): TargetData | undefined => {
    if (!containerRef?.current || !tooltipRef?.current) {
      return;
    }
    let at: Dir = "top";
    const content = el.getAttribute(attribute) as string;
    const {
      top: containerTop,
      left: containerLeft,
    } = containerRef.current.getBoundingClientRect();

    const {
      width: tooltipWidth,
      height: tooltipHeight,
    } = tooltipRef.current.getBoundingClientRect();

    const {
      top: targetTop,
      left: targetLeft,
      width: targetWidth,
      height: targetHeight,
    } = el.getBoundingClientRect();

    const isHoriz = ["left", "right"].includes(at);

    const {
      clientHeight,
      clientWidth,
    } = document.documentElement;

    const directions = {
      left: (isHoriz
        ? targetLeft - tooltipWidth
        : targetLeft + (targetWidth - tooltipWidth >> 1)) > 0,
      right: (isHoriz
        ? targetLeft + targetWidth + tooltipWidth
        : targetLeft + (targetWidth + tooltipWidth >> 1)) < clientWidth,
      bottom: (isHoriz
        ? targetTop + (targetHeight + tooltipHeight >> 1)
        : targetTop + targetHeight + tooltipHeight) < clientHeight,
      top: (isHoriz
        ? targetTop - (tooltipHeight >> 1)
        : targetTop - tooltipHeight) > 0,
    };

    const getAt = {
      left: () => {
        if (!directions.left) return "right";
        if (!directions.top) return "bottom";
        if (!directions.bottom) return "top";
        return;
      },
      right: () => {
        if (!directions.right) return "left";
        if (!directions.top) return "bottom";
        if (!directions.bottom) return "top";
        return;
      },
      bottom: () => {
        if (!directions.bottom) return "top";
        if (!directions.left) return "right";
        if (!directions.right) return "left";
        return;
      },
      top: () => {
        if (!directions.top) return "bottom";
        if (!directions.left) return "right";
        if (!directions.right) return "left";
        return;
      },
    };

    const getTopLeft = {
      left: () => ({
        top: targetHeight - tooltipHeight >> 1,
        left: -tooltipWidth,
      }),
      right: () => ({
        top: targetHeight - tooltipHeight >> 1,
        left: targetWidth,
      }),
      bottom: () => ({
        top: targetHeight,
        left: targetWidth - tooltipWidth >> 1,
      }),
      top: () => ({
        top: -tooltipHeight,
        left: targetWidth - tooltipWidth >> 1,
      }),
    };

    at = getAt[at]() || at;
    const { top, left } = getTopLeft[at]() || getTopLeft["top"]();
    return {
      content,
      at,
      top: (top + targetTop - containerTop) | 0,
      left: (left + targetLeft - containerLeft) | 0,
    };
  };

  return (
    <TooltipContainer
      ref={containerRef}
    >
      {target ? (
        <TooltipElement
          ref={tooltipRef}
          role="tooltip"
          position={position}
          style={{
            top,
            left,
          }}
        >
          <TooltipInner>
            {content}
          </TooltipInner>
        </TooltipElement>
      ) : null}
    </TooltipContainer>
  );
};

type Props = {
  attribute?: string
}

type TargetData = {
  top: number
  left: number
  content: string
  at: Dir
}

type Dir = "top" | "bottom" | "left" | "right";