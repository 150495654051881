import * as React from "react";
import tw, { styled } from "twin.macro";

import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";

import * as Providers from "~/providers";
import * as Hooks from "~/hooks";
import * as Components from "~/components";

import { SwipeModal, SwipeModalProps } from "./SwipeModal";

import IconFacebook from "~/../assets/icons/facebook.svg";
import IconTrash from "~/../assets/icons/trash.svg";

export const RemoveButton = styled.button(() => [
  tw`bg-white rounded-full flex flex-shrink-0 items-center justify-center text-grey-dark hover:bg-grey-light h-12 w-12 ml-4 focus:outline-none`,
]);

export const SwipeModalShareArticle: React.FunctionComponent<SwipeModalShareArticleProps> = (props) => {
  const modalContext = React.useContext(Providers.ModalContext);

  const { handleFacebook, addRecipient, removeRecipient, changeRecipient, handleMessage, validateRecipient, handleShare, disabled, message, recipients } = Hooks.useShareModal({
    article: props.article,
    forDiagnosis: props.forDiagnosis,
    onShare: () => modalContext.hideModal(),
  });

  return (
    <SwipeModal
      {...props}
      title="Share article"
    >
        <Components.Grid
          cols="1"
          colsMd="2"
          gap="4"
        >
          <Components.Form
            onSubmit={handleShare}
          >
            <Components.Grid
              cols="1"
              gap="4"
            >
              <Components.TextVariation
                variation="heading3"
                fullWidth
              >
                {"Share via email"}
              </Components.TextVariation>
              <Components.Grid
                cols="1"
                gap="2"
              >
                {recipients.map((props, key) => {
                  return (
                    <Components.Box
                      key={key}
                      alignItems="center"
                      fullWidth
                    >
                      <Components.Input
                        {...props}
                        placeholder="Email address"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeRecipient(e.target.value, key)}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => validateRecipient(e.target.value, key)}
                        required
                      />
                      {recipients.length > 1 ? (
                        <RemoveButton
                          onClick={() => removeRecipient(key)}
                          type="button"
                        >
                          <IconTrash
                            width={24}
                            height={24}
                          />
                        </RemoveButton>
                      ) : null}
                    </Components.Box>
                  );
                })}
              </Components.Grid>
              <Components.ButtonVariation
                variation="secondary"
                onClick={() => addRecipient()}
                type="button"
              >
                {"Add another recipient"}
              </Components.ButtonVariation>
              <Components.TextArea
                label="Include a message"
                value={message}
                onChange={(e) => handleMessage(e.target.value)}
              />
              <Components.Box
                space="2"
                flex
              >
                <Components.ButtonVariation
                  variation="primary"
                  disabled={disabled}
                  fullWidth
                  type="submit"
                >
                  {"Send to recipient"}
                </Components.ButtonVariation>
                <Components.ButtonVariation
                  variation="secondary"
                  onClick={() => modalContext.hideModal()}
                  type="button"
                >
                  {"Cancel"}
                </Components.ButtonVariation>
              </Components.Box>
            </Components.Grid>
          </Components.Form>
          <Components.Grid
            cols="1"
            gap="4"
          >
            <Components.TextVariation
              variation="heading3"
              fullWidth
            >
              {"Share via Facebook"}
            </Components.TextVariation>
            <Components.ButtonVariation
              variation="square"
              iconLeft={(<IconFacebook
                width="24px"
                height="24px"
                         />)}
              onClick={handleFacebook}
            >
              {"Share via Facebook"}
            </Components.ButtonVariation>
          </Components.Grid>
        </Components.Grid>
    </SwipeModal>
  );
};

export type SwipeModalShareArticleProps = SwipeModalProps & {
  children?: never;
  article: MergedArticle;
  forDiagnosis: Array<string>;
};
