import * as React from "react";

import * as Utils from "~/utils";
import * as Components from "~/components";

import IconBell from "~/../assets/icons/icon-bell.svg";

export const Topics: React.FunctionComponent<TopicsProps> = () => {
  return (
    <Components.AppLayout
      headerVariation="navigationHome"
    >
      <Components.Meta
        title="Topics"
      />
      <Components.SearchInput />
      <Components.Row
        gutter="md"
      >
        <Components.Column
          spacing="md"
        >
          <Components.ButtonVariation
            href={Utils.APP_ARTICLES_CATEGORY.replace(":categoryId", "featured")}
            variation="square"
            iconLeft={<IconBell />}
          >
            {"View featured articles"}
          </Components.ButtonVariation>
        </Components.Column>
        <Components.Column>
          <Components.H2
            spacing="xs"
          >
            {"Which topic interests you today?"}
          </Components.H2>
        </Components.Column>
        <Components.Column>
          <Components.TopicList />
        </Components.Column>
      </Components.Row>
    </Components.AppLayout>
  );
};

export type TopicsProps = unknown;
