import * as React from "react";
import { useSymptomTrackerApi } from "~/hooks/useSymptomTrackerApi";
import { getBase64 } from "~/utils/functions";

export const withFileInput = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ displayName = "FileInput", setFileIds, ...props }: Props) => {
  Component.displayName = displayName;
  const { uploadImage } = useSymptomTrackerApi();
  const [dataUrls, setDataUrls] = React.useState<Array<string>>(props.dataUrls ? props.dataUrls : []);
  const [status, setStatus] = React.useState<Record<string, "uploading" | "uploaded" | "error">>({});
  const [files, setFiles] = React.useState<Array<File>>([]);

  React.useEffect(() => {
    const getDataUrls = async (): Promise<void> => {
      if (files.length) {
        const data = await Promise.all(files.map(getBase64));
        /** Merge the existing selection with the new selection and filter out any images that have been selected twice */
        setDataUrls((prev) => [...prev, ...data].filter((value, index, self) => self.indexOf(value) === index));
        files.forEach(async (file, i) => {
          setStatus((prev) => ({
            ...prev,
            [data[i]]: "uploading",
          }));
          try {
            const res = await uploadImage(file);
            const json = await res.json();
            setFileIds((prev) => [...prev, json.uploadFile.id]);
            if (res.status === 200) {
              setStatus((prev) => ({
                ...prev,
                [data[i]]: "uploaded",
              }));
            } else {
              setStatus((prev) => ({
                ...prev,
                [data[i]]: "error",
              }));
            }
          } catch {
            setStatus((prev) => ({
              ...prev,
              [data[i]]: "error",
            }));
          }
          
        });
      }
    };
    
    getDataUrls();
  }, [files]);

  React.useEffect(() => {
    setDataUrls([]);
    if (props?.files?.length) {
      props?.files?.forEach((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64 = reader.result as string;
          setDataUrls((prev) => [...prev, base64]);
        };
        
      });
    }
  }, [props.files]);

  const handleRemoveImage = (index: number) => {
    setDataUrls((prev) => prev.filter((_item, i) => i !== index));
  };

  const handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      setFiles(Array.from(event.target.files));
    }
  };

  return (
    <Component
      { ...props as P & Props }
      handleImage={handleImage}
      dataUrls={dataUrls}
      handleRemoveImage={handleRemoveImage}
      status={status}
    />
  );
};

type Props = {
  displayName?: string
  label?: string | (() => React.ReactNode)
  placeholder: string
  setFileIds: React.Dispatch<React.SetStateAction<Array<string>>>
  dataUrls?: Array<string>
  files?: Array<Blob>
  allowMultiple?: boolean
  showThumbnail?: boolean
}
