import { sub } from "date-fns";
import * as React from "react";
import { PatientContext, UserAuth } from "../providers/PatientProvider";
import { StickyHeaderProps, useStickyHeader } from "./useStickyHeader";

export const useDashboard = (): DashboardProps => {
  const { auth } = React.useContext(PatientContext);
  const stickyHeaderProps = useStickyHeader();
  const [datePicker, setDatePicker] = React.useState<boolean>(false);
  const [fromDate, setFromDate] = React.useState<Date>(sub(new Date(), {
    months: 1,
  }));
  const [toDate, setToDate] = React.useState<Date>(new Date);
  const [dateRange, setDateRange] = React.useState<0 | 1>(0);

  return {
    ...stickyHeaderProps,
    auth,
    datePicker,
    setDatePicker,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    dateRange,
    setDateRange,
  };
};

export type DashboardProps = StickyHeaderProps & {
  auth: UserAuth | null
  datePicker: boolean
  setDatePicker: React.Dispatch<React.SetStateAction<boolean>>
  fromDate: Date
  setFromDate: React.Dispatch<React.SetStateAction<Date>>
  toDate: Date
  setToDate: React.Dispatch<React.SetStateAction<Date>>
  dateRange: 0 | 1
  setDateRange: React.Dispatch<React.SetStateAction<0 | 1>>
}

