import * as React from "react";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router-dom";
import { useAccountDetails } from "~/hooks/useAccountDetails";
import { usePatientApi } from "~/hooks/usePatientApi";
import { USER_MY_ACCOUNT } from "~/utils/constants";
import { PatientContext } from "~/providers/PatientProvider";
import { EndpointPatientUpdate } from "@myjourney/shared";
import { useAuthentication } from "~/hooks/useAuthentication";
import { ToastContext } from "~/providers/ToastProvider";
import Cookies from "js-cookie";

export const withAccountDetails =
  <P extends Record<string, unknown>>(
    Component: React.ComponentType<P>
  ): React.FC<Props> =>
  ({ name = "AccountDetails", ...props }: Props) => {
    Component.displayName = name;
    const history = useHistory();
    useAuthentication();

    const { auth, handleLogout } = React.useContext(PatientContext);
    const { handleToast } = React.useContext(ToastContext);

    const accountDetailsProps = useAccountDetails();
    const { patientUpdate } = usePatientApi();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [changed, setChanged] = React.useState<boolean>(false);
    const [loadingPassword, setLoadingPassword] =
      React.useState<boolean>(false);
    const [changedPassword, setChangedPassword] =
      React.useState<boolean>(false);
    const [password, setPassword] = React.useState<string>("");
    const [oldPassword, setOldPassword] = React.useState<string>("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    const [passwordFormError, setPasswordFormError] = React.useState<
      string | null
    >(null);
    const [passwordError, setPasswordError] = React.useState<
      React.FC | string | null
    >(null);
    const [passwordValid, setPasswordValid] = React.useState<boolean>(false);
    const [oldPasswordError, setOldPasswordError] = React.useState<
      React.FC | string | null
    >(null);
    const [oldPasswordValid, setOldPasswordValid] =
      React.useState<boolean>(false);
    const [confirmPasswordValid, setConfirmPasswordValid] =
      React.useState<boolean>(false);
    const [passwordRules, setPasswordRules] = React.useState<
      Record<string, boolean>
    >({
      "6 characters minimum": false,
      "One uppercase character": false,
      "One number": false,
      "One lowercase character": false,
      "One symbol": false,
      "Confirmation password matches": false,
    });
    const [confirmPasswordError, setConfirmPasswordError] = React.useState<
      React.FC | string | null
    >(null);

    React.useEffect(() => {
      if (
        accountDetailsProps.email !== auth?.patient_details.email ||
        accountDetailsProps.phone !== auth?.patient_details.phone
      ) {
        setChanged(true);
      } else {
        setChanged(false);
      }
    }, [accountDetailsProps]);

    React.useEffect(() => {
      setPasswordRules({
        "6 characters minimum": password ? password.length >= 6 : false,
        "One uppercase character": password ? /[A-Z]/.test(password) : false,
        "One number": password ? /[0-9]/.test(password) : false,
        "One lowercase character": password ? /[a-z]/.test(password) : false,
        "One symbol": password ? /\W|_/gm.test(password) : false,
        "Confirmation password matches": password
          ? password === confirmPassword
          : false,
      });
      if (password !== "" && confirmPassword !== "" && oldPassword !== "") {
        setChangedPassword(true);
      } else {
        setChangedPassword(false);
      }
    }, [password, confirmPassword]);

    const handleOldPassword = (value: string) => {
      setOldPassword(value);
      if (passwordValid) {
        setOldPasswordValid(false);
      }
      if (value === "") {
        setOldPasswordError("Old Password required");
      } else if (value.length < 6) {
        setOldPasswordError(
          "Your password must be at least 6 characters in length"
        );
      } else if (!/[A-Z]/.test(value)) {
        setOldPasswordError(
          "Your password must contain at least one uppercase letter"
        );
      } else if (!/[0-9]/.test(value)) {
        setOldPasswordError("Your password must contain at least one number");
      } else if (!/\W|_/gm.test(value)) {
        setOldPasswordError(
          "Your password must contain at least one non-alphanumeric symbol"
        );
      } else {
        setOldPasswordError(null);
        setOldPasswordValid(true);
      }
    };

    const handlePassword = (value: string) => {
      setPassword(value);
      if (passwordValid) {
        setPasswordValid(false);
      }
      if (value === "") {
        setPasswordError("Password required");
      } else if (value.length < 6) {
        setPasswordError(
          "Your password must be at least 6 characters in length"
        );
      } else if (!/[A-Z]/.test(value)) {
        setPasswordError(
          "Your password must contain at least one uppercase letter"
        );
      } else if (!/[0-9]/.test(value)) {
        setPasswordError("Your password must contain at least one number");
      } else if (!/\W|_/gm.test(value)) {
        setPasswordError(
          "Your password must contain at least one non-alphanumeric symbol"
        );
      } else {
        setPasswordError(null);
        setPasswordValid(true);
      }
    };

    const handleConfirmPassword = (value: string) => {
      setConfirmPassword(value);
      if (value === "") {
        setConfirmPasswordError("Confirm password required");
      } else if (password !== value) {
        setConfirmPasswordError("The passwords you entered do not match");
      } else {
        setConfirmPasswordError(null);
        setConfirmPasswordValid(true);
      }
    };

    const handleSubmitDetails = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      try {
        const res = await patientUpdate({
          patient_credentials: {
            email: accountDetailsProps.email,
          },
          patient_details: {
            phone: accountDetailsProps.phone,
          },
        });
        if (!res) {
          return;
        }
        history.push(USER_MY_ACCOUNT);
        handleToast?.("Profile updated successfully");
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
      setLoading(false);
    };

    const handleSubmitPassword = async (
      e: React.FormEvent<HTMLFormElement>
    ) => {
      e.preventDefault();
      setLoadingPassword(true);
      try {
        const res = await patientUpdate({
          patient_password: {
            password_before: oldPassword,
            password_after: password,
          },
        });
        if (!res) {
          return;
        }
        const json: EndpointPatientUpdate.Response["body"] = await res.json();
        if (json.success) {
          history.push(USER_MY_ACCOUNT);
          handleToast?.("Profile updated successfully");
        } else {
          setPasswordFormError(
            "Unable to change password. Either the old password is incorrect or the new password does not meet requirements."
          );
        }
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
      setLoadingPassword(false);
    };

    const protocolOrWhiteSpace = runtimeconfig.koben_umbraco_domain.includes(
      "https://"
    )
      ? ""
      : "https://";

    const handleDeletePatient = async () => {
      try {
        const token =
          Cookies.get("koben_token") || auth?.koben_token.access_token;
        const res = await fetch(
          `${
            protocolOrWhiteSpace + runtimeconfig.koben_umbraco_domain
          }/umbraco/api/app/DeleteMember`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: token ? `Bearer ${token}` : "Bearer",
            },
          }
        );

        if (res.status === 200) {
          if (handleLogout) {
            handleLogout();
          }
        } else {
          if (handleToast) {
            handleToast(
              "Service is unavailable. Please contact BCNA support.",
              true
            );
          }
        }
      } catch (error) {
        if (handleToast) {
          handleToast(
            "Service is unavailable. Please contact BCNA support.",
            true
          );
        }
        console.error(error);
        Sentry.captureException(error);
      }
    };

    return (
      <Component
        {...(props as P & Props)}
        {...accountDetailsProps}
        passwordRules={passwordRules}
        handlePassword={handlePassword}
        handleConfirmPassword={handleConfirmPassword}
        handleOldPassword={handleOldPassword}
        passwordError={passwordError}
        confirmPasswordError={confirmPasswordError}
        confirmPasswordValid={confirmPasswordValid}
        oldPasswordError={oldPasswordError}
        oldPasswordValid={oldPasswordValid}
        loading={loading}
        changed={changed}
        loadingPassword={loadingPassword}
        changedPassword={changedPassword}
        handleSubmitDetails={handleSubmitDetails}
        handleSubmitPassword={handleSubmitPassword}
        passwordFormError={passwordFormError}
        handleDeletePatient={handleDeletePatient}
      />
    );
  };
type Props = {
  name?: string;
};
