import * as React from "react";
import { ContentWrapper } from "../styledHp";
import { withHpStep1 } from "./withHpStep1";

import * as Components from "~/components";

export const HpStep1 = withHpStep1(({ handleNoEmail, handleSubmit, noEmail }: Props) => (
  <ContentWrapper>
    <Components.Form
      onSubmit={handleSubmit}
    >
      <Components.Column
        spacing="md"
      >
        <Components.TextVariation
          variation="heading1"
          spacing="og1"
        >
          {"Does your patient have an email address or mobile phone?"}
        </Components.TextVariation>
        {noEmail ? (
          <Components.Card
            color="blueDusty"
            padding="md"
          >
            <Components.TextVariation
              variation="heading4"
              spacing="xxs"
            >
              {"Email or mobile phone access is required to sign up and access the My Journey app."}
            </Components.TextVariation>
            <Components.TextVariation
              variation="paragraph2"
              color="greyDark"
            >
              {"If your patient doesn't have access to email or SMS, please call "}
              <Components.StyledHyperlink
                href="tel:1800500258"
              >
                {"1800 500 258"}
              </Components.StyledHyperlink>
              {" to order a printed version of My Journey Early Breast Cancer Guide."}
            </Components.TextVariation>
          </Components.Card>
        ) : null}
      </Components.Column>
      <Components.Column
        spacing="xs"
      >
        <Components.ButtonVariation
          variation="primary"
          type="submit"
          fullWidth
        >
          Yes
        </Components.ButtonVariation>
      </Components.Column>
      <Components.Column
        spacing="lg"
      >
        <Components.ButtonVariation
          variation="secondary"
          type="button"
          onClick={() => handleNoEmail()}
          fullWidth
        >
          No
        </Components.ButtonVariation>
      </Components.Column>
    </Components.Form>
  </ContentWrapper>
));

type Props = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  handleNoEmail: () => void
  noEmail: boolean
}
