import * as React from "react";
import { ButtonSmall } from "../Styled";
import { ButtonWrapper, Card, Triangle } from "./styledSpeechBubble";

export const SpeechBubble: React.FC<Props> = ({ children, onClick }) => (
  <>
    <Card
      hasButton={!onClick}
    >
      {children}
      <Triangle>
      <svg
        width="85"
        height="95"
        viewBox="0 0 85 95"
      >
        <defs>
          <filter
            id="a"
            width="340%"
            height="233.3%"
            x="-120%"
            y="-33.3%"
            filterUnits="objectBoundingBox"
          >
            <feOffset
              dy="12"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            />
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation="12"
            />
            <feColorMatrix
              in="shadowBlurOuter1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            />
          </filter>
          <path
            id="b"
            d="M24 20h40L24 92z"
          />
        </defs>
        <g
          fill="none"
          fillRule="evenodd"
          transform="translate(0 -20)"
        >
          <use
            fill="rgba(98, 45, 45, 0.2)"
            filter="url(#a)"
            xlinkHref="#b"
          />
          <use
            fill="#fff"
            xlinkHref="#b"
          />
        </g>
      </svg>
      </Triangle>
    </Card>
    {onClick ? (
      <ButtonWrapper>
        <ButtonSmall
          onClick={onClick}
        >
          {"OK"}
        </ButtonSmall>
      </ButtonWrapper>
    ) : null}
  </>
);

type Props = {
  onClick?: () => void
}


