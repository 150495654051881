import * as React from "react";
import { Note, NoteResponse } from "myjourney-frontend/src/vendor/umbraco";

import * as Hooks from "~/hooks";
import * as Styled from "./styledNoteListItem";
import * as Components from "~/components";

export const NoteListItem: React.FunctionComponent<NoteListItemProps> = (props) => {
  const { trackTrackerAccordions } = Hooks.useAnalytics();
  const [open, setOpen] = React.useState<boolean>(false);
  const [editNoteId, setEditNoteId] = React.useState<string>();
  const [saved, setSaved] = React.useState<NoteResponse | null>({
    received: "",
    requestId: "",
    responseId: "",
    note: {
      attachments: [],
      created: "",
      day: 0,
      id: "",
      images: [],
      memberId: "",
      month: 0,
      text: "",
      timestamp: "",
      title: "",
      year: 0,
    },
  });

  return (
    <Components.EntryCard
      title={props.name}
      iconValue={props.date}
      open={open}
      onOpenOrClose={() => {
        setOpen((prev) => {
          trackTrackerAccordions({
            action: prev ? "close" : "open",
            group: "Notes",
            location: "Notes Listing",
            label: props.name,
            position: props.index,
          });
          return !prev;
        });
      }}
    >
      <Styled.ContentWrapper>
        {props.content}
      </Styled.ContentWrapper>
      <Components.TextVariation
        variation="heading4"
        color="greyMedium"
        fullWidth
      >
        {"Related photos"}
      </Components.TextVariation>
      <Components.Box
        spacing="og4"
        fullWidth
      >
        {props.note?.images?.length ? (
          <Components.UserImages
            ids={props.note.images}
          />
        ) : (
          <Components.TextVariation
            variation="paragraph2"
            color="blueNavy"
          >
            {"No photos uploaded for this note."}
          </Components.TextVariation>
        )}
      </Components.Box>
      <Components.Box
        spacing="og4"
        justifyContent="center"
        fullWidth
      >
        <Components.Column
          xs="1/2"
        >
          <Components.ButtonVariation
            variation="primarysmall"
            onClick={() => setEditNoteId(props.note.id)}
            fullWidth
          >
            {"Edit"}
          </Components.ButtonVariation>
        </Components.Column>
      </Components.Box>
      <Components.EditNote
        id={editNoteId}
        handleEditNoteId={(id) => setEditNoteId(id)}
        setSaved={setSaved}
        noteResponse={saved}
        note={props.note}
        getNotes={props.getNotes}
      />
    </Components.EntryCard>
  );
};
export type NoteListItemProps = {
  name: string
  date: Date
  content: JSX.Element
  note: Note
  index: number
  getNotes?: () => Promise<void>
}
