import * as React from "react";
import * as Store from "store";
import { useHistory } from "react-router-dom";
import {  USER_LOGIN } from "~/utils/constants";
import { usePatientApi } from "~/hooks/usePatientApi";

export const withPasswordReset = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "PasswordReset", ...props }: Props) => {
  Component.displayName = name;
  const history = useHistory();
  const { patientPasswordReset } = usePatientApi();

  const searchParams = new URLSearchParams(history.location.search);

  const [resetKey] = React.useState<"email" | "phone">((Store.get("forgot_key")) as "email" | "phone" ?? "email");
  const [resetValue] = React.useState<string>(searchParams.get("email") ?? Store.get("forgot_value") ?? "");

  const [code, setCode] = React.useState<string>(searchParams.get("code") ?? "");

  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");

  const [passwordError, setPasswordError] = React.useState<React.FC | string | null>(null);
  const [passwordValid, setPasswordValid] = React.useState<boolean>(false);
  const [confirmPasswordValid, setConfirmPasswordValid] = React.useState<boolean>(false);
  const [passwordRules, setPasswordRules] = React.useState<Record<string, boolean>>({
    "6 characters minimum": false,
    "One uppercase character": false,
    "One number": false,
    "One lowercase character": false,
    "One symbol": false,
    "Confirmation password matches": false,
  });
  const [confirmPasswordError, setConfirmPasswordError] = React.useState<React.FC | string | null>(null);

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [formError, setFormError] = React.useState<FormError | null>(null);

  React.useEffect(() => {
    setPasswordRules({
      "6 characters minimum": password ? password.length >= 6 : false,
      "One uppercase character": password ? /[A-Z]/.test(password) : false,
      "One number": password ? /[0-9]/.test(password) : false,
      "One lowercase character": password ? /[a-z]/.test(password) : false,
      "One symbol": password ? /\W|_/gm.test(password) : false,
      "Confirmation password matches": password ? password === confirmPassword : false,
    });

    if (confirmPasswordValid) {
      setConfirmPasswordValid(false);
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm password required");
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("The passwords you entered do not match");
    } else {
      setConfirmPasswordError(null);
      setConfirmPasswordValid(true);
    }
  }, [password, confirmPassword]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setFormError(null);
    setSubmitting(true);

    try {
      const response = await patientPasswordReset({
        key: resetKey,
        value: resetValue,
        password: password,
        code: code,
      });

      if (response.status === 200) {
        history.push(USER_LOGIN);
      } else {
        setFormError({
          message: "An unexpected error ocurred. Please try again later",
        });
      }
    } catch (error) {
      setFormError({
        message: "Service unavailable. Please try again later.",
      });
      throw error;
    }
    setSubmitting(false);
  };

  const handlePassword = (value: string) => {
    setPassword(value);
    if (passwordValid) {
      setPasswordValid(false);
    }
    if (value === "") {
      setPasswordError("Password required");
    } else if (value.length < 6) {
      setPasswordError("Your password must be at least 6 characters in length");
    } else if (!/[A-Z]/.test(value)) {
      setPasswordError("Your password must contain at least one uppercase letter");
    } else if (!/[0-9]/.test(value)) {
      setPasswordError("Your password must contain at least one number");
    } else if (!/\W|_/gm.test(value)) {
      setPasswordError("Your password must contain at least one non-alphanumeric symbol");
    } else {
      setPasswordError(null);
      setPasswordValid(true);
    }
  };

  const handleConfirmPassword = (value: string) => {
    setConfirmPassword(value);
  };

  const handleCode = (value: string) => {
    setCode(value);
  };

  return (
    <Component
      { ...props as P }
      handleSubmit={handleSubmit}
      submitting={submitting}
      code={code}
      resetKey={resetKey}
      handleCode={handleCode}
      handlePassword={handlePassword}
      handleConfirmPassword={handleConfirmPassword}
      passwordRules={passwordRules}
      passwordError={passwordError}
      confirmPasswordError={confirmPasswordError}
      passwordValid={passwordValid}
      confirmPasswordValid={confirmPasswordValid}
      formError={formError}
    />
  );
};

type Props = {
  name?: string
}

type FormError = {
  message: string;
  action?: () => void
  actionText?: string
  CallToAction?: React.FC
}
