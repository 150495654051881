import * as React from "react";
import tw, { styled, css } from "twin.macro";
import { HiddenCheckbox } from "../Styled/Form";

import * as Components from "~/components";

import IconTimelineDotActive from "~/../assets/icons/timeline-dot-active.svg";
import IconTimelineDotInactive from "~/../assets/icons/timeline-dot-inactive.svg";

const StyledWrapper = styled.div(() => [
  tw`-mt-4`,
]);

const StyledTimelineItem = styled.label(() => [
  tw`flex flex-col items-center relative`,
]);

const StyledTimelineIndicator = styled.div(() => [
  tw`mb-4`,
  css`
    z-index: 2;
  `,
]);

const StyledTimelineLabel = styled.div(() => [
  tw`text-center`,
]);

const StyledTimelineStroke = styled.div(() => [
  tw``,
  css`
    position: absolute;
    width: calc(100% + 16px);
    height: 1px;
    top: 16px;
    left: calc(50%);
    z-index: 1;
    background-color: #ded6d6;
  `,
]);

export const Timeline: React.FunctionComponent<TimelineProps> =(props) => {
  return (
    <StyledWrapper>
      <Components.HorizontalSlider
        itemWidth={27.5}
        hideNavigation={props.hideNavigation}
        dynamicWidth
      >
        {props.data.map((item, index) => (
          <StyledTimelineItem
            key={item.value}
          >
            <HiddenCheckbox
              type="radio"
              value={item.value}
              checked={props.value === item.value}
            />
            <StyledTimelineIndicator>
              {props.value === item.value ? (
                <IconTimelineDotActive />
              ) : (
                <IconTimelineDotInactive
                  fill="#DED6D6"
                />
              )}
            </StyledTimelineIndicator>
            {index < props.data.length - 1 && (
              <StyledTimelineStroke />
            )}
            <StyledTimelineLabel>
              <Components.TextVariation
                variation="label2"
                color={props.value === item.value ? "blueNavy" : "greyMedium"}
                fullWidth
              >
                {item.label}
              </Components.TextVariation>
            </StyledTimelineLabel>
          </StyledTimelineItem>
        ))}
      </Components.HorizontalSlider>
    </StyledWrapper>
  );
};

export type TimelineProps = {
  data: Array<{
    label: string;
    value: string;
  }>;
  value?: string;
  hideNavigation?: boolean;
};
