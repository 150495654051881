import tw, { styled } from "twin.macro";

export const Overlay = styled.div(({ active }: ActiveProps) => [
  tw`fixed inset-0 bg-white z-90 flex flex-col h-full transition`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0 pointer-events-none`]),
]);
export const Inner = tw.div`max-w-screen-md w-full mx-auto h-full flex flex-col shadow`;
export const Header = tw.header`border-b border-pink-light pt-12 pb-4 px-4 flex justify-between items-center shadow-pink`;
export const Content = tw.div`flex-grow px-4 py-8 bg-pink-light overflow-y-auto`;
export const OverlayTitle = tw.p`text-blue-navy text-sm`;
export const CloseButton = tw.button`text-grey-light outline-none focus:(outline-none)`;

type ActiveProps = {
  active: boolean
}
