import * as React from "react";
import tw, { css, styled } from "twin.macro";

import IconSlideArrowLeft from "~/../assets/icons/slide-arrow-left.svg";
import IconSlideArrowRight from "~/../assets/icons/slide-arrow-right.svg";

const StyledWrapper = styled.div((props: { dynamicWidth?: boolean }) => [
  tw`relative overflow-auto`,
  ...props.dynamicWidth ? [tw`-mx-og4 md:-mx-og12`] : [],
]);

const StyledItems = styled.div(() => [
  tw`flex flex-nowrap space-x-4 p-4`,
]);

const StyledItem = styled.div<{ index: number; width: number; }>((props) => [
  tw`flex-none`,
  ...props.index === 0 ? [tw`md:ml-og10`] : [],
  css`
    width: ${props.width}%;
  `,
]);

const StyledOffsetBox = styled.div<{ width: number; }>((props) => [
  tw`flex-none`,
  css`
    width: ${100 - props.width}%;
  `,
]);

const StyledNavigation = styled.div(() => [
  tw`w-full flex justify-between px-4`,
]);

const StyledNavigationItem = styled.div(() => [
  tw`cursor-pointer p-1`,
]);

export const HorizontalSlider: React.FunctionComponent<HorizontalSliderProps> = (props) => {
  const [itemWidth, setItemWidth] = React.useState<number>(Array.isArray(props.itemWidth) ? props.itemWidth[window.innerWidth < 760 ? 0 : 1] : props.itemWidth ?? 100);

  const sliderRef = React.createRef<HTMLDivElement>();

  const handleSlide = (slideDirection: "prev" | "next"): void => {
    if (!sliderRef.current) {
      return;
    }

    const slideWidth = (sliderRef.current.clientWidth * (itemWidth) / 100);
    const scrollAmount =  sliderRef.current.scrollLeft;
    const slideMiddle = Math.round(scrollAmount / slideWidth);

    let slideTo = -1;
    switch (slideDirection) {
      case "prev":
        slideTo = Math.max(slideMiddle - 1, 0);
        break;
      case "next":
        slideTo = slideMiddle + 1;
        break;
    }

    // @ts-expect-error types are missing offsetLeft property
    const offsetAmount = sliderRef.current.children[0].children[slideTo]?.offsetLeft ?? Infinity;

    sliderRef.current.scrollTo({
      left: Math.max(offsetAmount - 16, 0),
      behavior: "smooth",
    });
  };

  const [scrollPosition, setScrollPosition] = React.useState<"start" | "end" | undefined>("start");

  React.useEffect(() => {
    const onScroll = () => {
      if (sliderRef.current) {
        if (sliderRef.current?.scrollLeft === 0) {
          setScrollPosition("start");

          return;
        }

        if (sliderRef.current?.scrollLeft === (sliderRef.current?.scrollWidth ?? Infinity) - (sliderRef.current?.clientWidth ?? Infinity)) {
          setScrollPosition("end");

          return;
        }

        setScrollPosition(undefined);
      }
    };

    sliderRef.current?.addEventListener("scroll", onScroll);

    return () => sliderRef.current?.removeEventListener("scroll", onScroll);
  }, [sliderRef]);


  React.useLayoutEffect(() => {
    const updateSize = () => {
      setItemWidth(Array.isArray(props.itemWidth) ? props.itemWidth[window.innerWidth < 760 ? 0 : 1] : props.itemWidth ?? 100);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return (
    <React.Fragment>
      <StyledWrapper
        ref={sliderRef}
        dynamicWidth={props.dynamicWidth}
      >
        <StyledItems>
          {props.children.map((item, index) => (
            <StyledItem
              key={index}
              index={index}
              width={itemWidth}
            >
              {item}
            </StyledItem>
          ))}
          <StyledOffsetBox
            width={itemWidth}
          />
        </StyledItems>
      </StyledWrapper>
      {props.hideNavigation !== true && (
        <StyledNavigation>
          <StyledNavigationItem
            onClick={() => handleSlide("prev")}
          >
            <IconSlideArrowLeft
              fill={scrollPosition === "start" ? "#DED6D6" : "#DD1D80"}
            />
          </StyledNavigationItem>
          <StyledNavigationItem
            onClick={() => handleSlide("next")}
          >
            <IconSlideArrowRight
              fill={scrollPosition === "end" ? "#DED6D6" : "#DD1D80"}
            />
          </StyledNavigationItem>
        </StyledNavigation>
      )}
    </React.Fragment>
  );
};

export type HorizontalSliderProps = {
  children: Array<JSX.Element>;
  itemWidth?: number | [number, number];
  hideNavigation?: boolean;
  dynamicWidth?: boolean;
}
