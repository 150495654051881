import { DiagnosisTree } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { Checkbox } from "~/components/Checkbox/Checkbox";
import { DateInput } from "~/components/DateInput/DateInput";
import { Input } from "~/components/Input/Input";
import { Select } from "~/components/Select/Select";
import { ContentWrapper } from "../styledHp";
import { withHpStep3 } from "./withHpStep3";

import * as Components from "~/components";

export const HpStep3 = withHpStep3(
  ({
    accept,
    diagnosisTree,
    diagnosis,
    firstNameValid,
    firstNameError,
    lastNameValid,
    lastNameError,
    diagnosisError,
    diagnosisDateError,
    phoneValid,
    phoneError,
    diagnosisValid,
    diagnosisDateValid,
    phoneValidating,
    firstName,
    lastName,
    diagnosisDate,
    email,
    phone,
    emailValid,
    emailValidating,
    emailError,
    staticDiagnosis,
    handleAccept,
    handleSubmit,
    handleBack,
    handleDiagnosis,
    handleFirstName,
    handleLastName,
    handleDiagnosisDate,
    handleEmail,
    handlePhone,
  }: Props) => (
    <ContentWrapper>
      <Components.Form onSubmit={handleSubmit}>
        <Components.Row>
          <Components.Column spacing="md">
            <Components.TextVariation variation="heading1" spacing="og1">
              {"Patient's details"}
            </Components.TextVariation>
            <Components.TextContainer>
              <Components.TextVariation variation="paragraph1" color="greyDark">
                {
                  "Please fill out some basic details of the patient who will be using the My Journey app. All information provided is stored securely on BCNA's database. If you have any questions please call "
                }
                <Components.StyledHyperlink href="tel:1800500258">
                  {"1800 500 258"}
                </Components.StyledHyperlink>
                {" or email "}
                <Components.StyledHyperlink href="mailto:myjourney@bcna.org.au">
                  {"myjourney@bcna.org.au"}
                </Components.StyledHyperlink>
                {"."}
              </Components.TextVariation>
            </Components.TextContainer>
          </Components.Column>
        </Components.Row>
        <Components.Row gutter="lg" spacing="xxs">
          <Components.Column gutter="lg" spacing="ogxs">
            <Components.TextVariation variation="heading3">
              {"Basic information"}
            </Components.TextVariation>
            <Components.TextContainer>
              <Components.TextVariation variation="paragraph1" color="greyDark">
                {
                  "These details will be used to customise content to your patient's needs."
                }
              </Components.TextVariation>
            </Components.TextContainer>
          </Components.Column>
          <Components.Column sm="1/2" spacing="md" gutter="lg">
            <Input
              type="text"
              label="First name"
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFirstName(e.target.value)
              }
              onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                handleFirstName(e.target.value)
              }
              value={firstName}
              valid={firstNameValid}
              error={firstNameError}
            />
          </Components.Column>
          <Components.Column sm="1/2" spacing="md" gutter="lg">
            <Input
              type="text"
              label="Last name"
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleLastName(e.target.value)
              }
              onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                handleLastName(e.target.value)
              }
              value={lastName}
              valid={lastNameValid}
              error={lastNameError}
            />
          </Components.Column>
          <Components.Column sm="1/2" spacing="md" gutter="lg">
            <Select
              label="Diagnosis"
              required
              value={diagnosis}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleDiagnosis(e.target.value)
              }
              onBlur={(e: React.FocusEvent<HTMLSelectElement>) =>
                handleDiagnosis(e.target.value)
              }
              error={diagnosisError}
              options={
                diagnosisTree
                  ? diagnosisTree.map(({ hpReferralTitle, id }) => ({
                      label: hpReferralTitle,
                      value: `${id}`,
                    }))
                  : staticDiagnosis
              }
            />
          </Components.Column>
          <Components.Column sm="1/2" spacing="md" gutter="lg">
            <DateInput
              label="Diagnosis date"
              onChange={(date) => handleDiagnosisDate(date)}
              value={diagnosisDate}
              error={diagnosisDateError}
              max={new Date()}
              // hideDayInput
              required
            />
          </Components.Column>
        </Components.Row>
        <Components.Row spacing="lg" gutter="lg">
          <Components.Column gutter="lg" spacing="ogxs">
            <Components.TextVariation variation="heading3">
              {"Contact details"}
            </Components.TextVariation>
            <Components.TextContainer>
              <Components.TextVariation variation="paragraph1" color="greyDark">
                {
                  "Your patient will receive an SMS and/or email with instructions on completing the sign-up process."
                }
              </Components.TextVariation>
            </Components.TextContainer>
          </Components.Column>
          <Components.Column sm="1/2" spacing="md" gutter="lg">
            <Input
              type="email"
              label="Email address"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEmail(e.target.value)
              }
              onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                handleEmail(e.target.value)
              }
              value={email}
              valid={emailValid}
              validating={emailValidating}
              error={emailError}
              hideRequired={true}
            />
          </Components.Column>
          <Components.Column sm="1/2" spacing="md" gutter="lg">
            <Input
              type="tel"
              label="Mobile number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handlePhone(e.target.value)
              }
              onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                handlePhone(e.target.value)
              }
              value={phone}
              valid={phoneValid}
              validating={phoneValidating}
              error={phoneError}
              hideRequired={true}
            />
          </Components.Column>
          <Components.Column gutter="lg">
            <Checkbox
              label="Notify me when patient has activated their account using my referral"
              checked={accept}
              onChange={handleAccept}
            />
          </Components.Column>
        </Components.Row>
        <Components.Row>
          <Components.Column spacing="xs">
            <Components.ButtonVariation
              variation="primary"
              type="submit"
              disabled={
                !(
                  firstNameValid &&
                  lastNameValid &&
                  diagnosisValid &&
                  diagnosisDateValid &&
                  (emailValid || phoneValid)
                )
              }
              fullWidth
            >
              {"Submit & Continue"}
            </Components.ButtonVariation>
          </Components.Column>
          <Components.Column spacing="xs">
            <Components.ButtonVariation
              variation="secondary"
              type="button"
              onClick={handleBack}
              fullWidth
            >
              {"Back"}
            </Components.ButtonVariation>
          </Components.Column>
        </Components.Row>
      </Components.Form>
    </ContentWrapper>
  )
);

type Props = {
  diagnosisTree: DiagnosisTree;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  handleAccept: () => void;
  handleBack: () => void;
  handleFirstName: (value: string) => void;
  firstNameValid: boolean;
  firstNameError?: FormError;
  handleLastName: (value: string) => void;
  lastNameValid: boolean;
  lastNameError?: FormError;
  handleDiagnosis: (value: string) => void;
  diagnosisError?: FormError;
  diagnosisValid: boolean;
  handleDiagnosisDate: (value: Date) => void;
  handleEmail: (value: string) => void;
  emailValid: boolean;
  emailValidating: boolean;
  emailError?: FormError;
  handlePhone: (value: string) => void;
  phoneValid: boolean;
  phoneValidating: boolean;
  phoneError?: FormError;
  diagnosisDateError?: FormError;
  diagnosisDateValid: boolean;
  accept: boolean;
  diagnosis: string;
  firstName: string;
  lastName: string;
  diagnosisDate: Date;
  email: string;
  phone: string;
  staticDiagnosis: Array<{
    label: string;
    value: string;
  }>;
};

type FormError = JSX.Element | string | undefined;
