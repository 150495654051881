import { StackedContent } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { Video } from "~/components/Video/Video";
import { VideoBlockWrapper } from "./styledArticle";

export const VideoBlock: React.FC<Props> = ({ videoUrl, title }) => (
  <VideoBlockWrapper>
    <Video
      videoUrl={videoUrl}
      title={title}
    />
  </VideoBlockWrapper>
);

type Props = StackedContent.Video
