import * as React from "react";
import Cross from "~/../assets/icons/cross.svg";
import { MaxWidths } from "../Styled/Global";
import { CloseButton, ModalBackground, ModalContent, ModalInner, ModalOuter } from "./styledModal";

export const Modal: React.FC<Props> = ({ children, active, maxWidth, padding, noClose, setActive, handleClose }) => (
  <ModalOuter
    active={active}
    className="Modal"
  >
    <ModalBackground
      active={active}
      onClick={handleClose ? () => handleClose() : setActive ? () => setActive(false) : undefined}
    />
    <ModalInner>
      <ModalContent
        active={active}
        maxWidth={maxWidth}
        padding={padding}
      >
        {!noClose ? (
          <CloseButton
            type="button"
            onClick={handleClose ? () => handleClose() : setActive ? () => setActive(false) : undefined}
          >
            <Cross />
          </CloseButton>
        ) : null}
        {children}
      </ModalContent>
    </ModalInner>
  </ModalOuter>
);

type Props = {
  active: boolean;
  noClose?: boolean;
  setActive?: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: () => void
  maxWidth?: MaxWidths
  padding?: "default" | "none" | "md"
}
