import { Header, ProgressBar, ProgressInner } from "./styledHPHeader";
import { Small } from "../Styled";
import { Step, withHPHeader } from "./withHPHeader";

export const HPHeader = withHPHeader(({ step }: Props) => (
  <Header
    step={step.sequence}
    data-hp-header
  >
    <Small
      spacing="none"
    >My Journey sign-up form</Small>
    <Small
      spacing="none"
      align="right"
    >{step.text}</Small>
    <ProgressBar>
      <ProgressInner
        step={step.sequence}
      />
    </ProgressBar>
  </Header>
));

type Props = {
  step: Step
}
