import tw, { styled } from "twin.macro";

export const Overlay = styled.div(({ active }: ActiveProps) => [
  tw`fixed inset-0 z-80 flex flex-col h-full transition`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0 pointer-events-none`]),
]);

export const ModalBackground = styled.div(({ active }: ActiveProps) => [
  tw`bg-black bg-opacity-80 z-10 fixed inset-0 w-full h-full transition duration-500`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0`]),
]);

export const Inner = tw.div`max-w-screen-md w-full my-auto mx-auto h-fit flex flex-col py-8 px-2 md:(py-8 px-4) z-20`;
export const Header = tw.header`border-b border-pink-light pt-6 pb-6 px-4 flex justify-end items-start shadow-pink rounded-t-sm bg-white`;
export const Content = tw.div`flex flex-col flex-grow px-4 py-8 bg-pink-light overflow-y-auto rounded-b-sm`;
export const OverlayTitle = tw.p`text-blue-navy font-bold text-sm text-center w-full px-4`;
export const CloseButton = tw.button`text-grey-medium outline-none focus:(outline-none)`;



type ActiveProps = {
  active: boolean
}
