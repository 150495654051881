import * as React from "react";
import { DiagnosisForm } from "~/components/DiagnosisForm/DiagnosisForm";
import { DiagnosisProps } from "~/hooks/useDiagnosis";
import { USER_MY_ACCOUNT } from "~/utils/constants";
import { withDiagnosis } from "./withDiagnosis";

import * as Components from "~/components";

export const Diagnosis = withDiagnosis((props: Props) => (
  <Components.AppLayout
    headerVariation="navigationBack"
  >
    <Components.Meta
      title="Your Diagnosis"
    />
    <Components.Form
      onSubmit={props.handleSubmit}
      aria-label="Diagnosis and situation form"
    >
      <Components.Row>
        <Components.Column
          spacing="ogsm"
        >
          <Components.TextVariation
            variation="heading1"
            spacing="og1"
          >
            {"What is your new diagnosis?"}
          </Components.TextVariation>
          <DiagnosisForm
            {...props}
          />
        </Components.Column>
      </Components.Row>
      <Components.Grid
        cols="1"
        colsMd="2"
        gapX="4"
        gapY="6"
        spacing="og6"
      >
        <Components.ButtonVariation
          variation="secondary"
          href={USER_MY_ACCOUNT}
          aria-label="Cancel"
          fullWidth
        >
          {"Cancel"}
        </Components.ButtonVariation>
        <Components.ButtonVariation
          type="submit"
          variation="primary"
          disabled={!props.changed || props.loading}
          aria-label="Save"
          fullWidth
        >
          {"Confirm & save"}
        </Components.ButtonVariation>
      </Components.Grid>
    </Components.Form>
  </Components.AppLayout>
));

type Props = {
  changed: boolean
  loading: boolean
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
} & DiagnosisProps;
