import * as React from "react";
import tw, { styled } from "twin.macro";
import Slider from "react-input-slider";

import * as Utils from "~/utils";
import * as Components from "~/components";

const StyledLabelWrapper = styled.div(() => [
  tw`flex justify-between mt-2`,
]);
const StyledLabelIncrementValue = styled.label(() => [
  tw`text-xxs w-5 text-center text-blue-navy font-medium`,
]);
const StyledLabelRangeValue = styled.label(() => [
  tw`text-xs text-center text-blue-navy font-medium mt-2`,
]);

export const Range: React.FunctionComponent<RangeProps> = (props) => (
  <Components.Column>
    <Components.Label
      hidden={props.hideLabel}
    >
      <Components.TextVariation
        variation="label1"
      >
        {props.label}
      </Components.TextVariation>
    </Components.Label>
    <Slider
      axis="x"
      xstep={1}
      xmin={props.min}
      xmax={props.max}
      x={props.value}
      onChange={(e) => props.onChange(e.x)}
      styles={{
        track: {
          backgroundColor: "#dfd4d4",
          width: "100%",
          height: "4px",
        },
        active: {
          backgroundColor: "transparent",
          backgroundImage: "linear-gradient(90deg, rgba(221, 29, 128, 0.5) 0%, #DD1D80 100%);",
        },
        thumb: {
          width: 23,
          height: 23,
          border: "3px solid #DD1D80",
          boxShadow: "var(--shadow-md)",
        },
      }}
    />
    {!props.hideIncrementLabel && (
    <StyledLabelWrapper>
      {Utils.getArrayRange(props.min, props.max).map((value) => (
        <StyledLabelIncrementValue
          key={value}
        >
          {value}
        </StyledLabelIncrementValue>
      ))}
    </StyledLabelWrapper>
    )}
    {!props.hideMinMaxLabel && (
      <StyledLabelWrapper>
        <StyledLabelRangeValue>{props.minLabel}</StyledLabelRangeValue>
        <StyledLabelRangeValue>{props.maxLabel}</StyledLabelRangeValue>
      </StyledLabelWrapper>
    )}
  </Components.Column>
);

type RangeProps = {
  name?: string;
  label?: string | (() => React.ReactNode);
  minLabel: string;
  maxLabel: string;
  ariaLabel?: string;
  min: number;
  max: number;
  value: number;
  hideLabel?: boolean;
  hideIncrementLabel?: boolean;
  hideMinMaxLabel?: boolean;
  onChange: (value: number) => void;
}
