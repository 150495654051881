import * as React from "react";
import tw, { css, styled } from "twin.macro";
import * as Store from "store";
import { useHistory } from "react-router-dom";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Providers from "~/providers";
import * as Components from "~/components";
import { Column } from "../../components/Styled";

import { deviceDetect, getUA } from "react-device-detect";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";

const CaptchaWrapper = styled.div(() => [
  css`
    margin: 0 auto;
  `,
]);

const StyledPageTitle = styled.div(() => [
  tw`py-og10`,
]);

export const Login: React.FunctionComponent<LoginProps> = () => {
  const history = useHistory();
  const patientHook = Hooks.usePatientApi();
  const analyticsHook = Hooks.useAnalytics();
  const patientContext = React.useContext(Providers.PatientContext);
  const rememberMeStorage = Store.get("rememberMe");
  const [email, setEmail] = React.useState<string>(rememberMeStorage?.email ?? "");
  const [emailError, setEmailError] = React.useState<JSX.Element | string | undefined>();
  const [emailValid, setEmailValid] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>(rememberMeStorage?.token ?? "");
  const [rememberMe, setRememberMe] = React.useState<boolean>(!!rememberMeStorage);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [formError, setFormError] = React.useState<FormError>();
  const [failedAttempts, setFailedAttempts] = React.useState<number>(0);
  const [captchaCompleted, setCaptchaCompleted] = React.useState<boolean>(false);
  const [cookieModal, setCookieModal] = React.useState<boolean>(false);
  const [trustDevice, setTrustDevice] = React.useState<boolean | null>(null);

  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const deviceInfo = deviceDetect(getUA);
  const deviceCookie = Cookies.get("device_info") ? Cookies.get("device_info") : Store.get("device_info");
  React.useEffect(() => {
    if (patientContext.auth) {
      history.push(Utils.USER_DASHBOARD);
    }
  }, [patientContext.auth, failedAttempts]);


  React.useEffect(() => {
    if (trustDevice !== null) {
      handleTrustDevice();
      handleSubmit(null);
    }
  }, [trustDevice]);



  const handleTrustDevice = () => {
    const browserType = deviceInfo?.isBrowser ? `${deviceInfo.browserName}${deviceInfo.browserMajorVersion};${deviceInfo.osName}${deviceInfo.osVersion}` : "No browser detected";
    const deviceType = deviceInfo?.isMobile ? `${deviceInfo.vendor}${deviceInfo.model};${deviceInfo.os}${deviceInfo.osVersion}` : "";
    if (trustDevice === true) {
      Cookies.set("device_info", `${deviceInfo.isBrowser ? browserType : deviceType}`);
    }

    if (trustDevice === true && deviceInfo.isMobile) {
      Store.set("device_info", `${deviceInfo.isBrowser ? browserType : deviceType}`);
    }

  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement> | null): Promise<void> => {
    event?.preventDefault();
    setFormError(undefined);
    if (email === "" || password === "") {
      setFormError({
        message: "We're missing your email and password. Please type them in above.",
        action: () => history.push(Utils.APP_FORGOTTEN_LOGIN_DETAILS),
        actionText: "I've forgotten my login details",
      });

      return;
    }

    if (!deviceCookie) {
      setCookieModal(!cookieModal);
    }

    setSubmitting(cookieModal);

    analyticsHook.trackFormInteraction({
      event: "submit",
      group: "userLogin",
      step: {
        sequence: 1,
        label: "user-login-form",
      },
    });

    if (trustDevice !== null || deviceCookie) {
      if (deviceCookie) {
        setSubmitting(true);
      } else {
        setSubmitting(cookieModal);
      }

      try {
        const patientSignInResponse = await patientHook.patientSignIn(rememberMeStorage?.token === password ? {
          email: email.trim(),
          password: rememberMeStorage.token,
        } : {
          email: email.trim(),
          password: password,
        });

        if (patientSignInResponse.success && rememberMe) {
          Store.set("rememberMe", {
            email,
            token: password,
          });
        } else {
          Store.remove("rememberMe");
        }

        if (patientSignInResponse.success) {
          setFailedAttempts(0);
          if (patientContext.handleAuth) {
            await patientContext.handleAuth(patientSignInResponse.data);
          }

          analyticsHook.trackFormInteraction({
            event: "complete",
            group: "userLogin",
            step: {
              sequence: 1,
              label: "user-login-form",
            },
          });
        } else if (patientSignInResponse.error?.code === "external_error_koben_identity_auth") {
          recaptchaRef.current?.reset();
          setFailedAttempts(failedAttempts + 1);
          setCaptchaCompleted(false);
          setFormError({
            message: "Invalid email and password combination",
          });
        } else {
          setFormError({
            message: "An unexpected error ocurred. Please try again later",
          });

        }

        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setFormError({
          message: "Service unavailable. Please try again later.",
        });
        setSubmitting(false);
        throw error;
      }
    }
  };

  const handleEmail = (value: string) => {
    const parsedValue = Utils.getFormattedPhoneOrEmail(value);

    setEmail(parsedValue);
    setEmailError(undefined);
    if (parsedValue === "") {
      setEmailError("Please enter a valid email or phone number");
      setEmailValid(false);
      return;
    }
    if (Utils.validEmail(parsedValue) || Utils.validPhone(parsedValue)) {
      setEmailValid(true);
    } else if (!Utils.validEmail(parsedValue)) {
      setEmailError("Please enter a valid email");
    } else if (!Utils.validPhone(parsedValue)) {
      setEmailError(Utils.invalidDescriptionPhone(parsedValue));
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }

  };



  const handlePassword = (value: string) => {
    setPassword(value);
  };


  return (
    <Components.AppLayout
      headerVariation="centered"
    >
      <Components.Meta
        title="Log in to My Journey"
        metaDescription="Log in to the My Journey app and access reliable information tailored to your breast cancer experience."
      />
      <StyledPageTitle>
        <Components.TextVariation
          variation="heading1"
          align="center"
          fullWidth
        >
          {"Log in"}
        </Components.TextVariation>
      </StyledPageTitle>
      <Components.Form
        aria-label="Login"
        onSubmit={handleSubmit}
      >
        <Components.Grid
          cols="1"
          gap="6"
          spacing="og6"
        >
          <Components.Input
            name="emailOrPhone"
            type="text"
            required
            hideRequired
            label="Email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmail(e.target.value)}
            value={email}
            valid={emailValid}
            error={emailError}
          />
          <Components.Input
            name="password"
            type="password"
            required
            hideRequired
            label="Password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassword(e.target.value)}
            value={password}
          />
          {failedAttempts >= 2 &&
            <>
              <Components.TextVariation
                variation="paragraph2"
                align="center"
                fullWidth
              >
                {"Please complete CAPTCHA and try again."}
              </Components.TextVariation>
              <CaptchaWrapper>
                <ReCAPTCHA
                  sitekey={runtimeconfig.google_recaptcha_api_key}
                  onChange={() => setCaptchaCompleted(true)}
                  size="compact"
                  ref={recaptchaRef}
                />
              </CaptchaWrapper>
            </>
          }


          <Components.Box
            justifySelf="center"
            spacing="og6"
          >
            <Components.Checkbox
              label="Remember Me"
              checked={rememberMe}
              onChange={() => setRememberMe((prev) => !prev)}
            />
          </Components.Box>
          {formError ? (
            <Components.Box
              spacing="og4"
            >
              <Components.ErrorContainer
                {...formError}
              />
            </Components.Box>
          ) : null}
          <Components.Box
            spacing="og6"
          >
            <Components.ButtonVariation
              variation="primary"
              type="submit"
              disabled={submitting || (failedAttempts >= 2 && !captchaCompleted)}
              fullWidth
            >
              {submitting ? "Loading..." : "Log in"}
            </Components.ButtonVariation>
          </Components.Box>
        </Components.Grid>
      </Components.Form>
      <Components.Row>
        <Components.Column>
          <Components.Column
            justify="center"
          >
            <Components.Row>
              <Components.TextContainer
                align="center"
                spacing="ogxxxs"
              >
                <Components.TextLink
                  href={Utils.APP_FORGOTTEN_LOGIN_DETAILS}
                  aria-label="Forgot login"
                  textVariation="textlink2nounderline"
                >
                  {"Forgotten your login details?"}
                </Components.TextLink>
              </Components.TextContainer>
              <Components.TextContainer
                align="center"
              >
                <Components.TextVariation
                  variation="paragraph2"
                  color="greyDark"
                >

                  {"You need to register to be able to log in. "}
                </Components.TextVariation>
                <br />
                <Components.TextLink
                  href={Utils.SIGNUP}
                  aria-label="Forgot login"
                  textVariation="textlink2nounderline"
                >
                  {"Register Now"}
                </Components.TextLink>
              </Components.TextContainer>
            </Components.Row>
          </Components.Column>
        </Components.Column>
      </Components.Row>
      <Components.Row>
        <Components.DeviceTrustModal
          active={cookieModal}
          handleClose={() => setCookieModal(!cookieModal)}
          deviceInfo={deviceCookie}
        >
          <Column
            gutter="lg"
            spacing="sm"
          >
            <Components.ButtonVariation
              variation="primary"
              onClick={() => setTrustDevice(true)}
              fullWidth
            >
              {"Yes"}
            </Components.ButtonVariation>
          </Column>
          <Column
            gutter="lg"
            spacing="sm"
          >
            <Components.ButtonVariation
              variation="primary"
              fullWidth
              onClick={() => setTrustDevice(false)}
            >
              {"No"}
            </Components.ButtonVariation>
          </Column>
        </Components.DeviceTrustModal>
      </Components.Row>
    </Components.AppLayout>
  );
};

export type LoginProps = unknown;

type FormError = {
  message: string;
  action?: () => void
  actionText?: string
  CallToAction?: React.FC
}
