import * as React from "react";

import { Note } from "myjourney-frontend/src/vendor/umbraco";

import * as Hooks from "../hooks";

export const useNotes = (options: useNotesOptions = {}): {
  loading: boolean;
  notes: Array<Note>;
} => {
  const { getNotesByDateRange } = Hooks.useSymptomTrackerApi();
  const hookDateRange = Hooks.useDateRange();

  const [notes, setNotes] = React.useState<Array<Note>>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const getNotes = async () => {
    const selectedDateRange = options.dateRange ?? hookDateRange.default;
    const res = await getNotesByDateRange({
      FromDay: selectedDateRange.start.getDate(),
      FromMonth: selectedDateRange.start.getMonth() + 1,
      FromYear: selectedDateRange.start.getFullYear(),
      ToDay: selectedDateRange.end.getDate(),
      ToMonth: selectedDateRange.end.getMonth() + 1,
      ToYear: selectedDateRange.end.getFullYear(),
    });
    if (res.status === 200) {
      const notesRes = await res.json();
      setNotes(notesRes.notes);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    getNotes();
  }, [options.dateRange?.start.toDateString(), options.dateRange?.end.toDateString()]);

  return {
    loading: loading,
    notes: notes,
  };
};

export type useNotesOptions = {
  dateRange?: {
    start: Date;
    end: Date;
  };
};
