import * as React from "react";
import parse from "html-react-parser";
import { H2, RichText } from "~/components/Styled";
import { StackedContent } from "myjourney-frontend/src/vendor/umbraco";

export const Actions: React.FC<Props> = ({ actions }) => (
  <>
    <H2>Things you can do now</H2>
    <RichText
      spacing="lg"
    >
      {parse(`<ul>${actions.map((action) => `<li>${action}</li>`).join("")}<ul>`)}
    </RichText>
  </>
);

type Props = StackedContent.Actions
