import * as React from "react";
import { ErrorContainer } from "~/components/ErrorContainer/ErrorContainer";
import { Input } from "~/components/Input/Input";
import { Meta } from "~/components/Meta/Meta";
import { PasswordHelper } from "~/components/PasswordHelper/PasswordHelper";
import { Form, H2, P } from "~/components/Styled";
import { AccountDetailsProps } from "~/hooks/useAccountDetails";
import { USER_MY_ACCOUNT } from "~/utils/constants";
import { withAccountDetails } from "./withAccountDetails";

import * as Components from "~/components";

export const AccountDetails = withAccountDetails(
  ({
    oldPassword,
    password,
    confirmPassword,
    handleOldPassword,
    handlePassword,
    handleConfirmPassword,
    oldPasswordError,
    passwordError,
    confirmPasswordError,
    changed,
    loading,
    handleEmail,
    handlePhone,
    emailValid,
    emailValidating,
    emailError,
    phoneValid,
    phoneValidating,
    phoneError,
    email,
    phone,
    passwordRules,
    handleSubmitDetails,
    changedPassword,
    loadingPassword,
    handleSubmitPassword,
    passwordFormError,
    handleDeletePatient,
  }: Props) => {
    const [openDelete, setOpenDelete] = React.useState(false);
    return (
      <Components.AppLayout headerVariation="navigationBack">
        <Meta title="Update your account details" />
        <Components.Row>
          <Components.Column spacing="og2">
            <Components.TextVariation variation="heading1" spacing="og1">
              {"Update your account details"}
            </Components.TextVariation>
          </Components.Column>
        </Components.Row>
        <Form onSubmit={handleSubmitDetails} aria-label="Account details form">
          <Components.Grid cols="1" colsMd="2" gapX="4" gapY="6" spacing="og6">
            <Input
              label="Email address (used to log in)"
              hideRequired
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEmail(e.target.value)
              }
              value={email}
              error={emailError}
              valid={emailValid}
              validating={emailValidating}
            />
            <Input
              label="Mobile"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handlePhone(e.target.value)
              }
              value={phone}
              error={phoneError}
              valid={phoneValid}
              validating={phoneValidating}
              hideRequired
            />
          </Components.Grid>
          <Components.Grid cols="1" colsMd="2" gapX="4" gapY="6" spacing="og6">
            <Components.ButtonVariation
              variation="secondary"
              href={USER_MY_ACCOUNT}
              aria-label="Cancel"
              fullWidth
            >
              {"Cancel"}
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="primary"
              disabled={!changed || loading}
              aria-label="Save"
              fullWidth
            >
              {loading ? "Updating..." : "Confirm & save"}
            </Components.ButtonVariation>
          </Components.Grid>
        </Form>
        <H2>Change password</H2>
        <P>
          {
            "So that we can keep your information secure, your password must be at least 6 characters in length and contain at least one uppercase letter, number, and non-alphanumeric symbol (e.g. !@#$%^&*)"
          }
        </P>
        <Form onSubmit={handleSubmitPassword} aria-label="Password form">
          <Components.Grid cols="1" colsMd="2" gap="4" spacing="og6">
            <Components.Column>
              {passwordFormError ? (
                <Components.Column spacing="sm">
                  <ErrorContainer message={passwordFormError} />
                </Components.Column>
              ) : null}
              <Input
                label="Old Password"
                type="password"
                value={oldPassword}
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleOldPassword(e.target.value)
                }
                error={oldPasswordError}
                hideRequired
              />
            </Components.Column>
          </Components.Grid>
          <Components.Grid cols="1" colsMd="2" gapX="4" gapY="6" spacing="og6">
            <Input
              label="New Password"
              type="password"
              value={password}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handlePassword(e.target.value)
              }
              error={passwordError}
              hideRequired
            />
            <Input
              label="Confirm New Password"
              type="password"
              required
              value={confirmPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleConfirmPassword(e.target.value)
              }
              error={confirmPasswordError}
              hideRequired
            />
          </Components.Grid>
          <Components.Row>
            <Components.Column spacing="sm">
              <PasswordHelper rules={passwordRules} />
            </Components.Column>
          </Components.Row>
          <Components.Grid cols="1" colsMd="2" gapX="4" gapY="6" spacing="og6">
            <Components.ButtonVariation
              variation="secondary"
              href={USER_MY_ACCOUNT}
              aria-label="Cancel"
              fullWidth
            >
              {"Cancel"}
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="primary"
              disabled={!changedPassword || loadingPassword}
              aria-label="Save"
              fullWidth
            >
              {"Confirm & save"}
            </Components.ButtonVariation>
          </Components.Grid>
        </Form>
        <H2>Delete your account</H2>
        <Components.ButtonVariation
          variation="primary"
          aria-label="Delete"
          fullWidth
          onClick={() => setOpenDelete(true)}
        >
          {"Delete account"}
        </Components.ButtonVariation>
        <ConfirmDeleteModal
          title={"Delete your account"}
          active={openDelete}
          onClose={() => setOpenDelete(false)}
          confirm={async () => {
            await handleDeletePatient();
            setOpenDelete(false);
          }}
        />
      </Components.AppLayout>
    );
  }
);

type ConfirmDeleteModalProps = {
  confirm: () => void;
} & Components.SwipeModalProps;
const ConfirmDeleteModal = ({
  title,
  active,
  onClose,
  confirm,
}: ConfirmDeleteModalProps) => {
  return (
    <Components.SwipeModal title={title} active={active} onClose={onClose}>
      <P>{"Are you sure you want to delete your account?"}</P>

      <Components.Column spacing="ogxxxs">
        <Components.ButtonVariation
          variation="primary"
          aria-label="Delete"
          fullWidth
          onClick={confirm}
        >
          {"Yes delete my account"}
        </Components.ButtonVariation>
      </Components.Column>
      <Components.Column>
        <Components.ButtonVariation
          variation="secondary"
          aria-label="Cancel"
          fullWidth
          onClick={onClose}
        >
          {"Cancel delete"}
        </Components.ButtonVariation>
      </Components.Column>
    </Components.SwipeModal>
  );
};

type Props = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
  handleOldPassword: (value: string) => void;
  handlePassword: (value: string) => void;
  handleConfirmPassword: (value: string) => void;
  handleDeletePatient: () => Promise<void>;
  oldPasswordError: JSX.Element | string | undefined;
  passwordError: JSX.Element | string | undefined;
  confirmPasswordError: JSX.Element | string | undefined;
  handleSubmitDetails: (
    event: React.FormEvent<HTMLFormElement>
  ) => Promise<void>;
  handleSubmitPassword: (
    event: React.FormEvent<HTMLFormElement>
  ) => Promise<void>;
  changed: boolean;
  loading: boolean;
  changedPassword: boolean;
  loadingPassword: boolean;
  passwordRules: Record<string, boolean>;
  passwordFormError?: JSX.Element | string | undefined;
} & AccountDetailsProps;
