import * as React from "react";
import { useSymptomTrackerApi } from "~/hooks/useSymptomTrackerApi";

export const withUserImages = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "UserImage", ids, ...props }: Props) => {
  Component.displayName = name;
  const [blobs, setBlobs] = React.useState<Array<Blob>>([]);
  const { getUserImage } = useSymptomTrackerApi();

  React.useEffect(() => {
    if (ids.length) {
      ids.forEach(async (id) => {
        const res = await getUserImage(id);
        const blob = await res.blob();
        setBlobs((prev) => [...prev, blob]);
      });
    }
  }, [ids]);

  return (
    <Component
      { ...props as P & Props }
      blobs={blobs}
    />
  );
};
type Props = {
  name?: string
  ids: Array<string>
}
