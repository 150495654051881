import * as React from "react";

export const useReadMore = (): {
  handleReadMore: typeof handleReadMore
  wrapperRef: React.RefObject<HTMLDivElement>
  active: boolean
} => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const [active, setActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (wrapperRef.current) {
      const wrapper = wrapperRef.current;
      wrapper.style.height = active ? `${wrapper.scrollHeight}px` : "";
    }
  }, [active, wrapperRef]);

  const handleReadMore = () => setActive((prev) => !prev);

  return {
    handleReadMore,
    wrapperRef,
    active,
  };
};
