import * as React from "react";
import { format } from "date-fns";

import { Symptom, SymptomLogEntry, SymptomResponse } from "myjourney-frontend/src/vendor/umbraco";

import * as Hooks from "~/hooks";
import * as Components from "~/components";

export const SymptomListItem: React.FunctionComponent<SymptomListItemProps> = (props) => {
  const { trackTrackerAccordions } = Hooks.useAnalytics();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedSymptom, setSelectedSymptom] = React.useState<SymptomLogEntry>();
  const [editSymptomId, setEditSymptomId] = React.useState<string>();
  const [saved, setSaved] = React.useState<(SymptomResponse & { feedback?: string })>();

  React.useEffect(() => {
    setSelectedSymptom(props.symptoms[0]);
    setSaved({
      received: "",
      requestId: "",
      responseId: "",
      symptomLogEntry: props.symptoms[0],
    });
  }, []);

  const handleChange = (val: string) => {
    const symptom = props.symptoms.find(({ id }) => val === id);
    if (symptom) {
      setSelectedSymptom(symptom);
    }
  };

  const handleEditSymptomId = (id?: string) => {
    setEditSymptomId(id);
  };

  const items = [];

  if (selectedSymptom?.description) {
    items.push({
      content: (
        <Components.TextVariation
          variation="paragraph2"
          color="blueNavy"
        >
          {selectedSymptom.description}
        </Components.TextVariation>
      ),
      label: "Description",
    });
  }
  if (selectedSymptom?.actionsTaken) {
    items.push({
      content: (
        <Components.TextVariation
          variation="paragraph2"
          color="blueNavy"
        >
          {selectedSymptom.actionsTaken}
        </Components.TextVariation>
      ),
      label: "Actions taken",
    });
  }
  if (selectedSymptom?.questions) {
    items.push({
      content: (
        <Components.TextVariation
          variation="paragraph2"
          color="blueNavy"
        >
          {selectedSymptom.questions}
        </Components.TextVariation>
      ),
      label: "My questions",
    });
  }

  const symptomContent = selectedSymptom ? props.allSymptoms.find((s) => selectedSymptom.symptomTypeId === s.id) : null;
  return (
    <Components.EntryCard
      title={props.name}
      iconValue={props.count}
      iconLabel="Entries"
      open={open}
      onOpenOrClose={() => {
        setOpen((prev) => {
          trackTrackerAccordions({
            action: prev ? "close" : "open",
            group: "Symptoms",
            location: "Symptom Listing",
            label: props.name,
            position: props.index,
          });
          return !prev;
        });
      }}
    >
      {selectedSymptom?.severityType === "range" && (
        <Components.Grid
          gapY="4"
          spacing="og6"
        >
          <Components.Box>
            <Components.TextVariation
              variation="heading4"
              color="greyMedium"
            >
              {"Frequency and intensity"}
            </Components.TextVariation>
            <Components.TextVariation
              variation="paragraph2"
              color="greyMedium"
            >
              {"(for this date range)"}
            </Components.TextVariation>
          </Components.Box>
          <Components.SymptomGraphSingle
            // @ts-expect-error note: data is not typed but does exist
            data={props.data}
          />
        </Components.Grid>
      )}
      <Components.Row>
        <Components.Column
          spacing="og4"
        >
          {props.symptoms?.length && (
            <Components.Select
              label="View details by date:"
              onChange={(e) => handleChange(e.target.value)}
              options={props.symptoms.map((s) => ({
                label: format(new Date(s.year, s.month - 1, s.day), "EEE, d MMMM yyy"),
                value: s.id,
              }))}
            />
          )}
        </Components.Column>
        <Components.Box
          spacing="og4"
          justifyContent="center"
          fullWidth
        >
          <Components.Column
            xs="1/2"
          >
            <Components.ButtonVariation
              variation="primarysmall"
              onClick={() => handleEditSymptomId(selectedSymptom?.id)}
              fullWidth
            >
              {"Edit"}
            </Components.ButtonVariation>
          </Components.Column>
        </Components.Box>
          {Boolean(items?.length) && (
            <Components.Column
              spacing="og4"
            >
              <Components.SymptomDetailAccordion
                items={items}
              />
            </Components.Column>
          )}
        <Components.TextVariation
          variation="heading4"
          color="greyMedium"
          fullWidth
        >
          {"Related photos"}
        </Components.TextVariation>
        <Components.Box
          fullWidth
        >
          {selectedSymptom?.images?.length ? (
            <Components.UserImages
              ids={selectedSymptom.images}
            />
          ) : (
            <Components.TextVariation
              variation="paragraph2"
              color="blueNavy"
            >
              {"No photos uploaded for this symptom."}
            </Components.TextVariation>
          )}
        </Components.Box>
      </Components.Row>
      {selectedSymptom && symptomContent ? (
        <Components.EditSymptom
          handleEditSymptomId={handleEditSymptomId}
          editSymptomId={editSymptomId}
          symptom={symptomContent}
          setSaved={setSaved}
          symptomResponse={saved}
          date={new Date(selectedSymptom.year, selectedSymptom.month - 1, selectedSymptom.day)}
        />
      ) : null}
    </Components.EntryCard>
  );
};

export type SymptomListItemProps = {
  name: string
  count: number
  symptoms: Array<SymptomLogEntry>
  allSymptoms: Array<Symptom>
  index: number
}
