import * as React from "react";
import TagManager from "react-gtm-module";
import { Capacitor, Device } from "@capacitor/core";

export const AnalyticsContext = React.createContext<AnalyticsContextState>({
  gtm: TagManager,
});

export const AnalyticsProvider: React.FunctionComponent = ({
  children,
}) => {
  React.useEffect(() => {
    /**
     * Quick and dirty check for mobile or desktop, from
     * https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript
     */
    const uiExperience = ((typeof window.orientation !== "undefined") || window.navigator.userAgent.indexOf("IEMobile") !== -1) ? "mobile" : "desktop";

    const dataLayer = [{
      content: {
        environment: "uat",
        topic: undefined,
        type: undefined,
        articleId: undefined,
        articleTitle: undefined,
        uiExperience,
        lastReviewed: undefined,
      },
      geo: {
        postcode: undefined,
        state: undefined,
      },
      user: {
        id: undefined,
        type: undefined,
        dob: undefined,
        gender: undefined,
        diagnosis: undefined,
        situation: undefined,
        diagnosis_type: undefined,
        diagnosis_location: undefined,
        lastlLoggedIn: undefined,
        daysSinceLastSession: undefined,
      },
      route: {
        current: undefined,
        previous: undefined,
        title: undefined,
      },
    }];

    const tagManagerArgs = {
      gtmId: runtimeconfig.google_tag_manager_id,
      dataLayer,
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  React.useEffect(() => {
    (async () => {
      const deviceInfo = await Device.getInfo();

      TagManager.dataLayer({
        dataLayer: {
          device: {
            native: Capacitor.isNative,
            platform: Capacitor.getPlatform(),
            os: deviceInfo.operatingSystem,
            os_version: deviceInfo.osVersion,
          },
        },
      });
    })();
  }, []);

  const value = {
    gtm: TagManager,
  };

  return (
    <AnalyticsContext.Provider
      value={value}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const withTracking = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "TrackedComponent", ...props }: Props) => {
  Component.displayName = name;
  return (
    <Component
      { ...props as P }
    />
  );
};

export type AnalyticsContextState = {
  gtm: typeof TagManager
}

type Props = {
  name?: string
}
