import * as React from "react";
import { CheckboxGroup } from "~/components/CheckboxGroup/CheckboxGroup";
import { Meta } from "~/components/Meta/Meta";

import { Column, Form, H3, Row } from "~/components/Styled";
import { UserTypeProps } from "~/hooks/useUserTypes";
import { withAboutMe } from "./withAboutMe";

import * as Components from "~/components";

export const AboutMe = withAboutMe(({
  selectedUserTypes, handleUserTypes, userTypeData, handleSubmit, changed, loading,
}: Props) => (
  <Components.AppLayout
    headerVariation="navigationBack"
  >
    <Meta
      title="About me"
    />
    <Form
      onSubmit={handleSubmit}
    >
      <Row
        spacing="lg"
      >
        <Column>
          <H3
            spacing="lg"
          >So we can provide you with more personalised content, please choose any of the following that apply to you:</H3>
        </Column>
        <Column>
          {userTypeData?.length > 0 ? (
            <CheckboxGroup
              checked={selectedUserTypes}
              onChange={handleUserTypes}
              data={userTypeData}
            />
          ) : null}
        </Column>
      </Row>
      <Row>
        <Column
          spacing="sm"
          md="1/2"
        >
          <Components.ButtonVariation
            variation="primary"
            disabled={!changed || loading}
            fullWidth
          >
            {loading ? "Loading..." : "Confirm & save"}
          </Components.ButtonVariation>
        </Column>
      </Row>
    </Form>
  </Components.AppLayout>
));

type Props = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  changed: boolean
  loading: boolean
} & UserTypeProps;
