import * as React from "react";
import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";
import { PatientContext } from "~/providers/PatientProvider";
import { useContentApi } from "~/hooks/useContentApi";
import { useAuthentication } from "~/hooks/useAuthentication";

export const withBookmarks = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "Bookmarks", ...props }: Props) => {
  Component.displayName = name;
  useAuthentication();

  const { auth } = React.useContext(PatientContext);

  const { getArticles } = useContentApi();

  const [bookmarkedArticles, setBookmarkedArticles] = React.useState<Array<MergedArticle>>([]);

  React.useEffect(() => {
    (async () => {
      const _articles = await getArticles();
      delete _articles?.featured;
      const articles: Array<MergedArticle> = Object.values(_articles ?? {}).flat();

      setBookmarkedArticles(articles.filter((article) => auth?.patient_details.bookmark_ids.includes(`${article.id}`)));
    })();
  }, [auth]);

  return (
    <Component
      { ...props as P }
      articles={bookmarkedArticles}
    />
  );
};
type Props = {
  name?: string
}
