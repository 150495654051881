import * as React from "react";
import { useHistory } from "react-router-dom";
import tw, { styled } from "twin.macro";

import * as Utils from "~/utils";
import * as Providers from "~/providers";
import * as Components from "~/components";

import IconLogo from "~/../assets/icons/logo.svg";
import IconProfile from "~/../assets/icons/icon-header-profile.svg";
import IconInfo from "~/../assets/icons/icon-header-info.svg";
import IconHome from "~/../assets/icons/icon-header-home.svg";
import IconBack from "~/../assets/icons/icon-back.svg";

const StyledHeader = styled.div(() => [
  tw`mt-og6 md:mt-0`,
]);

const HeaderVariations = {
  centered: () => (
    <StyledHeader>
      <Components.Row
        spacing="og4"
      >
        <Components.Column
          justify="center"
        >
          <Components.StyledLink
            to={Utils.APP_HOME}
            aria-label="Home"
          >
            <IconLogo
              width="160px"
            />
          </Components.StyledLink>
        </Components.Column>
      </Components.Row>
    </StyledHeader>
  ),
  standard: (props: HeaderVariationPropsNested) => (
    <StyledHeader>
      <Components.Row
        spacing="og4"
      >
        <Components.Column
          xs="1/2"
        >
          <Components.StyledLink
            to={props.patientContext.auth ? Utils.USER_DASHBOARD : Utils.APP_HOME}
            aria-label="Home"
          >
            <IconLogo
              width="140px"
            />
          </Components.StyledLink>
        </Components.Column>
        <Components.Column
          xs="1/2"
          justify="end"
        >
          {props.content}
        </Components.Column>
      </Components.Row>
    </StyledHeader>
  ),
  navigationUser: (props: HeaderVariationPropsNested) => {
    return (
      <HeaderVariation
        variation="standard"
        content={[(
          <Components.Box
            key={1}
            aria-label={"Intro"}
          >
            <Components.StyledLink
              to={Utils.USER_WELCOME}
            >
              <Components.Row>
                <Components.Column
                  justify="center"
                  spacing="ogxxxxxs"
                >
                  <IconInfo
                    width="32px"
                  />
                </Components.Column>
                <Components.Column
                  justify="center"
                >
                  <Components.TextVariation
                    variation="navigation"
                  >
                    {"Intro"}
                  </Components.TextVariation>
                </Components.Column>
              </Components.Row>
            </Components.StyledLink>
          </Components.Box>
        ),(
          <Components.Box
            key={2}
            onClick={() => props.modalContext.toggleModal("accountProfile")}
            aria-label={"My profile"}
          >
            <Components.Row>
              <Components.Column
                justify="center"
                spacing="ogxxxxxs"
              >
                <IconProfile
                  width="32px"
                />
              </Components.Column>
              <Components.Column
                justify="center"
              >
                <Components.TextVariation
                  variation="navigation"
                >
                  {"My profile"}
                </Components.TextVariation>
              </Components.Column>
            </Components.Row>
          </Components.Box>
        )]}
      />
    );
  },
  navigationUserWelcome: (props: HeaderVariationPropsNested) => {
    return (
      <HeaderVariation
        variation="standard"
        content={[(
          <Components.Box
            key={1}
            aria-label={"Intro"}
          >
            <Components.StyledLink
              to={Utils.USER_DASHBOARD}
            >
              <Components.Row>
                <Components.Column
                  justify="center"
                  spacing="ogxxxxxs"
                >
                  <IconHome
                    width="32px"
                  />
                </Components.Column>
                <Components.Column
                  justify="center"
                >
                  <Components.TextVariation
                    variation="navigation"
                  >
                    {"Home"}
                  </Components.TextVariation>
                </Components.Column>
              </Components.Row>
            </Components.StyledLink>
          </Components.Box>
        ),(
          <Components.Box
            key={2}
            onClick={() => props.modalContext.toggleModal("accountProfile")}
            aria-label={"My profile"}
          >
            <Components.Row>
              <Components.Column
                justify="center"
                spacing="ogxxxxxs"
              >
                <IconProfile
                  width="32px"
                />
              </Components.Column>
              <Components.Column
                justify="center"
              >
                <Components.TextVariation
                  variation="navigation"
                >
                  {"My profile"}
                </Components.TextVariation>
              </Components.Column>
            </Components.Row>
          </Components.Box>
        )]}
      />
    );
  },
  navigationLogin: () => {
    return (
      <HeaderVariation
        variation="standard"
        content={[(
          <Components.ButtonVariation
            key={1}
            variation="secondary"
            href={Utils.USER_LOGIN}
          >
            {"Login"}
          </Components.ButtonVariation>
        )]}
      />
    );
  },
  navigationHpLogin: () => {
    return (
      <HeaderVariation
        variation="standard"
        content={[(
          <Components.ButtonVariation
            key={1}
            variation="primary"
            href={Utils.HP_REGISTER_EXTERNAL}
            external={true}
          >
            {"Register"}
          </Components.ButtonVariation>
        ),(
          <Components.ButtonVariation
            key={2}
            variation="secondary"
            href={Utils.HP_LOGIN}
          >
            {"Login"}
          </Components.ButtonVariation>
        )]}
      />
    );
  },
  navigationHome: () => {
    return (
      <HeaderVariation
        variation="standard"
        content={(
          <Components.ButtonVariation
            variation="primarysmall"
            iconLeft={<IconBack />}
            href={Utils.USER_DASHBOARD}
          >
            {"Home"}
          </Components.ButtonVariation>
        )}
      />
    );
  },
  navigationBack: () => {
    const history = useHistory();

    return (
      <HeaderVariation
        variation="standard"
        content={(
          <Components.ButtonVariation
            variation="secondarysmall"
            iconLeft={<IconBack />}
            onClick={() => history.goBack()}
          >
            {"Back"}
          </Components.ButtonVariation>
        )}
      />
    );
  },
};

export const HeaderVariation: React.FunctionComponent<HeaderVariationProps> = (props) => {
  const patientContext = React.useContext(Providers.PatientContext);
  const modalContext = React.useContext(Providers.ModalContext);

  return HeaderVariations[props.variation]({
    ...props,
    patientContext,
    modalContext,
  });
};

export type HeaderVariationProps = {
  variation: keyof typeof HeaderVariations;
  content?: JSX.Element | Array<JSX.Element>;
};

export type HeaderVariationPropsNested = HeaderVariationProps & {
  patientContext: Providers.PatientContextState;
  modalContext: Providers.ModalContextState;
}
