import tw, { css, styled } from "twin.macro";
import ReactPlayer from "react-player";

export const VideoWrapper = styled.div(() => [
  css`
    padding-top: 75%;
  `,
  tw`relative w-full rounded-sm overflow-hidden`,
   
]);

export const VideoOverlay = styled.div(({ image, hidden }: Props) => [
  css`
    background-image: url("${image}");
    background-size: 150%;
    &::before {
      content: "";
      ${tw`absolute w-full h-full bg-pink rounded-sm transition opacity-20`}
    }
    &::after {
      content: "";
      ${tw`absolute w-full h-full bg-black rounded-sm transition opacity-50`}
    }
  `,
  tw`absolute inset-0 z-10 rounded-sm transition bg-center cursor-pointer`,
  ...(hidden ? [tw`opacity-0 pointer-events-none`] : []),
]);

export const StyledVideo = styled(ReactPlayer)`
  ${tw`absolute inset-0`}
  video {
    ${tw`w-full h-full rounded-sm pointer-events-none object-cover`}
  }

  iframe{
    ${tw`rounded-sm`}
  }
`;

export const StyledContent = styled.div(() => [
  tw`absolute inset-4 z-10`,
]);

export const StyledTextTop = styled.div(() => [
  tw`absolute top-0 left-0 m-0 w-full`,
]);

export const StyledTextBottom = styled.div(() => [
  tw`absolute bottom-0 left-0 m-0`,
]);

export const IconWrapper = tw.div`absolute inset-0 flex items-center justify-center`;

type Props = {
  image: string
  hidden: boolean
}
