import * as React from "react";
import { ButtonLink, Strong } from "../Styled";
import { CloseButton, Content, Header, Inner, ModalBackground, Overlay, OverlayTitle } from "./styledSymptomModal";
import CloseIcon from "~/../assets/icons/cross.svg";
import { format } from "date-fns";

export const SymptomModal: React.FC<Props> = ({ children, active, prefix, title, date, handleClose }) => (
<Overlay
  active={active}
>
  <ModalBackground
    active={active}
  />
  <Inner>
    <Header>
      <div>
        {title || prefix ? (
          <>
            <OverlayTitle>
              {prefix ? (
                <Strong
                  weight="bold"
                >{prefix}</Strong>
              ) : null}
              {title}
            </OverlayTitle>
            {date ? (
              <ButtonLink
                fontSize="xs"
                weight="medium"
                underlined
              >
                {format(date, "dd MMMM yyyy")}
              </ButtonLink>
            ) : null}
          </>
        ) : null}
        </div>
        
      <CloseButton
        onClick={handleClose}
      >
        <CloseIcon
          width="20"
          height="20"
          viewBox="0 0 24 24"
        />
      </CloseButton>
    </Header>
    <Content>
      {children}
    </Content>
  </Inner>
</Overlay>
);

type Props = {
  active: boolean
  title?: string
  prefix?: string
  handleClose: () => void
  date?: Date
}
