import {
  Article,
  DiagnosisTree,
  MergedArticle,
  Symptom,
  SymptomCategory,
  Topic,
  UserMetadata,
  UserType,
  WelcomeData,
} from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  PatientContext,
  UserArticles,
  UserAuth,
} from "../providers/PatientProvider";
import { SymptomContext } from "../providers/SymptomProvider";
import {
  API_GET_DIAGNOSIS_TREE,
  API_GET_MEMBER_METADATA,
  API_GET_USER_TYPES,
  API_GET_ARTICLES,
  USER_LOGIN,
  API_GET_CATEGORIES,
  API_SEARCH_ARTICLES_BY_KEYWORD,
  API_GET_ARTICLE,
  API_WELCOME_CONTENT,
  API_SEARCH_AUTCOMPLETE,
  API_GET_SYMPTOMS,
  API_GET_SYMPTOM_CATEGORIES,
  API_GET_SYMPTOM_ARTICLES_BY_ID,
} from "../utils/constants";
import Cookies from "js-cookie";

export const useContentApi = (): {
  getMemberMetadata: typeof getMemberMetadata;
  getDiagnosisTree: typeof getDiagnosisTree;
  getUserTypes: typeof getUserTypes;
  getArticles: typeof getArticles;
  getWelcomeData: typeof getWelcomeData;
  getCategories: typeof getCategories;
  getNonEmptyCategories: typeof getNonEmptyCategories;
  getCategory: typeof getCategory;
  getArticle: typeof getArticle;
  getMergedArticles: typeof getMergedArticles;
  searchArticlesByKeyword: typeof searchArticlesByKeyword;
  searchAutoComplete: typeof searchAutoComplete;
  getSymptoms: typeof getSymptoms;
  getSymptomCategories: typeof getSymptomCategories;
  getArticlesBySymptomId: typeof getArticlesBySymptomId;
  getStories: typeof getStories;
} => {
  const history = useHistory();
  const { symptoms, setSymptoms, symptomCategories, setSymptomCategories } =
    React.useContext(SymptomContext);
  const {
    auth,
    handleLogout,
    handleUserArticles,
    categories,
    handleNonEmptyCategories,
    nonEmptyCategories,
    diagnosisTree,
    setDiagnosisTree,
    getSituationIds,
    userTypes,
    setUserTypes,
    memberMetadata,
    handleMemberMetadata,
  } = React.useContext(PatientContext);

  const protocolOrWhiteSpace = runtimeconfig.koben_umbraco_domain.includes(
    "https://"
  )
    ? ""
    : "https://";

  const getContentApi = async (url: string, token?: string) => {
    console.log(
      "fetching with content api:",
      `${
        protocolOrWhiteSpace + runtimeconfig.koben_umbraco_domain
      }/umbraco/api${url}`,
      `token: ${token}`
    );
    const res = await fetch(
      `${
        protocolOrWhiteSpace + runtimeconfig.koben_umbraco_domain
      }/umbraco/api${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "Bearer",
        },
      }
    );

    console.log("WHAT IS MY STATUS CONTENT API", res.status);

    return res;
  };

  const getMemberMetadata = async (
    _auth?: UserAuth
  ): Promise<UserMetadata | null> => {
    if (memberMetadata) {
      return memberMetadata;
    }
    const a = _auth || auth;
    if (!a) {
      return null;
    }
    const res = await getContentApi(
      API_GET_MEMBER_METADATA,
      Cookies.get("koben_token") || a?.koben_token?.access_token
    );
    if (res.status === 401) {
      if (handleLogout) {
        handleLogout();
      }
      return null;
    }

    const text = await res.text();
    const json = JSON.parse(text);

    handleMemberMetadata?.({
      ...json.metadata,
      ...json,
    });

    return {
      ...json.metadata,
      ...json,
    };
  };

  const getDiagnosisTree = async (): Promise<DiagnosisTree> => {
    if (diagnosisTree) {
      return diagnosisTree;
    }
    const res = await getContentApi(API_GET_DIAGNOSIS_TREE);
    const json = await res.json();
    if (setDiagnosisTree) {
      setDiagnosisTree(json);
    }
    return json;
  };

  const getUserTypes = async (): Promise<Array<UserType>> => {
    if (userTypes) {
      return userTypes;
    }
    const res = await getContentApi(API_GET_USER_TYPES);
    const json = await res.json();
    if (setUserTypes) {
      setUserTypes(json);
    }
    return json;
  };

  const getArticles = async (
    categories: Array<string> = [""]
  ): Promise<UserArticles | null> => {
    if (!auth) {
      if (history) {
        history.push(USER_LOGIN);
      }
      return null;
    }

    const userTypes = (auth.patient_details.usertype_ids || []).join(",");
    const situationIds = getSituationIds ? await getSituationIds() : [];
    const _situationIds = situationIds.join(",");
    const res = await getContentApi(
      `${API_GET_ARTICLES}?userTypeIds=${userTypes}&SituationId=${_situationIds}&categories=${categories.join(
        ","
      )}`,
      Cookies.get("koben_token") || auth.koben_token.access_token
    );
    const json = await res.json();
    const metadata = await getMemberMetadata();
    if (json.message) {
      return null;
    }
    const articles: UserArticles = {};
    json.forEach((article: MergedArticle) => {
      /**
       * Merge the read data from the users metadata onto the article
       */
      article.readData =
        metadata?.patient_preferences?.readArticles?.find(
          (readArticle) => article.id === readArticle.id
        ) || null;
      /**
       * Create an object of articles structured by categories
       */
      for (const articleCategory of article.categories) {
        articles[articleCategory] = articles[articleCategory] || [];
        articles[articleCategory].push(article);
      }
    });

    articles.featured = Object.keys(articles)
      .reduce((a: Array<MergedArticle>, key) => {
        return [...a, ...articles[key]];
      }, [])
      // Only use featured
      .filter((a) => a.isFeatured)
      // Remove any duplicates
      .filter(
        (article, index, self) =>
          self.findIndex((item) => item.id === article.id) === index
      )
      .sort(
        (a, b) =>
          new Date(b.dateReviewed).getTime() -
          new Date(a.dateReviewed).getTime()
      );

    if (handleUserArticles) {
      handleUserArticles(articles);
    }
    if (handleNonEmptyCategories) {
      handleNonEmptyCategories(Object.keys(articles));
    }
    return articles;
  };

  const getStories = async (
    userTypeId: string
  ): Promise<Array<MergedArticle> | []> => {
    if (!auth) {
      return [];
    }

    const articles: Array<MergedArticle> = [];

    const situationIds = getSituationIds ? await getSituationIds() : [];
    const res = await getContentApi(
      `${API_GET_ARTICLES}?userTypeIds=${userTypeId}&categories=1164&SituationId=${situationIds.join(
        ","
      )}`,
      Cookies.get("koben_token") || auth.koben_token.access_token
    );
    const json = await res.json();

    if (json.message) {
      return articles;
    }

    const metadata = await getMemberMetadata();

    json.forEach((article: MergedArticle) => {
      if (article.documentTypeAlias === "video") {
        /**
         * Merge the read data from the users metadata onto the article
         */
        article.readData =
          metadata?.patient_preferences?.readArticles?.find(
            (readArticle) => article.id === readArticle.id
          ) || null;
        /**
         * Create an object of articles structured by categories
         */
        articles.push(article);
      }
    });

    return articles;
  };

  const getWelcomeData = async (
    auth: UserAuth | null
  ): Promise<WelcomeData | null> => {
    if (!auth) {
      history.push(USER_LOGIN);
      return null;
    }
    const situationIds = getSituationIds ? await getSituationIds() : [];
    const userTypes = [...auth.patient_details.usertype_ids].join(",");
    const _situationIds = situationIds.join(",");
    const res = await getContentApi(
      `${API_WELCOME_CONTENT}?userTypeIds=${userTypes}&SituationId=${_situationIds}`,
      Cookies.get("koben_token") || auth.koben_token.access_token
    );
    const json = await res.json();
    return json;
  };

  const getCategories = async (): Promise<Array<Topic>> => {
    if (categories) {
      return categories;
    }
    const res = await getContentApi(API_GET_CATEGORIES);
    const json = await res.json();
    return json;
  };

  const getMergedArticles = async (
    articles: Array<Article>
  ): Promise<MergedArticle[]> => {
    const metadata = await getMemberMetadata();
    const mergedArticles = articles.map((article) => {
      const readData =
        metadata?.patient_preferences?.readArticles?.find(
          (readArticle) => readArticle.id === article.id
        ) || null;
      const bookmarkIds = auth?.patient_details.bookmark_ids || [];
      return {
        ...article,
        readData,
        bookmarked: Boolean(
          bookmarkIds.find((bookmarkId) => bookmarkId === `${article.id}`)
        ),
      };
    });
    return mergedArticles;
  };

  const searchArticlesByKeyword = async (
    keyword: string
  ): Promise<Array<MergedArticle>> => {
    if (!auth) {
      return [];
    }
    const urlSearchParams = new URLSearchParams();
    const situationIds = getSituationIds ? await getSituationIds() : [];
    urlSearchParams.set("keyword", keyword.trim());
    urlSearchParams.set(
      "userTypeIds",
      auth.patient_details.usertype_ids.join(",")
    );
    urlSearchParams.set("situationIds", situationIds.join(","));
    const res = await getContentApi(
      `${API_SEARCH_ARTICLES_BY_KEYWORD}?${urlSearchParams.toString()}`,
      Cookies.get("koben_token") || auth.koben_token.access_token
    );

    const json = await res.json();
    return json;
  };

  const searchAutoComplete = async (
    keyword: string
  ): Promise<Array<string>> => {
    if (!auth) {
      return [];
    }
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set("input", keyword);
    const res = await getContentApi(
      `${API_SEARCH_AUTCOMPLETE}?${urlSearchParams.toString()}`,
      Cookies.get("koben_token") || auth.koben_token.access_token
    );
    const json = await res.json();
    return json;
  };

  const getCategory = async (_id: string) => {
    const allCategories = await getCategories();
    return allCategories.find(({ id }) => id === Number(_id));
  };

  const getNonEmptyCategories = async () => {
    if (!auth) {
      history.push(USER_LOGIN);
      return null;
    }

    if (nonEmptyCategories) {
      return nonEmptyCategories;
    }

    const articles = await getArticles();
    const _nonEmptyCategories = articles ? Object.keys(articles) : [];
    if (handleNonEmptyCategories) {
      handleNonEmptyCategories(_nonEmptyCategories);
    }
    return _nonEmptyCategories;
  };

  const getArticle = async (id: string): Promise<MergedArticle> => {
    const res = await getContentApi(`${API_GET_ARTICLE}?articleId=${id}`);
    const json = await res.json();
    return json;
  };

  const getSymptoms = async (): Promise<Array<Symptom>> => {
    if (symptoms.length) {
      return symptoms;
    }
    const res = await getContentApi(API_GET_SYMPTOMS);
    const json = await res.json();
    if (setSymptoms && res.status === 200) {
      setSymptoms(json);
    }
    return json;
  };

  const getSymptomCategories = async (): Promise<Array<SymptomCategory>> => {
    if (symptomCategories.length) {
      return symptomCategories;
    }
    const res = await getContentApi(API_GET_SYMPTOM_CATEGORIES);
    const json = await res.json();
    if (setSymptomCategories && res.status === 200) {
      setSymptomCategories(json);
    }
    return json;
  };

  const getArticlesBySymptomId = async (
    id: number
  ): Promise<Array<Article>> => {
    const res = await getContentApi(
      `${API_GET_SYMPTOM_ARTICLES_BY_ID}?symptomId=${id}`
    );
    const json = await res.json();
    return json;
  };

  return {
    getMemberMetadata,
    getDiagnosisTree,
    getUserTypes,
    getArticles,
    getWelcomeData,
    getCategories,
    getNonEmptyCategories,
    getCategory,
    getArticle,
    getMergedArticles,
    searchArticlesByKeyword,
    searchAutoComplete,
    getSymptoms,
    getSymptomCategories,
    getArticlesBySymptomId,
    getStories,
  };
};
