import * as React from "react";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "~/hooks/useAnalytics";
import { HpContext } from "~/providers/HpProvider";
import { HP_AUTH_LOGGED_IN } from "~/utils/config";
import { HP_LOGIN, HP_STEP_3 } from "~/utils/constants";

const step = {
  sequence: 3,
  label: "step2",
};

export const withHpStep2 = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<P & Props> => ({ name = "HpStep2", ...props }: Props) => {
  Component.displayName = name;
  const { hpAuthStatus, handleBack } = React.useContext(HpContext);
  const history = useHistory();
  const { trackFormStepComplete, trackFormStepView, trackFormInteraction } = useAnalytics();
  const [accept, setAccept] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (hpAuthStatus === HP_AUTH_LOGGED_IN) {
      trackFormStepView({
        group: "hpReferral",
        step,
      });
    } else {
      trackFormInteraction({
        event: "complete",
        group: "hpReferral",
        step,
      });
      history.push(HP_LOGIN);
    }
  }, []);

  const handleAccept = () => setAccept((prev) => !prev);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    trackFormStepComplete({
      parentGroup: "hpReferral",
      step,
    });
    history.push(HP_STEP_3);
  };

  return (
    <Component
      { ...props as P }
      handleAccept={handleAccept}
      handleSubmit={handleSubmit}
      handleBack={handleBack}
      accept={accept}
    />
  );
};

type Props = {
  name?: string
}
