import { Link } from "react-router-dom";
import tw, { css, styled } from "twin.macro";
import { Small } from "../Styled";
import Icon from "~/../assets/icons/question.svg";

const styles = {
  height: {
    none: tw``,
    card: tw`min-h-card`,
  },
};

export const CardLink = styled(Link)(({
  height = "none",
  image,
}: Props) => [
  css`
    scroll-snap-align: center;
    ${image ? css`
      background-image: url("${image}");
      &::before {
        content: "";
        ${tw`bg-pink absolute w-full h-full inset-0 opacity-80 transition-opacity`}
      }
      &::hover::before {
        ${tw`opacity-60`}
      }
    `  : ""}
  `,
  tw`bg-white bg-center bg-cover rounded-sm overflow-hidden block p-4 pt-10 shadow-pink hover:shadow-pink-md transition-shadow relative w-full`,
  styles.height[height],
]);

export const ReadMoreWrapper = tw.p`flex items-center text-pink text-xs underline absolute bottom-4 inset-x-4`;
export const ReadMore = tw.span`flex-1 transition opacity-0 group-hover:opacity-100`;
export const ProgressBar = tw.div`absolute bottom-0 right-0 left-0 bg-blue-dusty h-1.25`;
export const BookMarkButton = styled.button(({ active }: { active: boolean }) => [
  tw`absolute top-0 right-4 text-pink transition-opacity focus:outline-none`,
  ...(active ? [tw`opacity-100 hover:opacity-20`] : [tw`opacity-20 hover:opacity-100`]),
]);

export const Progress = styled.div(({ progress }: { progress: number }) => [
  css`width: ${progress * 100}%;`,
  tw`bg-pink h-1.25`,
]);

export const VideoContent = styled.div(() => [tw`absolute inset-0 z-10 px-4 pb-4 pt-10`]);
export const IconWrapper = tw.div`flex justify-center`;
export const StyledSmall = styled(Small)(() => [tw`absolute bottom-4 inset-x-4`]);
export const QuestionIcon = styled(Icon)(() => [tw`absolute top-2`]);
type Props = {
  height?: "none" | "card"
  image?: string
}
