import * as React from "react";
import { useHistory } from "react-router-dom";
import ArrowLeft from "~/../assets/icons/arrow-left.svg";
import { BackButton, Sticky } from "./styledAppHeader";

export const AppHeader: React.FunctionComponent<AppHeaderProps> = ({ back, backText }) => {
  const history = useHistory();

  const handleBack = React.useCallback(() => {
    if (back === "back") {
      history.goBack();
    } else if (back) {
      history.push(back);
    }
  }, [back]);

  return back ? (
    <Sticky>
      <BackButton
        onClick={handleBack}
        aria-label="Cancel"
      >
        <ArrowLeft
          width="12"
          height="12"
        />{backText ? backText : "Back"}
      </BackButton>
    </Sticky>
  ) : null;
};

export type AppHeaderProps = {
  back?: string;
  backText?: string;
};
