import * as React from "react";

import * as Utils from "~/utils";
import * as Components from "~/components";

export const NotFound: React.FunctionComponent<NotFoundProps> = () => {
  return (
    <Components.PublicLayout>
      <Components.Meta
        title="404 Page Not Found"
      />
      <Components.TextVariation
        variation="heading1"
      >
        {"404 Page Not Found"}
      </Components.TextVariation>
      <Components.P>
        {"We are sorry but the page you are looking for does not exist."}
      </Components.P>
      <Components.StyledLink
        weight="normal"
        padding="none"
        to={Utils.USER_DASHBOARD}
      >
        {"Home"}
      </Components.StyledLink>
    </Components.PublicLayout>
  );
};

export type NotFoundProps = unknown;
