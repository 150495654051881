import tw, { css, styled } from "twin.macro";

export const BackButton = styled.button(() => [
  tw`inline-flex select-none text-center max-w-full outline-none focus:outline-none transition-all duration-200 items-center rounded font-sans text-sm tracking-tight text-center outline-none py-3 px-6 leading-5 active:shadow-lg bg-white text-pink shadow hover:bg-pink-light hover:shadow-md`,
  css`
    svg {
      ${tw`mr-1`}
    }
    * {
      ${tw`pointer-events-none`}
    }
  `,
]);

export const Sticky = styled.div(() => [
  tw`sticky top-0 -mt-9 py-9 z-20`,
]);
