import React from "react";

import * as Components from "~/components";

export const PublicFooter: React.FunctionComponent = () => (
  <Components.Container maxWidth="md">
    <Components.Row spacing="ogxxs">
      <Components.Column justify="center">
        <Components.TextVariation
          variation="paragraph1bold"
          color="blueNavy"
          align="center"
        >
          {
            "If you can't find the information you're looking for or need further assistance, please contact the BCNA Helpline on"
          }
        </Components.TextVariation>
      </Components.Column>
    </Components.Row>
    <Components.Row spacing="ogxxs">
      <Components.Column justify="center">
        <Components.TextVariation
          variation="headingdisplayshrink"
          color="pink"
          align="center"
        >
          <Components.TextLink
            textVariation="none"
            href="tel:1800500258"
            target="_blank"
          >
            {"1800 500 258"}
          </Components.TextLink>
        </Components.TextVariation>
      </Components.Column>
    </Components.Row>
    <Components.Row spacing="ogxxs">
      <Components.Column justify="center">
        <Components.TextVariation
          variation="paragraph1bold"
          color="blueNavy"
          align="center"
        >
          {"You can also contact us here"}
        </Components.TextVariation>
      </Components.Column>
    </Components.Row>
    <Components.Row spacing="ogxxs">
      <Components.Column justify="center">
        <Components.TextVariation
          variation="headingdisplayshrink"
          color="pink"
          align="center"
        >
          <Components.TextLink
            textVariation="none"
            href="https://www.bcna.org.au/get-in-touch/"
            target="_blank"
          >
            {"Get in touch"}
          </Components.TextLink>
        </Components.TextVariation>
      </Components.Column>
    </Components.Row>
    <Components.HorizontalRule />
    <Components.Row spacing="ogxxs">
      <Components.Column justify="center">
        <Components.TextVariation
          variation="paragraph1bold"
          color="blueNavy"
          align="center"
        >
          {
            "BCNA acknowledges the traditional custodians of country throughout Australia and their connections to land, sea and community. We pay respect to their elders past and present and extend that respect to all First Peoples today."
          }
          <br />
          <br />
          {"BCNA is committed to providing support for all our communities"}
        </Components.TextVariation>
      </Components.Column>
    </Components.Row>
    <Components.Row spacing="ogxxs">
      <Components.Column justify="center">
        <Components.CommunityFlags />
      </Components.Column>
    </Components.Row>
    <Components.Row>
      <Components.BuildVersion />
    </Components.Row>
  </Components.Container>
);
