import * as React from "react";
import tw, { styled } from "twin.macro";

import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";

import * as Hooks from "~/hooks";
import * as Components from "~/components";

const StyledWrapperNegative = styled.div(() => [
  tw`-mx-og4 md:-mx-og12`,
]);

export const Stories: React.FunctionComponent<StoriesProps> = () => {
  Hooks.useAuthentication();
  const userTypeHook = Hooks.useUserTypes();
  const contentApiHook = Hooks.useContentApi();

  const [userTypeStories, setUserTypeStories] = React.useState<Array<{
    label: string;
    stories: Array<MergedArticle>
  }>>([]);

  React.useEffect(() => {
    if (userTypeHook.selectedUserTypes) {
      (async() => {
        const content = await Promise.all(userTypeHook.selectedUserTypes.map(async (itemUserTypeId) => ({
          label: userTypeHook.userTypeData.find((itemUserType) => itemUserType.value === itemUserTypeId)?.label ?? "",
          stories: await contentApiHook.getStories(itemUserTypeId),
        })));

        setUserTypeStories(content.filter((item) => item.stories.length > 0));
      })();
    }
  }, [userTypeHook.selectedUserTypes.sort().join()]);


  return (
    <Components.AppLayout
      headerVariation="navigationHome"
    >
      <Components.Meta
        title="Stories"
      />
      <Components.TextVariation
        variation="heading1"
        spacing="og1"
        fullWidth
      >
        {"My Journey stories"}
      </Components.TextVariation>
      <Components.TextVariation
        variation="heading3"
        spacing="og6"
        fullWidth
      >
        {"Hear about the experiences of:"}
      </Components.TextVariation>
      <Components.Box
        spacing="og4"
        fullWidth
      >
          <Components.TextVariation
            variation="heading4"
            color="blueNavy"
            fullWidth
          >
            General
          </Components.TextVariation>
          <StyledWrapperNegative>
            <Components.HorizontalSlider
              itemWidth={75}
              hideNavigation
            >
              {[
                {
                  label: "Welcome aboard My Journey",
                  title:
                    "Start using My Journey for articles, research, references, and more",
                  duration: "2:58",
                  videoUrl: "https://youtu.be/1CBrsTP7_80",
                },
                {
                  label: "What is My Journey?",
                  title:
                    "Discover My Journey and how easy it is to start using",
                  duration: "2:14",
                  videoUrl: "https://youtu.be/dibJqL6rQoM",
                },
                {
                  title: "Communicating with family and friends",
                  videoUrl: "https://youtu.be/Aq9I1gfjRns",
                },
                {
                  title: "Getting through treatment for breast cancer",
                  videoUrl: "https://youtu.be/kGTHz4jZzfw",
                },
                {
                  title: "Other ways to manage anxiety",
                  videoUrl: "https://youtu.be/_wntEa0vpZk",
                },
              ].map((itemUserTypeStory, index) => (
                <Components.Video
                  key={index}
                  title={itemUserTypeStory.title}
                  videoUrl={itemUserTypeStory.videoUrl ?? ""}
                />
              ))}
            </Components.HorizontalSlider>
          </StyledWrapperNegative>
        </Components.Box>
      {userTypeStories.length > 0 && userTypeStories.map((itemUserType) => (
        <Components.Box
          key={itemUserType.label}
          spacing="og4"
          fullWidth
        >
          <Components.TextVariation
            variation="heading4"
            color="blueNavy"
            fullWidth
          >
            {itemUserType.label}
          </Components.TextVariation>
          <StyledWrapperNegative>
            <Components.HorizontalSlider
              itemWidth={75}
              hideNavigation
            >
              {itemUserType.stories.map((itemUserTypeStory, index) => (
                <Components.Video
                  key={index}
                  title={itemUserTypeStory.name}
                  videoUrl={itemUserTypeStory.videoUrl ?? ""}
                />
              ))}
            </Components.HorizontalSlider>
          </StyledWrapperNegative>
        </Components.Box>
      ))}
      
    </Components.AppLayout>
  );
};

export type StoriesProps = unknown;
