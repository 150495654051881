import * as React from "react";
import { CloseButton, Content, Header, Inner, ModalBackground, Overlay, OverlayTitle} from "./styledDeviceTrustModal";
import CloseIcon from "~/../assets/icons/cross.svg";

export const DeviceTrustModal: React.FC<Props> = ({ children, active, handleClose, deviceInfo}) => (
<Overlay
  active={active}
>
  <ModalBackground
    active={active}
  />
  <Inner>
    <Header>
    <OverlayTitle>
      {!deviceInfo && (
        <>{"You are signing in from an unknown device, do you want to trust this device?"}</>
      )}
    </OverlayTitle>
      <CloseButton
        onClick={handleClose}
      >
        <CloseIcon
          width="20"
          height="20"
          viewBox="0 0 24 24"
        /> 
      </CloseButton>
    </Header>
    <Content>
      {children}
    </Content>
  </Inner>
</Overlay>
);

type Props = {
  active: boolean
  deviceInfo: string | null | undefined
  handleClose: () => void
}
