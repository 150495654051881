import { StackedContent } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { ArticleQuotes } from "~/components/ArticleQuotes/ArticleQuotes";

export const Quotes: React.FC<Props> = ({ quotesSelected }) => (
  <ArticleQuotes
    title="Hear from others with similar experiences"
    data={quotesSelected.map(({ author, copy }) => ({ author, quote: copy }))}
  />
);

type Props = StackedContent.Quotes
