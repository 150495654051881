import * as React from "react";
import { useHistory } from "react-router-dom";

import * as Utils from "~/utils";
import * as Providers from "~/providers";
import * as Hooks from "~/hooks";
import * as Components from "~/components";
import useGetMetastaticBreastCancerDiagnosisId from "~/hooks/useGetMetastaticBreastCancerDiagnosisId";

const step = {
  sequence: 4,
  label: "step3",
};

export const withHpStep3 =
  <P extends Record<string, unknown>>(
    Component: React.ComponentType<P>
  ): React.FC<P & Props> =>
  ({ name = "HpStep3", ...props }: Props) => {
    Component.displayName = name;
    const {
      hpAuthStatus,
      setPatientInfo,
      patientInfo,
      diagnosisTree,
      handleBack,
    } = React.useContext(Providers.HpContext);
    const history = useHistory();
    const { trackFormStepComplete, trackFormStepView, trackFormInteraction } =
      Hooks.useAnalytics();

    const [firstNameValid, setFirstNameValid] = React.useState<boolean>(
      !!patientInfo?.firstName
    );
    const [firstNameError, setFirstNameError] = React.useState<
      React.FC | string | null
    >(null);
    const [firstName, setFirstName] = React.useState<string | undefined>(
      patientInfo?.firstName || ""
    );
    const [lastNameValid, setLastNameValid] = React.useState<boolean>(
      !!patientInfo?.lastName
    );
    const [lastNameError, setLastNameError] = React.useState<
      React.FC | string | null
    >(null);
    const [lastName, setLastName] = React.useState<string | undefined>(
      patientInfo?.lastName
    );
    const [diagnosisValid, setDiagnosisValid] = React.useState<boolean>(
      !!patientInfo?.diagnosis
    );
    const [emailValid, setEmailValid] = React.useState<boolean>(
      !!patientInfo?.email
    );
    const [emailValidating, setEmailValidating] =
      React.useState<boolean>(false);
    const [emailError, setEmailError] = React.useState<
      React.FC | string | null
    >(null);
    const [email, setEmail] = React.useState<string | undefined>(
      patientInfo?.email
    );
    const [phoneValid, setPhoneValid] = React.useState<boolean>(false);
    const [phoneValidating] = React.useState<boolean>(false);
    const [phoneError, setPhoneError] = React.useState<
      React.FC | string | null
    >(null);
    const [phone, setPhone] = React.useState<string | undefined>(
      patientInfo?.mobile
    );
    const [diagnosisError, setDiagnosisError] = React.useState<
      React.FC | string | null
    >(null);
    const [diagnosisDateError, setDiagnosisDateError] = React.useState<
      React.FC | string | null
    >(null);
    const [diagnosisDateValid, setDiagnosisDateValid] = React.useState<boolean>(
      !!patientInfo?.diagnosisDate
    );
    const [diagnosisDate, setDiagnosisDate] = React.useState<Date | undefined>(
      patientInfo?.diagnosisDate
        ? new Date(patientInfo?.diagnosisDate)
        : undefined
    );
    const [accept, setAccept] = React.useState<boolean>(
      patientInfo?.activationNotification || false
    );
    const [diagnosis, setDiagnosis] = React.useState<string | undefined>(
      patientInfo?.diagnosis || ""
    );

    const { metastaticDiagnosisId } = useGetMetastaticBreastCancerDiagnosisId();

    React.useEffect(() => {
      if (hpAuthStatus === Utils.HP_AUTH_LOGGED_IN) {
        trackFormStepView({
          group: "hpReferral",
          step,
        });
      } else {
        trackFormInteraction({
          event: "complete",
          group: "hpReferral",
          step,
        });
        history.push(Utils.HP_LOGIN);
      }
    }, []);

    const handleAccept = () => setAccept((prev) => !prev);
    const handleDiagnosis = (value: string) => {
      setDiagnosis(value);
      if (value === "") {
        setDiagnosisError("Required");
      } else {
        setDiagnosisError(null);
        setDiagnosisValid(true);
      }
      setDiagnosis(value);
    };
    const handleDiagnosisDate = (date: Date) => {
      setDiagnosisDate(date);
      if (!date) {
        setDiagnosisDateError("Required");
      } else {
        setDiagnosisDateError(null);
        setDiagnosisDateValid(true);
      }
    };
    const handleFirstName = (value: string) => {
      setFirstName(value);
      if (value === "") {
        setFirstNameValid(false);
        setFirstNameError("Required");
      } else if (!Utils.validName(value)) {
        setFirstNameValid(false);
        setFirstNameError("Invalid first name");
      } else {
        setFirstNameValid(true);
        setFirstNameError(null);
      }
    };
    const handleLastName = (value: string) => {
      setLastName(value);
      if (value === "") {
        setLastNameValid(false);
        setLastNameError("Required");
      } else if (!Utils.validName(value)) {
        setLastNameValid(false);
        setLastNameError("Invalid first name");
      } else {
        setLastNameValid(true);
        setLastNameError(null);
      }
    };

    const handleEmail = async (value: string) => {
      setEmail(value);
      if (emailValidating) {
        setEmailValidating(false);
      }
      if (value.length > 5) {
        if (!Utils.validEmail(value)) {
          setEmailValid(false);
          setEmailError(Components.EmailError);
        } else {
          setEmailValid(true);
          setEmailError(null);
        }
      }
    };

    const handlePhone = async (value: string) => {
      const parsedValue = Utils.getFormattedPhoneOrEmail(value);

      setPhone(parsedValue);
      if (parsedValue === "" && email === "") {
        setEmailValid(false);
        setEmailError("At least one of email or mobile required");
      } else if (!Utils.validPhone(parsedValue)) {
        setPhoneValid(false);
        const error = Utils.invalidDescriptionPhone(parsedValue);
        setPhoneError(error);
      } else {
        setPhoneValid(true);
        setPhoneError(null);
      }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (email === "" && phone === "") {
        setEmailValid(false);
        setEmailError("At least one of email or mobile required");
        return;
      }

      trackFormStepComplete({
        parentGroup: "hpReferral",
        step,
      });

      if (diagnosisTree) {
        const diagnosisName = diagnosisTree.find(
          ({ id }) => `${id}` === diagnosis
        )?.hpReferralTitle;
        if (setPatientInfo) {
          setPatientInfo((prev) => ({
            ...prev,
            firstName,
            lastName,
            mobile: phoneValid ? phone : "",
            diagnosis,
            diagnosisName,
            diagnosisDate,
            email,
            activationNotification: accept,
          }));
        }
        history.push(Utils.HP_STEP_4);
      } else {
        throw new Error("Diagnosis tree is undefined");
      }
    };

    const staticDiagnosis = [
      {
        label: "DCIS",
        value: "1179",
      },
      {
        label: "Early breast cancer",
        value: "1089",
      },
      {
        label: "Metastatic breast cancer",
        value: metastaticDiagnosisId.toString() || "1094",
      },
    ];

    return (
      <Component
        {...(props as P)}
        handleAccept={handleAccept}
        handleSubmit={handleSubmit}
        handleBack={handleBack}
        handleFirstName={handleFirstName}
        handleLastName={handleLastName}
        handleEmail={handleEmail}
        handlePhone={handlePhone}
        handleDiagnosis={handleDiagnosis}
        handleDiagnosisDate={handleDiagnosisDate}
        accept={accept}
        diagnosis={diagnosis}
        diagnosisTree={diagnosisTree}
        firstNameError={firstNameError}
        firstNameValid={firstNameValid}
        lastNameError={lastNameError}
        lastNameValid={lastNameValid}
        emailError={emailError}
        emailValid={emailValid}
        emailValidating={emailValidating}
        phoneError={phoneError}
        phoneValid={phoneValid}
        phoneValidating={phoneValidating}
        diagnosisError={diagnosisError}
        diagnosisValid={diagnosisValid}
        diagnosisDateError={diagnosisDateError}
        diagnosisDateValid={diagnosisDateValid}
        firstName={firstName}
        lastName={lastName}
        diagnosisDate={diagnosisDate}
        email={email}
        phone={phone}
        staticDiagnosis={staticDiagnosis}
      />
    );
  };

type Props = {
  name?: string;
};
