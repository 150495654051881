import { Column, Row, RadioWrapper, RadioLabel, RadioCircle, HiddenCheckbox, RadioCheckbox, RadioTextWrapper } from "../Styled";
import { RadioData, withRadio } from "./withRadio";

export const Radio = withRadio((props: Props) => (
  <Row>
    {props.data.map(({ label, value }, index) => (
      <Column
        sm="1/2"
        key={value}
      >
        <RadioWrapper>
          <HiddenCheckbox
            type="radio"
            value={value}
            onChange={(e) => props.onChange(e.target.value)}
            onBlur={() => props.onBlur?.(index)}
            checked={props.checked === value}
          />
          <RadioCheckbox>
            <RadioCircle
              checked={props.checked === value}
            />
          </RadioCheckbox>
          <RadioTextWrapper>
            <RadioLabel>
              {label}
            </RadioLabel>
          </RadioTextWrapper>
        </RadioWrapper>
      </Column>
    ))}
  </Row>
));

type Props = {
  data: Array<RadioData>
  checked: string
  onChange: (index: string) => void;
  onBlur?: (index: number) => void
}
