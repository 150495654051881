import * as React from "react";

export const withCheckboxGroup = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "CheckboxGroup", ...props }: Props) => {
  Component.displayName = name;
  return (
    <Component
      { ...props as P & Props }
    />
  );
};

type Props = {
  label?: JSX.Element | string;
  name?: string
  data: Array<CheckboxGroupData>
  checked: Array<string>
  onChange: (index: string) => void
  onBlur?: (index: number) => void
}

export type CheckboxGroupData = {
  label: string
  description?: string
  value: string
}
