import * as React from "react";
import tw, { styled, css } from "twin.macro";
import { HiddenCheckbox } from "../Styled/Form";

import * as Components from "~/components";

const StyledWrapper = styled.div(() => [
  tw`-mt-4`,
]);

const StyledGraphicItem = styled.label(() => [
  tw`flex flex-col items-center relative`,
]);

const StyledGraphicImage = styled.img(() => [
  tw`w-full h-full rounded-full p-2`,
]);

const StyledGraphicIndicator = styled.div(() => [
  tw`absolute w-8 h-8 rounded-full flex items-center justify-center border border-pink-dusty bg-white`,
  css`
    z-index: 2;
    top: .75rem;
    right: .75rem;
  `,
]);

export const StyledGraphicTick = styled.span(({ checked }: { checked: boolean; }) => [
  tw`border border-t-0 border-l-0 border-2 border-pink w-1.5 h-3.5 transform rotate-45 -translate-y-0.5`,
  ...(checked ? [tw`opacity-100 visible`] : [tw`opacity-0 invisible`]),
]);

const StyledGraphicLabel = styled.div(() => [
  tw`text-center`,
]);

export const RadioGroupGraphic: React.FunctionComponent<RadioGroupGraphicProps> =(props) => {
  return (
    <StyledWrapper>
      <Components.HorizontalSlider
        itemWidth={40}
        dynamicWidth
      >
        {props.data.map((item, index) => (
          <StyledGraphicItem
            key={item.value}
          >
            <HiddenCheckbox
              type="radio"
              value={item.value}
              onChange={(e) => props.onChange(e.target.value)}
              onBlur={() => props.onBlur?.(index)}
              checked={props.checked === item.value}
            />
            <StyledGraphicIndicator>
              <StyledGraphicTick
                checked={props.checked.includes(item.value)}
              />
            </StyledGraphicIndicator>
            <StyledGraphicImage
              src={item.image}
            />
            <StyledGraphicLabel>
              <Components.TextVariation
                variation="label2"
                color="greyMedium"
                fullWidth
              >
                {item.label}
              </Components.TextVariation>
            </StyledGraphicLabel>
          </StyledGraphicItem>
        ))}
      </Components.HorizontalSlider>
    </StyledWrapper>
  );
};

export type RadioGroupGraphicProps = {
  data: Array<{
    label: string;
    image: string;
    value: string;
  }>;
  checked: string | Array<string>;
  onChange: (index: string) => void;
  onBlur?: (index: number) => void;
  showDescription?: boolean;
};
