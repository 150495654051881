import tw, { styled } from "twin.macro";

export const StepWrapper = tw.div`relative  border-t-2 border-pink-dusty h-0 overflow-visible flex justify-between items-center mt-18 mb-24 md:(mx-4 mb-12)`;

export const IconWrapper = styled.div(({ active, completed }: Active) => [
  tw`z-10 h-12 w-12 md:(h-18 w-18) border-2 rounded-full text-pink flex items-center justify-center bg-white transition-width`,
  ...(active ? [tw`border-pink`] : [tw`border-pink-dusty`]),
  ...(completed ? [tw`border-pink bg-pink text-white`] : [tw``]),
]);

const widths = [tw`w-0`, tw`w-1/3`, tw`w-2/3`, tw`w-full`];

export const ProgressBar = styled.div(({ progress }: Progress) => [
  tw`h-0.5 bg-pink absolute -top-0.5 transition-width duration-500`,
  widths[progress],
]);

export const TextWrapper = tw.div`hidden md:flex justify-between mb-15`;

export const Text = styled.p(({ active }: Active) => [
  ...(active ? [tw`font-bold`] : []),
  tw`text-blue-navy w-24 text-center leading-5`,
]);

type Active = {
  active: boolean
  completed: boolean
}

type Progress = {
  progress: number
}
