import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";
import { Colours, Directions, Global, Items, Justifys, Selfs, Shadows, Spacings } from "./Global";

const styles = {
  ...Global,
  padding: {
    default: tw`p-8`,
    sm: tw`py-10 px-4`,
    md: tw`p-4`,
  },
  buttonPadding: {
    default: tw`p-4`,
  },
};

export const CardLink = styled(Link)(({
  items = "center",
  self = "none",
  justify = "none",
  padding = "default",
  direction = "none",
  background = "pinkLight",
  shadow = "none",
}: Props) => [
  tw`text-center text-blue-navy rounded-md w-full flex hover:(shadow bg-white) h-40 lg:h-52 relative`,
  styles.shadow[shadow],
  styles.background[background],
  styles.items[items],
  styles.self[self],
  styles.justify[justify],
  styles.padding[padding],
  styles.direction[direction],
]);

export const CardButton = styled.button(({
  padding = "default",
  background = "pinkLight",
  colour = "blueNavy",
  spacing = "none",
  shadow = "none",
}: ButtonProps) => [
  tw`text-center rounded-md w-full hover:shadow hover:bg-white relative focus:outline-none`,
  styles.background[background],
  styles.colour[colour],
  styles.buttonPadding[padding],
  styles.spacing[spacing],
  styles.shadow[shadow],
]);

export const SecondaryCardButton = styled.button(({
  padding = "default",
  background = "white",
  colour = "pink",
}: ButtonProps) => [
  tw`text-center rounded-md shadow hover:(bg-white shadow-md) relative focus:outline-none`,
  styles.background[background],
  styles.colour[colour],
  styles.buttonPadding[padding],
]);

type Props = {
  direction?: Directions
  items?: Items
  self?: Selfs
  justify?: Justifys
  padding?: "default" | "sm" | "md"
  background?: Colours
  shadow?: Shadows
}

type ButtonProps = {
  padding?: "default"
  background?: Colours
  colour?: Colours
  spacing?: Spacings
  shadow?: Shadows
}
