import * as React from "react";

import * as Hooks from "~/hooks";
import * as Components from "~/components";
import * as Styled from "./styledSearchForm";

import MagnifyingGlassIcon from "~/../assets/icons/magnifying-glass.svg";

export const SearchForm: React.FC<SearchFormProps> = () => {
  const { onSubmit, onChangeKeyword, onClickSuggestion, keywordInput, suggestions } = Hooks.useSearch();

  return (
    <Styled.SearchForm
      onSubmit={onSubmit}
    >
      <Components.FieldWrapper>
        <Components.StyledSearchInput
          type="text"
          value={keywordInput}
          placeholder="Search articles..."
          onChange={(e) => onChangeKeyword(e.target.value)}
          aria-label="Search"
        />
        <Components.IconWrapper
          colour="pink"
        >
          <MagnifyingGlassIcon
            width="17"
            height="17"
          />
        </Components.IconWrapper>
      </Components.FieldWrapper>
      <Styled.SuggestionList
        active={Boolean(suggestions.length)}
      >
        {suggestions.slice(0, 5).map((suggestion) => (
          <Styled.SuggestionListItem
            key={suggestion}
          >
            <Styled.Suggestion
              onClick={() => onClickSuggestion(suggestion)}
            >{suggestion}</Styled.Suggestion>
          </Styled.SuggestionListItem>
        ))}
      </Styled.SuggestionList>
    </Styled.SearchForm>
  );
};

export type SearchFormProps = unknown;
