import * as React from "react";

export const withGlossaryAccordion = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "GlossaryAccordion", active = [], items: list, single, ...props }: Props) => {
  Component.displayName = name;

  const [isActive, setActive] = React.useState<Array<number>>(active);

  const handleActive = (index: number) => setActive((prev) => {
    const i = prev.indexOf(index);
      if (single) {
        if (i > -1) {
          return [];
        }
        return [index];
      }
      if (i > -1) {
        return prev.filter((f) => f !== index);
      }
      return [...prev, index];
  });

  const items = list?.map((item, index) => ({
    ...item,
    active: isActive.includes(index),
  }));

  return (
    <Component
      { ...props as P }
      handleActive={handleActive}
      items={items}
    />
  );
};

type Props = {
  name?: string
  active?: Array<number>
  items: Array<GlossaryAccordionItem>
  /** allow only one item to be open at a time */
  single?: boolean
}

export type GlossaryAccordionItem = {
  active?: boolean
  content: React.ReactNode;
  label: string
}
