import { HP_STEP_1 } from "~/utils/constants";
import { withHpStep5 } from "./withHpStep5";
import imageSMS from "~/../assets/images/phone-sms.png";
import { Image } from "./styledHpStep5";
import { ContentWrapper } from "../styledHp";

import * as Components from "~/components";

export const HpStep5 = withHpStep5(() => (
  <>
    <ContentWrapper>
      <Components.Row
        spacing="none"
      >
        <Components.Column
          spacing="sm"
        >
          <Components.TextVariation
            variation="heading1"
            spacing="og1"
            align="center"
          >
            {"Thank you!"}
          </Components.TextVariation>
          <Components.TextContainer
            align="center"
          >
            <Components.TextVariation
              variation="paragraph2"
            >
              {"Your patient will receive an SMS and/or email message with a link to activate their account. The message or email will look something like the image below."}
            </Components.TextVariation>
          </Components.TextContainer>
        </Components.Column>
        <Components.Column
          justify="center"
          spacing="none"
        >
          <Components.ButtonVariation
            variation="primary"
            href={HP_STEP_1}
            fullWidth
          >
            {"Register another patient"}
          </Components.ButtonVariation>
        </Components.Column>
      </Components.Row>
    </ContentWrapper>
    <Components.Column
      justify="center"
    >
      <Image
        src={imageSMS}
        alt="Phone showing SMS message recieved from My Journey"
      />

    </Components.Column>
  </>
));
