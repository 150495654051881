import * as React from "react";

import * as Hooks from "~/hooks";
import * as Components from "~/components";

export const ArticlesSearch: React.FunctionComponent<ArticlesSearchProps> = () => {
  Hooks.useAuthentication();
  const searchProps = Hooks.useSearch();

  return (
    <Components.AppLayout
      headerVariation="navigationHome"
    >
      <Components.Row
        gutter="md"
      >
        <Components.Column
          spacing="xs"
        >
          <Components.SearchForm />
        </Components.Column>
        <Components.Column
          spacing="md"
        >
          {searchProps.loading ? (
            <Components.Card
              color="pinkMedium"
              padding="md"
            >
              <Components.H2>
                Processing your query...
              </Components.H2>
            </Components.Card>
          ) : (
            <Components.Card
              color="pinkMedium"
              padding="md"
            >
              <Components.H2>
                Search results ({searchProps.results.length})
              </Components.H2>
              {searchProps.results.length > 0 ? (
                <React.Fragment>
                  <Components.P>
                    {"Your search for '"}{searchProps.keywordSearch}{"' has returned "}{searchProps.results.length}{" results"}
                  </Components.P>
                  <Components.HorizontalRule />
                </React.Fragment>
              ) : (
                <Components.P>
                  {"We're sorry, no results were found for '"}{searchProps.keywordSearch}{"' please try a new search or contact the BCNA Helpline "}
                  <Components.StyledHyperlink
                    href="tel:1800500258"
                  >
                    {"1800 500 258"}
                  </Components.StyledHyperlink>
                  {" for assistance."}
                </Components.P>
              )}
              <Components.ArticleList
                articles={searchProps.results}
                onClickArticle={(article) => searchProps.onClickResult(article)}
              />
            </Components.Card>
          )}
        </Components.Column>
        <Components.Column>
          <Components.Card
            color="blueDusty"
            padding="md"
          >
            <Components.P
              spacing="none"
              leading="md"
            >
              {"Can't find what you're looking for? Updating your diagnosis can return more results. Please read "}
              <Components.StyledHyperlink
                href="/article/3022"
              >
                {"this"}
              </Components.StyledHyperlink>
              {" for more tips"}
            </Components.P>
          </Components.Card>
        </Components.Column>
        <Components.Meta
          title={`${searchProps.keywordSearch ? `${searchProps.keywordSearch} | ` : ""}Article Search`}
        />
      </Components.Row>
    </Components.AppLayout>
  );
};

export type ArticlesSearchProps = unknown;
