import * as React from "react";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "~/hooks/useAnalytics";
import { HpContext } from "~/providers/HpProvider";
import { HP_AUTH_LOGGED_IN } from "~/utils/config";
import { HP_LOGIN, HP_STEP_2 } from "~/utils/constants";

export const withHpStep1 = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<P & Props> => ({ name = "HpStep1", ...props }: Props) => {
  Component.displayName = name;
  const { hpAuthStatus, setPatientInfo } = React.useContext(HpContext);
  const history = useHistory();
  const { trackFormStepComplete, trackFormStepView, trackFormInteraction } = useAnalytics();
  const [noEmail, setNoEmail] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (hpAuthStatus === HP_AUTH_LOGGED_IN) {
      if (setPatientInfo) {
        setPatientInfo({});
      }

      trackFormStepView({
        group: "hpReferral",
        step: {
          sequence: 2,
          label: "step1",
        },
      });
    } else {
      trackFormInteraction({
        event: "complete",
        group: "hpReferral",
        step: {
          sequence: 2,
          label: "step1",
        },
      });
      history.push(HP_LOGIN);
    }
  }, []);

  const handleNoEmail = () => {
    setNoEmail(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    trackFormStepComplete({
      parentGroup: "hpReferral",
      step: {
        sequence: 2,
        label: "step1",
      },
    });
    history.push(HP_STEP_2);
  };

  return (
    <Component
      { ...props as P }
      handleNoEmail={handleNoEmail}
      handleSubmit={handleSubmit}
      noEmail={noEmail}
    />
  );
};

type Props = {
  name?: string
}
