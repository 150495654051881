import * as React from "react";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Components from "~/components";

import IconPlus from "~/../assets/icons/icon-plus.svg";

export const Symptoms: React.FunctionComponent<SymptomsProps> = () => {
  Hooks.useAuthentication();

  const hookDateRange = Hooks.useDateRange();
  const hookSymptoms = Hooks.useSymptoms({
    dateRange: hookDateRange.values[Hooks.DATE_RANGE.ALL_TIME],
  });
  const [selectedDateRange, setSelectedDateRange] =
    React.useState<Hooks.DATE_RANGE>(Hooks.DATE_RANGE.DEFAULT);

  return (
    <Components.AppLayout headerVariation="navigationHome">
      <Components.Meta title="Tracker" />
      <Components.TextVariation variation="heading1" spacing="og1">
        {"My tracker"}
      </Components.TextVariation>
      <Components.TextVariation variation="heading3" spacing="og4">
        {"How are you feeling today?"}
      </Components.TextVariation>
      <Components.Box spacing="og4">
        <Components.ButtonVariation
          variation="primary"
          href={Utils.USER_SYMPTOMS_ADD}
          aria-label="Add symptom"
          iconLeft={<IconPlus />}
          fullWidth
        >
          {"Symptom"}
        </Components.ButtonVariation>
      </Components.Box>
      {hookSymptoms.symptoms.length === 0 ? (
        <Components.Card
          color="pinkMedium"
          padding="og4"
          aria-label="Symptoms card"
        >
          <Components.TextVariation variation="heading3">
            {"Add your first symptom above so you can:"}
          </Components.TextVariation>
          <Components.TextContainer>
            <Components.TextVariation variation="paragraph1" color="blueNavy">
              <ul>
                <li>{"Track your progress over time"}</li>
                <li>{"Share information with health professionals"}</li>
                <li>{"Improve your treatment plan"}</li>
              </ul>
            </Components.TextVariation>
          </Components.TextContainer>
        </Components.Card>
      ) : (
        <Components.Card
          color="pinkMedium"
          padding="og4"
          aria-label="Symptoms card"
        >
          <Components.Box spacing="og4">
            <Components.Select
              options={hookDateRange.options}
              value={selectedDateRange}
              onChange={(event) =>
                setSelectedDateRange(event.target.value as Hooks.DATE_RANGE)
              }
            />
          </Components.Box>
          {hookSymptoms.graph && (
            <Components.Box spacing="og6">
              <Components.SymptomGraphCombined
                dateRange={hookDateRange.values[selectedDateRange]}
              />
            </Components.Box>
          )}
          <Components.Box>
            <Components.TextVariation
              variation="heading3"
              spacing="og4"
              fullWidth
            >
              {"Recent symptoms"}
            </Components.TextVariation>
            <Components.SymptomList
              dateRange={hookDateRange.values[selectedDateRange]}
            />
          </Components.Box>
        </Components.Card>
      )}
    </Components.AppLayout>
  );
};

export type SymptomsProps = unknown;
