import * as React from "react";
import tw, { css, styled } from "twin.macro";

import * as Components from "~/components";

import IconCross from "~/../assets/icons/cross.svg";

export const ModalOuter = styled.div(({ active }: { active?: boolean }) => [
  tw`fixed inset-0 transition h-full w-full z-100 print:(hidden)`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0 pointer-events-none`]),
]);

export const ModalBackground = styled.div(({ active }: { active?: boolean }) => [
  tw`bg-black bg-opacity-50 fixed inset-0 w-full h-full transition duration-500`,
  ...(active ? [tw`opacity-100`] : [tw`opacity-0`]),
]);

export const ModalInner = styled.div(() => [
  tw`flex flex-col h-full items-center justify-end p-og2 md:p-og4`,
]);

export const ModalContent = styled.div(({ active }: { active?: boolean }) => [
  tw`w-full max-h-full md:max-w-screen-md bg-white rounded-sm rounded-t-3xl z-40 relative mx-auto transform transition duration-500 py-og4 pt-og8`,
  ...(active ? [tw`translate-y-0`] : [tw`translate-y-28`]),
]);

export const ModalContentHeader = styled.div(() => [
  tw`px-og4`,
]);

export const ModalContentScroll = styled.div(() => [
  tw`pt-og6 px-og4 relative overflow-y-auto`,
  css`
    height: 75vh;
    &:after {
      content: "";
      z-index: 10;
      position: fixed;
      top: 4rem;
      left: 0;
      width: 100%;
      height: 50px;
      background: linear-gradient(to bottom, #ffffff 25%, transparent 75%);
    }
  `,
]);

export const StyledCloseButton = styled.button(() => [
  tw`bg-white rounded-full flex items-center justify-center text-pink hover:bg-pink-light h-12 w-12 absolute top-0 right-0 mr-og1 mt-og1 focus:outline-none`,
]);

export const SwipeModal: React.FunctionComponent<SwipeModalProps> = (props) => (
  <ModalOuter
    active={props.active}
    className="SwipeModal"
  >
    <ModalBackground
      active={props.active}
      onClick={() => props.onClose ? props.onClose() : props.setActive ? props.setActive(false) : undefined}
    />
    <ModalInner>
      <ModalContent
        active={props.active}
      >
        {props.title && (
          <ModalContentHeader>
            <Components.TextVariation
              variation="heading1"
              fullWidth
            >
              {props.title}
            </Components.TextVariation>
            {!props.noClose ? (
              <StyledCloseButton
                type="button"
                onClick={() => props.onClose ? props.onClose() : props.setActive ? props.setActive(false) : undefined}
              >
                <IconCross />
              </StyledCloseButton>
            ) : null}
          </ModalContentHeader>
        )}
        <ModalContentScroll>
          {props.children}
        </ModalContentScroll>
      </ModalContent>
    </ModalInner>
  </ModalOuter>
);

export type SwipeModalProps = {
  title?: string;
  active?: boolean;
  noClose?: boolean;
  setActive?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
};
