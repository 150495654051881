import tw, { styled } from "twin.macro";
import { Global, Spacings, MinHeights, MaxWidths, Colours } from "./Global";

const styles = {
  ...Global,
  padding: {
    default: tw``,
    md: tw`px-4`,
    lg: tw`px-8`,
  },
};

export const Container = styled.div(({
  minHeight = "none",
  maxWidth = "default",
  spacing = "none",
  padding = "default",
  background = "none",
  flex = false,
}: Props) => [
  tw`w-full max-w-full mx-auto`,
  ...flex ? [tw`flex`] : [tw``],
  styles.minHeight[minHeight],
  styles.maxWidth[maxWidth],
  styles.spacing[spacing],
  styles.padding[padding],
  styles.background[background],
]);

type Props = {
  minHeight?: MinHeights,
  maxWidth?: MaxWidths;
  spacing?: Spacings;
  padding?: "default" | "md" | "lg";
  background?: Colours;
  flex?: boolean;
}
