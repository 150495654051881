import * as React from "react";
import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";
import { PatientContext } from "~/providers/PatientProvider";
import { usePatientApi } from "~/hooks/usePatientApi";
import { useAnalytics } from "~/hooks/useAnalytics";
import { APP_ARTICLE } from "~/utils/constants";

export const withArticleCard = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<Props> => ({ name = "ArticleCard", ...props }: Props) => {
  Component.displayName = name;

  const { auth } = React.useContext(PatientContext);

  const { patientUpdate } = usePatientApi();
  const analytics = useAnalytics();
  const bookmarkIds = auth?.patient_details.bookmark_ids || [];
  const [bookmarked, setBookmarked] = React.useState<boolean>(Boolean(bookmarkIds.find((bookmarkId) => bookmarkId === `${props?.article?.id}`)));
  const articleUrl = APP_ARTICLE.replace(":articleId", `${props.article?.id}`);

  const handleBookmark = async () => {
    if (!props.article) {
      return;
    }
    setBookmarked((prevState) => !prevState);

    if (!bookmarked) {
      await patientUpdate({
        patient_details: {
          bookmark_ids: [...auth?.patient_details.bookmark_ids ?? [], `${props.article.id}`],
        },
      });
    } else {
      await patientUpdate({
        patient_details: {
          bookmark_ids: auth?.patient_details.bookmark_ids.filter((bookmarkId) => bookmarkId !== `${props?.article?.id}`),
        },
      });
    }
  };

  const handleArticleClick = () => {
    if (props.article) {
      if (props.article.isFeatured) {
        analytics.trackRecommendedArticleClick({
          label: props.article.name,
          url: articleUrl,
          location: window.location.href,
        });
      } else {
        analytics.trackArticleClick({
          label: props.article.name,
          parentLabel: "",
          url: articleUrl,
          id: props.article.id,
          position: 0,
          value: props.article.documentTypeAlias,
        });
      }
      if (props.onClick) {
        props.onClick();
      }
    }
  };

  return (
    <Component
      { ...props as P & Props }
      articleUrl={articleUrl}
      bookmarked={bookmarked}
      handleBookmark={handleBookmark}
      handleArticleClick={handleArticleClick}
    />
  );
};

type Props = {
  name?: string;
  article?: MergedArticle;
  onClick?: () => void;
}
