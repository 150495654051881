import * as React from "react";

import * as Components from "~/components";

/**
 * Wrapper component for unauthenticated app routes
 */
export const PublicLayout: React.FunctionComponent = (props) => (
  <Components.Container
    minHeight="screen"
    maxWidth="md"
    spacing="oglg"
  >
    <Components.Card
      padding="container"
      shadow="md:default"
    >
      <Components.HeaderVariation
        variation="navigationLogin"
      />
      {props.children}
      <Components.Column>
        <Components.BuildVersion />
      </Components.Column>
    </Components.Card>
  </Components.Container>
);
