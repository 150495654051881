import tw, { styled } from "twin.macro";

import { Colours, Global } from "~/components/Styled/Global";

const styles = {
  ...Global,
};

export const ColourCaption = tw.figcaption`absolute bottom-0 inset-x-0 font-sans font-bold text-xs text-center py-1 bg-white`;

export const ColourSwatch = styled.figure(({ colour }: SwatchProps) => [
  tw`relative w-full h-32 overflow-hidden shadow mb-6 rounded`,
  styles.background[colour],
]);

type SwatchProps = {
  colour: Colours
}
