import * as React from "react";
import * as Sentry from "@sentry/react";

import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";

import { useContentApi } from "~/hooks/useContentApi";
import { useSalesforceApi } from "~/hooks/useSalesforceApi";
import { PatientContext } from "~/providers/PatientProvider";
import { validEmail } from "~/utils/validation";

export const useShareModal = ({ article, forDiagnosis, onShare }: ShareModalParams): ShareModalProps => {
  const { auth } = React.useContext(PatientContext);
  const { getUserTypes } = useContentApi();
  const { apiSendEmail } = useSalesforceApi();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [recipients, setRecipients] = React.useState<Array<{
    value: string;
    error?: JSX.Element | string | undefined;
    valid: boolean;
  }>>([{
    value: "",
    error: undefined,
    valid: false,
  }]);
  const [message, setMessage] = React.useState<string>("");
  const handleFacebook = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    FB.ui({
      method: "share",
      href: window.location.href.replace("localhost:5000", "myjourney.org.au"),
    });
  };

  const addRecipient = () => {
    setRecipients((prev) => [...prev, {
      value: "",
      error: undefined,
      valid: false,
    }]);
  };

  const removeRecipient = (key: number) => {
    setRecipients((prev) => prev.filter((_address, _key) => _key !== key));
  };

  const changeRecipient = (address: string, key: number) => {
    setRecipients((prev) => {
      const next = prev.slice();
      next[key].value = address;
      next[key].error = undefined;
      next[key].valid = false;
      return next;
    });
    validateRecipient(address, key);
  };

  const validateRecipient = (address: string, key: number) => {
    setRecipients((prev) => {
      const next = prev.slice();
      if (address.length === 0) {
        next[key].valid = false;
        next[key].error = "Required";
      } else if (!validEmail(address)) {
        next[key].valid = false;
        next[key].error = "Please enter a valid email address";
      } else {
        next[key].valid = true;
        next[key].error = undefined;
      }
      return next;
    });
  };

  const handleShare = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const userTypes = await getUserTypes();
    const relevantForObj = userTypes.find((type) => type.userTypeId === article.userType);
    const relevantFor = relevantForObj === undefined || relevantForObj.userTypeId === "1" ? "Anyone" : relevantForObj.name;

    const templateData = {
      referrerFirstName: auth?.patient_details.first_name,
      referrerLastName: auth?.patient_details.last_name,
      message,
      article: {
        title: article.name,
        intro: article.intro,
        relevantFor,
        forDiagnosis,
        url: `${window.location.origin}/article/${article.id}`,
      },
    };

    const res = await apiSendEmail({
      to: recipients.map(({ value }) => value),
      templateData,
    });

    if (res.status === 200) {
      onShare();
    } else {
      Sentry.captureException("Unable to share article");
    }

    setLoading(false);
  };

  const handleMessage = (value: string) => setMessage(value);
  const disabled = loading || Boolean(recipients.find(({ valid }) => valid === false));

  return {
    handleFacebook,
    addRecipient,
    removeRecipient,
    changeRecipient,
    validateRecipient,
    recipients,
    handleMessage,
    handleShare,
    message,
    disabled,
  };
};

type ShareModalParams = {
  article: MergedArticle
  forDiagnosis: Array<string>
  onShare: () => void;
};

export type ShareModalProps = {
  handleFacebook: (e: React.MouseEvent<HTMLButtonElement>) => void;
  addRecipient: () => void;
  removeRecipient: (key: number) => void;
  changeRecipient: (address: string, key: number) => void;
  validateRecipient: (address: string, key: number) => void;
  handleMessage: (value: string) => void;
  handleShare: (e: React.FormEvent) => Promise<void>;
  message: string;
  recipients: Array<{
    value: string;
    error?: JSX.Element | string | undefined;
    valid: boolean;
  }>;
  disabled: boolean;
};
